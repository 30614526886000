import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {HomeIcon , SettingsIcon , Analytics , Calender, UserIcon ,AnalyticsIcon, MenuIcon} from '../SvgIcon';
import {hairdresserActions, userActions} from "../../_actions";
import siteSetting from "../../config/env";
import {connect} from "react-redux";
import { FaBoxOpen } from 'react-icons/fa';
import {paymentService} from "../../_services";

const toggleMenuClass = (e) => {
    // console.log("localStorage.getItem('menu')",localStorage.getItem('menu'))
    // if(localStorage.getItem('menu')){
    //     if(localStorage.getItem('menu')===1||localStorage.getItem('menu')==="1"){
    //         localStorage.setItem('menu',0);
    //     }else{
    //         localStorage.setItem('menu',1);
    //     }
    // }else{
    //     localStorage.setItem('menu',0);
    // }
    document.body.classList.toggle('nav_menu_open')
};
const removeMenuClass = () => {
    document.body.classList.remove('nav_menu_open')
    // document.body.classList.add('setting-temps-pages')
}

const salonId = localStorage.getItem('salonId') ;

const SideNav = (props) => {
    // console.log("props",props)

    const [isCardAdded, setIsCardAdded] = useState(false);

    const logout =() =>{
        let self = this;
        let _filterState = {
            allStaffStatus :true,
            workingStaffStatus:false,
            selectedFilterName:'all_staff',
            isFilterChange:false,
            isDefaultStaff:true,
        }
        props.dispatch(hairdresserActions.activeFilterStatus(_filterState));
        if (localStorage.getItem('accessToken')) {
            if(props.user) {
                let params = {
                    userId: props.user.userInfo.userId
                }
                props.dispatch(userActions.logout(params))
                    .then(function (response) {
                        localStorage.removeItem('persist:root');
                        localStorage.clear();
                        props.history.push("/");
                    })
            }else{
                localStorage.removeItem('persist:root');
                localStorage.clear();
                props.history.push("/");
            }
        }else{
            localStorage.removeItem('persist:root');
            localStorage.clear();
            props.history.push("/");
        }
    }

    useEffect( () => {
        if (localStorage.getItem('accessToken')) {
            checkpaymentMethodAdded();
        }else{
            props.history.push('/')
        }
        document.body.classList.add('only---sidenav');
        document.body.classList.remove('menu_open')
        return () => {
            document.body.classList.remove('only---sidenav');
            document.body.classList.remove('menu_open')
        }

    }, [])

    const getSalon =() => {
        let self=this;
        let params = {}
        params.salonId = salonId;
        params.latitude =  siteSetting.default_latitude;
        params.longitude =  siteSetting.default_longitude;
        self.props.dispatch(hairdresserActions.getSalonProfile(params))
            .then(function (response) {

            })
    }

    const switchProfile=()=>{
        let self=this;
        let param={
            latitude: siteSetting.default_latitude,
            longitude:siteSetting.default_longitude,
            switchTo:0

        }
        //self.setState({dropdown:false})
        props.dispatch(hairdresserActions.hairdresserSwitchProfile(param))
            .then(function (response) {

                localStorage.setItem('defaultRedirectTo', 0);

                props.history.push('/')

                // if(response.data.data.userInfo&&response.data.data.userInfo.hairdresser.salon) {
                //    // localStorage.setItem('salonId',response.data.data.userInfo.hairdresser.salon._id);
                //
                // }
            })
    }

    const checkReadOnly=()=>{
        if(props.user&&props.user?.userInfo&&(props.user?.userInfo?.hairdresser?.isSalonOwner===1||props.user?.userInfo?.hairdresser?.employmentTypeId===2)){
            return true;
        }else{
            return false;
        }
    }

    const checkpaymentMethodAdded = () => {
        let params={};
        paymentService.checkCardAdded(params)
            .then(function(res){
                if(res.data.statusCode===1) {
                    //console.log("res", res.data.responseData?.result);
                    if(res.data.responseData?.result?.data.length>0){
                        //return true;
                        setIsCardAdded(true);
                    }else{
                        //return false;
                        setIsCardAdded(false);
                    }
                }else{
                    //return false;
                    setIsCardAdded(false);
                }
            })
    };
    console.log("new_service_props111",props?.user?.userInfo?.hairdresser)
    return(
            <>
              <div className="sidenav menu-show-desktop" >
                      <span onClick={toggleMenuClass} className="menu_toogle_icon">
                        <span className="sidebar_tog_icon_open"><MenuIcon/></span>
                        <span className="sidebar_tog_icon_close"><MenuIcon/></span>
                        {/* <img className="sidebar_tog_icon_open" src={require('../../assets/images/menu-openW-icon.png')} />
                        <img className="sidebar_tog_icon_close" src={require('../../assets/images/menu-closeW-icon.png')} /> */}
                    </span>
                     <ul>
                            <li>
                                   <Link  to={`/diary/${salonId}`} className={props.match.path==="/diary/:salonId"?"active":""} title="Calender">
                                          <span className="menu_icon">
                                          <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"/></svg>
                                          </span>
                                          <span className="sidenav_text">Diary</span>
                                   </Link>
                            </li>
                            <li>
                                   <Link  to={`/new-checkout/${salonId}`} className={props.match.path==="/new-checkout/:salonId"?"active":""} title="Calender">
                                          <span className="menu_icon _checkout">
                                          <svg viewBox="0 0 27.9 33.799">
                                            <path d="M15.23,32.631l-2.106-.289Q10,31.914,6.882,31.485c-.572-.079-.572-.086-.572-.669,0-2.656,0-5.312,0-7.968a.479.479,0,0,1,.3-.511q5.879-2.95,11.744-5.929a.4.4,0,0,1,.446.019c2.4,1.444,4.8,2.878,7.2,4.325a1.639,1.639,0,0,0,.9.247c2.026-.011,4.051-.006,6.077-.006a2.186,2.186,0,0,1,2.33,2.274q.022,4.987,0,9.974a2.189,2.189,0,0,1-2.307,2.241q-7.749,0-15.5,0a2.231,2.231,0,0,1-2.262-2.212c0-.2,0-.4,0-.641Zm2.233-7.937c.084.008.15.019.216.019,1.541,0,3.083.009,4.624,0a2.89,2.89,0,0,1,1.946.631,2.773,2.773,0,0,1-1.776,4.917c-1.571,0-3.141.005-4.711,0-.242,0-.348.046-.336.318.023.523,0,1.047.012,1.57a1.129,1.129,0,0,0,.986,1.119,4.075,4.075,0,0,0,.552.016c4.246,0,8.492-.012,12.738.012a1.275,1.275,0,0,0,1.4-1.385c-.036-2.472-.015-4.944-.014-7.416A1.137,1.137,0,0,0,31.812,23.2q-6.558,0-13.117,0a1.176,1.176,0,0,0-1.233,1.494Z" transform="translate(-17.52 27.587) rotate(-70)" fill="#f7f7f7"/>
                                          </svg>
                                          </span>
                                          <span className="sidenav_text">Checkout</span>
                                   </Link>
                            </li>
                            <li>
                                   <Link to="/clients" className={props.match.path==="/clients"||props.match.path==="/clients/add"||props.match.path==="/clients/:id"||props.match.path==="/clients/edit/:id"?"active":""} title="Clients">
                                          <span className="menu_icon">
                                          <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4z"/></svg>
                                          </span>
                                          <span className="sidenav_text">Clients</span>
                                   </Link>
                            </li>

                            {checkReadOnly()? <li>
                                   <Link  to="/transactions" className={props.match.path==="/Transactions"||props.match.path==="/transactions"||props.match.path==="/transactions"?"active":""} title="Transactions">
                                          <span className="menu_icon trncIcon">
                                          <AnalyticsIcon/>
                                          </span>
                                          <span className="sidenav_text">Transactions</span>
                                   </Link>
                            </li>:null}


                            {/* <li>
                                   <Link  to={`/services`} className={props.match.path==="/services"||props.match.path==="/services/:hairdresserId/:serviceId"||props.match.path==="/services/:hairdresserId"?"active":""} >
                                          <span className="menu_icon">
                                          <svg viewBox="0 0 24 24"><g fill="none"><path d="M0 0h24v24H0V0z"/><path d="M0 0h24v24H0V0z" opacity=".87"/></g><path d="M4 13c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1zm-3 5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1z"/></svg>
                                          </span>
                                          <span className="sidenav_text">Services</span>
                                   </Link>
                            </li> */}
                            {
                            (props?.user?.userInfo?.hairdresser?.isSalonOwner == 1) || 
                            (props?.user?.userInfo?.hairdresser?.isSalonOwner == 0 && props?.user?.userInfo?.hairdresser?.employmentTypeId == 2)?
                            <li>
                               <Link  to={`/new-services`} 
                                className={props.match.path==="/new-services"|| props.match.path === "/product"|| props.match.path === "/product/deleteProduct"|| props.match.path === "/product/addProduct" || props.match.path === "/product/editProduct" || props.match.path === "/product/viewProduct" || props.match.path === "/edit-services/:id" || props.match.path === "/add-services" ?"active":""}
                                >
                                      <span className="menu_icon">
                                      <svg viewBox="0 0 24 24"><g fill="none"><path d="M0 0h24v24H0V0z"/><path d="M0 0h24v24H0V0z" opacity=".87"/></g><path d="M4 13c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1zm-3 5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1z"/></svg>
                                      </span>
                                      <span className="sidenav_text">Catalogue</span>
                               </Link>
                            </li>:''
                            }
                         {/*{props.user&&props.user.userInfo&&props.user.userInfo.hairdresser.isSalonOwner===1?*/}
                            <li>
                                   <Link  to={`/staff`} className={props.match.path==="/staff"||props.match.path==="/staff/add"||props.match.path==="/staff/:id"?"active":""} title="Staff">
                                          <span className="menu_icon">
                                          <svg viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24"/><rect fill="none" height="24" width="24"/></g><g><g/><g><g><path d="M16.67,13.13C18.04,14.06,19,15.32,19,17v3h3c0.55,0,1-0.45,1-1v-2 C23,14.82,19.43,13.53,16.67,13.13z" fillRule="evenodd"/></g><g><circle cx="9" cy="8" fillRule="evenodd" r="4"/></g><g><path d="M15,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4c-0.47,0-0.91,0.1-1.33,0.24 C14.5,5.27,15,6.58,15,8s-0.5,2.73-1.33,3.76C14.09,11.9,14.53,12,15,12z" fillRule="evenodd"/></g><g><path d="M9,13c-2.67,0-8,1.34-8,4v2c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1v-2 C17,14.34,11.67,13,9,13z" fillRule="evenodd"/></g></g></g></svg>
                                          </span>
                                          <span className="sidenav_text">Staff</span>
                                   </Link>
                            </li>
                         {/*:""}*/}
                         {checkReadOnly() === true ?
                             <li>
                                 <Link to={`/settings/payout-account`}
                                       className={props.match.path === "/settings/payout-account" || props.match.path === "/settings/card-reader" || props.match.path === "/settings/salon-details" || props.match.path === "/settings/booking-url" || props.match.path === "/settings/profile" || props.match.path === "/settings/billing"|| props.match.path === "/settings/account-password"||props.match.path === "/settings/edit-password" ? "active" : ""}>
                                 <span className="menu_icon">
                                 <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path
                                     d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>
                                 </span>
                                     <span className="sidenav_text">Settings</span>
                                 </Link>
                             </li> : <li>
                                 <Link to={`/settings/card-reader`}
                                       className={props.match.path === "/settings/payout-account" || props.match.path === "/settings/card-reader" || props.match.path === "/settings/salon-details" || props.match.path === "/settings/booking-url" || props.match.path === "/settings/profile" || props.match.path === "/settings/billing"|| props.match.path === "/settings/account-password"||props.match.path === "/settings/edit-password" ? "active" : ""}>
                                 <span className="menu_icon">
                                 <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path
                                     d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>
                                 </span>
                                     <span className="sidenav_text">Settings</span>
                                 </Link>
                             </li>
                         }

                         {((props.user&&props.user?.userInfo&&props.user?.userInfo?.hairdresser?.isSalonOwner===1) || (props.user?.userInfo?.hairdresser?.employmentTypeId==2))?<li>
                                 <Link to={isCardAdded===true?`/marketing`:"/marketing-setting"}
                                       className={props.match.path === "/marketing" || props.match.path === "/invoices" || props.match.path === "/marketing-setting" ? "active" : ""}>
                                 <span className="menu_icon _markt">
                                 <svg width="31.455" height="31.512" viewBox="0 0 31.455 31.512">
                                <path id="Icon_open-bullhorn" data-name="Icon open-bullhorn" d="M27,0V27a1.215,1.215,0,0,0,.405,0H31.05a.4.4,0,0,0,.405-.405V.45A.378.378,0,0,0,31.05.045H26.955ZM22.5,2.25,9.4,8.865A2.62,2.62,0,0,1,8.55,9H.4A.378.378,0,0,0,0,9.4V17.55a.378.378,0,0,0,.405.405H4.5l4.635,12.24a2.418,2.418,0,0,0,3.1,1.125,2.418,2.418,0,0,0,1.125-3.1L9.99,20.2a1.188,1.188,0,0,1,1.26-.99V19.08L22.5,24.7V2.2Z" transform="translate(0)" fill="#fff"/>
                                </svg>
                                 </span>
                                     <span className="sidenav_text">Marketing</span>
                                 </Link>
                            </li>:null}
                         {/* {props.user&&props.user?.userInfo&&props.user?.userInfo?.hairdresser?.isSalonOwner===1?
                         <li>
                                 <Link to={`/product`}
                                       className={props.match.path === "/product" || props.match.path === "/product/list-product" || props.match.path === "/product/addProduct" || props.match.path === "/product/editProduct" || props.match.path === "/product/deleteProduct" || props.match.path === "/product/viewProduct" || props.match.path === "/product/checkout" || props.match.path === "/product/productCheckout" ? 'active' : '' }>
                                 <span className="menu_icon">
                                 <FaBoxOpen />
                                  </span>
                                     <span className="sidenav_text">Products</span>
                                 </Link>
                             </li>
                             :""}
                             <li className='menu-show-mobile'>
                                 <Link onClick={() => switchProfile()} to={''}>
                                 <span className="menu_icon">
                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M5.5 21c.83 0 1.5-.67 1.5-1.5V18h10v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V17c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v2.5c0 .83.67 1.5 1.5 1.5zM20 10h1c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1h-1c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1zM3 10h1c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1zm14 3H7V5c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v8z"/></svg>
                                 </span>
                                     <span className="sidenav_text">Switch to client</span>
                                 </Link>
                             </li>*/}

                            <li className='menu-show-mobile'>
                                   <Link onClick={() => logout()} to={''}>
                                          <span className="menu_icon">
                                          <svg viewBox="0 0 512.005 512.005"><path d="M192,256.003c0-35.285,28.715-64,64-64h85.333V21.336c0-11.776-9.536-21.333-21.333-21.333H21.333C9.536,0.003,0,9.56,0,21.336v469.333c0,11.776,9.536,21.333,21.333,21.333H320c11.797,0,21.333-9.557,21.333-21.333V341.336v-21.333H256C220.715,320.003,192,291.288,192,256.003z"/><path d="M507.201,269.48c0.065-0.079,0.119-0.164,0.182-0.244c0.366-0.462,0.721-0.933,1.048-1.425c0.154-0.23,0.284-0.47,0.428-0.705c0.216-0.353,0.437-0.703,0.632-1.069c0.161-0.3,0.296-0.61,0.441-0.916c0.15-0.317,0.309-0.629,0.444-0.954c0.145-0.35,0.263-0.707,0.388-1.062c0.104-0.295,0.219-0.585,0.31-0.886c0.127-0.418,0.224-0.841,0.325-1.264c0.059-0.248,0.131-0.491,0.182-0.742c0.117-0.581,0.199-1.166,0.267-1.753c0.012-0.101,0.033-0.199,0.043-0.301c0.145-1.434,0.145-2.88,0-4.314c-0.01-0.102-0.032-0.2-0.043-0.301c-0.068-0.587-0.151-1.172-0.267-1.753c-0.05-0.251-0.122-0.494-0.182-0.742c-0.101-0.423-0.198-0.847-0.325-1.264c-0.091-0.301-0.206-0.591-0.31-0.886c-0.126-0.355-0.243-0.713-0.388-1.062c-0.135-0.325-0.293-0.637-0.444-0.954c-0.145-0.306-0.28-0.615-0.441-0.916c-0.196-0.366-0.416-0.716-0.632-1.069c-0.144-0.235-0.274-0.475-0.428-0.705c-0.327-0.492-0.682-0.963-1.048-1.425c-0.063-0.08-0.117-0.164-0.182-0.244c-0.48-0.589-0.991-1.151-1.531-1.685l-85.254-85.254c-8.341-8.341-21.824-8.341-30.165,0c-8.341,8.341-8.341,21.824,0,30.165l48.917,48.917H256c-11.797,0-21.333,9.557-21.333,21.333c0,11.776,9.536,21.333,21.333,21.333h183.168l-48.917,48.917c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251l85.254-85.254C506.209,270.631,506.72,270.069,507.201,269.48z"/></svg>
                                          </span>
                                          <span className="sidenav_text">Logout</span>
                                   </Link>
                            </li>
                     </ul>

              </div>
              <div className="sidenava menu-show-mobile" onClick={removeMenuClass}>
              <div className="sidenava-meue sideMenu-bar">
                <span onClick={toggleMenuClass} className="menu_toogle_icon">
                   <span className="sidebar_tog_icon_open"><MenuIcon/></span>
                    {/* <img className="sidebar_tog_icon_open" src={require('../../assets/images/menu-openW-icon.png')} />
                    <img className="sidebar_tog_icon_close" src={require('../../assets/images/menu-closeW-icon.png')} /> */}
                </span>
                <div className="user_pic-profile">
                    <img src={props.user&&props.user.userInfo&&props.user.userInfo.userImage.circleImage?props.user.userInfo.userImage.circleImage: require('../../assets/images/client-plachoder.jpg')} />
                    <span className="ml-2">{props.user&&props.user.userInfo&&props.user.userInfo.name}</span>
                </div>
                 <ul>
                        <li>
                               <Link onClick={removeMenuClass} to={`/diary/${salonId}`} className={props.match.path==="/diary/:salonId"?"active":""} title="Calender">
                                      <span className="menu_icon">
                                      <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"/></svg>
                                      </span>
                                      <span className="sidenava_text">Diary</span>
                               </Link>
                        </li>
                        <li>
                                   <Link  to={`/new-checkout/${salonId}`} className={props.match.path==="/new-checkout/:salonId"?"active":""} title="Calender">
                                          <span className="menu_icon _checkout">
                                          <svg viewBox="0 0 27.9 33.799">
                                            <path d="M15.23,32.631l-2.106-.289Q10,31.914,6.882,31.485c-.572-.079-.572-.086-.572-.669,0-2.656,0-5.312,0-7.968a.479.479,0,0,1,.3-.511q5.879-2.95,11.744-5.929a.4.4,0,0,1,.446.019c2.4,1.444,4.8,2.878,7.2,4.325a1.639,1.639,0,0,0,.9.247c2.026-.011,4.051-.006,6.077-.006a2.186,2.186,0,0,1,2.33,2.274q.022,4.987,0,9.974a2.189,2.189,0,0,1-2.307,2.241q-7.749,0-15.5,0a2.231,2.231,0,0,1-2.262-2.212c0-.2,0-.4,0-.641Zm2.233-7.937c.084.008.15.019.216.019,1.541,0,3.083.009,4.624,0a2.89,2.89,0,0,1,1.946.631,2.773,2.773,0,0,1-1.776,4.917c-1.571,0-3.141.005-4.711,0-.242,0-.348.046-.336.318.023.523,0,1.047.012,1.57a1.129,1.129,0,0,0,.986,1.119,4.075,4.075,0,0,0,.552.016c4.246,0,8.492-.012,12.738.012a1.275,1.275,0,0,0,1.4-1.385c-.036-2.472-.015-4.944-.014-7.416A1.137,1.137,0,0,0,31.812,23.2q-6.558,0-13.117,0a1.176,1.176,0,0,0-1.233,1.494Z" transform="translate(-17.52 27.587) rotate(-70)" fill="#f7f7f7"/>
                                          </svg>
                                          </span>
                                          <span className="sidenav_text">Checkout</span>
                                   </Link>
                            </li>
                        <li>
                               <Link onClick={removeMenuClass} to="/clients" className={props.match.path==="/clients"||props.match.path==="/clients/add"||props.match.path==="/clients/:id"|props.match.path==="/clients/edit/:id"?"active":""} title="Clients">
                                      <span className="menu_icon">
                                      <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4z"/></svg>
                                      </span>
                                      <span className="sidenava_text">Clients</span>
                               </Link>
                        </li>

                        {/*<li>*/}
                        {/*       <Link onClick={removeMenuClass} to="/analytics" className={props.match.path==="/Analytics"||props.match.path==="/analytics"||props.match.path==="/analytics"?"active":""} title="Analytics">*/}
                        {/*              <span className="menu_icon">*/}
                        {/*              <AnalyticsIcon/>*/}
                        {/*              </span>*/}
                        {/*              <span className="sidenava_text">Analytics</span>*/}
                        {/*       </Link>*/}
                        {/*</li> */}
                        {checkReadOnly()?<li>
                             <Link onClick={removeMenuClass} to="/transactions" className={props.match.path==="/Transactions"||props.match.path==="/transactions"||props.match.path==="/transactions"?"active":""} title="Transactions">
                                              <span className="menu_icon trncIcon">
                                              <AnalyticsIcon/>
                                              </span>
                                 <span className="sidenav_text">Transactions</span>
                             </Link>
                         </li>:null}
                        {/* <li>
                               <Link onClick={removeMenuClass} to={`/services`} className={props.match.path==="/services"||props.match.path==="/services/:hairdresserId/:serviceId"||props.match.path==="/services/:hairdresserId"?"active":""} >
                                      <span className="menu_icon">
                                      <svg viewBox="0 0 24 24"><g fill="none"><path d="M0 0h24v24H0V0z"/><path d="M0 0h24v24H0V0z" opacity=".87"/></g><path d="M4 13c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1zm-3 5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1z"/></svg>
                                      </span>
                                      <span className="sidenav_text">Services</span>
                               </Link>
                        </li> */}
                        <li>
                            <Link  to={`/service-nav`} 
                            className={props.match.path==="/new-services"|| props.match.path === "/product"|| props.match.path === "/product/deleteProduct"|| props.match.path === "/product/addProduct" || props.match.path === "/product/editProduct" || props.match.path === "/product/viewProduct" || props.match.path === "/edit-services/:id" || props.match.path === "/add-services" ?"active":""}
                            >
                                <span className="menu_icon">
                                <svg viewBox="0 0 24 24"><g fill="none"><path d="M0 0h24v24H0V0z"/><path d="M0 0h24v24H0V0z" opacity=".87"/></g><path d="M4 13c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1zm-3 5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1z"/></svg>
                                </span>
                                <span className="sidenav_text">Catalogue</span>
                            </Link>
                        </li>
                     {/*{props.user&&props.user.userInfo&&props.user.userInfo.hairdresser.isSalonOwner===1?*/}
                     {console.log("props.userprops.user",props.user)}
                        <li>
                               <Link onClick={removeMenuClass} to={`/staff`} className={props.match.path==="/staff"||props.match.path==="/staff/add"||props.match.path==="/staff/:id"?"active":""} title="Staff">
                                      <span className="menu_icon">
                                      <svg viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24"/><rect fill="none" height="24" width="24"/></g><g><g/><g><g><path d="M16.67,13.13C18.04,14.06,19,15.32,19,17v3h3c0.55,0,1-0.45,1-1v-2 C23,14.82,19.43,13.53,16.67,13.13z" fillRule="evenodd"/></g><g><circle cx="9" cy="8" fillRule="evenodd" r="4"/></g><g><path d="M15,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4c-0.47,0-0.91,0.1-1.33,0.24 C14.5,5.27,15,6.58,15,8s-0.5,2.73-1.33,3.76C14.09,11.9,14.53,12,15,12z" fillRule="evenodd"/></g><g><path d="M9,13c-2.67,0-8,1.34-8,4v2c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1v-2 C17,14.34,11.67,13,9,13z" fillRule="evenodd"/></g></g></g></svg>
                                      </span>
                                      <span className="sidenav_text">Staff</span>
                               </Link>
                        </li>
                     {/*:""}*/}
                     <li>
                         <Link onClick={removeMenuClass}  to={`/settings`} className={props.match.path==="/settings/payout-account"||props.match.path==="/settings/card-reader" ||props.match.path==="/settings/salon-details"||props.match.path==="/settings/booking-url"||props.match.path==="/settings/profile"|| props.match.path === "/settings/billing"||props.match.path==="/settings/account-password"||props.match.path === "/settings/edit-password"?"active":""} >
                             <span className="menu_icon">
                             <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>
                             </span>
                             <span className="sidenav_text">Settings</span>
                         </Link>
                     </li>
                    {/* for marketing navigation marketing-nav*/}
                    {(props.user&&props.user?.userInfo&&props.user?.userInfo?.hairdresser?.isSalonOwner===1)  || (props.user?.userInfo?.hairdresser?.employmentTypeId==2)?<li>
                    <Link to={`/marketing-nav`}
                          className={props.match.path === "/marketing" || props.match.path === "/invoices" || props.match.path === "/marketing-setting" ? "active" : ""}>
                    <span className="menu_icon _markt">
                    <svg width="31.455" height="31.512" viewBox="0 0 31.455 31.512">
                   <path id="Icon_open-bullhorn" data-name="Icon open-bullhorn" d="M27,0V27a1.215,1.215,0,0,0,.405,0H31.05a.4.4,0,0,0,.405-.405V.45A.378.378,0,0,0,31.05.045H26.955ZM22.5,2.25,9.4,8.865A2.62,2.62,0,0,1,8.55,9H.4A.378.378,0,0,0,0,9.4V17.55a.378.378,0,0,0,.405.405H4.5l4.635,12.24a2.418,2.418,0,0,0,3.1,1.125,2.418,2.418,0,0,0,1.125-3.1L9.99,20.2a1.188,1.188,0,0,1,1.26-.99V19.08L22.5,24.7V2.2Z" transform="translate(0)" fill="#fff"/>
                   </svg>
                    </span>
                        <span className="sidenav_text">Marketing</span>
                    </Link>
                  </li>:null}
                     {/* {props.user&&props.user?.userInfo&&props.user?.userInfo?.hairdresser?.isSalonOwner===1?
                     <li>
                         <Link onClick={removeMenuClass}  to={`/product`} className={props.match.path === "/product" || props.match.path === "/product/list-product" || props.match.path === "/product/addProduct" || props.match.path === "/product/editProduct" || props.match.path === "/product/deleteProduct" || props.match.path === "/product/viewProduct" || props.match.path === "/product/checkout" || props.match.path === "/product/productCheckout" ? 'active' : '' }>
                             <span className="menu_icon">
                             <FaBoxOpen />
                            </span>
                             <span className="sidenav_text">Products</span>
                         </Link>
                     </li>
                         :""} */}
                         <li>
                             <Link onClick={() => switchProfile()} to={''}>
                             <span className="menu_icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M5.5 21c.83 0 1.5-.67 1.5-1.5V18h10v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V17c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v2.5c0 .83.67 1.5 1.5 1.5zM20 10h1c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1h-1c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1zM3 10h1c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1zm14 3H7V5c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v8z"/></svg>
                             </span>
                                 <span className="sidenav_text">Switch to client</span>
                             </Link>
                         </li>

                        <li>
                               <Link onClick={() => logout()} to={''}>
                                      <span className="menu_icon">
                                      <svg viewBox="0 0 512.005 512.005"><path d="M192,256.003c0-35.285,28.715-64,64-64h85.333V21.336c0-11.776-9.536-21.333-21.333-21.333H21.333C9.536,0.003,0,9.56,0,21.336v469.333c0,11.776,9.536,21.333,21.333,21.333H320c11.797,0,21.333-9.557,21.333-21.333V341.336v-21.333H256C220.715,320.003,192,291.288,192,256.003z"/><path d="M507.201,269.48c0.065-0.079,0.119-0.164,0.182-0.244c0.366-0.462,0.721-0.933,1.048-1.425c0.154-0.23,0.284-0.47,0.428-0.705c0.216-0.353,0.437-0.703,0.632-1.069c0.161-0.3,0.296-0.61,0.441-0.916c0.15-0.317,0.309-0.629,0.444-0.954c0.145-0.35,0.263-0.707,0.388-1.062c0.104-0.295,0.219-0.585,0.31-0.886c0.127-0.418,0.224-0.841,0.325-1.264c0.059-0.248,0.131-0.491,0.182-0.742c0.117-0.581,0.199-1.166,0.267-1.753c0.012-0.101,0.033-0.199,0.043-0.301c0.145-1.434,0.145-2.88,0-4.314c-0.01-0.102-0.032-0.2-0.043-0.301c-0.068-0.587-0.151-1.172-0.267-1.753c-0.05-0.251-0.122-0.494-0.182-0.742c-0.101-0.423-0.198-0.847-0.325-1.264c-0.091-0.301-0.206-0.591-0.31-0.886c-0.126-0.355-0.243-0.713-0.388-1.062c-0.135-0.325-0.293-0.637-0.444-0.954c-0.145-0.306-0.28-0.615-0.441-0.916c-0.196-0.366-0.416-0.716-0.632-1.069c-0.144-0.235-0.274-0.475-0.428-0.705c-0.327-0.492-0.682-0.963-1.048-1.425c-0.063-0.08-0.117-0.164-0.182-0.244c-0.48-0.589-0.991-1.151-1.531-1.685l-85.254-85.254c-8.341-8.341-21.824-8.341-30.165,0c-8.341,8.341-8.341,21.824,0,30.165l48.917,48.917H256c-11.797,0-21.333,9.557-21.333,21.333c0,11.776,9.536,21.333,21.333,21.333h183.168l-48.917,48.917c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251l85.254-85.254C506.209,270.631,506.72,270.069,507.201,269.48z"/></svg>
                                      </span>
                                      <span className="sidenav_text">Logout</span>
                               </Link>
                        </li>
                 </ul>
              </div>
          </div>
          </>

)
}
//export default SideNav;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(SideNav);
