import React, { Component } from 'react';
import SideNav from '../template/SideNav';
import SettingSidebar from '../settings/SettingSidebar';
import {connect} from "react-redux";
import ServiceSettingSidebar from './ServiceSettingSidebar';


class ServiceMobileView extends Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    handleOpenNavbar = ()=>{
        document.body.classList.toggle('nav_menu_open')
        // document.querySelector('body').classList.toggle('setting-temps-pages')
        // if(document.body.classList.contains('setting-temps-pages')){
        //     // document.querySelector('body').classList.remove('setting-temps-pages')
        //     // document.body.classList.toggle('nav_menu_open')
        // }
        // alert("frefr")
    }




    render() {
        return (
            <>
                <div className='setting-page-main-mobileView-sidebar padding-40'>
                    <div className='top-header-setting-title'>
                        <button className='btn' onClick={() => this.handleOpenNavbar()}>
                            <i className='fa fa-chevron-left'></i>
                        </button>
                        <h5>Service</h5>
                    </div>
                    <ServiceSettingSidebar service={true}/>
                </div>

                <SideNav {...this.props} />
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ServiceMobileView);
