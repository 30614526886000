import React from 'react';

export const PayoutAccount = ()=>
<svg width="40.498" height="36" viewBox="0 0 40.498 36">
  <path id="Icon_awesome-piggy-bank" data-name="Icon awesome-piggy-bank" d="M39.375,15.75H37.3a11.285,11.285,0,0,0-2.63-3.691L36,6.75H33.75a6.707,6.707,0,0,0-5.133,2.412A11.151,11.151,0,0,0,27,9H18A11.261,11.261,0,0,0,6.975,18H3.938a1.689,1.689,0,0,1-1.652-2.025,1.732,1.732,0,0,1,1.723-1.35h.07A.423.423,0,0,0,4.5,14.2V12.8a.423.423,0,0,0-.422-.422A4.028,4.028,0,0,0,.035,15.792a3.94,3.94,0,0,0,3.9,4.458H6.75a11.2,11.2,0,0,0,4.5,8.951v5.674A1.128,1.128,0,0,0,12.375,36h4.5A1.128,1.128,0,0,0,18,34.875V31.5h9v3.375A1.128,1.128,0,0,0,28.125,36h4.5a1.128,1.128,0,0,0,1.125-1.125V29.2a11.251,11.251,0,0,0,2.2-2.2h3.424A1.128,1.128,0,0,0,40.5,25.875v-9A1.128,1.128,0,0,0,39.375,15.75Zm-9,4.5A1.125,1.125,0,1,1,31.5,19.125,1.128,1.128,0,0,1,30.375,20.25ZM18,6.75h9c.38,0,.752.028,1.118.056,0-.021.007-.035.007-.056a6.75,6.75,0,0,0-13.5,0c0,.148.035.288.042.436A13.366,13.366,0,0,1,18,6.75Z" transform="translate(-0.002)" fill="#29282e"/>
</svg>
export const Services = ()=>
<svg width="26.932" height="23.417" viewBox="0 0 26.932 23.417">
  <path id="Icon_map-hair-care" data-name="Icon map-hair-care" d="M23.855,14.837a2.263,2.263,0,0,1,.886-.612l2.409-.984a1,1,0,0,0,.5-.812V5.556a.877.877,0,0,0-.525-.76S22.087,2.88,18.711,2.88a26.412,26.412,0,0,0-7.486,1.657,5.6,5.6,0,0,1-1.081.269l-8.866.953a.653.653,0,0,0-.557.63v4.665a.7.7,0,0,0,.552.664l8.862,1.43a6.326,6.326,0,0,1,1.075.3L17.278,15a1.02,1.02,0,0,1,.738.614l3.568,10.3a.569.569,0,0,0,.715.352L26.072,25a.557.557,0,0,0,.348-.709l-2.477-7.144c-.1-.292-.186-.548-.19-.568l0-.021a2.053,2.053,0,0,1,.107-1.719ZM19.55,11.129a2.506,2.506,0,1,1,2.545-2.505A2.525,2.525,0,0,1,19.55,11.129Z" transform="translate(-0.72 -2.88)" fill="#29282d"/>
</svg>


export const CardIcon = ()=>
<svg width="30" height="24" viewBox="0 0 30 24">
  <path id="Icon_material-payment" data-name="Icon material-payment" d="M30,6H6A2.977,2.977,0,0,0,3.015,9L3,27a2.99,2.99,0,0,0,3,3H30a2.99,2.99,0,0,0,3-3V9A2.99,2.99,0,0,0,30,6Zm0,21H6V18H30Zm0-15H6V9H30Z" transform="translate(-3 -6)" fill="#29282e"/>
</svg>
export const BlockIcon = ()=>
<svg  width="18.388" height="18.388" viewBox="0 0 18.388 18.388">
  <path id="Icon_material-do-not-disturb-alt" data-name="Icon material-do-not-disturb-alt" d="M12.194,3a9.194,9.194,0,1,0,9.194,9.194A9.221,9.221,0,0,0,12.194,3ZM4.839,12.194a7.377,7.377,0,0,1,7.355-7.355A7.246,7.246,0,0,1,16.7,6.4L6.4,16.7A7.246,7.246,0,0,1,4.839,12.194Zm7.355,7.355a7.246,7.246,0,0,1-4.505-1.563l10.3-10.3a7.246,7.246,0,0,1,1.563,4.505A7.377,7.377,0,0,1,12.194,19.549Z" transform="translate(-3 -3)" fill="#29282e"/>
</svg>

export const SaloonIcon = ()=>
<svg  width="23.934" height="34.56" viewBox="0 0 23.934 34.56">
  <path id="Icon_map-beauty-salon" data-name="Icon map-beauty-salon" d="M25.042,4.8h-5a.671.671,0,0,0,0,1.343h5V6.83h-5a.671.671,0,0,0,0,1.343h5v.7h-5a.671.671,0,0,0,0,1.343h5v.671h-5a.68.68,0,0,0,0,1.359h5v.672h-5a.671.671,0,0,0,0,1.343h5v.671h-5a.672.672,0,0,0,0,1.344h5v.688h-5a.671.671,0,0,0,0,1.343h5v.67h-5a.672.672,0,0,0,0,1.344h5V33.4a1.519,1.519,0,0,0,3.038,0V2.8A2.092,2.092,0,0,0,26.094.737H20.038a.671.671,0,0,0,0,1.343l5,.017v.67h-5a.672.672,0,0,0,0,1.344h5V4.8Zm-5.349,23.18c0-2.326-2.413-4.827-5.293-3.207V15.876L12.537,1.206A.547.547,0,0,0,11.948.72c-.228.006-.685.1-.735.486L9.36,15.876v8.895c-2.88-1.62-5.213.9-5.214,3.189a3.652,3.652,0,0,0,3.694,3.709A3.756,3.756,0,0,0,11.52,27.96V18.72h.72v9.24A3.636,3.636,0,0,0,15.6,31.635a5.119,5.119,0,0,0,1.031,3.374.859.859,0,0,0,1.382-.989,3.181,3.181,0,0,1-.454-2.74,3.62,3.62,0,0,0,2.131-3.3ZM7.923,29.807a1.831,1.831,0,1,1,1.818-1.83,1.831,1.831,0,0,1-1.818,1.83Zm6.171-1.83a1.818,1.818,0,1,1,1.819,1.83,1.838,1.838,0,0,1-1.819-1.83Z" transform="translate(-4.146 -0.72)" fill="#29282e"/>
</svg>

export const LinkIcon = ()=>
<svg width="30" height="15" viewBox="0 0 30 15">
  <path id="Icon_material-insert-link" data-name="Icon material-insert-link" d="M5.85,18a4.654,4.654,0,0,1,4.65-4.65h6V10.5h-6a7.5,7.5,0,0,0,0,15h6V22.65h-6A4.654,4.654,0,0,1,5.85,18ZM12,19.5H24v-3H12Zm13.5-9h-6v2.85h6a4.65,4.65,0,0,1,0,9.3h-6V25.5h6a7.5,7.5,0,0,0,0-15Z" transform="translate(-3 -10.5)" fill="#29282e"/>
</svg>

export const UsersIcon = ()=>
<svg width="26.992" height="20.244" viewBox="0 0 26.992 20.244">
  <path id="Icon_metro-profile" data-name="Icon metro-profile" d="M29.563,10.6H27.676V7.772l-3.9-.06L23.8,10.6H12.09l.08-2.892-3.816.06v2.892L6.427,10.6A1.929,1.929,0,0,0,4.5,12.532v13.5a1.929,1.929,0,0,0,1.928,1.928H29.563a1.929,1.929,0,0,0,1.928-1.928v-13.5A1.929,1.929,0,0,0,29.563,10.6Zm-4.82-1.928h1.928v3.856H24.743ZM12.211,15.243c1.165,0,2.109,1.241,2.109,2.772s-.945,2.772-2.109,2.772S10.1,19.545,10.1,18.015s.945-2.772,2.109-2.772ZM9.319,8.676h1.928v3.856H9.319ZM8.034,24.05s.228-1.834.748-2.18a7.393,7.393,0,0,1,2.015-.576s.97,1.036,1.382,1.036,1.381-1.036,1.381-1.036a7.371,7.371,0,0,1,2.016.576c.61.407.763,2.18.763,2.18H8.034Zm19.6-.914H18.959v-.964h8.676Zm0-1.928H18.959v-.964h8.676Zm0-1.928H18.959v-.964h8.676Zm0-1.928H18.959v-.964h8.676Z" transform="translate(-4.499 -7.712)" fill="#29282e"/>
</svg>
export const PasswordIcons = ()=>
<svg width="27" height="33" viewBox="0 0 27 33">
  <path id="Icon_material-security" data-name="Icon material-security" d="M18,1.5,4.5,7.5v9c0,8.325,5.76,16.11,13.5,18,7.74-1.89,13.5-9.675,13.5-18v-9Zm0,16.485H28.5C27.7,24.165,23.58,29.67,18,31.4V18H7.5V9.45L18,4.785Z" transform="translate(-4.5 -1.5)" fill="#29282e"/>
</svg>
export const EllipseIcon = ()=>
<svg width="21" height="21" viewBox="0 0 21 21" fill="none">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5018 6.60915C11.3615 6.60915 12.0584 5.91227 12.0584 5.05262C12.0584 4.19297 11.3615 3.49609 10.5018 3.49609C9.64219 3.49609 8.94531 4.19297 8.94531 5.05262C8.94531 5.91227 9.64219 6.60915 10.5018 6.60915ZM10.5018 14.3918C9.64219 14.3918 8.94531 15.0887 8.94531 15.9483C8.94531 16.808 9.64219 17.5049 10.5018 17.5049C11.3615 17.5049 12.0584 16.808 12.0584 15.9483C12.0584 15.0887 11.3615 14.3918 10.5018 14.3918ZM10.5018 8.94395C9.64219 8.94395 8.94531 9.64083 8.94531 10.5005C8.94531 11.3601 9.64219 12.057 10.5018 12.057C11.3615 12.057 12.0584 11.3601 12.0584 10.5005C12.0584 9.64083 11.3615 8.94395 10.5018 8.94395Z" fill="#9FA5B3"/>
</svg>

export const ADDCARDREADER = ()=> <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
  <path id="Icon_ionic-ios-add-circle" data-name="Icon ionic-ios-add-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm6.363,15.75H19.125v5.238a1.125,1.125,0,0,1-2.25,0V19.125H11.637a1.125,1.125,0,0,1,0-2.25h5.238V11.637a1.125,1.125,0,0,1,2.25,0v5.238h5.238a1.125,1.125,0,0,1,0,2.25Z" transform="translate(-3.375 -3.375)"/>
</svg>

export const InvoiceIcon = ()=><svg  width="27.546" height="32.2" viewBox="0 0 27.546 32.2">
<path id="Icon_payment-invoice-sign-alt" data-name="Icon payment-invoice-sign-alt" d="M29.2,27.859h9.748V46.852a1.506,1.506,0,0,1-.5,1.195,1.633,1.633,0,0,1-1.2.5H13.1a1.627,1.627,0,0,1-1.195-.5,1.5,1.5,0,0,1-.5-1.195V18.047a1.5,1.5,0,0,1,.5-1.195,1.629,1.629,0,0,1,1.195-.5H27.5V26.1a1.577,1.577,0,0,0,.5,1.258,1.632,1.632,0,0,0,1.2.5Zm2.893,4.025V30.752a.607.607,0,0,0-.189-.44.542.542,0,0,0-.377-.189h-12.7a.545.545,0,0,0-.377.189.6.6,0,0,0-.189.44v1.132q0,.316.189.377a.543.543,0,0,0,.377.189h12.7a.545.545,0,0,0,.377-.189c.126-.041.188-.167.188-.377Zm0,4.591V35.343a.607.607,0,0,0-.189-.44.515.515,0,0,0-.377-.126h-12.7a.512.512,0,0,0-.377.126.6.6,0,0,0-.189.44v1.132a.6.6,0,0,0,.189.44.95.95,0,0,0,.377.126h12.7a.957.957,0,0,0,.377-.126.605.605,0,0,0,.188-.44Zm0,4.591V39.934a.607.607,0,0,0-.189-.44.97.97,0,0,0-.377-.126h-12.7a.959.959,0,0,0-.377.126.6.6,0,0,0-.189.44v1.132a.6.6,0,0,0,.189.44.95.95,0,0,0,.377.126h12.7a.957.957,0,0,0,.377-.126.605.605,0,0,0,.188-.44ZM37.748,24.9a7.674,7.674,0,0,1,.5.629h-8.49V17.041a7.933,7.933,0,0,1,.629.5L37.748,24.9Z" transform="translate(-11.398 -16.35)" fill="#29282e"/>
</svg>

