import React, { useEffect, useState } from "react";
import reactRouterDom, { Link, useHistory } from "react-router-dom";
import TopHeader from "../../template/TopHeader";
import SideNav from "../../template/SideNav";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "react-toast-notification";
// import '../service.css';
import Select from 'react-select';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CancelSaveBtn from "./CancelSaveBtn";
import useWindowSize from "../useWindowSize";
import { newServicesAction } from "../../../_actions";

function AddService(props) {
    const [serviceName, setServiceName] = useState('');
    const [category, setCategory] = useState(null);
    const [serviceTime,setServiceTime] =useState('')
    const [description, setDescription ] = useState("")
    const [errors, setErrors] = useState({}); // State for error messages
    const dispatch = useDispatch();
    const history = useHistory();
    const { width } = useWindowSize();
    const listCategory = useSelector(state => state.newServices.listCategory);
    const listHairdresser = useSelector(state => state?.newServices?.listHairdresser)
    console.log("filter_listHairdresser add_service",listHairdresser,listCategory)
    const [staffSettings, setStaffSettings] = useState([]);
const categoryOptions = listCategory?.map(category => ({
  value: category?._id, // Use '_id' for the value
  label: category?.categoryName // Use 'categoryName' for the label
}));

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#ccc',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#707070',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#29282e' : 'white',
    color: state.isSelected ? 'white' : '#333',
    '&:hover': {
      backgroundColor: state.isSelected ? '#29282e' : '#f0f0f0',
    },
  }),
};

    const categoryList = () => {
        const payload = {}
        dispatch(newServicesAction.listCategoryData(payload))
            .then((res) => {
                console.log("Category list successfully:", res);

            })
            .catch((error) => {
                console.error("Error with category list:", error);
            });
    }

const handleStaffSettingChange = (index, field, value) => {
  console.log(" ", index, field, value);
  // debugger;

  if (field === "serviceTime"  && value !== 0) {
   
    const error = validateFields(field, value);
    setErrors(prev => ({
      ...prev,
      [`${field}_${index}`]: error // Use a unique key for each staff member's field
    }));
  } else if(field === "processingTime"  && value !== 0) {
   
    const error = validateFields(field, value);
    setErrors(prev => ({
      ...prev,
      [`${field}_${index}`]: error // Use a unique key for each staff member's field
    }));
  } else {
    // Clear the error if the field is valid or empty
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[`${field}_${index}`];
      return newErrors;
    });
  }
  const newStaffSettings = [...staffSettings];
  newStaffSettings[index][field] = value;
  setStaffSettings(newStaffSettings);
};

const validateFields = (name, value) => {
  // debugger
  //Deposit amount never greater than Service Cost ,it should equal but not greaterthan
  let error = '';
  switch (name) {
      case 'serviceName':
          if (!value) {
              error = "Service Name is required.";
          }
          break;
      case 'category':
          if (!value) {
              error = "Category is required.";
          }
          break;
      case 'serviceTime':
        if (value!=0 && value%5!=0) {
              error = "Must be multiple of 5.";
          }
          break;
      case 'processingTime':
          if (value!=0 && value%5!=0) {
              error = "Must be multiple of 5.";
          }
          break;
          
      default:
          break;
  }
  return error;
};

const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
        case 'serviceName':
            setServiceName(value);
            break;
        case 'description':
            setDescription(value);
            break;
        default:
            break;
    }

    setErrors(prev => ({
        ...prev,
        [name]: validateFields(name, value)
    }));
};

const handleCategoryChange = (selected) => {
    setCategory(selected);
    setErrors(prev => ({
        ...prev,
        category: validateFields('category', selected)
    }));
};

const listHairdresseData =()=>{
  let payload = { }
  dispatch(newServicesAction.listHairDresserData(payload))
    .then((res) => {
      console.log("Hairdresser data fetched successfully:", res?.listHairdresser);
      if (res?.listHairdresser) {
        const hairdresserList = res?.listHairdresser;
        const staffMembers = hairdresserList.map(hairdresser => ({
          name: hairdresser?.name,
          _id: hairdresser?._id,
          enabled: false
        }));

        // Update staffSettings with the new data
        setStaffSettings(staffMembers.map(staff => ({
          ...staff,
          isDeactivated: false,
          onlineBooking: false,
          serviceTime: '',
          processingTime: '',
          depositPrice: '',
          servicePrice: ''
        })));
      }
    })
    .catch((error) => {
      console.error("Error edit category:", error);
    });
}

// const staffMembers = listHairdresser?.map(hairdresser => ({
//   name: hairdresser?.name,
//   _id:hairdresser?._id,
//   enabled: false
//   }))

// const [staffSettings, setStaffSettings] = useState(staffMembers?.map(staff => ({
//   ...staff,
//   isDeactivated:false,
//   onlineBooking: false,
//   serviceTime: '',
//   processingTime: '',
//   depositPrice: '',
//   servicePrice: ''
// })));

const handleSave = () => {
  
  setErrors({});
  
  const newErrors = {
      serviceName: validateFields('serviceName', serviceName),
      // serviceTime: validateFields('serviceTime', serviceTime),
      category   : validateFields('category', category)
  };
  
   if (staffSettings && Array.isArray(staffSettings)) {
    for (let index = 0; index < staffSettings.length; index++) {
      const staff = staffSettings[index];
      if (staff.isDeactivated == true && ( staff.serviceTime == '' || staff.serviceTime == 0)) {
        newErrors[`serviceTime_${index}`] = "Service time can't be set to 0 when service is enabled";
      } else if (staff.serviceTime) {
        const serviceTimeError = validateFields('serviceTime', parseInt(staff.serviceTime));
        if (serviceTimeError) {
          newErrors[`serviceTime_${index}`] = serviceTimeError;
        }
      }
      if (staff.processingTime) {
        const processingTimeError = validateFields('processingTime', parseInt(staff.processingTime));
        if (processingTimeError) {
          newErrors[`processingTime_${index}`] = processingTimeError;
        }
      }

      if (parseFloat(staff.depositPrice) > parseFloat(staff.servicePrice)) {
        newErrors[`depositPrice_${index}`] = "Deposit price cannot be greater than service price.";
      }
    }
  }
  

  const filteredErrors = Object.fromEntries(Object.entries(newErrors).filter(([_, v]) => v));
  setErrors(filteredErrors);

  if (Object.keys(filteredErrors).length > 0) {
      return; 
  }
  // debugger;
  const payload = {
    serviceName,
    categoryID: category ? category.value: "", 
    serviceDescription: description,
    hairdresserService: staffSettings?.map(staff => (
      {
        hairdresserId: staff._id, 
        servicePrice: parseFloat(staff.servicePrice) || 0,
        serviceTime: parseFloat(staff.serviceTime) || 0,
        isDeactivated: !staff.isDeactivated, 
        depositPrice: parseFloat(staff.depositPrice) || 0,
        onlineBooking: staff.onlineBooking,
        processingTime: parseFloat(staff.processingTime) || 0,
    }
  )),
  };
  console.log("Saving add service data:", payload);
  dispatch(newServicesAction.createServicesData(payload))
  .then((res) => {
    console.log("Category created successfully:", res);
    if(res?.newServices?.statusCode===1) {
      toast.success(res?.newServices?.responseData?.message);
    }
    else{
      toast.error(res?.newServices?.error?.responseMessage);
    }
    history.push("/new-services");
  })
  .catch((error) => {
    console.error("Error creating category:", error);
  });
  };

useEffect(()=>{
  listHairdresseData();
  categoryList();
  },[])


const renderServiceDetails = () => (
  <div className="cm_box_view mt-4 _left_right_spac">
    <h4 className="mt-2">Service Details</h4>
    <div className="filters-popup pt-3">
      <div className="row">
        <div className="col-md-6">
          <div className="filter-group">
            <label>Service Name</label>
            <input
              type="text"
              name="serviceName"
              placeholder="e.g. Haircut"
              className="form-controls"
              value={serviceName}
              onChange={handleChange}
            />
            {errors.serviceName && <span className="cm_error_msg">{errors.serviceName}</span>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="filter-group">
            <label>Category</label>
            <Select
              options={categoryOptions}
              value={category}
              classNamePrefix="my-select"
              onChange={handleCategoryChange}
              styles={customStyles}
              placeholder="Choose a Category"
              isSearchable={false} 
            />
            {errors.category && <span className="cm_error_msg">{errors.category}</span>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="filter-group">
            <label>Service Description</label>
            <textarea
              name="description"
              placeholder="e.g. Transform your look with a professional haircut tailored to your style preferences..."
              value={description}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const renderStaffSettings = () => (
  <div className="cm_box_view mt-4 staff-settings">
    {/* <div className="table-responsive"> */}
      <table>
        <thead>
          <tr>
            <th className="fixed-column">Staff Member</th>
            <th className="fixed-column">Service Enabled</th>
            <th className="fixed-column">Online Bookings</th>
            <th className="fixed-column">Service Duration</th>
            <th className="fixed-column">Processing Time</th>
            <th className="fixed-column">Deposit</th>
            <th className="fixed-column">Service Cost</th>
          </tr>
        </thead>
        <tbody>
          {staffSettings?.map((staff, index) => {
             const hasRowError = Object.keys(errors).some(key => 
              key.endsWith(`_${index}`) && errors[key]
            );
            return(
            <tr key={staff.name}>
              <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }} className="fixed-column" >{staff.name}</td>
              <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                <div className="salon_switch_container">
                  <label className="switch">
                    <input type="checkbox" 
                      onChange={(e) => handleStaffSettingChange(index, 'isDeactivated', e.target.checked)}
                      checked={staff.isDeactivated} 
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </td>
              <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                <div className="salon_switch_container">
                  <label className="switch">
                    <input type="checkbox" 
                      onChange={(e) => handleStaffSettingChange(index, 'onlineBooking', e.target.checked)}
                      checked={staff.onlineBooking} 
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </td>
              <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                <input
                  type="number"
                  value={staff.serviceTime}
                  onChange={(e) => handleStaffSettingChange(index, 'serviceTime', e.target.value)}
                  // placeholder="5min"
                />
                  {errors[`serviceTime_${index}`] && <span className="cm_error_msg">{errors[`serviceTime_${index}`]}</span>}
              </td>
              <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                <input
                  type="number"
                  value={staff.processingTime}
                  onChange={(e) => handleStaffSettingChange(index, 'processingTime', e.target.value)}
                  // placeholder="5min"
                />
                {errors[`processingTime_${index}`] && <span className="cm_error_msg">{errors[`processingTime_${index}`]}</span>}
              </td>
              <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                <div className="_fix_left_sign pos-relative">
                  <input
                    type="number"
                    value={staff.depositPrice}
                    onChange={(e) => handleStaffSettingChange(index, 'depositPrice', e.target.value)}
                    placeholder=""
                  />
                  <span>£</span>
                </div>
                {errors[`depositPrice_${index}`] &&  <p className="cm_error_msg">{errors[`depositPrice_${index}`]}</p> }
              </td>
              <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>  
                <div className="_fix_left_sign pos-relative">
                  <input
                    type="number"
                    value={staff.servicePrice}
                    onChange={(e) => handleStaffSettingChange(index, 'servicePrice', e.target.value)}
                    placeholder=""
                  />
                  <span>£</span>
                </div>
              </td>
            </tr>
            )
          })}
        </tbody>
      </table>
    {/* </div> */}
  </div>
);
 
return (
  <div className="h_n _new__service">
    <div className="body_container cm_bg_white _nowhite __add_service">
      <TopHeader 
      title="New Service" 
      headerButtonStatus={true}
      {...props} 
      onSave={handleSave}
      />
      <SideNav {...props} />
      <div className="new-setting-wrapper">
        <div className="container">
        {width<1101 ? 
            <Tabs
            defaultActiveKey="detail"
            id="uncontrolled-tab-example"
            className="add_edit_service mb-3"
          >
            <Tab eventKey="detail" title="Detail" className="add_edit_service_content">
              {renderServiceDetails()}
              
            </Tab>
            <Tab eventKey="staff" title="Staff" className="add_edit_service_content">
              {renderStaffSettings()}
              {/* <CancelSaveBtn/>  */}
            </Tab>
            <CancelSaveBtn onSave={handleSave}/> 
          </Tabs>
          : (
            <>
            {renderServiceDetails()}
            {renderStaffSettings()}
            </>
          )
        }
        {width<1101 ? <CancelSaveBtn onSave={handleSave} />:''}
        </div>
        
      </div>
    </div>


  </div>
)
}

export default AddService