import React, { Component } from "react";
import moment from 'moment';
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { hairdresserActions, userActions } from "../../_actions";
import Lottie from "react-lottie";
import DatePicker from "react-datepicker";
import { default as ReactSelect } from "react-select";
import animationData from "../../assets/animation-data/loader.json";
import { DownloadIcon } from "../SvgIcon";
import { components } from "react-select";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function debounce(func, delay) {
  let timer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(context, args), delay);
  };
}


const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        {props.value == 0 ? (
          <label className="select-first-input">{props.label}</label>
        ) : (
          <label>{props.label}</label>
        )}
      </components.Option>
    </div>
  );
};

var date = new Date();
class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      startDate: new Date(date.getFullYear()-3, date.getMonth(), 1),
      endDate: new Date(),
      myData: [{ label: "All Staff", value: "0" }],
      isSelectopen: false,
      selectedOption: [],
      selectedValue: [],
      hairdresserLodingStatus:true,
      staffData: [],
      selectedStaffName: "",
      hairdresserClient: [],
      selectedHairdresserIds:[],
      isSalonOwner:0,
      selectedHairdressers:[],
      staffList:[]

    };
    this.selectClient = this.selectClient.bind(this);
    this.searchClient = this.searchClient.bind(this);
    this.checkReadOnly = this.checkReadOnly.bind(this);
    this.debouncedFunction = this.debouncedFunction.bind(this);
    this.debouncedSearch = debounce(this.debouncedFunction, 500);
    this.exportCSV = this.exportCSV.bind(this);
    // this.exportCSV = this.exportCSV.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("accessToken")) {
      let hairdresserId = localStorage.getItem("userId");
      let params = {
        searchKey: this.state.searchKey,
        hairdresserId: hairdresserId,
        page: 1,
        listType: 1,
        isDownload: 0,
      };
      this.getHairdresserClient(params);
      this.getStaff();
    } else {
      this.props.history.push("/");
    }
  }

  getHairdresserClient(params) {
    var self = this;
    self.props.dispatch(hairdresserActions.getHairdresserClient(params)).then(res=>{
      self.setState({
        hairdresserLodingStatus:false
      })
    }).catch(err=>{
      self.setState({
        hairdresserLodingStatus:false
      })
      console.log(err);
    });
  }
 
  // this function have for staff dropdown
  getStaff() {
    var self = this;
    let hairdresserId = localStorage.getItem("userId");
    let staffParams = {
      searchKey: this.state.searchKey,
      hairdresserId: hairdresserId,
      page: 1,
      listType: 1,
    };

    self.props
      .dispatch(hairdresserActions.hairdresserStaffRota(staffParams))
      .then((res_staff) => {
       
        if (res_staff?.status === 200) {
          const data = res_staff?.data?.data?.rota;
          let loginUserId = localStorage.getItem('userId');
          
          if (data?.length > 0) {
            let staff_data = data.filter(
              (check_data) => check_data?.name !== undefined
            );
            let staffOptions = staff_data.map((emp) => {
              return { label: emp?.name, value: emp?._id };
            });

            let _staffII = staff_data.find(_staff=>_staff?._id===loginUserId); 
            if(_staffII?.hairdresserInfo?.isSalonOwner===1){
              this.setState({
                isSalonOwner: _staffII?.hairdresserInfo?.isSalonOwner,
              });
            }

            staffOptions.unshift({ label:'All Staff', value: '0' });          
            this.setState({
              staffData: staff_data,
              myData:staffOptions
            });
          }
        }
      });
  }

  selectClient(clientId) {
    this.props.history.push("/clients/" + clientId);
  }

  checkEmail = (email) => {
    let emailArr = email.split("@");
    if (emailArr[1] === "beuapp.com") {
      return false;
    } else {
      return true;
    }
  };

  debouncedFunction() {
    let self = this;
    // self.props.dispatch(
    //   hairdresserActions.getHairdresserClient({
    //     history: this.props.history,
    //     searchKey: this.state.searchKey,
    //     hairdresserId: localStorage.getItem("userId"),
    //     page: 1,
    //     listType: 1,
    //   })
   // );

    let clientData = self?.props?.hairdresserClient?.length>0?self?.props?.hairdresserClient:self?.props?.hairdresserClient?.client;

   
   let responseList;
    if (self.state.searchKey.length>1) {
       responseList = clientData?.filter((client) =>
        client.name.toLowerCase().includes(self.state.searchKey.toLowerCase()));
        self.setState({
          hairdresserClient :responseList
        });
        console.log('clientData',clientData);
    }else{
      self.setState({
        hairdresserClient :clientData
      });
      console.log('clientData',responseList);
    }
   


//    console.log('dddddddd',);
  }

  searchClient(e) {
    let self = this;
       self.setState(
      {
        searchKey: e.target.value,
      },
      function () {
        this.debouncedSearch();
      }
    );
  }

  exportCSV(download=1) {
    var self = this;
    let { dispatch } = self.props;
    if (localStorage.getItem("accessToken")) {
      let hairdresserId =self?.state.selectedHairdresserIds?.length>0?self?.state.selectedHairdresserIds:[localStorage.getItem("userId")];
      let  params = {
          searchKey: this.state.searchKey,
          hairdresserId: hairdresserId,
          empIds: this.state.selectedValue,
          listType: 1,
          isDownload: download,
          startDate:this.state.startDate,
          endDate:this.state.endDate,
        };
      // this.state.startDate
      // this.state.endDate
      dispatch(hairdresserActions.getLastBookedClients(params)).then((res) => {
        const { responseData } = res?.data;
        if(params.isDownload===1){
          window.open(responseData?.file);
        }else{
          let clientSortData = responseData?.clientData.sort( this.compare );
          this.setState({
            hairdresserClient:clientSortData
          })
        }
        
      });
    }
  }

 compare = ( a, b ) => {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  
 

  checkReadOnly() {
    if (
      this.props.user &&
      this.props.user.userInfo &&
      (this.props.user.userInfo.hairdresser.isSalonOwner === 1 ||
        this.props.user.userInfo.hairdresser.isSalonOwner === "1" ||
        this.props.user.userInfo.hairdresser.employmentTypeId === "2" ||
        this.props.user.userInfo.hairdresser.employmentTypeId === 2)
    ) {
      return true;
    } else {
      return false;
    }
  }

  dateFilter = (e, field) => {
    console.log(e, field);
    let self = this.state;
    this.setState(
      {
        [field]: e,
      },
      function () {
        this._getDataVal(this.state,1);
      }
    );
  };

  _getDataVal(val,updateClient=0) {
    var self = this;
    self.setState({ showLoader: true });
    if(updateClient===1){
      if(self.state.startDate && self.state.endDate ){
        let hairdresserId = localStorage.getItem("userId");
         let params = {
            searchKey: self.state.searchKey,
            hairdresserId: hairdresserId,
            empIds: self.state.selectedValue,
            listType: 1,
            isDownload: 0,
            startDate: self.state.startDate,
            endDate: self.state.endDate,
          };
          this.exportCSV(0);
          console.log('exportCsvData1',0)
          }
    }
  }

  componentWillUnmount() {
    let self = this;
    window.removeEventListener('scroll', self.handleScroll);
    document.body.classList.remove('diary-temp');
    document.removeEventListener("mousedown", this.handleClickOutsideFilter);
  }


  handleSelectChange = (value, event) => {
    let self = this;
    let hairdresserId = localStorage.getItem("userId");
    let params = {
      searchKey: '',
      hairdresserId:value?.length>1?hairdresserId:value?.length==1?value[0].value:hairdresserId,
      page: 1,
      listType: 1,
      isDownload: 0,
    };

    if (event.action === "select-option" && event.option.value == "0") {
      let _selected_staff = this.state.myData?.map((_selected) => {
        return _selected.value;
      });
      _selected_staff.splice(0, 1);

      console.log('check the all selected data',_selected_staff);

      this.setState({
        selectedStaffName: "All Staff Selected",
      });
     // params.empIds = _selected_staff;
      // this.getHairdresserClient(params);

      this.setState(
        { searchKey: '',
          selectedValue: _selected_staff,
          selectedOption: this.state.myData,
        },
        function () {
          self._getDataVal(self.state);
        }
      );
      self.exportCSV(0);
     this.getHairdresserClient(params);
    } else if (
      event.action === "deselect-option" &&
      event.option.value == "0"
    ) {
      this.setState({
        selectedStaffName: "",
      });
      this.setState(
        {
          searchKey: '',
          selectedValue: [],
          selectedOption: [],
        },
        function () {
          self._getDataVal(self.state);
        }
      );
    } else if (event.action === "deselect-option") {
      let selectedValue = [];
      this.setState({
        selectedStaffName: "",
        searchKey: '',
      });
      value.map((obj) => {
        if (obj.value != "0") {
          selectedValue.push(obj.value);
        }
      });
      this.setState(
        {
          searchKey: '',
          selectedValue,
          selectedOption: value.filter((o) => o.value !== "0"),
        },
        function () {
          self._getDataVal(self.state);
          self.exportCSV(0);
        }
      );
    } else if (value.length === this.state.myData.length - 1) {
      let _selected_staff = this.state.myData?.map((_selected) => {
        return _selected.value;
      });

      _selected_staff.splice(0, 1);
     // params.empIds = _selected_staff;
     // console.log("check the data here", params);
     // this.getHairdresserClient(params);
      
      this.setState(
        {
          searchKey: '',
          selectedValue: _selected_staff,
          selectedOption: this.state.myData,
        },
        function () {
          self._getDataVal(self.state);
          self.exportCSV(0);
        }
      );
    } else {
      let selectedValue = [];
      value.map((obj) => {
        //if(obj.value!="0"){
        selectedValue.push(obj.value);
        // }
      });
      //   params.empIds = _selected_staff;
      if (value?.length === 1) {
       // params.empIds = [value[0].value];
       // this.getHairdresserClient(params);
        this.getHairdresserClient(params);
        this.setState({
          searchKey: '',
          selectedStaffName: value[0].label,
        });
      } else {
        let empIds = value?.map((_selected_value) => {
          return _selected_value?.value;
        });
        params.hairdresserId = hairdresserId;
        this.getHairdresserClient(params);
        this.setState({
          searchKey: '',
          selectedStaffName: "",
        });
      }

      this.setState(
        {
          searchKey: '',
          selectedValue,
          selectedOption: value,
        },
        function () {
          self._getDataVal(self.state);
          self.exportCSV(0);
        }
      );
    }
  };

  handleSelectOpen = () => {
    document.body.classList.add('dairy-filter-menu_open')
    this.setState({
      isSelectopen: true,
    });
  };

  handleSelectClose = () => {
    document.body.classList.remove('dairy-filter-menu_open');
    this.setState({
      isSelectopen: false,
    });
  };

  componentDidUpdate(prevProps) {
    const _hairdresser =this.props.hairdresserClient;
    if (_hairdresser !== prevProps.hairdresserClient) {
      if(_hairdresser.client !=undefined){
        this.setState({
          hairdresserClient:_hairdresser?.client
        })
      }else{
        this.setState({
          hairdresserClient:[]
        })
      }
    }
  }

  handleClickOutsideFilter = (event) => {
    let self = this;
    if (this.filterRef.current && !this.filterRef.current.contains(event.target)) {
      self.setState({
        openDragFilterPopup :false,
        isSelectopen:false
     })
     if(!this.state.openDragFilterPopup){
        document.body.classList.remove('dairy-filter-menu_open');
     }
     self.props.dispatch(hairdresserActions.getHairdresserScheduleReset());
     if(self.state.selectedHairdressers?.length>0){
        self.props.dispatch(hairdresserActions.dairyFilterData(self.state.selectedHairdressers));
        self.createCalendarSlots(self.state.selectedHairdressers);
     }else{
        self.props.dispatch(hairdresserActions.dairyFilterData(self.state.staffList));
        self.createCalendarSlots(self.state.staffList);
     }
    }
  };

  
  render() {{console.log("marketibgproips---",this.props)}
    return (
      <div className="h_n">
        <div className="body_container cm_bg_white">
          <TopHeader title="Clients" {...this.props} />
          <SideNav {...this.props} />
          <div className="container">
            <div className="d-flex justify-content-between cm_mob_block client_top_container">
              {/* <div className='client_top_container_first'> */}
              <div  style={{zIndex:9999}}
                className={`client_filter ${
                  this.state.isSelectopen ? "openSelect" : "closeSelect"
                }`}
              >
                <ReactSelect
                  isSearchable={false}
                  options={this.state.myData}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option }}
                  onChange={this.handleSelectChange}
                  allowSelectAll={true}
                  placeholder="Select Staff"
                  isClearable={false}
                  // ref={this.myMultiselectRef}
                  // value={this.state.optionSelected}
                  // menuIsOpen
                  style={{menu:provided => ({
                    ...provided,
                    zIndex:999
                  })}}
                  onMenuOpen={this.handleSelectOpen}
                  onMenuClose={this.handleSelectClose}
                  menuPlacement="auto"
                  value={this.state.selectedOption}
                />
                {this.state.selectedStaffName !== "" ? (
                  <p className="selected_count">
                    {this.state.selectedStaffName}
                  </p>
                ) : null}

                {this.state.selectedValue.length > 0 &&
                this.state.selectedStaffName === "" ? (
                  <p className="selected_count">
                    {this.state.selectedValue.length} Staff Selected
                  </p>
                ) : null}
              </div>
              <div className="cm_search_wrapper client_search m-0">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.searchKey}
                  placeholder="Search…"
                  name="searchKey"
                  onChange={this.searchClient}
                />
                <i className="fa fa-search" />
              </div>
              {/* </div>
                            <div className='client_top_container_last'> */}
            
            {/* this code have filter last booking date */}
            {(this.state.isSalonOwner==1)?<div className="export_btn_parent btn-parent">
                <button
                  onClick={()=>this.exportCSV(1)}
                  className="btn btn-dark-blue justify-content-center"
                >
                  <span>
                    <DownloadIcon />
                  </span>
                  Export
                </button>
              </div>:null
            }
              
              <div className="export_btn_parent">
                <div className="start-end-datepicker">
                  <div className="form-group mb-0 sm_date_picker">
                    <DatePicker
                      popperPlacement="bottom-start"
                      selected={this.state.startDate}
                      onChange={(e) => this.dateFilter(e, "startDate")}
                      dateFormat={"dd-MM-yyyy"}
                    />
                  </div>
                  <span className="dash">-</span>
                  <div className="form-group mb-0 sm_date_picker">
                    <DatePicker
                      popperPlacement="bottom-end"
                      selected={this.state.endDate}
                      onChange={(e) => this.dateFilter(e, "endDate")}
                      dateFormat={"dd-MM-yyyy"}
                    />
                  </div>
                </div>
              </div>

              {this.checkReadOnly() ? (
                <Link
                  to="/clients/add"
                  style={{ lineHeight: "38px" }}
                  className="btn btn-dark pl-3 pr-3 add_client-btn"
                >
                  Add New Client
                </Link>
              ) : (
                ""
              )}
              {/* </div> */}
            </div>

            <div className="table-responsive client_table">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Number</th>
                    {/* <th>Last Booking Date </th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.hairdresserClient ? (
                    this.state.hairdresserClient.length > 0 ? (
                      this.state.hairdresserClient.map((client, i) =>
                        client.name !== "Unknown" ? (
                          <tr
                            key={client._id}
                            onClick={this.selectClient.bind(this, client._id)}
                            className="pointer-event"
                          >
                            <td className="alfa_text">
                              <h3 className="cm_fw_b">
                                {(this.state.hairdresserClient[i - 1] &&
                                  this.state.hairdresserClient[i]
                                    .nameLower[0] !==this.state.hairdresserClient[i - 1]?.nameLower[0]) ||
                                i == 0? client?.nameLower[0]?.toUpperCase(): ""}
                              </h3>
                            </td>
                            <td>
                              {/*<Link to={"/clients/"+client._id}>*/}
                              <img
                              alt=""
                                width="50px"
                                src={
                                  client.localAvatar
                                    ? client.localAvatar
                                    : require("../../assets/images/client-plachoder.jpg")
                                }
                              />
                              {/*</Link>*/}
                            </td>
                            <td>
                              {/*<Link to={"/clients/"+client._id}>*/}
                              {client.name}
                              {/*</Link>*/}
                            </td>
                            <td>
                              {this.checkEmail(client.email)
                                ? client.email
                                : "--"}
                            </td>
                            <td>
                              {client.phoneNumber ? client.phoneNumber : "--"}
                            </td>
                            {/* <td>{client?.lastBookingDate!=undefined?moment(client?.lastBookingDate).format('DD/MM/YYYY'):'__'}</td> */}
                          </tr>
                        ) : (
                          ""
                        )
                      )
                    ) : (<>
                {this?.state?.hairdresserLodingStatus? <tr>
                  <td colSpan={5} className="text-center">
                    <Lottie
                      options={defaultOptions}
                      height={50}
                      width={200}
                    />
                    <h4 className="">Loading Data...</h4>
                  </td>
                </tr>:<tr>
                    <td colSpan={5} className="text-center">
                      No Client available
                    </td>
                      </tr>}
                      </>)
                  ) : (
                    null
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Clients);
