import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { marketingAction } from "../../_actions/marketing.actions";
import { CardIcon, LinkIcon, PasswordIcons, PayoutAccount, SaloonIcon, UsersIcon,InvoiceIcon } from '../../assets/SVG';
import {connect} from "react-redux";
import { AutomatedCommunications } from '../SvgIcon';
import { withRouter } from 'react-router-dom';
import { FaBoxOpen } from 'react-icons/fa';


class SettingSidebar extends Component{

    constructor(props){
        super(props);
        this.state = {
            paymentSettingNav:[],
            settingNav:[
                {id: 1, url: '/settings/payout-account', title: 'Payout Account', bTitle: 'Add or edit your payout account', icon: <PayoutAccount />},
                {id: 2, url: '/settings/card-reader', title: 'Card Readers', bTitle: 'Take payments with a card reader', icon: <CardIcon />},
                {id: 3, url: '/settings/salon-details', title: 'Salon Settings', bTitle: 'Salon and client list settings and info', icon: <SaloonIcon />},
                {id: 4, url: '/settings/booking-url', title: 'Booking Settings', bTitle: 'Online bookings & no show protection', icon: <LinkIcon />},
                {id: 6, url: '/settings/billing', title: 'Billing', bTitle: 'Invoices and payment method', icon: <CardIcon />},   
                {id: 7, url: '/settings/profile', title: 'Profile Settings', bTitle: 'Update your personal profile', icon: <UsersIcon />},
                {id: 8, url: '/settings/account-password', title: 'Password', bTitle: 'Keep your account secure', icon: <PasswordIcons />},
            ],
            setSalonData:[],
            paymentSettingNavStatus:true,

        }
    }

    componentDidMount(){
        let self = this;
        console.log("self.props",self.props);
        this.getMarketingSetting();
       this.setMarketingNavigation(self.props.marketingSettingNavigation);
}

setMarketingNavigation(status){
    let _navData,self=this;
    if(status){
        _navData=[
            {id: 1, url: '/marketing', title: 'Automated Communications', bTitle: 'Reminders and appointment updates', icon: <AutomatedCommunications />},
            // {id: 2, url: '/blasts', title: 'Blasts', bTitle: 'Keep your chairs full with marketing blasts', icon: <CardIcon />},
            // {id: 3, url: '/invoices', title: 'Invoices', bTitle: 'SMS messages used and billing info', icon: <InvoiceIcon />},
            {id: 4, url: 'marketing-setting', title: 'Marketing Settings', bTitle: 'Manage your marketing settings', icon: <CardIcon />},   
        ]
    }else{
         _navData = [
            // {id: 3, url: '/invoices', title: 'Invoices', bTitle: 'SMS messages used and billing info', icon: <InvoiceIcon />},
        {id: 4, url: 'marketing-setting', title: 'Marketing Settings', bTitle: 'Manage your marketing settings', icon: <CardIcon />}];
    }

    self.setState({
        paymentSettingNav:_navData,
       })
}

getMarketingSetting(){
    let self = this;
    return self.props.dispatch(marketingAction.getMarketingSetting()).then((res) => {
      if (res.data.statusCode === 1) {
        if (res?.data?.responseData?.result.length>0) {
          let data = res?.data?.responseData?.result;
          if((data[0].salonConsent===0 && data[0].selfEmpConsent===1)||(data[0].salonConsent===1 && data[0].selfEmpConsent===0) || self.props?.isOwner===1 || (data[0].salonConsent===0 && data[0].selfEmpConsent===0)){
          self.props.dispatch(marketingAction?.marketingSettingNavigation(true))
           this.setMarketingNavigation(true);
          }else{
            self.props.dispatch(marketingAction?.marketingSettingNavigation(false))
            this.setMarketingNavigation(false);
          }
        }
      }
    });
  };

    handleRemoveClass = ()=>{
        document.querySelector('body').classList.remove('setting-temps-pages')
    }

    checkReadOnly=()=>{
        if(this.props.user&&this.props.user.userInfo&&(this.props.user.userInfo.hairdresser.isSalonOwner===1||this.props.user.userInfo.hairdresser.employmentTypeId===2)){
            return true;
        }else{
            return false;
        }
    }

    render(){
        // console.log("this_propssdsdsdsd",this.props);
        //console.log("this.state",this.state);
        return(
            <>
                <div className={`new-setting-menu-bar ${this.props.marketing ? '_market_sidebar':''}`}>
                    {this.props.marketing ? 
                     <div className='setting-menu-bar'>
                        <ul className='list-unstyled m-0 p-0'>
                        {this.state.paymentSettingNav.map((item, i)=>
                             <li key={'payment_nav_'+i}>
                             <NavLink to={item?.url} >
                               <span className='icon'> {item.icon}</span>
                               <span className='sTitle'>
                                   <strong className='d-block'>{item.title} </strong>
                                   <span className={`text-sm d-block ${item.redclass} `}>{item.bTitle}</span>
                               </span>
                             </NavLink>
                           </li>
                       )}
                        </ul>
                    </div> 
                   
                //    :this.props.service ? 
                //     <div className='setting-menu-bar'>
                //     <ul className='list-unstyled m-0 p-0'>
                    
                //     {settingNavDetail?.map((item, i)=>
                //             <li key={'payment_nav_'+i}>
                //             <NavLink to={item?.url} >
                //             <span className='icon'> {item.icon}</span>
                //             <span className='sTitle'>
                //                 <strong className='d-block'>{item.title} </strong>
                //                 <span className={`text-sm d-block ${item.redclass} `}>{item.bTitle}</span>
                //             </span>
                //             </NavLink>
                //         </li>
                //         )}
                //     </ul>
                //     </div>
                   :
                    <div className='setting-menu-bar'>
                        <ul className='list-unstyled m-0 p-0'>
                            {this.state.settingNav.map(item =>
                                this.checkReadOnly()===true?
                                    <li onClick={()=>this.handleRemoveClass()} key={item.id}>
                                        <NavLink to={item.url} >
                                    <span className='icon'>
                                        {item.icon}
                                    </span>
                                            <span className='sTitle'>
                                        <strong className='d-block'>{item.title}</strong>
                                        <span className={`text-sm d-block ${item.redclass} `}>{item.bTitle}</span>
                                    </span>
                                        </NavLink>
                                    </li>:(
                                this.checkReadOnly()===false&&item.id!==1&&item.id!==4&&item.id!==6?
                                <li onClick={()=>this.handleRemoveClass()} key={item.id}>
                                <NavLink to={item.url} >
                                    <span className='icon'>
                                        {item.icon}
                                    </span>
                                    <span className='sTitle'>
                                        <strong className='d-block'>{item.title}</strong>
                                        <span className='text-sm d-block'>{item.bTitle}</span>
                                    </span>
                                </NavLink>
                            </li>:"" ))}
                        </ul>
                    </div>
                     }
                </div>
          
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default withRouter(connect(mapStateToProps)(SettingSidebar));
// const settingNavDetail=[
//     {id: 1, url: '/new-services', title: 'Services', bTitle: 'Manage your service list', icon: <PayoutAccount />},
//     {id: 2, url: '/product', title: 'Product', bTitle: 'Manage products sold by business', icon: <FaBoxOpen />},
// ]