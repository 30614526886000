import axios from 'axios';
import API from "../config/API";
import siteSetting from "../config/env";

// Function to generate common headers
function getCommonHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
        'Content-Type': 'application/json',
        'Authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
}

// Function to add common parameters
function addCommonParams(params) {
    return {
        ...params,
        clientId: siteSetting.api_clientId,
        clientSecret: siteSetting.api_clientSecret
    };
}


export const newServices = {
    createCategory,
    editCategory,
    deleteCategory,
    listCategory,
    listServices,
    createServices,
    viewService,
    editServices,
    deleteServices,
    listHairdresser,
    categoryOrderChange,
    serviceOrderChange,
    serviceShareSetting,
    serviceStatusChange,
};

function createCategory(params) {
    console.log("under service page")
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.CREATE_CATEGORY, updatedParams, { headers });
    // return axios.post(API.CREATE_CATEGORY, params, {headers:headers})
}

function editCategory(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.EDIT_CATEGORY, updatedParams, { headers })
}

function deleteCategory(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.DELETE_CATEGORY, updatedParams, { headers })
}

function listCategory(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.LIST_CAETGORY, updatedParams, { headers })
}

function listServices(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.LIST_SERVICES, updatedParams, { headers })
}
function listHairdresser(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.LIST_HAIRDRESSER, updatedParams, { headers })
}
function createServices(params) {
    console.log("under service page")
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.CREATE_SERVICES, updatedParams, { headers });
    // return axios.post(API.CREATE_CATEGORY, params, {headers:headers})
}
function viewService(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.VIEW_SERVICES, updatedParams, { headers })
}
function editServices(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.EDIT_SERVICES, updatedParams, { headers })
}

function deleteServices(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.DELETE_SERVICES, updatedParams, { headers })
}

function categoryOrderChange(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.CATEGORY_ORDERCHANGE, updatedParams, { headers })
}

function serviceOrderChange(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.SERVICE_ORDERCHANGE, updatedParams, { headers })
}

function serviceShareSetting(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.SERVICE_SHARESETTING, updatedParams, { headers })
}

function serviceStatusChange(params) {
    const headers = getCommonHeaders();
    const updatedParams = addCommonParams(params);
    return axios.post(API.SERVICE_STATUSCHANGE, updatedParams, { headers })
}