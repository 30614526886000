import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Close, FilterIcon, MenuIcon, WhiteMenuicon } from "../SvgIcon";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MessagePopupBody from "../new-service/MessagePopupBody";
import useWindowSize from "../new-service/useWindowSize";
import CancelSaveBtn from "../new-service/add-edit-service/CancelSaveBtn";
import { useDispatch, useSelector } from "react-redux";
import MobileDotPopup from "../new-service/MobileDotPopup";
import {bookingActions, hairdresserActions, newServicesAction, userActions} from "../../_actions";
import toast from "react-toast-notification";
import siteSetting from "../../config/env";
const toggleMenuClass = () => {
  document.body.classList.toggle("nav_menu_open");
};

const TopHeader = (props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedClient, setSelectedClient] = useState({});
  const [openDropdownNav, setOpenDropdownNav] = useState(false);
  const [bookings, setBooking] = useState(false);
  const [newCategoryPopup,setNewCategoryPopup]=useState(false)
  const [mobileDotAdd ,setMobileDotAdd] = useState(false)
  const [animate, setAnimate] = useState(false);
  // const [categoryName,setCategoryName]= useState("")
  const changeStapPos = (val) => setStep(val);
  const { width } = useWindowSize();
  const newCategory = useSelector(
    (state) => state?.newCategory
  );
  console.log("newCategorynewCategorysdsdsdds",newCategory)
  const handleClose = () => {
    setShow(false);
    setStep(1);
  };

  useEffect(() => {
    let self=this;
    if (localStorage.getItem('accessToken')) {
      // let params={}
      // self.props.dispatch(userActions.profile(params))
      //     .then(function (response) {
      //
      //     })
    }else{
      props.history.push('/')
    }
    setSelectedClient(props?.selectedClient);
  }, [props?.selectedClient]);

  useEffect(() => {
    setBooking(props?.booking);
  }, [props?.booking]);

  const handleShow = () => setShow(true);

  let { title, cross } = props;
  // console.log("cross cross cross", props)
  const gotoSalon = () => {
    props.history.push("/settings/booking-url");
    setShow(false);
    setStep(1);
  };

  const goHome = () => {
    props.history.push("/diary/" + localStorage.getItem("salonId"));
  };
  console.log("props?.previousPageUrl",props?.previousPageUrl)
  const goToPrevPage = () => {
   
    if (props?.previousPageUrl != undefined) {
      let stateObj = props?.location?.state;
      props.history.push({
        pathname: props?.previousPageUrl,
        state: stateObj,
      });
    } else {
      window.history.back();
    }
  };

  const openDropdown = () => {
    setOpenDropdownNav(!openDropdownNav);
  };

  // const handleEditBooking=()=>{
  //         this.setState({
  //             rescheduleRequestModal:false,
  //             editBookingModal:true
  //         })
  //     }

  const editBooking = () => {
    //console.log("editBooking",this.props)

    const stateObj = { pageLoadingStatus: 1 };
    const url = "/new-booking-edit/" + props.bookingId;
    props.history.push({
      pathname: url,
      state: stateObj,
    });
  };

  const viewInvoice = () => {
    props.history.push("/invoice/" + props.bookingId);
  };

  const checkoutBooking = () => {
    // this.props.history.push('/booking-checkout/'+this.props.bookings._id)
    if (props?.checkoutBooking != undefined) {
      props.checkoutBooking();
    } else {
      const stateObj = { pageLoadingStatus: 1 };
      const url = "/checkout/" + props.bookingId;
      props.history.push({
        pathname: url,
        state: stateObj,
      });
      // props.history.push('/checkout/'+props.bookingId)
    }
  };

  const updateEmailBookingStatus = (status) => {
    let statusVal = status ? 1 : 0;
    props.updateEmailBookingStatus(statusVal);
  };


  useEffect(() => {
    if (newCategoryPopup || mobileDotAdd ) {
      setAnimate(true);
    }
  }, [newCategoryPopup,mobileDotAdd]);

  // const newServiceData = ()=>{
  //   debugger
  //     let payload = { }
  //     dispatch(newServicesAction.listHairDresserData(payload))
  //       .then((res) => {
  //         console.log("listHairDresserData", res);
  //       })
  //       .catch((error) => {
  //         console.error("Error edit category:", error);
  //       });
    
  // }
  const addNewService = () =>{
    history.push('/add-services')
  }
  const addNewCategory = () =>{
    setMobileDotAdd(false)
    setNewCategoryPopup(true)
  }

  const mobileDotPopup = () =>{
    setMobileDotAdd(true)
  }
  const handleCloseMobilePopup = () => {
    setAnimate(false);
    const timer = setTimeout(() => setMobileDotAdd(false), 300); 
    return () => clearTimeout(timer);
  };
  const handleCloseNewCategoryPopup = () => {
    setAnimate(false);
    const timer = setTimeout(() => setNewCategoryPopup(false), 300); 
   
    return () => clearTimeout(timer);
  };
const createCategory = (categoryName) => {
    console.log("under Received category name:", categoryName);
   
    const payload = {
      "categoryName": categoryName
    }
    
    console.log("Payload for creating category:", payload);
    dispatch(newServicesAction.createCategoryData(payload))
      .then((res) => {
        console.log("Category created successfully:", res);
        if(res?.newCategory?.statusCode===1) {
           toast.success(res?.newCategory?.responseData?.message);
        }
        else{
          toast.error(res?.newCategory?.error?.responseMessage);
        }
        handleCloseNewCategoryPopup();
        props.servicesList();
        props.categoryList()
      })
      .catch((error) => {
        console.error("Error creating category:", error);
      });

}



  const switchProfile=()=>{
    let self=this;
    let param={
        latitude: siteSetting.default_latitude,
        longitude:siteSetting.default_longitude,
        switchTo:0

    }
    //self.setState({dropdown:false})
    props.dispatch(hairdresserActions.hairdresserSwitchProfile(param))
        .then(function (response) {

            localStorage.setItem('defaultRedirectTo', 0);

            props.history.push('/')

            // if(response.data.data.userInfo&&response.data.data.userInfo.hairdresser.salon) {
            //    // localStorage.setItem('salonId',response.data.data.userInfo.hairdresser.salon._id);
            //
            // }
        })
  }

  const logout =() =>{
    let self = this;
    let _filterState = {
        allStaffStatus :true,
        workingStaffStatus:false,
        selectedFilterName:'all_staff',
        isFilterChange:false,
        isDefaultStaff:true,
    }
    props.dispatch(hairdresserActions.activeFilterStatus(_filterState));
    if (localStorage.getItem('accessToken')) {
        if(props.user) {
            let params = {
                userId: props.user.userInfo.userId
            }
            props.dispatch(userActions.logout(params))
                .then(function (response) {
                    localStorage.removeItem('persist:root');
                    localStorage.clear();
                    props.history.push("/");
                })
        }else{
            localStorage.removeItem('persist:root');
            localStorage.clear();
            props.history.push("/");
        }
    }else{
        localStorage.removeItem('persist:root');
        localStorage.clear();
        props.history.push("/");
    }
}

// console.log("this_props_selectedCheckoutList",props)
  return (
    <div className="top_header">
      {/*{console.log("props",props)}*/}
      <span onClick={toggleMenuClass} className="menu_toogle_icon">
        {/* <img src={require('../../assets/images/menu-icon.png')} />  */}
        <MenuIcon />
      </span>
      {props?.editPageStatus ? (
        <div className="salon_switch_container _header_toggle pl-0">
          <p className="ml-0 mr-3">Notify client of booking update</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={(e) => updateEmailBookingStatus(e.target.checked)}
              checked={props?.isMailOff ? true : false}
            />
            <span className="slider round"></span>
          </label>
        </div>
      ) : (
        ""
      )}
      <h1>{title}</h1>
      {console.log(props,"props?.userprops?.user")}
      {props?.headerButtonStatus !== true ? (
        <div className="user_pic">
          {localStorage.getItem("isShow") !== "true" ? (
            // <button onClick={handleShow} className="btn btn-dark online-btn">
            //   Online Bookings
            // </button>
            <></>
          ) : (
            ""
          )}
              <Dropdown>
                <Dropdown.Toggle  className={"more__option pr-1"} id="dropdown-basic">
                {/* require("../../assets/images/Pixie.png") */}
                {console.log("props_userImage12121212",props?.userImage)}
                <img src={props?.userImage ? props?.userImage : props?.user?.userInfo?.userImage?.circleImage  ? props?.user?.userInfo?.userImage?.circleImage : require("../../assets/images/Pixie.png")} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="more__option_menu">
                  <Dropdown.Item onClick={() => switchProfile()}>Switch to client</Dropdown.Item>
                  <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
          {/* <img
          src={props?.userImage ? props?.userImage : props?.user?.userInfo?.userImage?.circleImage}
            src={
              props.user &&
              props.user.userInfo &&
              props.user.userInfo.userImage.circleImage
                ? props.user.userInfo.userImage.circleImage
                : require("../../assets/images/client-plachoder.jpg")
            }
          /> */}
        </div>
        
      ) : (
        <>
        <div className="d-flex topbar_btn_group">
          {title === "View Booking" || title === "Completed Booking" ? (
            props?.noShowButtonStatus?null:<Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic-button"
                className={"more__option pr-1"}
              >
                More Options{" "}
                <img
                  src={require("../../assets/images/down-arrow-icon1.png")}
                  className={`toggle_arrow ${openDropdownNav ? "up" : "down"}`}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="more__option_menu">
                {props?.bookingStatus === 4 ? (
                  <Dropdown.Item onClick={viewInvoice}>
                    {" "}
                    <span >View Invoice </span>
                  </Dropdown.Item>
                ) : (
                  
                  <>
                    {bookings.bookingStatus !== 5 &&
                    bookings.bookingStatus !== "5" ? (
                      <>
                        <Dropdown.Item onClick={() => props?.showModalHendler("editBookingModal")}>
                          <span

                          >
                            Edit Booking
                          </span>
                        </Dropdown.Item>
                        {/*{selectedClient?._id==='64f5c7789a069cda0188fea3'?null:<Dropdown.Item ><span onClick={()=>props?.showModalHendler('rescheduleRequestModal')} >Reschedule Booking</span></Dropdown.Item>}*/}
                      </>
                    ) : null}
                    <Dropdown.Item onClick={() => props?.showModalHendler("cancelBookingModal")}>
                      <span
                        className="text-danger"

                      >
                        Cancel Booking
                      </span>
                    </Dropdown.Item>
                    {selectedClient?._id ===
                    "64f5c7789a069cda0188fea3" ? null : bookings.bookingStatus ===
                        1 ||
                      bookings.bookingStatus === "1" ||
                      bookings.bookingStatus === 0 ||
                      bookings.bookingStatus === "0" ? (
                      <Dropdown.Item onClick={() => props?.showModalHendler("noShowModal")}>
                        <span
                          className="text-danger"

                        >
                          No Show
                        </span>
                      </Dropdown.Item>
                    ) : null}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : null}

          {props?.deleteReminderButton?<button
            className={props?.type==='reminder'?"btn btn-danger ml-3 mr-3 pl-4 pr-4":"btn btn-danger ml-3 mr-3"}
            onClick={() => props?.deleteReminder()}
          >
            Delete
          </button>:null} 

          {props?.cancelButton && (props?.selectedCheckoutList?.length>0) && (props?.match?.path!=="/new-selectService/:hairdresserId/:indexId") ? (
            <button className="btn btn-white" onClick={() => goHome()}>
              Cancel
            </button>
          ) : null}

            {(props?.cancelButton && ((props?.match?.path=="/new-selectService/:hairdresserId/:indexId")||props?.match?.path=="/selectProduct/:salonId/:indexId")) ? (
                <button className="btn btn-white" onClick={() => goToPrevPage()}>
                Cancel
            </button>):null}

          {props?.cancelRefreshPage ? (
            <button className="btn btn-white" onClick={() => props?.resetAllSetting()}>
              Cancel
            </button>
          ) : null}

          {props?.saveReminderButton && props?.isLoadingStatus ? (
            <button
              className={props?.type==='reminder'?"btn btn-dark-blue ml-3 pl-4 pr-4":"btn btn-dark-blue ml-3"}
              onClick={() => props?.saveReminder()}
            >
              Save
            </button>
          ) : props?.saveReminderButton===undefined?null:(
            <button  className={props?.type==='reminder'?"btn btn-dark-blue ml-3 pl-4 pr-4":"btn btn-dark-blue ml-3"} disabled={true}>
              Save
            </button>
          )}

          {props?.saveBookingButton ? (
            <button
              className="btn btn-dark-blue ml-3"
              disabled={props?.isbookingSubmit === true ? true : false}
              onClick={() => props?.saveBooking()}
            >
              Save Booking
            </button>
          ) : null}

          {props?.saveCancellationButtonStatus ? (
            <button
              className="btn btn-dark-blue ml-3"
              disabled={props?.isPolicySubmit}
              onClick={() => props?.saveCancellationPolicy()}
            >
              Save 
            </button>
          ) : null}
          
          {props?.checkoutButton && props?.bookingStatus!=4 && props?.bookingStatus!=3 && props?.bookingStatus!=6 ?(
            <button
              className="btn btn-dark ml-3"
              disabled={props?.isbookingSubmit}
              onClick={checkoutBooking}
            >
              Checkout
            </button>
          ):null}

          {(props?.match?.path =="/newbooking/:salonId" || 
          props?.match?.path =="/event/:salonId" || 
          props?.match?.path == "/newbooking/:salonId/:hairdresserId" 
           )?
              <button className="btn btn-white ml-3"  onClick={() => goHome()}> Cancel</button>
             :props?.match?.path == "/marketing/reminder-update"?
             <button className="btn btn-white ml-3"  onClick={() => goToPrevPage()}> Cancel</button>:
             ''
              } 

            {/* <button className="btn btn-white  pl-4 pr-4" 
            onClick={() => props?.resetAllSetting()}
            > Cancel </button>
            <button
              className="btn btn-dark-blue ml-3  pl-4 pr-4"
              disabled={props?.isPolicySubmit}
              onClick={() => props?.saveCancellationPolicy()}
            > Save  </button> */}


        </div>
         {/* ------for checkout page profile dropdown */}
         {console.log("props.match.path",props?.match?.path)}
        {(props?.cancelButton &&(props?.match?.path=="/new-checkout/:salonId") &&  (
          props?.selectedCheckoutList?.length==0 || (props?.selectedCheckoutList !== null && typeof props?.selectedCheckoutList === 'object' && Object.keys(props?.selectedCheckoutList).length === 0 ))) ?
          <div className="user_pic">
            <Dropdown>
                <Dropdown.Toggle  className={"more__option pr-1"} id="dropdown-basic">
                <img src={props?.userImage ? props?.userImage : props?.user?.userInfo?.userImage?.circleImage} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="more__option_menu">
                  <Dropdown.Item onClick={() => switchProfile()}>Switch to client</Dropdown.Item>
                  <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
          </div>:null}


          </>
      )}
      <div className="d-flex topbar_btn_group _fix_mobile_add_btn">
         {props?.match?.path=="/new-services" ?
           <> {width > 991 ?
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic-button1"
                 className="more__option btn btn-dark-blue"
              >
               Add
              </Dropdown.Toggle>
              <Dropdown.Menu className="more__option_menu">
                <Dropdown.Item  className="w-100" onClick={addNewService}>New Service
                  {/* <Link to="/add-services"  className="w-100 d-block"><span>New Service</span></Link>  */}
                  </Dropdown.Item>
                <Dropdown.Item  className="w-100" onClick={addNewCategory}>  <span>New Category</span> </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            :<div className="more__option btn btn-dark-blue dropdown-toggle btn btn-primary" onClick={mobileDotPopup}> Add</div>
            }
          </>
          :
          (props?.match?.path=="/add-services" && width > 1101) ?
          <CancelSaveBtn  onSave={props.onSave}/>
         :(props?.match?.path=="/edit-services/:id" && width > 1101) ?
           <CancelSaveBtn  onSave={props.onSave}/>
           :''
         }
      </div>
       {/*{ console.log("ewewewweewe",props?.cancelButton , !(props.match.path=="/new-checkout/:salonId"))}*/}
      {(props?.cancelButton && !(props?.match?.path=="/new-checkout/:salonId")) ? (
        <span
          className="close-icon pointer-event cancel__mobile"
          onClick={() => goToPrevPage()}
        >
          <Close />
        </span>
      ) : null}
      {/* //show the cancel button only mobile */}

      {cross == "true" ? (
        <span className="close-icon pointer-event" onClick={goHome}>
          <Close />
        </span>
      ) : (
        ""
      )}

      <Modal show={show} onHide={handleClose} className="bg_white_modal">
        <i className="fa fa-times cm_hide_mod" onClick={handleClose} />
        <Modal.Body>
          {step == 1 ? (
            <div className="stap_wrapper">
              <div className="_img_wrap">
                <img src={require("../../assets/images/login-step-1.png")} />
              </div>
              <h2>Online Bookings</h2>
              <h3>
                Start taking bookings through your website and social media.
              </h3>
            </div>
          ) : (
            ""
          )}

          {step == 2 ? (
            <div className="stap_wrapper">
              <div className="_img_wrap">
                <img src={require("../../assets/images/login-step-2.png")} />
              </div>
              <h2>Get Discovered</h2>
              <h3>
                Get discovered by local clients through the Beu marketplace and
                rank higher in Google search results
              </h3>
            </div>
          ) : (
            ""
          )}

          {step == 3 ? (
            <div className="stap_wrapper">
              <div className="_img_wrap">
                <img src={require("../../assets/images/login-step-3.png")} />
              </div>
              <h2>Custom URL Link</h2>
              <h3>
                Simply add your URL to whatever platform you want to take online
                bookings with
              </h3>
            </div>
          ) : (
            ""
          )}

          {step == 4 ? (
            <div className="stap_wrapper">
              <div className="_img_wrap">
                <img src={require("../../assets/images/login-step-4.png")} />
              </div>
              <h2>Enable Online Bookings</h2>
              <h3>
                Make sure you have added a salon image, salon address and bank
                account if you want to take online payments
              </h3>
            </div>
          ) : (
            ""
          )}

          <ul className="stap-indicate">
            <li
              onClick={() => changeStapPos(1)}
              className={`${step == 1 ? "active" : ""}`}
            />
            <li
              onClick={() => changeStapPos(2)}
              className={`${step == 2 ? "active" : ""}`}
            />
            <li
              onClick={() => changeStapPos(3)}
              className={`${step == 3 ? "active" : ""}`}
            />
            <li
              onClick={() => changeStapPos(4)}
              className={`${step == 4 ? "active" : ""}`}
            />
          </ul>

          <div className="stap-action">
            {step == 4 ? (
              <React.Fragment>
                <button
                  className="btn btn-outline-dark mr-3"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button className="btn btn-dark" onClick={gotoSalon}>
                  Confirm
                </button>
              </React.Fragment>
            ) : (
              <button
                className="btn btn-dark"
                onClick={() => setStep(step + 1)}
              >
                Continue
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <MessagePopupBody
         centered="centered"
         show={newCategoryPopup} 
         onHide={handleCloseNewCategoryPopup} 
         backdropClassName="_light_bg"
         size="lg" 
         dialogClassName={`bottom-modal ${animate ? 'slide-in' : 'slide-out'}`}  
         className='_service__popup _no_animation'
         popupType="add"
          message="Category"
          submitFunction={createCategory}
        />
        <MobileDotPopup 
        blurClass="blure_bg"
        centered="centered"
        show={mobileDotAdd} 
        onHide={handleCloseMobilePopup}
        backdropClassName="_light_bg"
        dialogClassName={`bottom-modal ${animate ? 'slide-in' : 'slide-out'}`}
        size="lg"  
        className='_service__mobile_popup _no_animation'
        type="Add"
        linkName="/add-services"
        btnClickProps={addNewCategory}
        fade={false}
        /> 
        
    </div>
  );
};
export default TopHeader;


