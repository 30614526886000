import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Select } from 'react-select/dist/Select-a783e33f.cjs.prod';
import TopHeader from '../template/TopHeader';
import SideNav from '../template/SideNav';

function NewCustomReminder(props) {
    const [isEdit , setIsEdit]= useState(false)

    return(
        <div className="h_n">
            <div className="body_container cm_bg_white">
                <TopHeader title="Reminder" {...props}/>
                <SideNav {...props}/>
                <div className='new-setting-wrapper __reminder'>
                    <div className='container'>
                        <div className='row'>
                         
                            <div className="_reminder_section ">
                                <div className='new-stng-cmn _remind'>
                                  <h5 className="">Create Custom Automation</h5>
                                  <p className="mt-2 mb-3">Create a custom automation that sends a SMS or email based on your requirements.</p>
                                  {/* <div className={`input_group_u  _staff_member border_blue`}>
                                   <span className="_field_label_vk">Trigger time</span>
                                   {isEdit ?
                                    <Select />
                                    :
                                  <div className="service_selected1 disabled_div w-100">fdfffffff</div>}
                                  </div> */}
                                  <form className=''>
                                    <div className={`form-group input_group_u  _staff_member`}>
                                        <span className="_field_label_vk">Automation name</span>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className={`form-group input_group_u  _staff_member`}>
                                        <span className="_field_label_vk">Automation Description</span>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className={`form-group input_group_u  _staff_member`}>
                                        <span className="_field_label_vk">Automation Text</span>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className={`form-group input_group_u  _staff_member border_blue`}>
                                        <span className="_field_label_vk">Trigger</span>
                                        <Select />
                                    </div>
                                    <div className={`form-group input_group_u  _staff_member border_blue`}>
                                        <span className="_field_label_vk">Trigger time</span>
                                        <Select />
                                    </div>
                                  </form>
                                </div>
                                <div className='new-stng-cmn _sms_reminder'>
                                    <div className='_image'>
                                    <img src={require('../../assets/images/SMSPreviewSVG.svg')} className="img-fluid mb-3" />
                                    </div>
                                    <div className='_text'>
                                       <div className='_head d-flex'>
                                        <h4>SMS Reminder</h4>
                                            <div className='salon_switch_container'>
                                                <label className="switch" >
                                                    <input type="checkbox"  />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                       </div>
                                       <p className='mt-2 mb-4'>£0.04/ message</p>
                                       <p>SMS reminders are sent to <b>all clients</b> before their appointment to cut down on no-shows.</p>
                                       <Link to="/" className="_link">Preview SMS</Link>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
)
}

export default NewCustomReminder