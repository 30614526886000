export const newServicesConstants = {
    // CREATE_CATEGORY: 'CREATE_CATEGORY',
    CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
    CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
    CREATE_CATEGORY_ERROR: 'CREATE_CATEGORY_ERROR',

    EDIT_CATEGORY_REQUEST: 'EDIT_CATEGORY_REQUEST',
    EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
    EDIT_CATEGORY_ERROR: 'EDIT_CATEGORY_ERROR',

    DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',

    LIST_CATEGORY_REQUEST: 'LIST_CATEGORY_REQUEST',
    LIST_CATEGORY_SUCCESS: 'LIST_CATEGORY_SUCCESS',
    LIST_CATEGORY_ERROR:   'LIST_CATEGORY_ERROR',




    CREATE_SERVICES_REQUEST: 'CREATE_SERVICES_REQUEST',
    CREATE_SERVICES_SUCCESS: 'CREATE_SERVICES_SUCCESS',
    CREATE_SERVICES_ERROR  : 'CREATE_SERVICES_ERROR',

    EDIT_SERVICES_REQUEST: 'EDIT_SERVICES_REQUEST',
    EDIT_SERVICES_SUCCESS: 'EDIT_SERVICES_SUCCESS',
    EDIT_SERVICES_ERROR  : 'EDIT_SERVICES_ERROR',

    DELETE_SERVICES_REQUEST: 'DELETE_SERVICES_REQUEST',
    DELETE_SERVICES_SUCCESS: 'DELETE_SERVICES_SUCCESS',
    DELETE_SERVICES_ERROR  : 'DELETE_SERVICES_ERROR',

    LIST_SERVICES_REQUEST: 'LIST_SERVICES_REQUEST',
    LIST_SERVICES_SUCCESS: 'LIST_SERVICES_SUCCESS',
    LIST_SERVICES_ERROR:   'LIST_SERVICES_ERROR',

    VIEW_SERVICE_REQUEST: 'VIEW_SERVICE_REQUEST',
    VIEW_SERVICE_SUCCESS: 'VIEW_SERVICE_SUCCESS',
    VIEW_SERVICE_ERROR  : 'VIEW_SERVICE_ERROR',
   
    LIST_HAIRDRESSER_REQUEST: 'LIST_HAIRDRESSER_REQUEST',
    LIST_HAIRDRESSER_SUCCESS: 'LIST_HAIRDRESSER_SUCCESS',
    LIST_HAIRDRESSER_ERROR:   'LIST_HAIRDRESSER_ERROR',

    CATEGORY_ORDERCHANGE_REQUEST: 'CATEGORY_ORDERCHANGE_REQUEST',
    CATEGORY_ORDERCHANGE_SUCCESS: 'CATEGORY_ORDERCHANGE_SUCCESS',
    CATEGORY_ORDERCHANGE_ERROR:   'CATEGORY_ORDERCHANGE_ERROR',

    SERVICE_ORDERCHANGE_REQUEST: 'SERVICE_ORDERCHANGE_REQUEST',
    SERVICE_ORDERCHANGE_SUCCESS: 'SERVICE_ORDERCHANGE_SUCCESS',
    SERVICE_ORDERCHANGE_ERROR:   'SERVICE_ORDERCHANGE_ERROR',

    SERVICE_SHARESETTING_REQUEST: 'SERVICE_SHARESETTING_REQUEST',
    SERVICE_SHARESETTING_SUCCESS: 'SERVICE_SHARESETTING_SUCCESS',
    SERVICE_SHARESETTING_ERROR:   'SERVICE_SHARESETTING_ERROR',

    SERVICE_STATUSCHANGE_REQUEST: 'SERVICE_STATUSCHANGE_REQUEST',
    SERVICE_STATUSCHANGE_SUCCESS: 'SERVICE_STATUSCHANGE_SUCCESS',
    SERVICE_STATUSCHANGE_ERROR:   'SERVICE_STATUSCHANGE_ERROR'
    
}
