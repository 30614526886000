import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bookingActions, paymentActions } from '../../_actions';

export class BookingDetail extends Component {

    BookingDetails=(id)=>{
        let getparams = {
            bookingId: id,
            getBookingsOfType: 'upcoming',
            deviceToken: 'sfsadfsdfds',
            error: ''
        }

        this.props.dispatch(bookingActions.getBooking(getparams))
        .then(function (response) {
            console.log("BookingDetailresponse=>",response)
        }
      )

    }

componentDidMount(){
    // console.log("this.props.match.param",this.props.match.params)
    const {bookingId}=this.props.match.params
    this.BookingDetails(bookingId)
}
  render() {
    return (
        <React.Fragment>
                    {/* <div className="contentBg" >
                                            <div className="booking_dateinfo">
                                                <div className="review_star">
                                                <span>
                                                </span>
                                                </div>
                                                <div className="date_info booking_confirm">
                                                <h4 className="text-right">{this.getBookingDateTime(value.bookingSlot[0],value.bookingDate)}</h4>
                                                    <h5 className="text-right">{getslotTime(value.bookingSlot[0])} - {getslotTime(value.bookingSlot[1])}</h5>
                                                </div>
                                            </div>
                                            <div className="row p-rel">
                                                <div className="col-sm-6 pad-R0">
                                                <img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer','max-width': '100%','min-width': '100%'}}
                                                        src={value.hairdresser.hairdresser.salon?.imageUrl  ? value.hairdresser.hairdresser.salon?.imageUrl : require('../../assets/images/salonpic.png')}
                                                        className="img-fluid imgheight" />
                                                </div>
                                                <div className="team-round-img"><img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer'}}
                                                    src={value.hairdresser.userImage.circleImage ? value.hairdresser.userImage.circleImage : require('../../assets/images/Pixie.png')}
                                                    className="cm_team-round-img"/></div>
                                                <div className="col-sm-6 pad-L0">
                                                    <div className="gmap_canvas">
                                                        <a href={'http://maps.google.com/?q='+value.salonInfo.loc[1]+','+value.salonInfo.loc[0]} target="_blank">
                                                        <MapWithAMarker
                                                            googleMapURL={mapUrl}
                                                            loadingElement={<div style={{ height: `100%` }} />}
                                                            containerElement={<div style={{ height: `100%` }} />}
                                                            mapElement={<div style={{ height: `100%` }} />}
                                                            isMarkerShown={true}
                                                            lat={value.salonInfo.loc[1]}
                                                            lng={value.salonInfo.loc[0]}
                                                        />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="booking_dateinfo" style={{alignItems: 'center'}}>
                                                <div className="review_star  col-md-6 pad-0">
                                                    {value.servicesBooked.map((service, index) => (
                                                        <h6 key={'service_'+index}>{service.service.serviceName}, {value.currencySymbol&&value.currencySymbol!=''?value.currencySymbol:"£"} {service.servicePrice.toFixed(2)} </h6>
                                                    ))}
                                                    <p>{value.hairdresser.name} from {value.salonInfo.salonName}</p>
                                                </div>
                                                <div className="date_info colgray col-md-6 pad-0">
                                                    <h6 className="text-right">{value.salonInfo.formattedAddress}</h6>

                                                </div>
                                            </div>
                                            <div className="booking-footer">
                                                <div>
                                                    <p>{value.bookingStatusText}, {value.bookingPaymentMethod}</p>
                                                </div>
                                            </div>
                    </div>   
                */}
        </React.Fragment>
    )
  }
}


function mapStateToProps(state) {
    const { upcomingOrPastBooking,
    } = state;
    return {
        upcomingOrPastBooking
    };
}

export default connect(mapStateToProps)(BookingDetail);