import React, { Component } from "react";
import moment from "moment";
import SideNav from "../template/SideNav";
import TopHeader from "../template/TopHeader";
import ClientCheckout from "./ClientCheckout";
import { connect } from "react-redux";
import {
  bookingActions,
  hairdresserActions,
  paymentActions,
  userActions,
  productAction,
} from "../../_actions";
import siteSetting from "../../config/env";
import dateFormat from "dateformat";
import Checkout from "./Checkout";
import toast from "react-toast-notification";
import { bookingService, paymentService } from "../../_services";
import { loadStripeTerminal } from "@stripe/terminal-js";
import BookingService from "./BookingService";
import BookingProduct from "./BookingProduct";
var _ = require("lodash");

let userIdHolder = [];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getslotTime(time) {
  let hr = parseInt(time / 60);
  let min = time - hr * 60;
  return (
    (hr <= 9 ? "0" + hr : hr) +
    ":" +
    (min == 0 ? "00" : min <= 9 ? "0" + min : min) +
    ":00"
  );
}

var terminal;

// loadStripeTerminal().then(function (StripeTerminal) {
//   terminal = StripeTerminal.create({
//     onFetchConnectionToken: fetchConnectionToken,
//     onUnexpectedReaderDisconnect: unexpectedDisconnect,
//   });
//   console.log("terminal", terminal);
// });

function fetchConnectionToken() {
  let self = this;
  return paymentService.fetchConnectionToken().then(function (res) {
    console.log("fetchConnectionToken res", res.data);
    return res.data.responseData.result.secret;
  });
}

function unexpectedDisconnect() {
  console.timeLog("disconnect@@@@@@@@");
}

class BookingCheckout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingDate: new Date(),
      selectSlot: "",
      showOptionsModal: false,
      showTipModal: false,
      latitude: siteSetting.default_latitude,
      longitude: siteSetting.default_longitude,
      hairdresserName: "",
      selectedService: [],
      finalBookingSlots: [],
      includeTimes: [],
      includeEndTimes:[],
      selectEndTimeSlot:'',
      slotInfo: {},
      totalAmount: 0,
      priceError:'',
      priceErrorStatus:true,
      prevPrice: 0,
      servicePrice: 0,
      totalTime: 0,
      hairdresserId: "",
      selectedClientId: "",
      selectedClient: {},
      isSaveBooking: 0,
      bookingSlots: [],
      paymentType: "",
      isBookingAllowed: false,
      searchKey: "",
      bookingType: "past", //upcoming
      pendingAmount: 0,
      transaction: [],
      paymentAmount: 0,
      totalPaymentAmount: 0,
      isCheckout: 1,
      userId: "",
      isInvoice: 0,
      currencySymbol: "£",
      bookingId: "",
      isServiceEdit: false,
      transactions: [],
      invoiceNumber: 0,
      selectedPaymentType: null,
      selectedAmount: "",
      oldTransaction: [],
      serviceHardresserList: [],
      checkOutAmountType: true,
      selectedFixedAmount: 0,
      isPaymentComplete: 0,
      isMobileEnableState: true,
      locationId: "",
      selectedCardReader: {},
      paymentIntents: {},
      isCardReaderConnected: false,
      isShowConnectionLoader: false,
      registrationCode: "",
      showDiscoversModal: false,
      isCheckoutPayment: false,
      payoutError: "",
      cardReaderConnectError: "",
      label: "",
      tipAmount: 0,
      showService: false,
      showProduct: false,
      renderedServiceContent: [],
      renderedProductContent: [],
      selectedServices: [],
      allServices: [],
      fromServices: [],
      endTimeSlot: new Date(),
      hairDresserName: "",
      selectedTime: "",
      newStartTime: "",
      durationError:'',
      productList: [],
      checkoutSelectedItems: [],
      staffList: [],
      selectedClientRecord: null,
      selectedTotalAmount: 0,
      checkOutLoadingStatus:1,
      isTrStart:false,
      startTimeError:'',
      paymentMethodInfo:{},
      // serviceSelect : null
    };
    this.editBooking = this.editBooking.bind(this);
    this.resetBooking = this.resetBooking.bind(this);
    this.checkoutBooking = this.checkoutBooking.bind(this);
    this.serPaymentType = this.serPaymentType.bind(this);
    this.onChanged = this.onChanged.bind(this);
    this.updateBooking = this.updateBooking.bind(this);
    this.completeSale = this.completeSale.bind(this);
    this.removeCheckoutItem = this.removeCheckoutItem.bind(this);
    this.setSelectedCheckoutData = this.setSelectedCheckoutData.bind(this);
    this.setSelectedProduct = this.setSelectedProduct.bind(this);
    this.setSelectedService = this.setSelectedService.bind(this);
    this.calculateCheckoutItem = this.calculateCheckoutItem.bind(this);
    this.changeItemPrice = this.changeItemPrice.bind(this);
    this.selectEndTime = this.selectEndTime.bind(this);
    this.changeServiceDuration =this.changeServiceDuration.bind(this);
    this.addCheckoutItem =this.addCheckoutItem.bind(this);
    // this.updateNewEditAmount =this.updateNewEditAmount.bind(this);
  }


  componentDidMount() {
    var self = this;
    let pageStatus;
    const { state } = this.props.location;
    if (state) {
    const { pageLoadingStatus } = state;
        pageStatus = pageLoadingStatus!==undefined?pageLoadingStatus:0;
      if(pageLoadingStatus===1){
        // all redux clear here
        self.props.dispatch(hairdresserActions.selectedService({}));
        self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
        self.props.dispatch(bookingActions?.hairdresserNewBookingClient({})
        );
        self.props.dispatch(bookingActions?.selectHairdresserBookingDate({}));
        self.props.dispatch(bookingActions?.clientBookingSlots({}))
        self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({}));
        self.props.dispatch(productAction.selectedClient({}));
        self.props.dispatch(productAction?.selectCheckoutItem({}));
        self.props.dispatch(productAction?.selectedCheckoutList([]));
        let serviceData = [];
        self.props.dispatch(productAction?.selectedCheckoutList(serviceData));
        self.setState({
          checkoutSelectedItems:[],
        });
      } 
    }

    self.setSelectedService();
    let params = {
      defaultRedirectTo: localStorage.getItem("defaultRedirectTo"),
    };

    let selectedHairdresserId = self?.props?.hairdresserNewBookingStaff?._id!==undefined?self?.props?.hairdresserNewBookingStaff?._id:localStorage.getItem("userId");

    let params1 = {
      searchKey: this.state.searchKey,
      hairdresserId: selectedHairdresserId,
      page: 1,
      listType: 1,
    };
    let selectedHairdresserService = [];
    if (
      localStorage.getItem("accessToken") &&
      this.props.match.params.bookingId !== ""
    ) {
      self.props
        .dispatch(userActions.profile(params))
        .then(function (response) {
          //console.log("response",response.data.data);
          if (response.data.data) {
            self.setState({
              locationId: response?.data?.data?.userInfo?.hairdresser
                ?.stripeLocationId
                ? response?.data?.data?.userInfo?.hairdresser?.stripeLocationId
                : "",
            });
            self.cardReaderList(response.data.data.userInfo);
          }
        });
      //self.cardReaderList();
   
      if(self.props.match.params.bookingId!==undefined){
        let bookingParams = {
          bookingID: self.props.match.params.bookingId,
          getBookingsOfType: "updates",
        };

        self.props
        .dispatch(bookingActions?.getHairdresserBooking(bookingParams))
        .then(function (response) {
          console.log("response",response);
          let servicesBooked = [];
          let selectedProduct = [];
          let totalTime = 0;
          const { bookingDate, user, userId, bookingSlot} = response?.data?.data?.bookings[0];
          totalTime = bookingSlot[1]-bookingSlot[0];
          self.setState({
            newStartTime: bookingDate,
            selectedClient: user,
            selectedClientId: userId,
            selectedStaff:response?.data?.data?.bookings[0]?.hairdresser,
          });
          self.props.dispatch(bookingActions?.hairdresserNewBookingStaff(response?.data?.data?.bookings[0]?.hairdresser));
          let  totalAmount = 0;
          let bookingData = response.data.data.bookings[0];
          let services__Data= [];
          let prev = bookingData?.servicesBooked.map(
              (_service, i) => {
                _service.type = "service";
                _service.indexId = i + 1;
                _service.servicePrice =_service.servicePrice?.toFixed(2);
                services__Data.push(_service)
                return _service;
              }
          );

          if(self.props.selectedCheckoutList?.length<=0 || self?.props?.location?.state?.pageLoadingStatus==1) {
            self.props.dispatch(productAction?.selectedCheckoutList(prev));
          }

          let _selectedProductAndService =
            self.props?.selectedCheckoutList?.length > 0
              ? self.props?.selectedCheckoutList
              : bookingData.servicesBooked;

            _selectedProductAndService.map((selectedItem, i) => {
                if (selectedItem?.type === "product") {
                  totalAmount =
                    selectedItem?.retailPrice !== undefined ? totalAmount + parseFloat(selectedItem?.retailPrice) : 0;
                  selectedProduct.push(selectedItem);
                } else {
                  if (selectedItem.service?.serviceId || selectedItem?.id) {
                    // totalTime = totalTime + selectedItem.serviceTime;
                    totalAmount = totalAmount + parseFloat(selectedItem?.servicePrice);
                    selectedItem.serviceId = selectedItem.service?.serviceId || selectedItem?.service?.id;
                    selectedItem.serviceName = selectedItem.service?.serviceName || selectedItem?.serviceName;
                    servicesBooked.push(selectedItem);
                    selectedHairdresserService.push(selectedItem.service?.id || selectedItem?._id);
                  }
                }
              });

        
          self.serviceHairdresserList(
            bookingData?.salonId,
            selectedHairdresserService
          );
          let totalPaidAmount = 0;
          if (bookingData?.transaction && bookingData?.transaction.length > 0) {
            bookingData.transaction.map((transaction, i) => {
              if (transaction.paymentType !== 5) {
                totalPaidAmount =
                  totalPaidAmount + parseFloat(transaction.amount);
              }
            });
          }
          if (bookingData.transactions && bookingData.transactions.length > 0) {
            bookingData.transactions.map((transaction, i) => {
              totalPaidAmount =
                totalPaidAmount + parseFloat(transaction.pgTransactionAmount);
            });
          }
          let pendingAmount = totalAmount - totalPaidAmount;
          let paymentAmount = pendingAmount;
          if (pendingAmount > 0) {
            self.setState({
              isServiceEdit: true,
              selectedTotalAmount:totalAmount,
            });
          }

          let totalPaymentAmount = totalPaidAmount;
          let incTimeSlot = new Date(bookingData.bookingDate);
          // let newDateTime = bookingData.bookingDate;
          let hr = parseInt(bookingData.bookingSlot[0] / 60);
          let min = bookingData.bookingSlot[0] - hr * 60;
          incTimeSlot.setHours(hr);
          incTimeSlot.setMinutes(min);

          let slotInfoData = {
            startTime: bookingData.bookingSlot[0],
            endTime: bookingData.bookingSlot[1],
          }

          

          self.props.dispatch(bookingActions?.clientBookingSlots(slotInfoData))
           
          console.log('check hairdresser booking',incTimeSlot);
          // self.selectEndTime(incTimeSlot,totalTime);
          self.setState(
            {
              slotInfo: {
                startTime: self.getslotTime(bookingData.bookingSlot[0]),
                endTime: self.getslotTime(bookingData.bookingSlot[1]),
              },
              checkoutSelectedItems: _selectedProductAndService,
              salonId: bookingData.salonId,
              selectSlot: incTimeSlot,
              newStartTime: incTimeSlot,
              selectedTime:self.getslotTime(bookingData.bookingSlot[0]),
              transaction: bookingData.transaction,
              bookingId: self.props.match.params?.bookingId,
              bookingDate: new Date(bookingData.bookingDate),
              selectedService: servicesBooked,
              totalAmount: totalAmount.toFixed(2),
              totalTime: totalTime,
              pendingAmount: pendingAmount,
              paymentAmount,
              totalPaymentAmount,
              transactions: bookingData.transactions,
              oldTransaction: bookingData.transaction,
              hairdresserId: bookingData.hairdresserId,
              hairdresserName: bookingData.hairdresser.name,
              userId: bookingData.userId,
              currencySymbol:
                bookingData.hairdresser.hairdresser &&
                bookingData.hairdresser.hairdresser.currencySymbol &&
                bookingData.hairdresser.hairdresser.currencySymbol !== "" &&
                bookingData.hairdresser.hairdresser.currencySymbol !==
                  "undefined"
                  ? bookingData.hairdresser.hairdresser.currencySymbol
                  : "£",
            },
            function () {
              self.selectEndTime(incTimeSlot,totalTime)
              self.selectedHairdresser(
                self.state.hairdresserId,
                bookingData.hairdresser.name,
                1,
                bookingData.hairdresser
              );
              self.selectDay(self.state.bookingDate);
            }
          );
          self.props.dispatch(
            hairdresserActions.getHairdresserClientDetail({
              userId: bookingData.userId,
              hairdresserId: bookingData.hairdresserId,
              bookingType: self.state.bookingType,
            })
          );
        });
                 
      
      }
      
      self.props
        .dispatch(hairdresserActions.hairdresserStaffRota(params1))
        .then(function (response) {
          const hairdresserData = response?.data?.data?.rota;
          if(hairdresserData?.length>0){
          let _f_hairdresser =  hairdresserData?.find(_hairdresser=>_hairdresser?._id===selectedHairdresserId);
          
          // if(_f_hairdresser?._id!==undefined){
          //   self.setState({
          //     selectedStaff:_f_hairdresser
          //   })
          // }
        }
        });
    } else {
      this.props.history.push("/");
    }

    let { hairdresser, hairdresserBooking } = this.props;
    if (
      hairdresserBooking?.bookings &&
      hairdresserBooking?.bookings?.length > 0
    ) {

      let services__Data= [];
      let bookingData = self?.props?.location?.state?.pageLoadingStatus==2?self.props?.selectedCheckoutList:hairdresserBooking?.bookings[0]?.servicesBooked
     // checkoutSelectedItems
      let prev = bookingData.map(
        (_service, i) => {
            _service.type = "service";
            _service.indexId = i + 1;
            services__Data.push(_service)
            return _service;
        }
      );

      //console.log('checkfffffData',prev,_selectedProductAndService);

      this.props.dispatch(productAction?.selectedCheckoutList(prev))
          .then(function (){
            self.calculateCheckoutItem();
          })
      this.setSelectedCheckoutData();

      let servicesData = [];
      let hairdresserServices = hairdresser?.hairdresserServices;
      for (let i = 0; i < hairdresserServices?.length; i++) {
        let category = hairdresserServices[i].hairdresserService;
        for (let j = 0; j < category.length; j++) {
          servicesData.push(category[j]);
        }
      }

      let temp = [];
      // console.log("llllllllll",self?.state?.newStartTime);

      const filterData = servicesData.filter(
        (serviceData) =>
          !prev.some(
            (prevService) => prevService.serviceId === serviceData.serviceId
          )
      );

      for (let i = 0; i < prev.length; i++) {
        temp.push(filterData);
      }

      let staffMember =
        this?.props?.hairdresserBooking?.bookings[0]?.hairdresser.name;

  
      this.setState({
        allServices: servicesData,
        fromServices: temp,
        selectedServices: services__Data,
        hairDresserName: staffMember,
        newStartTime: self?.state?.selectSlot,
      });

    }
  }

  handleOptionModal = () =>
    this.setState({ showOptionsModal: !this.state.showOptionsModal });

  handleTipModal = () => {
    this.setState({
      showOptionsModal: false,
      showTipModal: !this.state.showTipModal,
    });
  };

  handleDiscoverModal = () => {
    this.setState({
      showDiscoversModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showDiscoversModal: false,
    });
  };



  // componentDidUpdate(prevProps) {
  //   if (prevProps?.selectedCheckoutList !== this.props.selectedCheckoutList) {
  //     console.log('calculateCheckoutItem',this.props.selectedCheckoutList)
  //     this.calculateCheckoutItem(this.props.selectedCheckoutList);
  //   }
  // }

  serviceHairdresserList = (salonId, selectedHairdresserService) => {
    let self = this;
    let hairdresserListParam = {
      salonId: salonId,
      selectedService: selectedHairdresserService,
    };
    self.props
      .dispatch(hairdresserActions.serviceHairdresserList(hairdresserListParam))
      .then(function (response) {
        console.log("response", response);
        if (response.data.errorCode) {
          self.setState({
            error: response.data.error.messages,
          });
        } else {
          self.setState({
            serviceHardresserList: response?.data?.data,
          });
        }
      });
  };

  selectedHairdresser(hairdresserId, hairdresserName, type, hairdresser) {
    // console.log("====",hairdresserId, hairdresserName,type,hairdresser)
    this.setState({ isSelected: true });
    this.setState({
      startTimeError: "",
      serviceError: "",
      hairdresserId,
      hairdresserName,
    });
    // console.log("salonId",salonId);
    //this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
    let params = {};
    let self = this;
    params.hairdresserId = hairdresserId;
    params.latitude = this.state.latitude;
    params.longitude = this.state.longitude;
    params.history = this.props.history;
    if (type === 1) {
      //self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': hairdresserId, page: 1, listType: 1 }))
    }

    self.props
      .dispatch(hairdresserActions.getHairdresserInfo(params))
      .then(function (response) {
        if (response.data.errorCode) {
          self.setState({
            error: response.data.error.messages,
          });
        } else {
          self.setState(
            {
              currencySymbol:
                hairdresser.hairdresser.currencySymbol &&
                hairdresser.hairdresser.currencySymbol != "" &&
                hairdresser.hairdresser.currencySymbol != "undefined"
                  ? hairdresser.hairdresser.currencySymbol
                  : "£",
            },
            function () {
              self.bookingSlotCreate(0);
            }
          );
        }
      });
  }

  changeHairdresser(event) {
    const selectedHairdresserId = event.target.value;
    const selectedHairdresser = this.state.serviceHardresserList.find(
      (h) => h._id === selectedHairdresserId
    );

    // console.log("====", selectedHairdresserId, selectedHairdresser.name, 1, selectedHairdresser);

    this.setState({
      isSelected: true,
      startTimeError: "",
      serviceError: "",
      hairdresserId: selectedHairdresserId,
      hairdresserName: selectedHairdresser.name,
    });

    let params = {};
    let self = this;
    params.hairdresserId = selectedHairdresserId;
    params.latitude = this.state.latitude;
    params.longitude = this.state.longitude;
    params.history = this.props.history;

    if (selectedHairdresser.type === 1) {
      // self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': selectedHairdresserId, page: 1, listType: 1 }))
    }

    self.props
      .dispatch(hairdresserActions.getHairdresserInfo(params))
      .then(function (response) {
        if (response.data.errorCode) {
          self.setState({
            error: response.data.error.messages,
          });
        } else {
          self.setState(
            {
              bookingSlots: response.data.data.bookingSlots,
              date: new Date(),
              isBookingButton: false,
              selectedDay: new Date(),
              month: monthNames[new Date().getMonth()],
              year: new Date().getFullYear(),
              selectedBookingSlots: "",
              selectedBookingSlotsIndex: "",
              finalBookingSlots: [],
              includeTimes: [],
              currencySymbol:
                selectedHairdresser.hairdresserInfo.currencySymbol &&
                selectedHairdresser.hairdresserInfo.currencySymbol !== "" &&
                selectedHairdresser.hairdresserInfo.currencySymbol !==
                  "undefined"
                  ? selectedHairdresser.hairdresserInfo.currencySymbol
                  : "£",
            },
            function () {
              self.bookingSlotCreate(0);
            }
          );
        }
      });
  }

  bookingSlotCreate(index) {
    let self = this;
    let finalBookingSlots = [];
    let includeTimes = [];
    let pre;
    let next;
    let tempArr = [];
    let flag = false;
    let k = index;
    let arrslots = [];
    let bookingSlots = self.state.bookingSlots;
    //bookingSlots.map(function(slots, i) {
    for (let i = index; i < bookingSlots.length; i++) {
      // console.log(index,bookingSlots.length,i, self.state.totalTime, bookingSlots[i],bookingSlots[i].status);
      let check = false;
      //console.log('=====',tempArr,bookingSlots[i]);
      if (bookingSlots[i].status === 0 || bookingSlots[i].status === 3) {
        tempArr.push(bookingSlots[i]);
        //console.log('tempArr', tempArr,k,i);
        if (
          tempArr[tempArr.length - 1]["endTime"] - tempArr[0]["startTime"] >=
          self.state.totalTime
        ) {
          finalBookingSlots.push(tempArr[0]);

          let hr = parseInt(tempArr[0].startTime / 60);
          let min = tempArr[0].startTime - hr * 60;

          let incTimeSlot = new Date(self.state.bookingDate);
          //console.log("incTimeSlot",incTimeSlot);
          incTimeSlot.setHours(hr);
          incTimeSlot.setMinutes(min);

          includeTimes.push(incTimeSlot);
          //console.log("tempArr[0]",incTimeSlot,includeTimes)

          for (let m = 0; m < bookingSlots.length; m++) {
            //console.log("###",tempArr[0]["startTime"],bookingSlots[m]["startTime"])
            if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
              k = m + 1;
              flag = true;
              break;
            }
          }
          tempArr = [];
          //console.log("if",tempArr.length)
        }
      } else if (
        bookingSlots[i].status === 1 &&
        bookingSlots[i].list[0].info.id === self.state.bookingId
      ) {
        //for (let t = bookingSlots[i]["startTime"]; t < bookingSlots[i]["endTime"]; t+=10) {
        //bookingSlots[i]["startTime"]=t;
        let booking = bookingSlots[i];
        //booking.startTime=t;
        //console.log('t', t,booking);
        tempArr.push(booking);
        // console.log('tempArr', tempArr,k,i,booking.startTime);
        if (
          tempArr[tempArr.length - 1]["endTime"] - tempArr[0]["startTime"] >=
          self.state.totalTime
        ) {
          finalBookingSlots.push(tempArr[0]);

          let hr = parseInt(tempArr[0].startTime / 60);
          let min = tempArr[0].startTime - hr * 60;

          let incTimeSlot = new Date(self.state.bookingDate);
          //console.log("incTimeSlot",incTimeSlot);
          incTimeSlot.setHours(hr);
          incTimeSlot.setMinutes(min);

          includeTimes.push(incTimeSlot);
          //console.log("tempArr[0]",incTimeSlot,includeTimes)

          for (let m = 0; m < bookingSlots.length; m++) {
            //console.log("###",tempArr[0]["startTime"],bookingSlots[m]["startTime"])
            if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
              k = m + 1;
              flag = true;
              break;
            }
          }
          tempArr = [];
          //console.log("if",tempArr.length)
        } else {
          //console.log("else",tempArr.length,tempArr,tempArr[tempArr.length - 1]["startTime"])
        }
        //}
      } else {
        tempArr = [];
      }

      if (flag === true) {
        break;
      }
    }
    //console.log('finalBookingSlots', finalBookingSlots,flag, k < bookingSlots.length);
    if (flag && k < bookingSlots.length) {
      self.bookingSlotCreate(k);
    }

    if (finalBookingSlots[0]) {
      let finalslot = this.state.finalBookingSlots;
      finalslot.unshift(finalBookingSlots[0]);

      let incTimes = this.state.includeTimes;
      //console.log('incTimes', incTimes);
      incTimes.unshift(includeTimes[0]);
      // console.log('finalslot', finalslot);
      let morningCount = 0;
      let afternoonCount = 0;
      let eveningCount = 0;
      for (let i = 0; i < finalslot.length; i++) {
        let bookingSlot = finalslot[i];
        let bookingIncTimes = incTimes[i];
        if (bookingSlot) {
          if (bookingSlot.status === 0 && bookingSlot.startTime < 720) {
            morningCount = 1;
          }
          if (
            bookingSlot.status === 0 &&
            bookingSlot.startTime >= 720 &&
            bookingSlot.startTime < 1020
          ) {
            afternoonCount = 1;
          }
          if (bookingSlot.status === 0 && bookingSlot.startTime >= 1020) {
            eveningCount = 1;
          }
        }
      }
      let selectedSlot = _.find(finalslot, function (slot) {
        let incTimeSlot = new Date(self.state.selectSlot);
    
        let hr = incTimeSlot.getHours();
        let min = incTimeSlot.getMinutes();
        return slot.startTime === hr * 60 + min;
      });

      this.setState({
        finalBookingSlots: finalslot,
        includeTimes: incTimes,
        morningCount,
        afternoonCount,
        eveningCount,
        slotInfo: selectedSlot,
        // selectSlot: incTimes[0]
      });
    }
  }

  getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - hr * 60;
    return (
      (hr <= 9 ? "0" + hr : hr) +
      ":" +
      (min == 0 ? "00" : min <= 9 ? "0" + min : min) +
      ":00"
    );
  }

  selectDay(e) {
    console.log("selectDay", e);
    this.state.bookingDate = e[0];
    this.state.selectedDay = e[0];
    //console.log(this.state.bookingDate)
    //this.setState({bookingDate:e[0]})
    let params = {};
    let self = this;
    let sdate = new Date(e[0]);
    self.setState({
      bookingDate: e,
      month: monthNames[sdate.getMonth()],
      year: sdate.getFullYear(),
    });
    // let salonId = this.props.match.params.salonId;
    let hairdresserId = this.state.hairdresserId
      ? this.state.hairdresserId
      : localStorage.getItem("userId");
    params.hairdresserId = hairdresserId;
    params.latitude = this.state.latitude;
    params.longitude = this.state.longitude;
    params.slotsForBookingDate = dateFormat(e, "yyyy-mm-dd");
    params.bookingId = this.props.match.params.bookingId;
    self.props
      .dispatch(hairdresserActions.getHairdresserInfo(params))
      .then(function (response) {
        if (response.data.error) {
          self.setState({
            message: response.data.error.message,
          });
        } else {
          console.log(response);
          self.setState(
            {
              bookingSlots: response.data.data.bookingSlots,
              selectedBookingSlotsIndex: "",
              finalBookingSlots: [],
              includeTimes: [],
            },
            function () {
              self.bookingSlotCreate(0);
            }
          );
        }
      });
  }

  unselectDay(e) {
    console.log("unselectDay", e);
  }

  checkSelectedService(serviceId) {
    let selectedService = this.state.selectedService;
    let isCheck = _.find(selectedService, function (o) {
      return o.serviceId === serviceId;
    });
    //console.log('isCheck',serviceId,isCheck)
    if (isCheck) {
      return true;
    } else {
      return false;
    }
  }

  //fix the start time state issue 
  editBooking(param = "") {
    let self = this;
    let info = {
      bookingDate: self.state.bookingDate,
    };

    if (param === "complete-booking") {
      self.props.history.push({
        pathname: "/diary/" + localStorage.getItem("salonId"),
        state: info,
      });
      return;
    } else {
      let params = {};

      params.servicesToBook = _.map(self.state.selectedService, function (o) {
        return {...o,id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.serviceId, price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
      });

      params.productIds  = _.map(self.state.productList, function (o) {
        return {...o,id:o._id,retailPrice:parseFloat(o?.retailPrice)};
      });

      // params.servicesToBook = _.map(this.state.selectedService, function (o) {
      //   return o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.serviceId;
      // });

      // params.productIds = _.map(this.state.productList, function (o) {
      //   return o._id;
      // });

      let slotsInfoData = self?.props?.clientBookingSlots;
      //params.userId = this.state.hairdresserId;
      params.bookingSlot = [
        slotsInfoData.startTime,
        slotsInfoData.startTime + parseInt(self.state.totalTime),
      ];

      let selectedHairdresserId = self?.props?.hairdresserNewBookingStaff?._id!==undefined?self?.props?.hairdresserNewBookingStaff?._id:localStorage.getItem("userId");

      params.bookingDate = dateFormat(this.state.bookingDate, "yyyy-mm-dd");
      params.deviceToken = "dfdsfdsfsd";
      params.isPayAtSalonBooking = 1;
      params.clientId = "";
      params.paidType = self.state.paidType;
      params.hairdresserId = selectedHairdresserId;
      params.bookingID = self.props.match.params.bookingId;
      params.transaction = this.state.transaction;
      params.totalAmount = this.state.totalAmount;
      params.bookingStatus = this.state.isPaymentComplete==1?4:0;

      self.props
        .dispatch(bookingActions.hairdresserEditBooking(params))
        .then(function (response) {
          if (response.data.errorCode) {
            toast.error(response.data.messages);
          } else {
            let client = {};
            self.props.history.push('/invoice/' + self.props.match.params.bookingId);
            self.props.dispatch(bookingActions?.hairdresserNewBookingClient(client));
            self.props.dispatch(productAction.selectedClient(client));
            self.props.dispatch(hairdresserActions.selectedService({}));
            self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({}));
            self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
            self.props.dispatch(productAction?.selectedCheckoutList([]));
            //self.props.history.push('/invoice/' + self.props.match.params.bookingId)
            //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
          }
        });
    }
  }

  resetBooking() {
    window.location.reload();
    // this.setState({
    //     bookingDate: new Date(),
    //     //selectSlot: new Date(),
    //     showOptionsModal: false,
    //     showTipModal: false,
    //     latitude: siteSetting.default_latitude,
    //     longitude: siteSetting.default_longitude,
    //     hairdresserName: 'Select Staff Member',
    //     selectedService: [],
    //     finalBookingSlots: [],
    //     includeTimes: [],
    //     slotInfo: {},
    //     totalAmount: 0,
    //     totalTime: 0,
    //     hairdresserId: '',
    //     selectedClientId: '',
    //     selectedClient: {},
    //     isSaveBooking: 0,
    //     bookingSlots: [],
    //     paymentType: '',
    //     searchKey: ''
    // })
  }

  changeClient() {
    this.setState({
      selectedClientId: "",
      selectedClient: {},
      isSaveBooking: 0,
      bookingSlots: [],
      paymentType: "",
      searchKey: "",
    });
  }

  handleEvent(e, d) {
    if (e === "eventStartTime") {
      let m = moment(d);
      let minutes = m.hour() * 60 + m.minute();
      this.setState({ eventTime: d, eventStartTime: minutes });
    }
    if (e === "eventEndTime") {
      let m = moment(d);
      let minutes = m.hour() * 60 + m.minute();
      this.setState({ endTime: d, eventEndTime: minutes });
    }
    if (typeof e === "object" && e.target.id === "eventTitle") {
      this.setState({ eventTitle: e.target.value });
    }
    if (typeof e === "object" && e.target.id === "isBookingAllowed") {
      this.setState({ isBookingAllowed: !this.state.isBookingAllowed });
    }
  }

  checkoutBooking() {
    this.setState({
      isCheckout: 1,
    });
  }

  serPaymentType(paymentType) {
    let transaction = this.state.transaction;

    transaction.push({
      paymentType: paymentType,
      amount: parseFloat(this.state.paymentAmount),
    });
    let totalPaymentAmount =
      parseFloat(this.state.totalPaymentAmount) +
      parseFloat(this.state.paymentAmount);
    let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;

    this.setState({
      totalPaymentAmount,
      transaction: transaction,
      pendingAmount: pendingAmount,
      paymentAmount: pendingAmount,
    });
  }

  onChanged(e) {
    //console.log("e",e.target);
    this.setState({ [e.target.name]: parseFloat(e.target.value) });
    if (e.target.name === "registrationCode") {
      this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.name === "label") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: parseFloat(e.target.value) });
    }
    if (e.target.name === "pendingAmount") {
      let totalPaymentAmount =
        parseFloat(this.state.totalPaymentAmount) + parseFloat(e.target.value);
      let pendingAmount =
        parseFloat(this.state.totalAmount) - totalPaymentAmount;
      if (parseFloat(e.target.value) > pendingAmount) {
        this.setState({
          paymentAmountError: "Invalid Amount",
        });
      } else {
        this.setState({
          paymentAmountError: "",
        });
      }
    }
  }

  updateBooking(reload = 1) {
    let params = {};
  
    params.servicesToBook = _.map(this.state.selectedService, function (o) {
      return o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.serviceId;
    });

    params.userId = this.state.hairdresserId;
    params.transaction = this.state.transaction;
    params.bookingStatus = this.state.isPaymentComplete==1?4:0;
    //params.totalAmount = parseFloat(this.state.totalAmount).toFixed(2);
    params.bookingID = this.props.match.params.bookingId;
    bookingService.updateBooking(params).then(function (response) {
      if (reload) {
        window.location.reload();
      }
    });
  }

  checkCompleteSale = () => {
    if (this.state.isCheckoutPayment === true) {
      return true;
    }
    if (this.state.pendingAmount == 0) {
      return false;
    }
    if (this.state.selectedPaymentType !== null) {
      return false;
    }
    if (
      this.state.checkOutAmountType == 1 &&
      this.state.isCardReaderConnected === true
    ) {
      return false;
    }
    return true;
  };

  async completeSale () {
    this.setState({
      paymentAmountError: "",
    });

    let self = this;
    //  let response = await this.updateNewEditAmount();
    //  console.log('checktheoutPutofdata')
    // if(response?.data?.statusCode==1){
    // if(parseFloat(this.state.selectedAmount)<=0){
    //     this.setState({
    //         paymentAmountError:'Please enter some amount'
    //     })
    // }else {
    //     this.setState({isInvoice: 1})
    // }
    if (this.state.totalTime === 0) {
      this.setState({
        paymentAmountError: "Please select service",
      });
    } else if (this.state.pendingAmount === 0) {
      if (this.state.isPaymentComplete == 0) {
        this.setState({
          isPaymentComplete: 1,
        });
      } else {
     
        // this.setState({ isInvoice: 1 });

        this.editBooking()
      }
    } else {
      let transaction = this.state.transaction;
      if (this.state.checkOutAmountType == true) {
        transaction.push({
          paymentType: self.state.selectedPaymentType,
          amount: parseFloat(this.state.pendingAmount),
        });
        let totalPaymentAmount =
          parseFloat(this.state.totalPaymentAmount) +
          parseFloat(this.state.pendingAmount);
        let pendingAmount =
          parseFloat(this.state.totalAmount) - totalPaymentAmount;
        let isPaymentComplete = 0;
        if (pendingAmount === 0) {
          isPaymentComplete = 1;
        }
        this.setState({
          totalPaymentAmount,
          transaction: transaction,
          pendingAmount: pendingAmount,
          selectedPaymentType: null,
          isPaymentComplete,
        });
      } else {
        if (
          parseFloat(this.state.selectedAmount) >
          parseFloat(this.state.totalAmount) -
            parseFloat(this.state.totalPaymentAmount)
        ) {
          this.setState({
            paymentAmountError: "Please enter valid amount",
          });
        } else if (this.state.selectedAmount == 0) {
          this.setState({
            paymentAmountError: "Please enter valid amount",
          });
        } else if (this.state.selectedAmount > 0) {

          transaction.push({
            paymentType: self.state.selectedPaymentType,
            amount: parseFloat(this.state.selectedAmount),
          });
          let totalPaymentAmount =
            parseFloat(this.state.totalPaymentAmount) +
            parseFloat(this.state.selectedAmount);
          let pendingAmount =
            parseFloat(this.state.totalAmount) - totalPaymentAmount;
          let isPaymentComplete = 0;
          if (pendingAmount === 0) {
            isPaymentComplete = 1;
          }
          this.setState({
            totalPaymentAmount,
            transaction: transaction,
            pendingAmount: pendingAmount,
            selectedAmount: "",
            selectedPaymentType: null,
            checkOutAmountType: true,
            isPaymentComplete,
          });

          // Here need to run update booking code
          this.updateBooking(0);
        }
      }
    // }
  }
  }

  selectSlot(e) {
    //console.log("e", e);
    let self = this;
    this.setState(
      {
        finalBookingSlots: [],
        includeTimes: [],
      },
      function () {
        self.bookingSlotCreate(0);
        //console.log('slotInfo,index',slotInfo,index,isSelect);
        let includeTimes = self.state.includeTimes;
        let finalBookingSlots = self.state.finalBookingSlots;
        let totalTime = self.state.totalTime;

        _.remove(includeTimes, function (o) {
          return o <= moment(e).add(totalTime, "m").toDate() && o > e;
        });

        let m = moment(e);
        let minutes = m.hour() * 60 + m.minute();
        let slotInfo = _.find(finalBookingSlots, function (o) {
          return o.startTime === minutes;
        });

        self.setState(
          {
            slotInfo: slotInfo,
            includeTimes,
            selectSlot: e,
            endTimeSlot: e,
          },
          function () {}
        );
      }
    );
  }

  setPaymentType = (paymentType) => {
    this.setState({
      payoutError: "",
      cardReaderConnectError: "",
      selectedCardReader: {},
      selectedPaymentType: paymentType,
      isCardReaderConnected: false,
    });
  };

  selectBookingAmount = (type) => {
    let self = this;
    if (type == 1) {
      self.setState({
        checkOutAmountType: true,
      });
    } else {
      self.setState({
        checkOutAmountType: false,
      });
    }
  };

  removeTransaction = (transaction) => {
    let transactionList = this.state.transaction;
    let newList = _.pull(transactionList, transaction);
    let totalPaymentAmount =
      parseFloat(this.state.totalPaymentAmount) -
      parseFloat(transaction.amount);
    let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
    this.setState({
      totalPaymentAmount,
      pendingAmount,
      transaction: newList,
    });
  };

  cardReaderList = (hairdresserInfo) => {
    let self = this;
    self.props
      .dispatch(paymentActions.cardReaderList({bookingId:self.props.match.params.bookingId}))
      .then(function (response) {
        console.log("cardReaderList", response);
        if (response.data.statusCode === 1) {
          self.setState(
            {
              paymentMethodInfo:response.data.responseData.result.paymentMethodInfo,
              cardReaderList: self.props.cardReaderList,
            },
            function () {
              if (hairdresserInfo?.hairdresser?.isSalonOwner === 1) {
                let count = 0;
                const names = self.state.cardReaderList.map((obj) => {
                  count++;
                });
                self.setState({
                  label: hairdresserInfo?.hairdresser?.salon.salonName
                    ? hairdresserInfo?.hairdresser?.salon?.salonName +
                      ` Card Reader (${count + 1})`
                    : "",
                });
              }
            }
          );
        } else {
        }
      });
  };

  setPaymentCardReader = (cardReader) => {
    let self = this;
    console.log('check the card reader data 1',cardReader);
    this.setState(
      {
        payoutError: "",
        cardReaderConnectError: "",
        selectedPaymentType: null,
        selectedCardReader: cardReader,
      },
      function () {
        self.discoverReaders();
      }
    );
  };

  checkout = async () => {

    let self = this;
    let salonId = self.props?.user?.userInfo?.hairdresser.salon?.id;
    if (this.state.isPaymentComplete === 1) {
      // this.setState({ isInvoice: 1 });
      let client = {};
          self.props.history.push('/invoice/' + self.props.match.params.bookingId);
          self.props.dispatch(bookingActions?.hairdresserNewBookingClient(client));
          self.props.dispatch(productAction.selectedClient(client));
          self.props.dispatch(hairdresserActions.selectedService({}));
          self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({}));
          self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
          self.props.dispatch(productAction?.selectedCheckoutList([]));
    } else {
    //   let response = await this.updateNewEditAmount();
    //  if(response?.data?.statusCode==1){
      self.setState({
        isCheckoutPayment: true,
        //isCardReaderConnected:false
      });
      // let servicesToBook = _.map(self.state.selectedService, function (o) {
      //   return o.serviceId;
      // });

      // let productIds=_.map(self.state.productList, function (o) {
      //   return o._id;
      // });

      let servicesToBook = _.map(self.state.selectedService, function (o) {
        return {id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.serviceId, price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
      });

      let productIds = _.map(this.state.productList, function (o) {
        return {...o,id:o._id,retailPrice:parseFloat(o?.retailPrice)};
      });

      self.props
        .dispatch(
          paymentActions.paymentIntents({
            selectedPaymentType:self.state.selectedPaymentType,
            bookingId: this.props.match.params.bookingId,
            servicesToBook,
            productIds
          })
        )
        .then(function (response) {
          let transaction = self.state.transaction;

          if(response.data.statusCode===0){
            if( response?.data?.error?.errorCode===10 || response?.data?.error?.errorCode===5 ){
              if(response.data.error.errors.errorCode){
                self.setState({
                  isCheckoutPayment: false,
                  isCardReaderConnected:false,
                },()=>{
                  toast.error(response?.data?.error?.errors?.responseMessage);
                })
                
              }else{
                self.setState({
                  isCheckoutPayment: false,
                  isCardReaderConnected:false,
                },()=>{
                  toast.error(response.data.error.responseMessage);
                })
               
              }
              
              // setTimeout(()=>{
              //   window.location.reload();
              // },4000)
            }
          }

          if (response.data.statusCode === 1) {
            self.setState({
              paymentIntents: response.data.responseData.result,
              isTrStart:false  //true
            });
            if(self.state.selectedPaymentType===10){
              if (response?.data?.responseData?.result) {

                // let servicesToBook = _.map(self.state.selectedService, function (o) {
                //   return o.serviceId;
                // });

                let servicesToBook =  _.map(self.state.selectedService, function (o) {
                  return {id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.serviceId, price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
                });

                self.props
                    .dispatch(
                        paymentActions.paymentIntentsCapture({
                          bookingId: self.props.match.params.bookingId,
                          paymentIntentId: response?.data?.responseData?.result.id,
                          servicesToBook,
                        })
                    )
                    .then(function (response) {
                      if(response.data.statusCode===0){
                        if( response?.data?.error?.errorCode===10 || response?.data?.error?.errorCode===5 ){
                          if(response.data.error.errors.errorCode){
                            self.setState({
                              isCheckoutPayment: false,
                              isCardReaderConnected:false,
                            },()=>{
                              toast.error(response?.data?.error?.errors?.responseMessage);
                            })

                          }else{
                            self.setState({
                              isCheckoutPayment: false,
                              isCardReaderConnected:false,
                            },()=>{
                              toast.error(response.data.error.responseMessage);
                            })

                          }
                          // setTimeout(()=>{
                          //   window.location.reload();
                          // },4000)
                        }
                      }
                      let _tipAmount =
                          response.data?.responseData.result.tipAmount != undefined
                              ? response.data?.responseData.result.tipAmount
                              : 0;
                      if (response.data.statusCode === 1) {
                        self.setState({
                          isPaymentComplete: 1,
                          isCheckoutPayment: false,
                          isTrStart:false,
                          tipAmount: _tipAmount,
                        },()=>{
                          bookingService.bookingComplete({bookingId:self.props.match.params.bookingId,
                            bookingStatus:4,salonId:salonId}).then(function (response) {
                            console.log('booking Complete status',response);

                          });
                        });
                      }
                    });

                // Placeholder for notifying your backend to capture result.paymentIntent.id
              }
            }else {
              let clientSecret = response.data.responseData.result.client_secret;
              terminal.collectPaymentMethod(clientSecret).then(function (result) {
                if (result.error) {
                  // Placeholder for handling result.error
                  self.setState({
                    isCheckoutPayment: false,
                    payoutError: result.error.message,
                  });
                } else {
                  if (
                      result?.paymentIntent?.amount_details?.tip?.amount !=
                      undefined
                  ) {
                    let tip_amount =
                        result?.paymentIntent?.amount_details?.tip?.amount / 100;
                    self.setState({
                      tipAmount: tip_amount,
                    });
                  } else {
                    console.log(
                        "result_paymentIntent_",
                        self.props.match.params.bookingId,
                        result.paymentIntent
                    );
                  }
                  let capturedAmount =
                      response?.data?.responseData?.result?.amount / 100;
                  const filterPaymentType = transaction.filter(
                      (_transaction) => _transaction?.paymentType !== 5
                  );
                  filterPaymentType.push({
                    paymentType: 5,
                    amount: parseFloat(capturedAmount),
                  });
                  self.setState({
                    transaction: filterPaymentType,
                  });

                  self.processPayment(result.paymentIntent);
                  // Placeholder for processing result.paymentIntent
                }
              });
            }
          } else {
          }
        });
    // }
  }
  };

  discoverReaders = async () => {
    let self = this;
    this.setState({
      activeCLass: true,
      isSearchReader: true,
      isShowConnectionLoader: true,
    });
    const config = {
      simulated: siteSetting.simulated,
      location: this.state.locationId,
    };
    let StripeTerminal = await loadStripeTerminal();
    terminal = StripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: unexpectedDisconnect,
    });
    console.log("terminal", terminal);
    terminal.discoverReaders(config).then(function (discoverResult) {
      console.log("discoverReaders !!!: ", discoverResult);
      if (discoverResult.error) {
        console.log("Failed to discover ###: ", discoverResult.error);
        self.setState({
          isCardReaderConnected: false,
          cardReaderConnectError: discoverResult.error.message,
        });
      } else if (discoverResult.discoveredReaders.length === 0) {
        self.setState({
          noDevice: true,
        });
        console.log("No available readers.");
        self.setState({
          selectedCardReader: {},
          isCardReaderConnected: false,
          cardReaderConnectError: "No available readers",
        });
      } else {
        let result = _.find(discoverResult.discoveredReaders, function (obj) {
          if (
            obj.serial_number === self.state.selectedCardReader.serial_number
          ) {
            return true;
          }
        });
        if (result) {
          self.connectReader(result);
        } else {
          self.connectReader(discoverResult.discoveredReaders[0]);
        }
        self.setState({
          discoverResult: discoverResult.discoveredReaders,
          cardReaderConnectError:"",
          payoutError:""
        });
      }
    });
  };

  connectReader = async (selectedReader) => {
    let self = this;

    await terminal.disconnectReader();
    terminal.connectReader(selectedReader).then(function (connectResult) {
      if (connectResult.error) {
        self.setState({
          isCardReaderConnected: false,
          cardReaderConnectError: connectResult.error.message,
        });
        console.log("Failed to connect: @@@@@ ", connectResult.error);
      } else {
        console.log("Connected to reader: ", connectResult.reader);
        //self.cardReaderList();
        self.setState({
          cardReaderConnectError:"",
          payoutError:"",
          isCardReaderConnected: true,
          isShowConnectionLoader: false,
        });
      }
    });
  };

  cancelTerminalTransaction=()=>{
    let self = this;
    let params={
      tlTransactionId:self.state.selectedCardReader.id,
      paymentIntentsId:self.state.paymentIntents.id
    }
    return paymentService.cancelTerminalTransaction(params)
        .then(function (res) {
          console.log("cancelTerminalTransaction res", res.data);
          //return res.data.responseData.result.secret;
    });
  }

  componentWillUnmount(){
    let self = this;
    console.log("componentWillUnmount");
    if(self.state.selectedCardReader.id&&self.state.selectedCardReader.id!==""){
      //console.log("cancelTerminalTransaction");
      //self.cancelTerminalTransaction();
    }
  }

  processPayment = async (paymentIntent) => {
    // transactions
    let self = this;
    let salonId = self.props?.user?.userInfo?.hairdresser.salon?.id;
    terminal.processPayment(paymentIntent).then(function (result) {
      self.setState({
        isTrStart:false
      })
      console.log("result",result);
      if (result.error) {
        self.setState({
          isCheckoutPayment: false,
          payoutError: result.error.message0,
        });
      } else if (result.paymentIntent) {

        // let servicesToBook = _.map(self.state.selectedService, function (o) {
        //   return o.serviceId;
        // });

        let servicesToBook =  _.map(self.state.selectedService, function (o) {
            return {id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.serviceId, price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
          });
    
        self.props
          .dispatch(
            paymentActions.paymentIntentsCapture({
              bookingId: self.props.match.params.bookingId,
              paymentIntentId: result.paymentIntent.id,
              servicesToBook,
            })
          )
          .then(function (response) {
            if(response.data.statusCode===0){
              if( response?.data?.error?.errorCode===10 || response?.data?.error?.errorCode===5 ){
                if(response.data.error.errors.errorCode){
                  self.setState({
                    isCheckoutPayment: false,
                    isCardReaderConnected:false,
                  },()=>{
                    toast.error(response?.data?.error?.errors?.responseMessage);
                  })
                  
                }else{
                  self.setState({
                    isCheckoutPayment: false,
                    isCardReaderConnected:false,
                  },()=>{
                    toast.error(response.data.error.responseMessage);
                  })
                 
                }
                // setTimeout(()=>{
                //   window.location.reload();
                // },4000)
              }
            }
            let _tipAmount =
              response.data?.responseData.result.tipAmount != undefined
                ? response.data?.responseData.result.tipAmount
                : 0;
            if (response.data.statusCode === 1) {
              self.setState({
                isPaymentComplete: 1,
                isCheckoutPayment: false,
                isTrStart:false,
                tipAmount: _tipAmount,
              },()=>{
                  bookingService.bookingComplete({bookingId:self.props.match.params.bookingId,
                    bookingStatus:4,salonId:salonId}).then(function (response) {
                      console.log('booking Complete status',response);
                      
                  });
              });
            }
          });

        // Placeholder for notifying your backend to capture result.paymentIntent.id
      }
    });
  };

  addStripeTerminal = () => {
    let self = this;
    self.props
      .dispatch(
        paymentActions.addTerminal({
          registrationCode: this.state.registrationCode,
          label: this.state.label,
        })
      )
      .then(function (response) {
        console.log("response", response.data);
        if (response.data.statusCode === 1) {
          self.cardReaderList();
          self.handleClose();
        } else {
          //setCardReaderConnectError(response.data.error.responseMessage)
        }
      });
  };


  updateDuration = (event) => {
    const inputTime = event.target.value;
    const duration = parseInt(inputTime, 10);

    if (!isNaN(duration)) {
      this.setState({
        totalTime: duration,
      });
    }
  };

  calculateEndTime = () => {
    let endTime = new Date(this.state.selectSlot);
    endTime.setMinutes(endTime.getMinutes() + this.state.totalTime);

    let date = new Date(endTime.getTime());

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    if(hours==='NaN'&&minutes==='NaN'){
      return ``;
    }else{
      return `${hours}:${minutes}`;
    }
  
  };



  removeCheckoutItem = (type, selectedItemId) => {
    let self = this;
    let dispatch = self.props?.dispatch;
    if (type === "product" || type === "service") {
      let updatedProductList = self.state.checkoutSelectedItems?.filter(
        (productItem) => productItem?.indexId !== selectedItemId
      );
      dispatch(productAction?.selectedCheckoutList(updatedProductList));
      self.setState({
        checkoutSelectedItems: updatedProductList,
      },()=>{
        self.calculateCheckoutItem();
      });
    }
  };

  setSelectedCheckoutData() {
    let self = this;
    const selectedCheckOutArr =
      self.props?.selectedCheckoutList?.length > 0
        ? self.props?.selectedCheckoutList
        : [];
    self.setState({
      checkoutSelectedItems: selectedCheckOutArr,
      // selectedStaff:
      //   selectedCheckOutArr[0]?.selectedStaff !== undefined
      //     ? selectedCheckOutArr[0]?.selectedStaff
      //     : null,
      selectedClient:
        selectedCheckOutArr[0]?.selectedClient !== undefined
          ? selectedCheckOutArr[0]?.selectedClient
          : null,
    });
    this.setSelectedProduct();
    this.setSelectedService();
  }


  setSelectedProduct() {
    let self = this;
    let dispatch = self.props.dispatch;
    let selectedProductData = self.props.selectCheckoutItem;
    if (selectedProductData?._id != undefined) {
      let updatedList =
        self.props?.selectedCheckoutList.length > 0
          ? self.props?.selectedCheckoutList.map((item) => {
              if (item?.id == selectedProductData.indexId) {
                item = { ...item, ...selectedProductData };
              }
              return item;
            })
          : [];
      dispatch(productAction?.selectedCheckoutList(updatedList));
      dispatch(productAction?.selectCheckoutItem({}));
    }
  }

  setSelectedService(){
    let self = this;
    let dispatch = self.props.dispatch;
    let selectedServiceData = self.props?.selectedService;
    if(selectedServiceData?._id !== undefined){
     let updatedList = self.props?.selectedCheckoutList.length>0?self.props?.selectedCheckoutList.map(item=>{
      if(item?.indexId===selectedServiceData?.indexId){
        return {
          ...item,
          service:selectedServiceData,
          ...selectedServiceData
        }
        }
        return item
      }):[];
      dispatch(hairdresserActions.selectedService({}));
      dispatch(productAction?.selectedCheckoutList(updatedList));
      self.setState({
        selectedCheckoutList: updatedList,
      })
    }
  }

  addCheckoutItem(type) {
    let self = this;
    let dispatch = self.props?.dispatch;
    let _selectedServices = self.props?.selectedCheckoutList;
    let unselectedService = _selectedServices.filter(__service=>__service.serviceName===undefined && __service.productName===undefined)

    if(unselectedService?.length>0){
      console.log('add service',unselectedService)
       return;
    }

    console.log('add service',unselectedService)
    if(!self?.state?.priceErrorStatus){
      return;
    }

 
    let lastIndex = _selectedServices.length + 1;

    if (type === "product") {
      const newProduct = {
        id: lastIndex,
        indexId: lastIndex,
        type: "product",
      };

      let productData = [..._selectedServices, newProduct];
      dispatch(productAction?.selectedCheckoutList(productData));
      self.setState({
        checkoutSelectedItems: productData,
      });
    } else {
      const newService = {
        id: lastIndex,
        indexId: lastIndex,
        type: "service",
      };
      let serviceData = [..._selectedServices, newService];
      dispatch(productAction?.selectedCheckoutList(serviceData));
      self.setState({
        checkoutSelectedItems: serviceData,
      });
    }
  }

  calculateCheckoutItem() {
    let self = this;
    let selectedCheckoutList = this.props?.selectedCheckoutList;
    let totalServicePrice = 0;
    let totalRetailPrice = 0;
    let selectedProduct = [];
    let _selectedServices = [];

    for (const item of selectedCheckoutList) {
      if (item.type === "product") {
        selectedProduct.push(item);
        totalRetailPrice += item?.retailPrice !== undefined ? parseFloat(item?.retailPrice) : 0;
      } else if (item?.type === "service") {
        _selectedServices.push(item);
        totalServicePrice += item?.servicePrice !== undefined ? parseFloat(item?.servicePrice) : 0;
      }
    }

    const checkOutTotalAmount = totalServicePrice + totalRetailPrice;

    const updatedData = {
      selectedService: _selectedServices,
      productList: selectedProduct,
      totalAmount: checkOutTotalAmount,
      pendingAmount: checkOutTotalAmount,
      paymentAmount: checkOutTotalAmount,
      selectedTotalAmount: checkOutTotalAmount,
    };
  console.log("###########SupdatedData",updatedData);
    self.setState(updatedData);
    return updatedData;
  }


  changeItemPrice(_item,type,price,err){
     let self = this;
     let  priceStatusCount = 0;
     console.log('checkError',err);
     self.setState({
      priceError:err
     });

     if(type==='service'){
     let updatedPriceData = self.props.selectedCheckoutList.map((_serviceData)=>{
        if(_serviceData.indexId===_item.indexId){
          _serviceData.servicePrice =price
        }
        return _serviceData;
       })

       let totalAmount = updatedPriceData.reduce((prev,curr)=>{
              if(curr?.retailPrice==='' || curr?.servicePrice===''){
                priceStatusCount++
              }

               if(curr?.servicePrice!=undefined){
                prev= prev+ parseFloat(curr.servicePrice===''?0:curr.servicePrice);
                return prev;
               }else{
                prev= prev+ parseFloat(curr.retailPrice===''?0:curr.retailPrice);
                return prev;
               } 
       },0);

       self.setState({
        totalAmount:totalAmount,
        pendingAmount:totalAmount,
        priceErrorStatus:priceStatusCount>0?false:true,
        selectedTotalAmount:totalAmount
       })
       self.props.dispatch(productAction?.selectedCheckoutList(updatedPriceData));
     }else{
      
      let updatedPriceData = self.props.selectedCheckoutList.map((_serviceData)=>{
        console.log('check eeeeeedata',_serviceData);
        if(_serviceData.id===_item.id){
          _serviceData.retailPrice =price
        }
        return _serviceData;
       })

       let totalAmount = updatedPriceData.reduce((prev,curr)=>{

        if(curr?.retailPrice==='' || curr?.servicePrice===''){
          priceStatusCount++
        }

        if(curr?.retailPrice!==undefined){
          prev= prev+ parseFloat(curr.retailPrice===''?0:curr.retailPrice);
          return prev;
        }else{
          prev= prev+ parseFloat(curr.servicePrice===''?0:curr.servicePrice);
          return prev;
        }     
       },0);

       self.setState({
        totalAmount:totalAmount,
        pendingAmount:totalAmount,
        priceErrorStatus:priceStatusCount>0?false:true,
        selectedTotalAmount:totalAmount
       })

       self.props.dispatch(productAction?.selectedCheckoutList(updatedPriceData));
     }
  }

  selectEndTime(e,totalSelectedMin=5){
    let self = this;
    console.log(totalSelectedMin);
    let _includeTimes =self.state.includeEndTimes?.length>0?self.state.includeEndTimes:self.state.includeTimes;
    let endTimeData =moment(e).add(totalSelectedMin, 'm').toDate();
    let isRelativeTime =0;
    let differenceValue =0;
    let totalDuration='';
  
    if(totalSelectedMin===5 ){
      differenceValue =(e.getTime() - this.state.selectSlot.getTime()) / 1000;
      differenceValue /= 60;
      isRelativeTime = Math.round(differenceValue)
      totalDuration = Math.abs(Math.round(differenceValue))
    }
  
    self.setState({
      includeEndTimes:_includeTimes
    })
  
    if(isRelativeTime<0){
      totalDuration ='';
    }
  
    const bookingInfo = {
      selectEndTimeSlot:totalSelectedMin===''?e:endTimeData,
      totalTime:totalSelectedMin!==''?totalSelectedMin:totalDuration<=0?'':totalDuration
      };
  
    self.props.dispatch(
      bookingActions?.updateBookingInfo(bookingInfo)
    );
     self.setState(bookingInfo)
  }
  


  changeServiceDuration(durationTime){
    let self = this;
    if(durationTime%5===0 && durationTime>0){
      self.setState({
        durationError:''
         })
    }else{
      self.setState({
        durationError:self.state.durationErrorMessage
         })
         
    }


    if(durationTime===''){
      self.setState({
        durationError:self.state.durationErrorMessage
         })
    }

    self.setState({
      totalTime:durationTime
       },()=>{
         self.selectEndTime(self.state.selectSlot,durationTime);
       })
  }

  render() {
    console.log("this.state",this.state);
    console.log("this.props",this.props);

    return (
      <div>
        <div className="h_n">
          <div className="body_container body_bg_lightGray">
            <TopHeader title="Checkout" 
            headerButtonStatus={true}
            cancelButton={true}
            {...this.props} />
            <SideNav {...this.props} />

            <div className="service_flex_div">
              <div
                className={
                  this.state.isInvoice === 0
                    ? "main_service_div"
                    : "main_service_div1"
                }
              >
                {this.state.isInvoice === 1 ? (
                    <></>
                  // <NewInvoice
                  //   transactionData={this.state.transaction}
                  //   bookingState={this.state}
                  // />
                ) : (
                  <>
                    {this.props.selectedCheckoutList?.length > 0
                      ? this.props.selectedCheckoutList.map((item, i) =>
                          item?.type === "service" ? (
                            <BookingService
                              key={"service_" + i}
                              item={item}
                              bookings={
                                this.props?.hairdresserBooking?.bookings
                              }
                              startTimeError={this.state.startTimeError}
                              selectSlotFunc={this.selectSlot}
                              selectEndTimeSlot={this.state.selectEndTimeSlot}
                              selectEndTime={this.selectEndTime}
                              includeEndTimes={this.state.includeEndTimes}
                              changeServiceDuration={this.changeServiceDuration}
                              includeTimes={this.state.includeTimes}
                              calculateEndTime={this.calculateEndTime}
                              durationError={this.state.durationError}
                              selectSlot={this.state.selectSlot}
                              totalTime={this.state.totalTime}
                              bookingDate={
                                this.props?.hairdresserBooking?.bookings &&
                                this.props?.hairdresserBooking?.bookings[0]
                                  ?.bookingDate
                              }
                              index={i + 1}
                              removeService={this.removeCheckoutItem}
                              propData={this.props}
                              staffList={this.state.staffList}
                              clientDetail={
                                this.props.clientDetail?.clientDetail
                              }
                              selectedStaff={this?.state?.selectedStaff}
                              isPaymentComplete={this.state.isPaymentComplete}
                              selectedTime={this.state.selectedTime}
                              changeItemPrice={this.changeItemPrice}
                            />
                          ) : (
                            <BookingProduct
                              index={i + 1}
                              key={"products_" + i}
                              item={item}
                              hairdresserName={this.state.hairdresserName}
                              removeProduct={this.removeCheckoutItem}
                              handleClickProduct={this.handleClickProduct}
                              staffList={this.state.staffList}
                              propData={this.props}
                              clientDetail={
                                this.props.clientDetail?.clientDetail
                              }
                              selectedStaff={this?.state?.selectedStaff}
                              selectedClient={this.state.selectedClient}
                              isPaymentComplete={this.state.isPaymentComplete}
                              changeItemPrice={this.changeItemPrice}
                           
                            />
                          )
                        )
                      : null}
                  </>
                )}

                {this.state.isInvoice === 0 && this.state.isPaymentComplete==0? (
                  <div className="row">
                    <div className="col-md-11">
                      <div className="button-group-service">
                        {this?.state?.priceError==''?<button
                        className="btn btn-default bg-light text-dark"
                        onClick={() => this.addCheckoutItem("service")}
                      >
                        Add Service
                      </button>:<button
                      className="btn btn-default bg-light text-dark cursor-notAllow"
                    >
                      Add Service
                    </button>}

                    {this?.state?.priceError==''?<button
                    className="btn btn-default bg-light text-dark"
                    onClick={() => this.addCheckoutItem("product")}
                  >
                    Add Product
                  </button>:<button
                  className="btn btn-default bg-light text-dark cursor-notAllow"
                 
                >
                  Add Product
                </button>}
                        
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={
                  this.state.isInvoice === 0
                    ? "checkout_page"
                    : "checkout_page1"
                }
              >
                {this?.state?.isInvoice === 0 ? (
                  this?.state?.isCheckout === 1 ? (
                    <>
                    {this?.state?.priceError!=''?<div className="overlay-wrapper-checkout"></div>:null}
                    <Checkout
                      checkCompleteSale={this.checkCompleteSale}
                      removeTransaction={this.removeTransaction}
                      selectBookingAmount={this.selectBookingAmount}
                      bookingState={{
                        ...this.state,
                        pendingAmount: this.state.pendingAmount,
                      }}
                      setPaymentType={this.setPaymentType}
                      saveBooking={this.saveBooking}
                      onChangedValue={this.onChanged}
                      currencySymbol={this.state.currencySymbol}
                      paymentAmountError={this.state.paymentAmountError}
                      selectedClient={this.state.selectedClient}
                      selectedService={this.state.selectedService}
                      totalAmount={this.state.selectedTotalAmount}
                      paymentType={this.state.paymentType}
                      serPaymentType={this.serPaymentType}
                      resetBooking={this.resetBooking}
                      paymentAmount={this.state.selectedTotalAmount}
                      completeSale={this.completeSale}
                      hairdresserId={this.state.hairdresserId}
                      bookings={
                        this.props?.hairdresserBooking?.bookings &&
                        this.props?.hairdresserBooking?.bookings[0]
                      }
                      cardReaderList={this.props.cardReaderList}
                      setPaymentCardReader={this.setPaymentCardReader}
                      checkout={this.checkout}
                      addStripeTerminal={this.addStripeTerminal}
                      handleClose={this.handleClose}
                      handleDiscoverModal={this.handleDiscoverModal}
                      selectedStaff={this?.state?.selectedStaff}
                      cancelTerminalTransaction={this.cancelTerminalTransaction}
                      paymentMethodInfo={this.state.paymentMethodInfo}
                    />
                    </>
                  ) : (
                    <ClientCheckout
                      borderr="borderemove"
                      selectedClientId={this.state.selectedClientId}
                      selectedClient={
                        this.props.hairdresserBooking.bookings
                          ? this.props.hairdresserBooking.bookings[0].user
                          : ""
                      }
                      totalAmount={this.state.selectedTotalAmount}
                      changeClient={this.changeClient}
                      currencySymbol={this.state.currencySymbol}
                      pendingAmount={this.state.pendingAmount}
                      checkoutBooking={this.checkoutBooking}
                      editBooking={this.editBooking}
                      userId={this.state.userId}
                      hairdresserId={this.state.hairdresserId}
                      bookings={this.props.hairdresserBooking.bookings[0]}
                      selectedStaff={this?.state?.selectedStaff}
                    />
                  )
                ) :null}
              </div>
            </div>
            {/* <div className="cm_loader"><div className="loader"></div></div> */}
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(BookingCheckout);
