import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useWindowSize from './useWindowSize';
import { newServicesAction } from '../../_actions';
import { toast } from 'react-toast-notification';
import { connect, useDispatch } from 'react-redux';


  const teamOptionsforSelfEmp = [
    { value: 1, label: 'Share services & categories with other staff' },
    { value: 0, label: 'Don’t share services & categories with other staff' },
  ];


function ServicePopup(props) {
  const teamOptions =  props?.user?.userInfo?.hairdresser?.isSalonOwner === 1 ?
  [
    { value: 1, label: 'Share services & categories with self employed staff' },
    { value: 0, label: 'Don’t share services & categories with self employed staff' },
  ]:[
    { value: 1, label: 'Share services & categories with other staff' },
    { value: 0, label: 'Don’t share services & categories with other staff' },
  ]
  // console.log("props?.user?.userInfo?.hairdresser?.isSalonOwner",props?.user?.userInfo?.hairdresser?.isSalonOwner)
  const dispatch = useDispatch();
    const [shareTeamMember, setShareTeamMember] = useState(teamOptions.find(option => option.value === props.selectedSetting));
    console.log("shareTeamMember_setting",shareTeamMember)
  //  console.log("props.selectedSetting",props.selectedSetting,"and===>" ,teamOptions[0])
    const { width } = useWindowSize();
    const menuPlacementPosition = width > 991 ? 'bottom' : 'top';
    // const handleApply = () => {
    //   console.log("shareTeamMember" ,shareTeamMember)
    //     setShareTeamMember(shareTeamMember.value)
    //     handleServiceSettings(shareTeamMember.value)
  
       
    //   }
    
    const customStyles = {
      control: (provided) => ({
        ...provided,
        borderColor: '#ccc',
        boxShadow: 'none',
        '&:hover': {
          borderColor: '#707070',
        },
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#29282e' : 'white',
        color: state.isSelected ? 'white' : '#333',
        '&:hover': {
          backgroundColor: state.isSelected ? '#29282e' : '#f0f0f0',
        },
      }),
    };
      const handleServiceSettings = (serviceValue) =>{
        let payload = {
          "shareService": shareTeamMember.value
        }
        console.log("payload_od_setting",payload)
        dispatch(newServicesAction.serviceShareSetting(payload))
          .then((res) => {
            console.log("serviceShareSetting successfully response =>:", res);
            if(res?.serviceShareSetting?.statusCode===1) {
              props.servicesList()
              props.onHide();
              toast.success(res?.serviceShareSetting?.responseData?.message);
              
           }
           else{
            //  toast.error(res?.editCategory?.error? res?.editCategory?.error?.responseMessage : res?.editCategory?.responseData?.message);
           }
          
           props.servicesList()
            
            
          })
          .catch((error) => {
            console.error("Error edit category:", error);
          });
      }
      
      console.log("shareTeamMembershareTeamMember",shareTeamMember)
      useEffect(()=>{

      })
  return (
    <div className="filters-popup pb-0">
        
    <div className="filter-group">
      <label>Service List Sharing</label>
      <Select
        menuPlacement={menuPlacementPosition}
        classNamePrefix="my-select"
        value={shareTeamMember}
        onChange={setShareTeamMember}
        options={teamOptions}
        styles={customStyles}
        isSearchable={false} 
        // menuIsOpen={true}
        
      />
    </div>
    {/* <div className="filter-group">
      <label> Which staff can edit and delete services added by manager</label>
    <div className='_checkbox'>
       <div className="check_wraper  mb-4">
           <input
               type="checkbox"
            //    checked={this.state.isCancelChecked}
               name="allow"
            //    onChange={this.checkBoxChange}
               // disabled={this.checkReadOnly() ? "" : "disabled"}
           />
           <span /><p className='_text'>Joel Wild</p>
       </div>
       <div className="check_wraper  mb-3">
           <input
               type="checkbox"
            //    checked={this.state.isCancelChecked}
               name="allow"
            //    onChange={this.checkBoxChange}
               // disabled={this.checkReadOnly() ? "" : "disabled"}
           />
           <span /><p className='_text'>Charlie Marjot</p>
       </div>
    </div>
    </div> */}
    <div className="filter-actions text-right mt-5">
      <button className="btn btn-outline-dark pl-4 pr-4" onClick={()=>props.onHide()}>Cancel</button>
      <button className="btn btn-dark ml-4 pl-4 pr-4" onClick={handleServiceSettings}>Apply</button>
    </div>
  </div>
  )
}

// export default ServicePopup

function mapStateToProps(state) {
  return {
      ...state
  };
}
export default connect(mapStateToProps)(ServicePopup);