import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import TopHeader from "../../template/TopHeader";
import SideNav from "../../template/SideNav";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "react-toast-notification";
// import '../service.css';
import Select from 'react-select';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CancelSaveBtn from "./CancelSaveBtn";
import useWindowSize from "../useWindowSize";
import { newServicesAction } from "../../../_actions";
import { useParams } from "react-router-dom";




function EditService(props) {
    // const [serviceName, setServiceName] = useState('');
    // const [category, setCategory] = useState(null);
    // const [serviceTime,setServiceTime] =useState('')
    // const [description, setDescription ] = useState("")
    const dispatch = useDispatch();
    const {id} = useParams();
    const[showTooltip , setShowTooltip] = useState(false);
    const [serviceData, setServiceData] = useState({
        serviceName: '',
        description: '',
        category: null,
        serviceEditBy: {
            name: "",
            userImage: {
              circleImage: null,
              rectangleImage: null
            }
        },
        staffSettings:[
          {                    
            hairdresserId: "", 
            servicePrice: 0,
            serviceTime: 0,
            isDeactivated: false, 
            depositPrice: 0,
            onlineBooking: false,
            processingTime: 0
          }
        ]
        
    });
    const [errors, setErrors] = useState({});
    const history = useHistory();
    const { width } = useWindowSize();
    const listCategory = useSelector(state => state.newServices.listCategory);
    const listHairdresser = useSelector(state => state?.newServices?.listHairdresser);
    const [serviceResponse, setServiceResponse] = useState(null);
    const showTooltipFn=()=>{setShowTooltip(true)}
    const hideTooltipFn=()=>{setShowTooltip(false)}
    console.log("listHairdresserlistHairdresser1",listHairdresser)
    const categoryOptions = listCategory?.map(category => ({
      value: category?._id, // Use '_id' for the value
      label: category?.categoryName // Use 'categoryName' for the label
    }));

    const customStyles = {
      control: (provided) => ({
        ...provided,
        borderColor: '#ccc',
        boxShadow: 'none',
        '&:hover': {
          borderColor: '#707070',
        },
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#29282e' : 'white',
        color: state.isSelected ? 'white' : '#333',
        '&:hover': {
          backgroundColor: state.isSelected ? '#29282e' : '#f0f0f0',
        },
      }),
    };

    const categoryList = () => {
        const payload = {}
        dispatch(newServicesAction.listCategoryData(payload))
            .then((res) => {
                console.log("Category list successfully:", res);

            })
            .catch((error) => {
                console.error("Error with category list:", error);
            });
    }

    const handleStaffSettingChange = (index, field, value) => {
      // console.log("SDSDSDSDSD ", index, field, value);
      if (field === "isDeactivated"  && value == true) {
       
        const error = validateFields(field, value );
        setErrors(prev => ({
          ...prev,
          [`${field}_${index}`]: error 
        }));
      }
      else  if (field === "serviceTime"  && value !== 0) {
       
        const error = validateFields(field, value );
        setErrors(prev => ({
          ...prev,
          [`${field}_${index}`]: error 
        }));
      } else if(field === "processingTime"  && value !== 0) {
       
        const error = validateFields(field, value);
        setErrors(prev => ({
          ...prev,
          [`${field}_${index}`]: error 
        }));
      } else {
        
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[`${field}_${index}`];
          return newErrors;
        });
      }

      const newStaffSettings = [...serviceData.staffSettings];
      newStaffSettings[index][field] = value;
      setServiceData(prev => ({ ...prev, staffSettings: newStaffSettings }));

    };
    

    

    const validateFields = (name, value ) => {
      let error = '';
      switch (name) {
          case 'serviceName':
              if (!value) {
                  error = "Service Name is required.";
              }
              break;
          case 'category':
              if (!value) {
                  error = "Category is required.";
              }
              break;
          case 'serviceTime':
           if (value!=0 && value%5!=0) {
                  error = "Must be multiple of 5.";
              }
              break;
          case 'processingTime':
              if (value!=0 && value%5!=0) {
                  error = "Must be multiple of 5.";
              }
              break;
              
          default:
              break;
      }
      return error;
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      
      if (name === "serviceName" || name === "description") {
        const error = validateFields(name, value);
        setErrors(prev => ({
          ...prev,
          [name]: error
        }));
      } else {
        // Clear the error if the field is valid or empty
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[name];
          return newErrors;
        });
      }
    
      setServiceData(prev => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleCategoryChange = (selected) => {
        setServiceData(prev => ({
            ...prev,
            category: selected,
        }));
        setErrors(prev => ({
            ...prev,
            category: validateFields('category', selected)
        }));
    };

    const handleSave = () => {
      setErrors({});
  
      const newErrors = {
        serviceName: validateFields('serviceName', serviceData.serviceName),
        category: validateFields('category', serviceData.category)
      };
    
      if (serviceData.staffSettings && Array.isArray(serviceData.staffSettings)) {
        for (let index = 0; index < serviceData.staffSettings.length; index++) {
          const staff = serviceData.staffSettings[index];

          if (staff.isDeactivated == true && ( staff.serviceTime == '' || staff.serviceTime == 0)) {
            newErrors[`serviceTime_${index}`] = "Service time can't be set to 0 when service is enabled";
          } else if (staff.serviceTime) {
            const serviceTimeError = validateFields('serviceTime', parseInt(staff.serviceTime));
            if (serviceTimeError) {
              newErrors[`serviceTime_${index}`] = serviceTimeError;
            }
          }
         
          if (staff.processingTime) {
            const processingTimeError = validateFields('processingTime', parseInt(staff.processingTime));
            if (processingTimeError) {
              newErrors[`processingTime_${index}`] = processingTimeError;
            }
          }

          if (parseFloat(staff.depositPrice) > parseFloat(staff.servicePrice)) {
            newErrors[`depositPrice_${index}`] = "Deposit price cannot be greater than service price.";
          }

        }
      }
    
      const filteredErrors = Object.fromEntries(
        Object.entries(newErrors).filter(([_, v]) => v !== '')
      );
      
      setErrors(filteredErrors);
    
      if (Object.keys(filteredErrors).length > 0) {
        return;
      }
      const payload = {
        commonServiceId:id,
        serviceName:serviceData.serviceName,
        categoryID: serviceData?.category ? serviceData?.category.value: "", 
        serviceDescription: serviceData.description,
        hairdresserService: serviceData.staffSettings?.map(staff => (
          {
            hairdresserId: staff.hairdresserId, 
            servicePrice: parseFloat(staff.servicePrice) || 0,
            serviceTime: parseFloat(staff.serviceTime) || 0,
            isDeactivated: !staff.isDeactivated, 
            depositPrice: parseFloat(staff.depositPrice) || 0,
            onlineBooking: staff.onlineBooking,
            processingTime: parseFloat(staff.processingTime) || 0,
        }
      )),
    };
      console.log("Saving add service data1212:", payload);
      dispatch(newServicesAction.editServicesData(payload))
      .then((res) => {
         console.log("Category edited successfully:", res);
          if(res?.editServices?.statusCode===1) {
            toast.success(res?.editServices?.responseData?.message);
          }
          else{
            toast.error(res?.editServices?.error?.responseMessage);
          }
        history.push("/new-services");
      })
      .catch((error) => {
        console.error("Error edit service:", error);
      });
    };


    const viewService = async (serviceId) => {
      const payload = { commonServiceId: serviceId };
      dispatch(newServicesAction.viewServiceData(payload))
          .then((res) => {
              setServiceResponse(res?.viewService);
          })
          .catch((error) => {
              console.error("Error fetching service data:", error);
          });
  };


    useEffect(()=>{
        categoryList();
      setTimeout(()=>{
        viewService(id)
      },500)
      
    },[])

    useEffect(() => {
      if (serviceResponse && listHairdresser?.length > 0) {
          console.log("Updating with hairdressers:", listHairdresser);
          let selectedCategoryOptions = categoryOptions?.find((item) => 
              item?.value == serviceResponse?.categoryID
          );

          setServiceData(prev => ({
              ...prev,
              category: selectedCategoryOptions || null,
              serviceName: serviceResponse?.serviceName || '',
              description: serviceResponse?.serviceDescription || '',
              serviceEditBy: {
                  name: serviceResponse?.serviceEditBy?.name,
                  userImage: {
                      circleImage: serviceResponse?.serviceEditBy?.userImage?.circleImage,
                      rectangleImage: serviceResponse?.serviceEditBy?.userImage?.rectangleImage
                  }
              },
              staffSettings: serviceResponse?.hairdresserService?.map((item) => {
                  const hairdresser = listHairdresser?.find(h => h._id === item.hairdresserId);
                  return {
                      hairdresserId: item.hairdresserId,
                      name: hairdresser?.name || '',
                      servicePrice: parseFloat(item.servicePrice) || 0,
                      serviceTime: parseFloat(item.serviceTime) || 0,
                      isDeactivated: !item.isDeactivated || false,
                      depositPrice: parseFloat(item.depositPrice) || 0,
                      onlineBooking: item.onlineBooking || false,
                      processingTime: parseFloat(item.processingTime) || 0,
                  }
              })
          }));
      }
  }, [listHairdresser, serviceResponse]);


    const renderServiceDetails = () => (
      <div className="cm_box_view mt-4 _left_right_spac">
        <h4 className="mt-2">Service Details</h4>
        <div className='tooltip_parent  _add_service_tooltip'>
        {console.log("width_width",width)}
          { width>1101 ? 
          <>
          {console.log("serviceData?.serviceEditBy",serviceData)}
          {serviceData?.serviceEditBy?.name && <span className='_profile' 
            onClick={showTooltipFn} onMouseEnter={showTooltipFn} 
            onMouseLeave={hideTooltipFn}
          >  <img src={serviceData?.serviceEditBy?.userImage?.circleImage || require('../../../assets/images/Pixie.png')} /></span>}
          { showTooltip===true? <div className='tooltip-body'>Service added by {serviceData?.serviceEditBy?.name}.  </div>:''  }
          </>
          :
          <>
            {serviceData?.serviceEditBy?.image && <span className='_profile' 
              onMouseEnter={showTooltipFn} 
              onMouseLeave={hideTooltipFn}>  <img src={require('../../../assets/images/Pixie.png')} /></span>}
              {showTooltip===true? <div className='tooltip-body'>Service added by {serviceData?.serviceEditBy?.name}.  </div>:''}
          </>
          }
        </div>
        <div className="filters-popup pt-3">
          <div className="row">
            <div className="col-md-6">
              {console.log("serviceData_serviceData",serviceData)}
              <div className="filter-group">
                <label>Service Name</label>
                <input
                  type="text"
                  name="serviceName"
                  placeholder="e.g. Haircut"
                  className="form-controls"
                  value={serviceData.serviceName}
                  onChange={handleChange}
                />
                {errors.serviceName && <span className="cm_error_msg">{errors.serviceName}</span>}
              </div>
            </div>
            {console.log("test-->", serviceData)}
            <div className="col-md-6">
              <div className="filter-group">
                <label>Category</label>
                
                <Select
                  options={categoryOptions}
                  value={serviceData.category}
                  classNamePrefix="my-select"
                  onChange={handleCategoryChange}
                  styles={customStyles}
                  isSearchable={false} 
                  placeholder="Choose a Category"
                />
                {errors.category && <span className="cm_error_msg">{errors.category}</span>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="filter-group">
                <label>Service Description</label>
                <textarea
                  name="description"
                  placeholder="e.g. Transform your look with a professional haircut tailored to your style preferences..."
                  value={serviceData.description}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const renderStaffSettings = () => (
      <div className="cm_box_view mt-4 staff-settings">
        {/* <div className="table-responsive"> */}
          <table>
            <thead>
              <tr>
                <th className="fixed-column">Staff Member</th>
                <th className="fixed-column">Service Enabled</th>
                <th className="fixed-column">Online Bookings</th>
                <th className="fixed-column">Service Duration</th>
                <th className="fixed-column">Processing Time</th>
                <th className="fixed-column">Deposit</th>
                <th className="fixed-column">Service Cost</th>
              </tr>
            </thead>
            <tbody>
              {console.log("serviceData==>",serviceData?.staffSettings)}
              {serviceData.staffSettings.map((staff, index) => {
                 const hasRowError = Object.keys(errors).some(key => 
                  key.endsWith(`_${index}`) && errors[key]
                );
                return(
                <tr key={staff.hairdresserId}>
                  <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }} className="fixed-column">{staff.name}</td>
                  <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                    <div className="salon_switch_container">
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="isDeactivated"
                          onChange={(e) => handleStaffSettingChange(index, 'isDeactivated', e.target.checked)}
                          checked={staff.isDeactivated}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </td>
                  <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                    <div className="salon_switch_container">
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="onlineBooking"
                          onChange={(e) => handleStaffSettingChange(index, 'onlineBooking', e.target.checked)}
                          checked={staff.onlineBooking}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </td>
                  <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                    <input
                      type="number"
                      name="serviceTime"
                      value={staff.serviceTime}
                      onChange={(e) => handleStaffSettingChange(index, 'serviceTime', e.target.value)}
                      // placeholder="5min"
                    />
                    {errors[`serviceTime_${index}`] && <span className="cm_error_msg">{errors[`serviceTime_${index}`]}</span>}
                  </td>
                  <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                    <input
                      type="number"
                      name="processingTime"
                      value={staff.processingTime}
                      onChange={(e) => handleStaffSettingChange(index, 'processingTime', e.target.value)}
                      // placeholder="5min"
                    />
                    {errors[`processingTime_${index}`] && <span className="cm_error_msg">{errors[`processingTime_${index}`]}</span>}
                  </td>
                  <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                  <div className="_fix_left_sign pos-relative">
                    <input
                      type="number"
                      name="depositPrice"
                      value={staff.depositPrice}
                      onChange={(e) => handleStaffSettingChange(index, 'depositPrice', e.target.value)}
                      placeholder=""
                    />
                    <span>£</span>
                    </div>
                    {errors[`depositPrice_${index}`] &&  <p className="cm_error_msg">{errors[`depositPrice_${index}`]}</p> }
                  </td>
                  <td style={{ verticalAlign: hasRowError ? 'baseline' : 'middle' }}>
                  <div className="_fix_left_sign pos-relative">
                    <input
                      type="number"
                      name="servicePrice"
                      value={staff.servicePrice}
                      onChange={(e) => handleStaffSettingChange(index, 'servicePrice', e.target.value)}
                      placeholder=""
                    />
                    <span>£</span>
                   
                  </div>
                  </td>
                </tr>)
             })}
            </tbody>
          </table>
        {/* </div> */}
      </div>
    );

  
    return (
      <div className="h_n _new__service">
      <div className="body_container cm_bg_white _nowhite __add_service">
        <TopHeader 
        title="Edit Service" 
        headerButtonStatus={true}
        {...props} 
        onSave={handleSave}
        />
        <SideNav {...props} />
        <div className="new-setting-wrapper">
          <div className="container">
        
            {/* <div className="_profile">
            
            </div> */}
         
          {width<1101 ? 
            <Tabs
            defaultActiveKey="detail"
            id="uncontrolled-tab-example"
            className="add_edit_service mb-3"
          >
            <Tab eventKey="detail" title="Detail" className="add_edit_service_content">
              {renderServiceDetails()}
              
            </Tab>
            <Tab eventKey="staff" title="Staff" className="add_edit_service_content">
              {renderStaffSettings()}
              {/* <CancelSaveBtn/>  */}
            </Tab>
            <CancelSaveBtn onSave={handleSave}/> 
          </Tabs>
            : (
            <>
              {renderServiceDetails()}
              {renderStaffSettings()}
            </>
            )
          }
          {width<1101 ? <CancelSaveBtn onSave={handleSave} />:''}
          </div>
        
        </div>
      </div>


    </div>
    )
}

export default EditService
// function mapStateToProps(state) {
//   return {
//     ...state,
//   };
// }

// export default connect(mapStateToProps)(EditService);
