import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { IoMdAdd } from "react-icons/io";
import { withRouter } from "react-router-dom";
import { productAction } from "../../_actions/product.action";
import { connect } from "react-redux";
import ServiceSettingSidebar from "../new-service/ServiceSettingSidebar";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      productList: [],
      limit: 20,
      search: "",
      currencySymbol:'£'
    };
  }

  searchProduct = (event) => {
    this.setState({ search: event.target.value }, this.listProduct);
  };

  componentDidMount() {
    this.listProduct();
  }

  listProduct = () => {
    const { search, page, limit } = this.state;

    const requestData = {
      search,
      //page,
      //limit,
    };
    
    // console.log("gggggggggggg", requestData);
    this.props
      .dispatch(productAction?.listProduct(requestData))
      .then((response) => {
        let responseList = response?.data?.responseData.result;
        console.log("response list---->", responseList);
        if (search) {
          responseList = responseList?.filter((product) =>
            product.productName.toLowerCase().includes(search.toLowerCase())
          );
        }
        this.setState({
          productList :responseList ,page,limit
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleClick = (product) => {
    const { history } = this.props;
    history.push("/product/viewProduct", { product });
  };

  handleAddClick = () => {
    const { history } = this.props;
    history.push("/product/addProduct");
  };

  render() {
    const { search, productList ,currencySymbol} = this.state;
    {console.log("marketibgproips---",this.props)}
    return (
      <div className="h_n">
        
        <div className="body_container cm_bg_white">
          <TopHeader title="Products" {...this.props} />
          <SideNav {...this.props} />
          <div className="new-setting-wrapper">
            <div className="container">
              <div className="row">
                  <div className="col-md-4 col-sm-12 mobile-hidden _service_sidebar">
                      <ServiceSettingSidebar />
                  </div>
                  <div className="col-md-8">
                      <div className="row ">
                        <div className="col-sm-4 add_btn __add_btn">
                          <button
                            className="btn btn-inf w-100 p-3 add_button sm-w-50 "
                            onClick={this.handleAddClick}
                          >
                            <IoMdAdd className="iconAdd _add_icon" size={20} />
                            Add Product
                          </button>
                        </div>

                        <div className="cm_search_wrapper client_search m-0 col-sm-8 search_product _product_search">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Search…"
                            name="search"
                            value={search}
                            onChange={this.searchProduct}
                          />
                          <i className="fa fa-search mr-4" />
                        </div>
                      </div>
                      <div className="row black_heading">
                          <div className="col-md-12 d-flex product">
                            <div className="col-md-6 ">
                              <h5 className="mb-0">Product Name</h5>
                            </div>
                            <div className="col-md-6 text-right">
                              <h5 className="mb-0">Pricing</h5>
                            </div>
                          </div>
                      </div>
                      <div className="row black_heading">
                        
                        <div className="col-md-12 product_data p-0 overflow-scroll">
                          <table className="table table-striped border-0">
                            <tbody className="">
                              {productList?.map((product) => (
                                <tr key={product.id} onClick={() => this.handleClick(product)}>
                                  <td className="align-middle border-0">
                                    <span className="text-dark pl-4">{product.productName}</span>
                                  </td>
                                  <td className="text-right border-0 m-0">
                                    <span className="text-dark pr-4">{currencySymbol}{(product.retailPrice).toFixed(2)}</span> <br />{" "}
                                    <span className="text-info pr-4 font_design">{currencySymbol}{(product.stockPrice).toFixed(2)}</span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        
                      </div>

                  </div>
              </div>
            </div>
          </div>


          {/* <div className="container">
            <div className="row ">
              <div className="col-md-1"></div>
              <div className="col-md-3 add_btn">
                <button
                  className="btn btn-inf w-100 p-3 add_button sm-w-50 "
                  onClick={this.handleAddClick}
                >
                  <IoMdAdd className="iconAdd" size={30} />
                  Add Product
                </button>
              </div>

              <div className="cm_search_wrapper client_search m-0 col-md-7 search_product">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search…"
                  name="search"
                  value={search}
                  onChange={this.searchProduct}
                />
                <i className="fa fa-search mr-4" />
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          <div className="container">
            <div className="row black_heading">
              <div className="col-md-1"></div>
              <div className="col-md-10 d-flex product">
                <div className="col-md-6 ">
                  <h5 className="mb-0">Product Name</h5>
                </div>
                <div className="col-md-6 text-right">
                  <h5 className="mb-0">Pricing</h5>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          <div className="container">
            <div className="row black_heading">
              <div className="col-md-1"></div>
              <div className="col-md-10 product_data p-0 overflow-scroll">
                <table className="table table-striped border-0">
                  <tbody className="">
                    {productList?.map((product) => (
                      <tr key={product.id} onClick={() => this.handleClick(product)}>
                        <td className="align-middle border-0">
                          <span className="text-dark pl-4">{product.productName}</span>
                        </td>
                        <td className="text-right border-0 m-0">
                          <span className="text-dark pr-4">{currencySymbol}{(product.retailPrice).toFixed(2)}</span> <br />{" "}
                          <span className="text-info pr-4 font_design">{currencySymbol}{(product.stockPrice).toFixed(2)}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("product_state",state)
  return {
    ...state,
  };
}
export default connect(mapStateToProps)(withRouter(Product));
