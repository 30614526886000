import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { hairdresserActions } from "../../_actions";
import StarRatings from "react-star-ratings";
import ImageGallery from "react-image-gallery";
import dateFormat from 'dateformat';
import $ from "jquery";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {settingConstants} from "../../_constants";
import "react-datepicker/dist/react-datepicker.css";
import {createBooking} from "../../_reducers/booking.reducer";
import { Modal } from 'react-bootstrap';
class HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            //serviceFilterValue:1,
            CreateImageashowPopup: false,
            poftFolioPopup: [],
            address: '',
            date: '',
            serviceName:'',
            serviceDisplay:{'display':'none'},
            currentLocation:{},
            latitude: '51.5063341',
            longitude:'-0.118092',
            mylat:'51.5063341',
            mylong:'-0.118092',
            serviceSelected:false,
            hairdresserSortBy:'distance',
            hairdresserLocationFilter:'anywhere',
            sortType:'asc',
            selectDate:new Date(),
            isOwner:1,
            searchExpend:false,
            addressSelected:false,
            locationDisplay:{'display':'none'},
            dateSelected:false,
            dateDisplay:{'display':'none'},
            isBackArrow:false,
        }
        this.openCreateImageashowPopup = this.openCreateImageashowPopup.bind(this);
        this.openSalon = this.openSalon.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.filterLocation = this.filterLocation.bind(this);
        this.filterService = this.filterService.bind(this);
        this.filterDate = this.filterDate.bind(this);
        this.getDate = this.getDate.bind(this);
        this.selectService = this.selectService.bind(this);
        this.toggleBookingService=this.toggleBookingService.bind(this);
        this.noLocation=this.noLocation.bind(this);
        this.foundLocation=this.foundLocation.bind(this);
        this.hairdresserSortBy=this.hairdresserSortBy.bind(this);
        this.servicePriceGet=this.servicePriceGet.bind(this);
        this.sortClick=this.sortClick.bind(this);
        this.toggleDatePopup=this.toggleDatePopup.bind(this);
        this.toggleLocationPopup=this.toggleLocationPopup.bind(this);
    }
    openxEpendSearch= () => {
       this.setState({
        searchExpend : !this.state.searchExpend
       })
    }
    componentDidMount() {
        this.noLocation();
        document.body.classList.add('login-home-temp'); 
        document.body.classList.remove('nav_menu_open');
        document.body.classList.remove('no-topbar');
        let { dispatch } = this.props;
        let self = this;
        navigator.geolocation.getCurrentPosition(this.foundLocation,this.noLocation.bind(this))


        self.props.dispatch(hairdresserActions.getService())
            .then(function (response) {
                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {

                }
            });

    }

    foundLocation(location) {
        let { dispatch } = this.props;
        let self = this;
        this.setState({
            currentLocation:location.coords,
            latitude: location.coords.latitude?location.coords.latitude:'51.5063341',
            longitude:location.coords.longitude?location.coords.longitude:'-0.118092',
            mylat: location.coords.latitude?location.coords.latitude:'51.5063341',
            mylong:location.coords.longitude?location.coords.longitude:'-0.118092',
        })
        let params = {
            sortType:this.state.sortType,
            hairdresserSortBy: this.state.hairdresserSortBy,
            hairdresserLocationFilter: this.state.hairdresserLocationFilter,
            latitude: location.coords.latitude?location.coords.latitude:'51.5063341',
            longitude:location.coords.longitude?location.coords.longitude:'-0.118092',
            isOwner:this.state.isOwner,
            page: 1
        }
        self.props.dispatch(hairdresserActions.getSalonList(params))
            .then(function (response) {

                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {

                }
            });
    }

    noLocation() {
        //let { dispatch } = this.props;
        let self = this;
        let params = {
            sortType:this.state.sortType,
            hairdresserSortBy: this.state.hairdresserSortBy,
            hairdresserLocationFilter: this.state.hairdresserLocationFilter,
            latitude: this.state.latitude,
            longitude:this.state.longitude,
            isOwner:this.state.isOwner,
            page: 1
        }
        self.props.dispatch(hairdresserActions.getSalonList(params))
            .then(function (response) {

                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {

                }
            });
    }

    handleSearch(event) {
        let self = this;
        let params = {}

        if (typeof self.state.dateFilter !== 'undefined' && self.state.dateFilter !== '') {
            params = {
                sortType:this.state.sortType,
                hairdresserSortBy: this.state.hairdresserSortBy,
                hairdresserLocationFilter: this.state.hairdresserLocationFilter,
                latitude: self.state.latitude ? self.state.latitude : self.state.currentLocation.latitude,
                hairdresserDateFilter: self.state.dateFilter ? self.state.dateFilter.toString() : '',
                longitude: self.state.longitude ? self.state.longitude : self.state.currentLocation.longitude,
                page: 1,
                isOwner:this.state.isOwner,
            }
        }
        else {
            params = {
                sortType:this.state.sortType,
                hairdresserSortBy: this.state.hairdresserSortBy,
                hairdresserLocationFilter: this.state.hairdresserLocationFilter,
                latitude: self.state.latitude ? self.state.latitude : self.state.currentLocation.latitude,
                hairdresserServiceFilter: self.state.serviceId ? self.state.serviceId : '',
                longitude: self.state.longitude ? self.state.longitude : self.state.currentLocation.longitude,
                page: 1,
                isOwner:this.state.isOwner,
            }
        }
        self.props.dispatch(hairdresserActions.getSalonList(params))
            .then(function (response) {
                if (response.data.error) {

                    self.setState({
                        message: response.data.error.message
                    })
                } else {

                }
            });
    }

    handleChange = address => {
        console.log('address',address);
        this.setState({ address });
    };

    handleSelect = address => {
        var self = this;
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(function (latLng) {
                self.setState({
                    address: address,
                    lat: latLng.lat,
                    lng: latLng.lng,
                    latitude: latLng.lat,
                    longitude:latLng.lng,
                    locationDisplay:{'display':'block'},
                    addressSelected:true,
                },function () {
                    self.setState({
                        locationFilterValue:4,
                    })
                    self.handleSearch()
                    self.props.dispatch({type: settingConstants.TOGGLE_LOCATION_POP,value:2});
                });
            })
    };
    closeModal = () => {
        this.setState({
            CreateImageashowPopup: false,
        })
    }

    filterLocation(event) {
        let self=this;
        //console.log("event.target.value",event.target.value);
        // if (event.target.value === 1||event.target.value === 2) {
        if (event.target.value === 1||event.target.value === "1") {
            let hairdresserLocationFilter='anywhere';
            this.setState({
                latitude: this.state.mylat,
                longitude:this.state.mylong,
                pickLocation: false,
                hairdresserLocationFilter,
                addressSelected:false,
                locationDisplay:{'display':'none'},
                address:'',
                locationFilterValue: event.target.value
            },function () {
                self.handleSearch()
            })
        }else if(event.target.value === 2||event.target.value === "2"){
            let hairdresserLocationFilter='nearme';
            this.setState({
                latitude: this.state.mylat,
                longitude:this.state.mylong,
                pickLocation: false,
                hairdresserLocationFilter,
                addressSelected:false,
                locationDisplay:{'display':'none'},
                address:'',
                locationFilterValue: event.target.value
            },function () {
                self.handleSearch()
            })
        } else {
            self.toggleLocationPopup(1)
            this.setState({
                locationFilterValue: event.target.value,
                // hairdresserLocationFilter:'location',
                // pickLocation: true
            })
        }
    }
    getDate(date) {
        //2019-07-16
        var self = this;
        self.setState({
            selectDate:date,
            dateFilter: dateFormat(date,'yyyy-mm-dd'),
            dateSelected:true,
            dateDisplay:{'display':'block'},
            dateFilterValue:3
        }, function () {
            self.handleSearch();
                self.props.dispatch({type: settingConstants.TOGGLE_DATE_POP,value:2});
        })
    }

    selectService(serviceId,serviceName) {
        console.log(serviceName)
        this.setState({
            serviceId: serviceId,
            serviceDisplay:{'display':'block'},
            serviceName:serviceName,
            serviceSelected:true,
            serviceFilterValue:3
        }, () => {
            this.handleSearch()
            this.toggleBookingService(2)
        })
    }

    filterService(event) {
        //console.log(event.target.value)
        if(event.target) {
            if (event.target.value === 1) {

                this.setState({
                    serviceId: '',
                    serviceDisplay: {'display': 'none'},
                    serviceName: '',
                    service: false,
                    serviceSelected: false,
                    serviceFilterValue:1,
                }, function () {
                    this.handleSearch()
                })
            } else {
                this.toggleBookingService(1)
                this.setState({
                    serviceFilterValue: event.target.value
                })
            }
        }else{
            this.setState({
                serviceId: '',
                serviceDisplay: {'display': 'none'},
                serviceName: '',
                service: false,
                serviceSelected: false,
                serviceFilterValue:1,
            }, function () {
                this.toggleBookingService(2)
            })
        }
    }

    toggleBookingService(value) {
        this.props.dispatch({type: settingConstants.TOGGLE_BOOKING_SERVICE,value:value});
    }

    toggleDatePopup(value) {
        if(value===2){
            this.setState({
                dateFilterValue:1
            })
        }
        this.props.dispatch({type: settingConstants.TOGGLE_DATE_POP,value:value});
    }

    toggleLocationPopup(value) {
        if(value===2){
            this.setState({
                locationFilterValue:1
            })
        }
        this.props.dispatch({type: settingConstants.TOGGLE_LOCATION_POP,value:value});
    }

    filterDate(event) {
        var self=this;
        if (event.target.value === 1) {

            this.setState({
                date: false,
                dateFilter:'',
                dateSelected:false,
                dateDisplay:{'display':'none'},
                dateFilterValue:1
            },function () {
                this.handleSearch()
            })

        } else {
            this.setState({
                dateFilterValue:event.target.value,
                selectDate:new Date(),
                //date: true
            },function () {
                self.toggleDatePopup(1);
            })
            $(function () {
                var dtToday = new Date();

                var month = dtToday.getMonth() + 1;
                var day = dtToday.getDate();
                var year = dtToday.getFullYear();
                if (month < 10)
                    month = '0' + month.toString();
                if (day < 10)
                    day = '0' + day.toString();

                var maxDate = year + '-' + month + '-' + day;
                //alert(maxDate);
                $('#datepicker').attr('min', maxDate);
            });
        }
    }

    openSalon(hairdresserId, salonId) {
        sessionStorage.setItem('prevUserId',hairdresserId);
        this.props.history.push({
            pathname: "/salon/" + salonId + "/professional/" + hairdresserId,
            state: {
              isBackArrow:true,
              marketPlaceUrl:this.props.history.location.pathname,
              prevUserId:hairdresserId
            },
          });
    }

    openCreateImageashowPopup(portfolio) {
        let SalonPortfolio = []
        portfolio.map((portfolio, i) => (
            SalonPortfolio.push({
                original: portfolio.imageUrl ? portfolio.imageUrl : require('../../assets/images/Pixie1.png'),
                thumbnail: portfolio.imageUrl ? portfolio.imageUrl : require('../../assets/images/Pixie1.png'),
            })
        ))

        this.setState({
            poftFolioPopup: SalonPortfolio,
            CreateImageashowPopup: !this.state.CreateImageashowPopup
        })
    }

    hairdresserSortBy(sortValue){
        this.setState({
            hairdresserSortBy:sortValue,
        },function () {
            this.handleSearch()
        })
    }

    servicePriceGet(serviceList){
        console.log('servicePriceGet',serviceList);
    }

    sortClick(sortType){
        this.setState({
            sortType:sortType
        },function () {
            this.handleSearch()
        })
    }

    render() {
        console.log('this.props',this.props);
        let { hairdresser, salonList, services } = this.props;
        return (
            <React.Fragment>
                {
                    salonList.hairdressers ?
                        <div className="container cm_bg_home ">
                         <div className="phone-search">
                             
                          {this.state.searchExpend === true ?
                          
                          <div className="row searching_mob">
                          <div className="col-sm-12 search-mob-pad">
                          <form className="form-inline">
                                          <div className="form-group searchParent">
                                          <i className='fa fa-search selectfa' aria-hidden='true'></i>
                                              <select className="my-forms" id="sel1" onChange={this.filterLocation} >
                                                  <option value="1">Anywhere</option>
                                                  <option value="2">Near Me</option>
                                                  <option value="3">Specific Location</option>
                                                  <option value="4" style={this.state.locationDisplay} selected={this.state.addressSelected}>{this.state.address}</option>
                                              </select>
                                          </div>
                                        
                                          {/*<div className="form-group searchParent">*/}
                                          {/*<i className='fa fa-search selectfa selectfa1' aria-hidden='true'></i>*/}
                                          {/*    <select className="my-forms" id="serviceFilter" onChange={this.filterService} value={this.state.serviceFilterValue}>*/}
                                          {/*        <option value="1">Any Service</option>*/}
                                          {/*        <option value="2">Specific Service</option>*/}
                                          {/*        <option value="3" style={this.state.serviceDisplay} selected={this.state.serviceSelected}>{this.state.serviceName}</option>*/}
                                          {/*    </select>*/}
                                          {/*</div>*/}
                                          <div className="form-group searchParent">
                                          <i className='fa fa-search selectfa selectfa1' aria-hidden='true'></i>
                                              <select className="my-forms" id="sel1" onChange={this.filterDate}>
                                                  <option value="1"> Any Date</option>
                                                  <option value="2">Specific Date</option>
                                                  <option value="3" style={this.state.dateDisplay} selected={this.state.dateSelected}>{this.state.dateFilter}</option>
                                              </select>
                                          </div>

                          </form>
                                      <div className="float-right sortingdiv">
                                                      <p>
                                                          <span className={this.state.hairdresserSortBy==='distance'?'active':''} onClick={this.hairdresserSortBy.bind(this,'distance')}>Distance</span>
                                                          <span className={this.state.hairdresserSortBy==='price'?'active':''} onClick={this.hairdresserSortBy.bind(this,'price')}>Price</span>
                                                          {/*<span className={this.state.hairdresserSortBy==='rating'?'active':''} onClick={this.hairdresserSortBy.bind(this,'rating')}>Rating</span>*/}
                                                          <span className="active">{this.state.sortType==='asc'?
                                                              (
                                                               <img src={require('../../assets/images/round_sort_asc.png')} onClick={this.sortClick.bind(this,'desc')}/>
                                                              // <i className="fa fa-sort-amount-asc" aria-hidden="true" onClick={this.sortClick.bind(this,'desc')}></i>
                                                              )
                                                             
                                                              :(
                                                            <img src={require('../../assets/images/round_sort_desc.png')} onClick={this.sortClick.bind(this,'asc')}/>
                                                            // <i className="fa fa-sort-amount-desc" aria-hidden="true" onClick={this.sortClick.bind(this,'asc')}></i>
                                                              )
                                                          }</span>
                                                      </p>
                                                    
                                      </div>
                          </div>
                          </div>
                          :
                          <div className="row">
                          {/* <div className="col-sm-12 small_searchbox">
                              {/*<div className="search" onClick={this.openxEpendSearch}> <i className="fa fa-search"></i>  <span>Near Me</span> &nbsp;<i className="fa fa-circle" aria-hidden="true"></i>&nbsp;<span>Any Service</span>&nbsp;<i className="fa fa-circle" aria-hidden="true"></i>&nbsp;<span>Any Date</span></div>*/}
                              {/* <div className="search" onClick={this.openxEpendSearch}> <i className="fa fa-search"></i>  <span>Near Me</span> &nbsp;<i className="fa fa-circle" aria-hidden="true"></i>&nbsp;<span>Any Date</span></div>
                               <div className="sort" onClick={this.openxEpendSearch}>
                                   {/* <span className="fa fa-sort-amount-asc"></span>  */}
                               {/* <img src={require('../../assets/images/sorting.png')} />
                               </div>
                           </div> */}  
                       </div>
                          }
                         
                        
                            </div>

{/* ---------------------------------------------------------------search for web------------------------------------------------------------------ */}
                           {/* <div className="row top_home searching_web">
                                <div className="col-md-7 col-lg-6 cm_search searchFlex" >
                                
                                  <div className="form-group searchgroup">
                                      <select className="" id="sel1" onChange={this.filterLocation}>
                                          <option value="1">Anywhere</option>
                                          <option value="2" >Near Me</option>
                                          <option value="3">Specific Location</option>
                                          <option value="4" style={this.state.locationDisplay} selected={this.state.addressSelected}>{this.state.address}</option>
                                      </select>
                                  </div>
                              
                                  {/*<div className="form-group searchgroup">*/}
                                  {/*    <select className="" id="serviceFilter" onChange={this.filterService} value={this.state.serviceFilterValue} >*/}
                                  {/*        <option value="1" >Any Service</option>*/}
                                  {/*        <option value="2" data-icon="glyphicon-glass">Specific Service</option>*/}
                                  {/*        <option value="3" style={this.state.serviceDisplay} selected={this.state.serviceSelected}>{this.state.serviceName}</option>*/}
                                  {/*    </select>*/}
                                  {/*</div>*/}
                                
                                  {/* <div className="form-group searchgroup">
                                      <select className="" id="sel1" onChange={this.filterDate}>
                                          <option value="1">Any Date</option>
                                          <option value="2">Specific Date</option>
                                          <option value="3" style={this.state.dateDisplay} selected={this.state.dateSelected}>{this.state.dateFilter}</option>
                                      </select>
                                  </div> */}
                       
                      {/* </div>
                                <div className=" col-md-5 col-lg-6 cm_sort"  >
                                    <div className="float-right sortingdiv">
                                                        <p>
                                                            <span className={this.state.hairdresserSortBy==='distance'?'active':''} onClick={this.hairdresserSortBy.bind(this,'distance')}>Distance</span>
                                                            <span className={this.state.hairdresserSortBy==='price'?'active':''} onClick={this.hairdresserSortBy.bind(this,'price')}>Price</span>
                                                            {/*<span className={this.state.hairdresserSortBy==='rating'?'active':''} onClick={this.hairdresserSortBy.bind(this,'rating')}>Rating</span>*/}
                                                            {/* <span className="cm_sort_i active">{this.state.sortType==='asc'?
                                                                // (<span onClick={this.sortClick.bind(this,'desc')}><img src={require('../../assets/images/sorting.png')} /></span>)
                                                                <img src={require('../../assets/images/round_sort_asc.png')} onClick={this.sortClick.bind(this,'desc')}/>
                                                                :
                                                                //(<span  onClick={this.sortClick.bind(this,'asc')}><img src={require('../../assets/images/sorting.png')} /></span>)
                                                                <img src={require('../../assets/images/round_sort_desc.png')} onClick={this.sortClick.bind(this,'asc')}/>
                                                            }</span>
                                                        </p>
                                    </div>
                                </div>

                           </div> */} 
                          
                          
                            <div className="row">
                               
                                {salonList.hairdressers.length > 0 ? salonList.hairdressers.map((hairdresser, i) => (
                                    <div className="col-lg-6" key={hairdresser._id}>
                                        <div className="home home-box">
                                            <div className="rating-header homerating float-right">
                                                {/*<span className="">{hairdresser.hairdresser.reviewStats.ratingsAverage}</span>*/}
                                                <span className="Star">
                                                    {/*<StarRatings*/}
                                                    {/*    rating={parseFloat(hairdresser.hairdresser.reviewStats.ratingsAverage)}*/}
                                                    {/*    starRatedColor="#aa9567"*/}
                                                    {/*    numberOfStars={5}*/}
                                                    {/*    name='rating'*/}
                                                    {/*    starDimension="25px"*/}
                                                    {/*    starSpacing="2px"*/}
                                                    {/*/>*/}
                                                </span>
                                                {/*<span className="comment_count">({hairdresser.hairdresser.reviewStats.reviewsCount})</span>*/}
                                            </div>
                                            {/*<img src={hairdresser.userImage ? hairdresser.userImage.rectangleImage : require('../../assets/images/salon_images.jpg')} className="img-fluid" style={{cursor:'pointer'}} onClick={this.openSalon.bind(this, hairdresser._id, hairdresser.hairdresser.salon._id)}/>*/}
                                            <img src={hairdresser.hairdresser.salon&&hairdresser.hairdresser.salon.imageUrl&&hairdresser.hairdresser.salon.imageUrl!=="" ? hairdresser.hairdresser.salon.imageUrl : require('../../assets/images/salonpic.png')} className="img-fluid homeimge-height"  onClick={this.openSalon.bind(this, hairdresser._id, hairdresser.hairdresser.salon._id)}/>
                                            <div className="row p12 cm_min_card_content_height">
                                                <div className="col-xl-6 text-left">
                                                    {/*<h4 style={{cursor:'pointer'}} onClick={this.openSalon.bind(this, hairdresser._id, hairdresser.hairdresser.salon._id)}>{hairdresser.name}</h4>*/}
                                                    <h4 className="light_text" style={{cursor:'pointer'}} onClick={this.openSalon.bind(this, hairdresser._id, hairdresser.hairdresser.salon._id)}>{hairdresser.hairdresser.salon.salonName}</h4>
                                                </div>
                                                <div className="col-xl-6 text-right">
                                                    <p>{hairdresser.hairdresser.salon.formattedAddress} ({hairdresser.hairdresser.salon.distance.toFixed(2)} mi)</p>
                                                </div>
                                            </div>
                                            <div className="row cm_min_slide">
                                                <div className="col-xl-12 align-self-end">
                                                    <div className="photobox">
                                                        {hairdresser.hairdresser.portfolio.map((portfolio, i) => (
                                                            <div key={portfolio._id}>
                                                                <img onClick={this.openCreateImageashowPopup.bind(this, hairdresser.hairdresser.portfolio)} src={portfolio.imageUrl ? portfolio.imageUrl : require('../../assets/images/Pixie1.png')} className="" />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <button className="view_service_btn cm_view_service_btn" onClick={this.openSalon.bind(this, hairdresser._id, hairdresser.hairdresser.salon._id)}>
                                                Book Now
                                                    {/* <p>View Services</p>
                                                    <p>  {hairdresser.hairdresser.currencySymbol&&hairdresser.hairdresser.currencySymbol!==""?hairdresser.hairdresser.currencySymbol:"£"}{hairdresser.hairdresser.services[0].servicePrice}</p>
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i> */}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                    <div className="col-md-12">
                                        <h1 className="text-center">We are coming to your area soon</h1>
                                    </div>
                                }
                            </div>

                        </div>

                        :
                        <div className="cm_loader"><div className="loader"></div></div>

                }
                {/*------------------------image gallary popup-----------------------------*/}
                <Popup
                    open={this.state.CreateImageashowPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal} className="image_modal">
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            <button type="button" className="close float-left"
                                onClick={this.closeModal}>&times;</button>
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <ImageGallery items={this.state.poftFolioPopup} />
                        </div>
                    </div>
                </Popup>
                {/* --------------------------------Services Popup---------------------------------------- */}

                <Modal show={this.props.setting.bookingService} onHide={this.filterService.bind(this,2)}>
                  <div className="cm_modal popupscroll">
                        <div className="modal-header " style={{borderBottom: 'none'}}>
                            <h5 className="serviceheader">Select Service</h5>
                            <button type="button" className="close" onClick={this.filterService.bind(this,2)}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <br/>
                            <div className="services-offered" >
                                {services && services.length > 0 ?
                                    services.map((category, i) => (
                                        <div key={"popup_"+category._id}>
                                            <h5>{category.categoryName}</h5>
                                            <div className="table-responsive  cm_table">
                                                <table>
                                                    <tbody>
                                                    {category.services.map((services, i) => (
                                                        <tr key={services._id} onClick={this.selectService.bind(this,services._id,services.serviceName)}>
                                                            <td>{services.serviceName}</td>
                                                            {/*<td>{services.serviceTime}m</td>*/}
                                                            {/*<td>£{services.servicePrice}</td>*/}

                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className="rating-header nodiv">
                                        <p>No Services Offered </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* <Popup
                    open={this.props.setting.bookingService}
                    closeOnDocumentClick
                >
                    <div className="cm_modal">
                        <div className="modal-header " style={{borderBottom: 'none'}}>
                            <h5 className="serviceheader">Select Service</h5>
                            <button type="button" className="close" onClick={this.filterService.bind(this,2)}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <br/>
                            <div className="services-offered" >
                                {services && services.length > 0 ?
                                    services.map((category, i) => (
                                        <div key={"popup_"+category._id}>
                                            <h5>{category.categoryName}</h5>
                                            <div className="table-responsive  cm_table">
                                                <table>
                                                    <tbody>
                                                    {category.services.map((services, i) => (
                                                        <tr key={services._id} onClick={this.selectService.bind(this,services._id,services.serviceName)}>
                                                            <td>{services.serviceName}</td>
                                                           

                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className="rating-header nodiv">
                                        <p>No Services Offered </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Popup> */}

                {/* --------------------------------Location Popup---------------------------------------- */}

                <Modal show={this.props.setting.location} >
                    <div className="cm_modal popupscroll selectDate">
                        <div className="modal-header " style={{borderBottom: 'none'}}>
                            <h5 className="serviceheader">Select Location</h5>
                            <button type="button" className="close" onClick={this.toggleLocationPopup.bind(this,2)}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className="cm_border_r_none" style={{position:'relative'}}>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Location ...',
                                                className: "my-forms popupSearch",
                                                name: 'locationdesc'
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#597f80', cursor: 'pointer' }
                                                    : { backgroundColor: '#597f80', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >

                                                        <span className="autoc_item">{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                        </div>
                    </div>
                </Modal>

                {/* --------------------------------Date Popup---------------------------------------- */}

                <Modal show={this.props.setting.date} >
                    <div className="cm_modal popupscroll selectDate">
                        <div className="modal-header " style={{borderBottom: 'none'}}>
                            <h5 className="serviceheader">Select Date</h5>
                            <button type="button" className="close" onClick={this.toggleDatePopup.bind(this,2)}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="services-offered" >
                                <DatePicker
                                    className="my-forms"
                                    minDate={this.state.selectDate}
                                    selected={this.state.selectDate}
                                    onChange={this.getDate.bind(this)}
                                    dateFormat="yyyy-MM-dd"
                                    inline
                                />
                            </div>
                            <br/><br/><br/><br/>
                        </div>
                    </div>
                </Modal>

            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {
        hairdresser, salon, hairdresserReview, user, salonList,
        setting,services,
        createBooking
    } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        salonList,
        services,
        createBooking
    };
}

export default connect(mapStateToProps)(HomePage);
