import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Item from './ServiceItem';
import { HiBars2 } from 'react-icons/hi2';
import { Dots } from '../SvgIcon';
import { Dropdown } from 'react-bootstrap';
import useWindowSize from './useWindowSize';
import ServiceItem from './ServiceItem';

function CategoryItem({ 
  category, 
  isCollapsed, 
  onToggleAll, 
  isDragging,
  mouseDownPosition,
  setMouseDownPosition,
  dragThreshold,
  mobileDotCategoryPopup,
  editCategory,
  deleteCategory,
  mobileDotServicePopup,
  deleteService,
  
}) {
  const { 
    attributes, 
    listeners, 
    setNodeRef, 
    transform, 
    transition 
  } = useSortable({ id: category.id });
  const { width } = useWindowSize();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  // console.log("widthserviceitem-category",width)
  const handleMouseDown = (e) => {
    console.log('Mouse Down:', e.clientY);
    setMouseDownPosition(e.clientY);
    handleScroll()
  };

  const handleMouseUp = (e) => {
    console.log('Mouse Up:', e.clientY);
    if (mouseDownPosition) {
      const distance = Math.abs(e.clientY - mouseDownPosition);
      console.log('Movement distance:', distance);
      
      if (distance <= dragThreshold) {
        console.log('Treating as click - toggling categories');
        onToggleAll();
      }
    }
    setMouseDownPosition(null);
  };

  const handleMouseMove = (e) => {
    if (mouseDownPosition) {
      const distance = Math.abs(e.clientY - mouseDownPosition);
      if (distance > dragThreshold) {
        console.log('Drag threshold exceeded:', distance);
      }
    }
  };

 const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const headerHeight = 80; 
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerHeight;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };
  
  return (
    <div 
      ref={setNodeRef} 
      style={style}  
      className={`service-category category ${category?.id}`} 
      data-dragging={isDragging}
      data-collapsed={isCollapsed}
      id={category?.id}
    >
      <div 
      // className="category-header"
      className="category-header"
      >
         <div className={`_first ${category?.id}`}>
               <HiBars2
                  className="menu-icon category-menu-icon drag-handle"
                  {...attributes} 
                  {...listeners}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                  title="Click to toggle all categories / Drag to reorder"
                  onClick={()=>handleScroll(category?.id)}
                />
                <h2 >{category.category}</h2>
         </div>
         {width > 991 ?
          <Dropdown className="transaction_Dropdown __service_dropdown"
           drop="up"
          >
                  <Dropdown.Toggle  id="dropdown-basic">
                     <span> <Dots/></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                      <Dropdown.Item 
                      className="w-100"
                      onClick={()=>editCategory(category?.id,category?.category)}
                      >Edit Category</Dropdown.Item>

                      <Dropdown.Item 
                      onClick={()=>deleteCategory(category?.id , category?.category)}
                      className="text-danger w-100">Delete Category</Dropdown.Item>
                  </Dropdown.Menu>
          </Dropdown>
           :
           <div className="transaction_Dropdown __service_dropdown" 
           onClick={()=>mobileDotCategoryPopup(category?.id , category?.category)}
           ><span> <Dots/></span></div>
          } 

      </div>

      

      {/* <div className={`items ${isCollapsed ? 'collapsed' : ''}`}> */}
      <div className={`service-items ${isCollapsed ? 'collapsed' : ''}`} >
        {!isCollapsed && (
          <SortableContext 
            items={category.items.map(item => item.id)}
            strategy={verticalListSortingStrategy}
          >
            {category.items.map((item) => (
              <ServiceItem 
                key={item.id} 
                item={item} 
                category={category.id}
                mobileDotServicePopup={mobileDotServicePopup}
                deleteService={deleteService}
              />
            ))}
          </SortableContext>
        )}
      </div>
    </div>
  );
}

export default CategoryItem;
