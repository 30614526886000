import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useWindowSize from './useWindowSize';
import { connect, useDispatch, useSelector } from 'react-redux';
import { newServicesAction } from "../../_actions";
// import './Filters.css';

const teamOptions = [
  { value: 'any', label: 'Any team member' },
  // Add more team members as needed
];

const enabledOptions = [
  { value: 'true', label: 'Enabled' },
  { value: 'false', label: 'Disabled' },
];

function FiltersPopup(props) {
  // console.log("props?.user?.userInfo?.hairdresser?.isSalonOwner",props?.user?.userInfo?.hairdresser?.isSalonOwner)
  const dispatch =useDispatch()
  const [teamMember, setTeamMember] = useState(props?.filters?.teamMember ? {
    value: props?.filters?.teamMember,
    label: props?.filters?.teamMemberName
  } : null);
  const [teamMemberName, setTeamMemberName] = useState(null);
  const [servicesEnabled, setServicesEnabled] = useState(props?.filters?.servicesEnabled ? {
    value: props?.filters?.servicesEnabled,
    label: props?.filters?.servicesEnabled === 'true' ? 'Enabled' : 'Disabled'
  } : null);
  const [onlineBookings, setOnlineBookings] = useState(props?.filters?.onlineBookings ? {
    value: props?.filters?.onlineBookings,
    label: props?.filters?.onlineBookings === 'true' ? 'Enabled' : 'Disabled'
  } : null);
  const [listHairdresserData ,setListHairdresserData] = useState([])
  const { width } = useWindowSize();
  const menuPlacementPosition = width > 991 ? 'bottom' : 'top';
  // const listHairdresser = useSelector(state => state.newServices.listHairdresser);
    



  const teamOptions = listHairdresserData?.map(hairdresser => ({
    value: hairdresser._id, 
    label: hairdresser.name 
  }));
  // console.log("teamOptions" ,teamOptions,enabledOptions)
  const handleApply = () => {
    const selectedFilters = {
      teamMemberName: teamMember?.label || props?.filters?.teamMemberName,
      teamMember: teamMember?.value || props?.filters?.teamMember,
      servicesEnabled: servicesEnabled?.value || props?.filters?.servicesEnabled,
      onlineBookings: onlineBookings?.value || props?.filters?.onlineBookings,
    };
    const mergedFilters = {
      ...props?.filters,  
      ...selectedFilters  ,
       searchQuery: props?.filters?.searchQuery || ""
    };

    props.servicesList(mergedFilters);
    props.onSend(mergedFilters);
    props.onHide();
    
    
   
  };

    // Update states when props.filters changes
    useEffect(() => {
      if (props?.filters) {
        // Update teamMember
        if (props.filters.teamMember) {
          setTeamMember({
            value: props.filters.teamMember,
            label: props.filters.teamMemberName
          });
        }
  
        // Update servicesEnabled
        if (props.filters.servicesEnabled) {
          setServicesEnabled({
            value: props.filters.servicesEnabled,
            label: props.filters.servicesEnabled === 'true' ? 'Enabled' : 'Disabled'
          });
        }
  
        // Update onlineBookings
        if (props.filters.onlineBookings) {
          setOnlineBookings({
            value: props.filters.onlineBookings,
            label: props.filters.onlineBookings === 'true' ? 'Enabled' : 'Disabled'
          });
        }
      }
    }, [props.filters]);


  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#707070',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#29282e' : 'white',
      color: state.isSelected ? 'white' : '#333',
      '&:hover': {
        backgroundColor: state.isSelected ? '#29282e' : '#f0f0f0',
      },
    }),
  };
  const getListHairdresseData =()=>{
    let payload = { }
    dispatch(newServicesAction.listHairDresserData(payload))
      .then((res) => {
        console.log("listHairDresserData successfully12121:", res?.listHairdresser);
        setListHairdresserData(res?.listHairdresser)
      })
      .catch((error) => {
        console.error("Error listHairDresserData:", error);
      });
  }
  useEffect(()=>{
    getListHairdresseData()
  },[])



  return (
      <div className="filters-popup">
        {props?.user?.userInfo?.hairdresser?.isSalonOwner==1&&
        <div className="filter-group">
          <label>Team Member</label>
          {/* {console.log("filter_teamMember",props?.filters)} */}
          <Select 
            menuPlacement={menuPlacementPosition}
            classNamePrefix="my-select"
            value={teamMember || teamOptions?.find(option => option?.label === props?.filters?.teamMemberName)}
            onChange={setTeamMember}
            options={teamOptions}
            styles={customStyles}
            placeholder="All Staff"
            isSearchable={false} 
            // menuIsOpen={true}
          />
        </div>
        }
        <div className="filter-group">
          <label>Services Enabled</label>
          <Select 
            menuPlacement={menuPlacementPosition}
            classNamePrefix="my-select"
            // value={servicesEnabled}
            value={servicesEnabled || enabledOptions?.find(option => option?.value === props?.filters?.servicesEnabled)}
            onChange={setServicesEnabled}
            options={enabledOptions}
            styles={customStyles}
            placeholder="All Services"
            isSearchable={false} 
          />
        </div>
        <div className="filter-group">
          <label>Online Bookings</label>
          <Select 
            menuPlacement={menuPlacementPosition}
            classNamePrefix="my-select"
            // value={onlineBookings}
            value={onlineBookings || enabledOptions?.find(option => option?.value === props?.filters?.onlineBookings)}
            onChange={setOnlineBookings}
            options={enabledOptions}
            styles={customStyles}
            placeholder="All Services"
            isSearchable={false} 
          />
        </div>
        <div className="filter-actions text-right mt-5">
          <button className="btn btn-outline-dark pl-4 pr-4" onClick={()=>props.onHide()}>Cancel</button>
          <button className="btn btn-dark ml-4 pl-4 pr-4" onClick={handleApply}>Apply</button>
        </div>

      </div>
  );
}


function mapStateToProps(state) {
  return {
      ...state
  };
}
export default connect(mapStateToProps)(FiltersPopup);