import React from 'react'
import { Services } from '../../assets/SVG';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaBoxOpen } from 'react-icons/fa';

function ServiceSettingSidebar(props) {
    const settingNavDetail=[
        {id: 1, url: '/new-services', title: 'Services', bTitle: 'Manage your service list', icon: <Services />},
    ]
    if(props.user&&props.user?.userInfo&&props.user?.userInfo?.hairdresser?.isSalonOwner===1){
        settingNavDetail.push({id: 2, url: '/product', title: 'Product', bTitle: 'Manage products sold by business', icon: <FaBoxOpen />})
    }
  return (
    <>
      <div className={`new-setting-menu-bar service_settingbar`}>
            <div className='setting-menu-bar'>
               <ul className='list-unstyled m-0 p-0'>
               
               {settingNavDetail?.map((item, i)=>
                    <li key={'payment_nav_'+i}>
                    <NavLink to={item?.url} >
                      <span className='icon'> {item.icon}</span>
                      <span className='sTitle'>
                          <strong className='d-block'>{item.title} </strong>
                          <span className={`text-sm d-block ${item.redclass} `}>{item.bTitle}</span>
                      </span>
                    </NavLink>
                  </li>
                )}
               </ul>
           </div>
        </div>
    </>
  )
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default withRouter(connect(mapStateToProps)(ServiceSettingSidebar));