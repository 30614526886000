import { dispatch } from 'd3';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { newServicesAction } from '../../_actions';
import { useDispatch } from 'react-redux';
import toast from "react-toast-notification";

function MessagePopupBody(props) {
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState('')
  const dispatch = useDispatch()
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setErrors('')

    if(e.target.value==''){
      setErrors('Category is required')
    }
  };

  const handleSave = (type) => {

    if((inputValue != undefined) && (inputValue !='')){
      console.log("input_value---", inputValue)
      console.log("Save button clicked",type);
      if (props.submitFunction && type=="addCategory" ) {
        props.submitFunction(inputValue);
        setInputValue("")
      }
      else if (props.submitFunction &&  type=="editCategory"){
        console.log("editCategory_inputtype",inputValue)
        props.submitFunction(inputValue);
        setInputValue("")
      }
        
        else {
        console.log("submitFunction is not defined");
      }

      setErrors('')
    }
    else{
      setErrors('Category is required')
    }
    
  };

  useEffect(() => {
    setInputValue(props.initialValue); // Set the initial value for editing
  }, [props.initialValue]);

  const handleDelete = (deleted_id,type)=>{
    // debugger
    if(type.startsWith("Delete Category")){
      handleDeleteCategory(deleted_id)
    }
    else if(type.startsWith("Delete Service")){
      handleDeleteService(deleted_id)
    }
    else{}

  }
  
  const handleDeleteCategory = (deleted_id) =>{
    // debugger
    const payload = {
      "categoryId": deleted_id,
    }
    dispatch(newServicesAction.deleteCategoryData(payload))
      .then((res) => {
        console.log("Category deleted successfully:", res);
        if(res?.deleteCategory?.statusCode===1) {
          toast.success(res?.deleteCategory?.responseData?.message);
          props.onHide()
       }
       else{
         toast.error(res?.deleteCategory?.error? res?.deleteCategory?.error?.responseMessage : res?.deleteCategory?.responseData?.message);
       }
       props.servicesList()
       
      })
      .catch((error) => {
        console.error("Error delete category:", error);
      });

  }
  const handleDeleteService = (deleted_id) =>{
    // debugger
    const payload = {
      "commonServiceId": deleted_id,
    }
    dispatch(newServicesAction.deleteServicesData(payload))
      .then((res) => {
        console.log("service deleted successfully:", res);
        if(res?.deleteServices?.statusCode===1) {
          toast.success(res?.deleteServices?.responseData?.message); props.onHide()
       }
       else{
         toast.error(res?.deleteServices?.error? res?.deleteServices?.error?.responseMessage : res?.deleteServices?.responseData?.message);
       }
        props.servicesList()
       
      })
      .catch((error) => {
        console.error("Error delete service:", error);
      });

  }
  const handleDisabledButton = (deleted_id) =>{
    // debugger
    const payload = {
      "commonServiceId": deleted_id,
    }
    dispatch(newServicesAction.serviceStatusChange(payload))
      .then((res) => {
        console.log("serviceStatusChange successfully:", res);
        if(res?.serviceStatusChange?.statusCode===1) {
          toast.success(res?.serviceStatusChange?.responseData?.message); 
          props.onHide()
       }
       else{
        //  toast.error(res?.deleteServices?.error? res?.deleteServices?.error?.responseMessage : res?.deleteServices?.responseData?.message);
       }
        props.servicesList()
       
      })
      .catch((error) => {
        console.error("Error service StatusChange:", error);
      });
  }


  return (
    <Modal 
        blurClass={props?.blurClass}
        centered={props?.centered}
        show={props?.show} 
        onHide={props?.onHide} 
        backdropClassName={props?.backdropClassName}
        size={props?.size} 
        dialogClassName={props?.dialogClassName} 
        className={props?.className} >
        <Modal.Body className=''>
        {props?.popupType == "delete" &&
         <div className='_delete_popup'>
          {props?.notAssigned != false ?
          <>
           <h4>{props?.message?.heading }</h4>
            <p>{props?.message?.discription}</p>
            <div className="filter-actions text-right">
              <button className="btn btn-outline-dark pl-4 pr-4" onClick={()=>props.onHide()}>{props?.message?.btntext1}</button>
              <button className="btn btn-dark ml-4 pl-4 pr-4 _deletebtn"
               onClick={()=>handleDelete(props?.deletedId , props?.message?.heading)}>{props?.message?.btntext2}</button>
            </div>
          </>
          :
          <>
           <h4>{props?.message?.headingAssigned}</h4>
            <p>{props?.message?.discriptionAssigned}</p>
            <div className="filter-actions text-right">
              <button className="btn btn-outline-dark pl-4 pr-4" onClick={()=>props.onHide()}>{props?.message?.btntext1}</button>
              <button className="btn btn-dark ml-4 pl-4 pr-4 _disabled"
               onClick={()=>handleDisabledButton(props?.deletedId)}>Disable</button>
            </div>
          </>
        }
           
         </div>
         }
         {(props.popupType == "edit"  || props.popupType == "add")&&
            <div className="_filter">
              {props.popupType== "edit" ? <h4>Edit {props?.message}</h4> : <h4>New {props?.message}</h4>}
              <div className="filters-popup pt-3">
                <div className="filter-group">
                  <label className='d-block'>{props?.message} Name</label>
                  <input 
                    type="text" 
                    className="form-controls w-100" 
                    name={props?.message+"_name"} 
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  {errors && <span className="cm_error_msg">{errors}</span>}
                </div>
                <div className="filter-actions text-right mt-5">
                  <button className="btn btn-outline-dark pl-4 pr-4" onClick={()=>props.onHide()}>Cancel</button>
                  <button className="btn btn-dark ml-4 pl-4 pr-4" onClick={()=>handleSave(props.popupType+props?.message)}>Save</button>
                </div>
              </div>
            </div>
          }
        </Modal.Body>
    </Modal>
  )
}

export default MessagePopupBody