import siteSetting from "./env";

export default {
    'HAIRDRESSER_PROFILE': siteSetting.api.url + "hairdresser/",
    'HAIRDRESSER_REVIEW': siteSetting.api.url + "hairdresser/",
    'SALON_PROFILE': siteSetting.api.url + "salon/",

    'LOGIN': siteSetting.api.url + "user/login",
    'USER_SIGNUP': siteSetting.api.url + "user",
    'USER_SOCIAL_LOGIN': siteSetting.api.url + "user/socialLogin",
    'USER_FORGOT': siteSetting.api.url + "user/forgot",
    'USER_RESET': siteSetting.api.url + "user/reset",
    'USER_CHANGEPASSWORD': siteSetting.api.url + "user/changePassword",

    'USER_EDITPROFILE':siteSetting.api.url +'user',
    'USER':siteSetting.api.url +'user',

    'USER_PROFILE': "user/profile",
    'LOGOUT': "user/logout/",
    'USER_REVIEW': "userreview",

    'USER_BOOKING': "user/booking",

    'USER_CREATE_BOOKING':"/bookings",
    'USER_CREATE_EVENT': "/events",

    'SALON_LIST': siteSetting.api.url + 'hairdresser/search',


    'UPCOMING': siteSetting.api.url + 'user/',
    'SERVICES': siteSetting.api.url + 'user/category',


    'T&C':siteSetting.api.backend +'admin/company?webPageType=0',
    'P&P':siteSetting.api.backend +'admin/company?webPageType=1',
    'GDPR':siteSetting.api.backend +'admin/company?webPageType=3',
    'HT&C':siteSetting.api.backend +'admin/company?webPageType=2',

    'HAIRDRESSER_SWITCH_PROFILE':siteSetting.api.url + "user/switchProfile",

    'SEARCH_SALON':siteSetting.api.url + "salon",
    'HAIRDRESSER_SIGNUP':siteSetting.api.url + "hairdresser/",

    'CATEGORY_SERVICE': siteSetting.api.url + "user/category",

    'ADD_SERVICE': siteSetting.api.url + 'service/',

    'STAFF_ROTA': siteSetting.api.url + 'rota/emp/',

    'EVENT': siteSetting.api.url + "hairdresser/",

    'HAIRDRESSER': siteSetting.api.url + "hairdresser/",

    'PAYMENT_ACTIVATATION_CODE': siteSetting.api.v2 + "payment/activationCode",
    'PAYMENT_SUBSCRIPTION_CHECKOUT': siteSetting.api.v2 + "payment/subscriptionCheckout",
    'PAYMENT_SUBSCRIPTION_RETRIEVE': siteSetting.api.v2 + "payment/subscriptionRetrieve",
    'PAYMENT_SUBSCRIPTION': siteSetting.api.v2 + "payment/subscription",
    'REQUEST_DEMO': siteSetting.api.v2 + "common/contactus",
    'BOOKING_PAYMENT': siteSetting.api.v2 + "payment/checkout",
    'BOOKING_PAYMENT_DONE': siteSetting.api.v2 + "payment/done",
    'MAKE_CUSTOMER_DEFAULT_CARD': siteSetting.api.v2 + "payment/cardCapureDone",
    'CHECK_EMAIL': siteSetting.api.v2 + "user/checkEmailExist",
    'PAYMENT_LIST':siteSetting.api.v2 + "payment/paymentList",

    'LIST_PRODUCT':siteSetting.api.v2 + "product/list-product",
    'GET_SALON_PRODUCT':siteSetting.api.v2 + "product/salon-product-list",
    'ADD_PRODUCT':siteSetting.api.v2 + "product/add-product",
    'UPDATE_PRODUCT':siteSetting.api.v2 + "product/update-product",
    'DELETE_PRODUCT':siteSetting.api.v2 + "product/delete-product",
    'GET_PRODUCT':siteSetting.api.v2 + "product/get-product",

    'BANK_LINK':siteSetting.api.v2 + "payment/bankLink",
    'BANK_DETAIL':siteSetting.api.v2 + "payment/getBank",
    'BANK_ACCOUNT_DELETE':siteSetting.api.v2 + "payment/deleteBankAccount",
    'PAYOUT_LIST':siteSetting.api.v2 + "payment/payoutList",
    'PAYOUT':siteSetting.api.v2 + "payment/payout",
    'INSTANT_PAYOUT':siteSetting.api.v2 + "payment/instantPayout",

    'ADD_TERMINAL':siteSetting.api.v2 + "payment/addStripeTerminal",
    'GET_TERMINAL':siteSetting.api.v2 + "payment/addStripeTerminal",

    'REQUEST_CARD_READER':siteSetting.api.v2 + "payment/requestCardReader",
    'FETCH_CONNECTION_TOKEN':siteSetting.api.v2 + "payment/fetchConnectionToken",
    'NO_SHOW_BOOKING': siteSetting.api.v2 + "payment/noShow",
    'REMOVE_BOOKING': siteSetting.api.v2 + "booking/remove",


    

    'CARD_READER_LIST':siteSetting.api.v2 + "payment/cardReaderList",
    'CARD_READER_DELETE':siteSetting.api.v2 + "payment/cardReaderDelete",

    'CARD_READER_PAYMENTINTENTS':siteSetting.api.v2 + "payment/paymentIntents",
    'PAYMENTINTENT_CAPTURE':siteSetting.api.v2 + "payment/paymentIntentsCapture",
    'SUBSCRIPTION_GET':siteSetting.api.v2 + "payment/subscriptionGet",

    'ADD_STAFF_V2':siteSetting.api.v2 + "user/addStaff",
    'REMOVE_STAFF_V2':siteSetting.api.v2 + "user/removeStaff",
    'EDIT_STAFF_V2':siteSetting.api.v2 + "user/editStaff",
    'STAFF_LIST_V2':siteSetting.api.v2 + "hairdresser/hairdresser-staff",
    'GET_HAIRDRESSER_POLICIES_V2':siteSetting.api.v2 + "hairdresser/getHairdresserPolicies",
    

    'UPDATE_TRANSACTION_FEE':siteSetting.api.v2 + "payment/updateTransactionFee",
    'CREATE_LOGIN_LINK':siteSetting.api.v2 + "payment/createLoginLink",
    'LAST_BOOKED_CLIENTS':siteSetting.api.v2 + "hairdresser/last-booked-clients",
    'HAIRDRESSER_UPDATE_INTERVAL':siteSetting.api.v2 + "hairdresser/updateBookingInterval",

    'RETRIEVE_PAYOUTS':siteSetting.api.v2 + "payment/retrieve-payouts",
    'CHECKOUT_NEW_BOOKING': siteSetting.api.v2 + "booking/new-checkout",

    'CHECKOUT_EDIT_BOOKING': siteSetting.api.v2 + "booking/checkout-edit",

    'CANCEL_TERMINAL_TRANSACTION':siteSetting.api.v2 + "payment/readerCancelAction",
    'CHECK_CARD_ADDED':siteSetting.api.v2 + "payment/checkCardAdded",
    'PAYMENT_METHOD_ADD':siteSetting.api.v2 + "payment/paymentMethodAdd",
    'CARD_REMOVE':siteSetting.api.v2 + "payment/cardRemove",
    'CARD_MAKE_PRIMERY':siteSetting.api.v2 + "payment/cardMakePrimery",
    'SETUP_PAYMENT_INTENT':siteSetting.api.v2 + "payment/customerIntentSetup",

    'CHECKOUT_EDIT_SERVICE_PRODUCT': siteSetting.api.v2 + "booking/edit-checkout-payment",

    'CREATE_MARKETING_SETTING':siteSetting.api.v2 + "setting/create",
    'UPDATE_SETTING':siteSetting.api.v2 + "setting/update",
    'GET_MARKETING_SETTING':siteSetting.api.v2 + "setting/get",
    'GET_MARKETING_SALON_SETTING':siteSetting.api.v2 + "setting/get-marketing-setting",
    'CREATE_REMINDER':siteSetting.api.v2 + "setting/create-reminder",
    'REMOVE_REMINDER':siteSetting.api.v2 + "setting/remove-reminder",

    'UPDATE_CANCELLATION_POLICY':siteSetting.api.v2 + "setting/updateCancellationPolicy",
    'UPDATE_NO_SHOW_PROTECTION_FEE':siteSetting.api.v2 + "setting/updateNoShowProtectionFee",
    'GET_CANCELLATION_POLICY':siteSetting.api.v2 + "setting/getCancellationPolicy",

    'BOOKING_COMPLETE': siteSetting.api.v2 + "booking/complete",
    'CANCEL_BOOKING': siteSetting.api.v2 + "booking/cancelBooking",
    
    'EDIT_DAIRY_BOOKING': siteSetting.api.v2 + "booking/edit-dairy-booking",
    'GET_MARKETING_INVOICE_LIST':siteSetting.api.v2 + "marketingInvoice/invoiceList",
    'UPDATE_MARKETING_SETTING':siteSetting.api.v2 + "setting/update-marketing-setting",

   
   'CREATE_CATEGORY':siteSetting.api.v2 + "category/create",
   'EDIT_CATEGORY':siteSetting.api.v2 + "category/edit",
   'DELETE_CATEGORY':siteSetting.api.v2 + "category/delete",
   'LIST_CAETGORY': siteSetting.api.v2 + "category/list",

   'CREATE_SERVICES':siteSetting.api.v2 + "service/create",
   'LIST_SERVICES': siteSetting.api.v2 + "service/list",
   'DELETE_SERVICES':siteSetting.api.v2 + "service/delete",
   'VIEW_SERVICES': siteSetting.api.v2 + "service/view",
   'EDIT_SERVICES':siteSetting.api.v2 + "service/edit",
   'LIST_HAIRDRESSER' : siteSetting.api.v2 + "service/hairdresserList",
   'CATEGORY_ORDERCHANGE':siteSetting.api.v2 + "category/orderChange" ,
   'SERVICE_ORDERCHANGE':siteSetting.api.v2 + "service/orderChange" ,
   'SERVICE_SHARESETTING':siteSetting.api.v2 + "service/serviceShareSetting" ,
   'SERVICE_STATUSCHANGE':siteSetting.api.v2 + "service/statusChange" ,


}


