import React, { useEffect, useState,useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { connect, useDispatch, useSelector } from "react-redux";
import animationData from '../../assets/animation-data/loader.json'
import Lottie from 'react-lottie';
import { Addicon, Dots, Mail, Msz, SettingsIcon } from "../SvgIcon";
import SettingSidebar from "../settings/SettingSidebar";
import AddCard from '../addCardPopup/addCard';
import { marketingAction } from "../../_actions/marketing.actions";
import toast from "react-toast-notification";
import Draggable from 'react-draggable';
import ServiceSettingSidebar from "./ServiceSettingSidebar";
import './service.css';
import { HiDotsVertical } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { RiSettings5Fill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi";
import { Dropdown, Modal } from "react-bootstrap";
import { HiBars2 } from "react-icons/hi2";
import { BsDisplay, BsDot } from "react-icons/bs";
import { TbMenu } from "react-icons/tb";

import Select from "react-select";
import FiltersPopup from "./FiltersPopup";
import ServicePopup from "./ServicePopup";
import MessagePopupBody from "./MessagePopupBody";
import { FaFilter } from "react-icons/fa";
import useWindowSize from "./useWindowSize";
import MobileDotPopup from "./MobileDotPopup";
import { newServicesAction } from "../../_actions";
import { NewServiceSkeletop } from "../newSalon/SkletonLoader";
import CategoryItem from "./CategoryItem";
import './newservice.css';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSensors, useSensor, PointerSensor, TouchSensor } from '@dnd-kit/core';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";






function NewServices(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [filterPopup, setFilterPopup]= useState(false)
  const [servicePopup,setServicePopup]=useState(false);
  const [deleteServicePopup, setDeleteServicePopup]= useState(false);
  const [editServicePopup, setEditServicePopup]= useState(false);
  const [addCategoryPopup, setAddCategoryPopup]= useState(false);
  const [editCategoryPopup, setEditCategoryPopup]= useState(false);
  const [deleteCategoryPopup, setDeleteCategoryPopup]= useState(false);
  const [staffLists ,setStaffLists] = useState(['hi','hello','jss','sds'])
  const staffList = ['hi','hello','jss','sds'];
  const { width } = useWindowSize();
  const[mobileDotCategory ,setMobileDotCategory] = useState(false)
  const[mobileDotService , setMobileDotService] = useState(false)
  const allServiceListData = useSelector(state=>state?.newServices?.listServices);
  console.log("allServiceListDataallServiceListData->",allServiceListData);
  const [searchCrossIcon, setSearchCrossIcon] = useState(""); 
  const initializeFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
        teamMemberName: searchParams.get('teamMemberName') || "",
        teamMember: searchParams.get('teamMember') || "",
        servicesEnabled: searchParams.get('servicesEnabled') || "",
        onlineBookings: searchParams.get('onlineBookings') || "",
        searchQuery: searchParams.get('searchQuery') || ""
    };
  };
  const [filters, setFilters] = useState(initializeFilters());
  const [searchQuery, setSearchQuery] = useState(initializeFilters().searchQuery);

  const [services, setServices] = useState([]);
  const [isLoader, setIsLoader] = useState(false)

  const categoryRefs = useRef([]);
  const serviceRefs = useRef({});
  const [showFilters, setShowFilters] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartTime, setDragStartTime] = useState(0);
  const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });
  const [touchStartPosition, setTouchStartPosition] = useState({ x: 0, y: 0 });
  const [touchStartY, setTouchStartY] = useState(null);
  const [currentDragIndex, setCurrentDragIndex] = useState(null);
  const [dragOffset, setDragOffset] = useState(0);
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [isTap, setIsTap] = useState(false);
  const [editcategoryPayload,setEditcategoryPayload]=useState({})
  const [selectedCategoryId,setSelectedCategoryId] = useState();
  const [selectedServiceId, setSelectedServiceId] = useState();
  const [selectedNotAssigned ,setSelectedNotAssigned] = useState()
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [selectedServiceName, setSelectedServiceName] = useState();
  const [animate, setAnimate] = useState(false);
  const [categoryEditdetail , setCategoryEditdetail] = useState({id:"",name:""})
  const [serviceEditdetail ,setServiceEditdetail] = useState({})
  const [draggedItem, setDraggedItem] = useState(null);
  const [selectedSetting,setSelectedSetting]=useState(0)
  const [resetFilterPayload, setResetFilterPayload] = useState(null);

  // ------for draginging-------
  // const [categories, setCategories] = useState(initialData);
  const [collapsedCategories, setCollapsedCategories] = useState({});
  // const [isDragging, setIsDragging] = useState(false);
  const [mouseDownPosition, setMouseDownPosition] = useState(null);
  const dragThreshold = 10; // pixels to determine if its a drag
  const openAllAcordian = () => {
    setServices(prevServices => {
      const allOpen = prevServices.every(category => category.isOpen);
      return prevServices.map(category => ({
        ...category,
        isOpen: !allOpen
      }));
    });
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: dragThreshold,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250, // ------add a small delay for touch devices, for differciate b/w scroll and drag
        tolerance: 5, // ---allow some movement before activating
      },
    })
  );

  const handleDragStart = (event) => {
    const { active } = event;
    
    if (!active.data.current?.type) {
      if (services?.find(cat => cat.id === active.id)) {
        setIsDragging(true);
        const allCollapsed = services?.reduce((acc, cat) => {
          acc[cat.id] = true;
          return acc;
        }, {});
        setCollapsedCategories(allCollapsed);
      }
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    if (active.data.current?.type === 'item') {
      setServices(prevCategories => {
        const activeCategory = active.data.current.category;
        const category = prevCategories.find(cat => cat.id === activeCategory);
        
        if (!category) return prevCategories;

        const oldIndex = category.items.findIndex(item => item.id === active.id);
        const newIndex = category.items.findIndex(item => item.id === over.id);

        if (oldIndex === -1 || newIndex === -1) return prevCategories;

        const updatedCategories = prevCategories.map(cat => {
          if (cat.id === activeCategory) {
            const updatedServices = {
              ...cat,
              items: arrayMove(cat.items, oldIndex, newIndex)
            };
            handleServiceOrderChange(updatedServices);
            return updatedServices;
          }
          return cat;
        });

        // ----for scroll to the dragged service item
        setTimeout(() => {
          const element = document.getElementById(active.id);
          if (element) scrollToElement(element);
        }, 100);

        return updatedCategories;
      });
    } else {
      if (active.id !== over.id) {
        setServices(prevCategories => {
          const oldIndex = prevCategories.findIndex(cat => cat.id === active.id);
          const newIndex = prevCategories.findIndex(cat => cat.id === over.id);
          const newArray = arrayMove(prevCategories, oldIndex, newIndex);
          handleCategoryOrderChange(newArray);

          // ---scroll logic chnage
          setTimeout(() => {
            const element = document.getElementById(active.id);
            if (element) {
              const headerHeight = -60;
              const scrollPosition = element.offsetTop - headerHeight - 0;
              window.scrollTo({
                top: Math.max(0, scrollPosition),
                behavior: 'smooth'
              });
            }
          }, 100);

          return newArray;
        });
       
        if (isDragging) {
          setCollapsedCategories({});
        }
      }
    }
    
    setIsDragging(false);
    setMouseDownPosition(null);
  };

  const handleToggleAll = () => {
    console.log('Toggling all categories');
    const allCollapsed = services?.every(cat => collapsedCategories[cat.id]);
    
    if (allCollapsed) {
      setCollapsedCategories({});
    } else {
      const allClosed = services?.reduce((acc, cat) => {
        acc[cat.id] = true;
        return acc;
      }, {});
      setCollapsedCategories(allClosed);
    }
  };

  const handleCancelFilters = () => {
    setShowFilters(false);
  };
 
  const settingClick = () =>{
    setServicePopup(true)
  }

  const addCategory = () =>{
    setAddCategoryPopup(true)
  }
  const servicesList = (selectedFilters) => {
    // console.log("servicesList_selectedFilters",selectedFilters)
    setIsLoader(true)
    // debugger
    const payload = {
      hairdresserId: selectedFilters?.teamMember || filters.teamMember || '', 
      onlineBooking: selectedFilters?.onlineBookings || filters.onlineBookings || '',
      status: selectedFilters?.servicesEnabled || filters.servicesEnabled || '',
      search: selectedFilters?.searchQuery || filters.searchQuery || ''
    }

  
      if(payload.search!=''){
      setSearchCrossIcon(true);
    }

    // console.log("payloadpayload",payload)
    dispatch(newServicesAction.listServicesData(payload))
      .then((res) => {
        // console.log("Services list successfully:", res,res?.shareService);
        setSelectedSetting(res?.shareService )
        setIsLoader(false)
      })
      .catch((error) => {
        console.error("Error with Services list:", error);
        setIsLoader(false)
      });

  }

  const areObjectsEqual = (obj1, obj2) => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
      return obj1 === obj2; // Primitive comparison
    }
  
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  };

// ----for filter 
 useEffect(() => {
  // debugger
  // get the filter from location url
  const currentFilters = initializeFilters();
  // check with updatedFilters if both are same don;t so anything
  if(areObjectsEqual(currentFilters, filters)) return ;
    
  // update the filters with currentFilters
  setFilters({...filters, ...currentFilters});
  servicesList(currentFilters);
  // update the query field
  setSearchQuery(currentFilters.searchQuery);
}, [location.search])

// Add this useEffect to handle filter updates
useEffect(() => {
  if (resetFilterPayload) {
      // console.log("Updated filters:", filters);
      servicesList(resetFilterPayload);
      setResetFilterPayload(null);
  }
}, [filters, resetFilterPayload]);

  const updateQueryParams = (newFilters) => {
    // debugger;
        const searchParams = new URLSearchParams();
            // Only add params that have values

      if (newFilters.teamMember) searchParams.set('teamMember', newFilters.teamMember);
      if (newFilters.teamMemberName) searchParams.set('teamMemberName', newFilters.teamMemberName);
      if (newFilters.servicesEnabled) searchParams.set('servicesEnabled', newFilters.servicesEnabled);
      if (newFilters.onlineBookings) searchParams.set('onlineBookings', newFilters.onlineBookings);
      if (newFilters.searchQuery) searchParams.set('searchQuery', newFilters.searchQuery);


        // const queryString = searchParams.toString();
        history.push({
          pathname: '/new-services',
          search: `?${searchParams.toString()}`
        });
  };
  const filterClick = () =>{
    setFilterPopup(true);
  }
  const handleApplyFilters = (selectedFilters) => {
    // console.log('Applied filters:', selectedFilters);
    const updatedFilters = {
        ...filters,          
        ...selectedFilters,
        searchQuery: searchQuery 
    };
    setFilters(updatedFilters);
    setFilterPopup(false);
    updateQueryParams(updatedFilters); 

  };
  const handleRestFilters = (resetFiltersName) => {
    
    const resetFilters = { ...filters }; 

    if (resetFiltersName === 'teamMemberName') {
        resetFilters.teamMemberName = "";
        resetFilters.teamMember = ""; 
    }
    else if (resetFiltersName === 'servicesEnabled') {
        resetFilters.servicesEnabled = ""; 
    }
    else if (resetFiltersName === 'onlineBookings') {
        resetFilters.onlineBookings = ""; 
    }

    // Update Query params
    updateQueryParams(resetFilters)
    // set filters
    setFilters(resetFilters);
    setResetFilterPayload(resetFilters);
  };
  const categoryList = () => {
    const payload = {}
    dispatch(newServicesAction.listCategoryData(payload))
      .then((res) => {
        // console.log("Category list successfully:", res);
       
      })
      .catch((error) => {
        console.error("Error with category list:", error);
      });
  }
  const handleSearchChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if(value){
        setSearchCrossIcon(true)
    }
    else{
        setSearchCrossIcon(false) 
    }

    setSearchQuery(value); 
    const updatedFilters = {
        ...filters,
        searchQuery: value
    };

    updateQueryParams(updatedFilters); 
    setFilters(updatedFilters);
    setResetFilterPayload(updatedFilters)
    
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    if (pastedData!=''){
      setSearchCrossIcon(true)
      setSearchQuery(pastedData)
      const updatedFilters = {
        ...filters,
        searchQuery: pastedData
    };
    setFilters(updatedFilters);
        updateQueryParams(updatedFilters);
        servicesList(updatedFilters);
      // servicesList({"searchQuery": pastedData});
    }
    else if(pastedData=='' || searchQuery){
      setSearchCrossIcon(false)
    }
  };
  const handleResetSearcChange = ()=>{
    setSearchQuery("");
    setSearchCrossIcon(false);
    const updatedFilters = {
        ...filters,
        searchQuery: ""
    };

    updateQueryParams(updatedFilters);
    setFilters(updatedFilters);
    setResetFilterPayload(updatedFilters)
  }
  const handleCategoryOrderChange =(newServices) =>{
    // console.log("handleCategoryOrderChange-----services",newServices)
    const allcategoryIdList =newServices?.map(item=>item.id)
    const payload={
          "categoryId":allcategoryIdList.join(',')
    }
    // console.log("handleCategoryOrderChange-----",payload)
    dispatch(newServicesAction.categoryOrderChange(payload))
      .then((res) => {
        console.log("category OrderChange list successfully:", res);
       
      })
      .catch((error) => {
        console.error("Error with category OrderChange  list:", error);
      });
  }
  const handleServiceOrderChange =(newServices) =>{
    // console.log("handleServiceOrderChange-----services",newServices)
    const allServiceIdList =newServices?.items?.map(item=>item.id)
    const payload={
          "commonServiceId":allServiceIdList.join(',')
    }
    // console.log("handleServiceOrderChange-----",payload)
    dispatch(newServicesAction.serviceOrderChange(payload))
      .then((res) => {
        console.log("service OrderChange list successfully:", res);
       
      })
      .catch((error) => {
        console.error("Error with service OrderChange  list:", error);
      });
  }
  const handleClose = () => {
    setAnimate(false);
    const timer = setTimeout(() =>
      setTimeout(() => setMobileDotService(false) , 300) ||  
      setTimeout(() => setFilterPopup(false), 300))
    const filterTimer= setTimeout(()=> setFilterPopup(false), 300 )  
    const timers =  setTimeout(() => setMobileDotService(false) , 300) ;
    const timer1 =setTimeout(() => setMobileDotCategory(false), 300);
    const  timer3 = setTimeout(()=> setServicePopup(false)  , 300)
    // return () => clearTimeout(timer,timers,timer1);
    return () => clearTimeout(timer,timers,timer1,filterTimer,timer3);
  };
  //mobile category edtit popup
  const mobileDotCategoryPopup = (categoryId, categoryName) =>{
    setMobileDotCategory(true)
    setCategoryEditdetail({ id: categoryId, name: categoryName });
  }
  // --edit category button click function
  const editCategory = (categoryId, categoryName) =>{
    // debugger
    setEditCategoryPopup(true)
    let payload = {
      "categoryId": categoryId,
      "categoryName": categoryName
    }
    setEditcategoryPayload(payload)
  }
 // --edit category Popup button click function
  const handleEditCategory = (categoryName) => {
    let payload = {
      "categoryId": editcategoryPayload.categoryId,
      "categoryName": categoryName
    }
    dispatch(newServicesAction.editCategoryData(payload))
      .then((res) => {
        console.log("Category edited successfully:", res);
        if(res?.editCategory?.statusCode===1) {
          toast.success(res?.editCategory?.responseData?.message);
       }
       else{
         toast.error(res?.editCategory?.error? res?.editCategory?.error?.responseMessage : res?.editCategory?.responseData?.message);
       }
        servicesList()
        setEditCategoryPopup(false)
        
        
      })
      .catch((error) => {
        console.error("Error edit category:", error);
      });
  };
  // --delete category button click function
  const deleteCategory = (categoryId ,categoryName) =>{
    setSelectedCategoryId(categoryId)
    setSelectedCategoryName(categoryName)
    setDeleteCategoryPopup(true);
  }
  // --delete service button click function
  const deleteService = (serviceId , serviceName ,notAssigned) =>{
    // debugger
    // console.log("serviceId_serviceName_notAssigned",serviceId , serviceName ,notAssigned)
    setSelectedServiceId(serviceId)
    setSelectedServiceName(serviceName)
    setSelectedNotAssigned(notAssigned)
    setDeleteServicePopup(true)
  }
  //mobile service edtit popup
  const listHairdresseData =()=>{
    // debugger
    let payload = { }
    dispatch(newServicesAction.listHairDresserData(payload))
      .then((res) => {
        console.log("listHairdresserlistHairdresser3333", res);
      })
      .catch((error) => {
        console.error("Error edit category:", error);
      });
  }
  const mobileDotServicePopup = (serviceDetail) =>{
    // debugger
    listHairdresseData()

    // console.log("mobileDotServicePopupmobileDotServicePopup",serviceDetail)
    setServiceEditdetail(serviceDetail)
    setMobileDotService(true)
  }
 // all useEffect code here
  useEffect(() => {
    if (servicePopup || filterPopup || mobileDotCategory ) {
      setAnimate(true);
    }
  }, [servicePopup,filterPopup,mobileDotCategory]);

  useEffect(() => {
    if (filterPopup || servicePopup) {
      document.body.classList.add('filter_modal_body');
    }
    
    // Clean
    return () => {
      document.body.classList.remove('filter_modal_body');
    };
  }, [filterPopup,servicePopup])
  useEffect(() => {
    // Map allServiceListData to the structure for services
    if (allServiceListData) {
        const mappedServices = allServiceListData.map(service => ({
            id: service?._id, 
            category: service?.categoryName, 
            isOpen: true, 
            items: service?.serviceList?.map(item => ({
              id: item?.commonServiceId, 
              name: item?.serviceName,
              description: item?.serviceDescription,
              duration: item?.serviceTime,
              price: item?.servicePrice,
              deposit: item?.depositPrice ,
              processingTime: item?.processingTime,
              notAssigned: item?.notAssigned // notAssigned come false, then disabled delete poppup show if it come true then we r able to deleteit
          }))
        }));
        setServices(mappedServices);   
        
    }
    else{
      setIsLoader(true)
    }
  }, [allServiceListData]);

  useEffect(() => {
    servicesList()
  },[])
  const deleteCategoryMsz ={
    heading :`Delete Category - ${selectedCategoryName}`,
    discription:"Are you sure you want to delete this category? This will also delete all services within this category for all staff using this category and these services. This action cannot be undone.",
    btntext1:"Cancel",
    btntext2:"Delete",
  }
  const deleteServiceMsz ={
    heading :`Delete Service - ${selectedServiceName}`,
    headingAssigned:'This service is currently in use, would you like to disable it instead?',
    discriptionAssigned:'This service is currently in use by other staff members so cannot be deleted. Would you like to disable this service instead?',
    discription:"Are you sure you want to delete this service? This will delete it for all other staff using this service too. This action cannot be undone.",
    btntext1:"Cancel",
    btntext2:"Delete"
  }

  // console.log("this_props_selectedCheckoutList new-services page",props)
  // console.log("new_service_props",props?.user?.userInfo)
  return (
    
    <div className="h_n _new__service">
      {/* {console.log("11111111111",mobileDotService,mobileDotCategory)} */}
      <div className="body_container cm_bg_white _nowhite">
        <TopHeader 
        title="Services" 
        headerButtonStatus={true}
        servicesList={servicesList}
        categoryList={categoryList}
        {...props} 
        // title="Edit Booking" 
        //     updateEmailBookingStatus={this.updateEmailBookingStatus}
        //     editPageStatus={true}
        //     isMailOff={this.state.isMailOff}
        //     headerButtonStatus={true}
        //     saveBookingButton={true}
        //     checkoutButton={true}
        //     checkoutBooking={this.checkoutBooking}
        //     saveBooking={this.editBooking}
        />
        <SideNav {...props} />
        <div className="new-setting-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12 mobile-hidden _service_sidebar">
                  <ServiceSettingSidebar />
              </div>
              
              <div className="col-md-8">
                <div className="search_filter_container mt-2">
                    <div className="_left">
                        <div className="cm_search_wrapper client_search m-0 ">
                            <input
                            type="text"
                            className="form-control"
                            name="serviceSearch"
                            value={searchQuery} // Bind input value to searchQuery state
                            onChange={handleSearchChange}
                            onPaste={handlePaste} 
                            placeholder="Search for service"
                            />
                            {searchCrossIcon?
                            <i><RxCross2 onClick={handleResetSearcChange}/></i>
                            :<i className="fa fa-search" />}
                            
                        </div>
                        <div className="_filter" onClick={filterClick}>{width >991 ? 'Filters' : <FaFilter />}</div>
                        {/* <FaFilter /> Filters*/}
                    </div>
                    {/* {props?.user?.userInfo?.hairdresser?.isSalonOwner ==1 && */}
                    <div className="_right" onClick={settingClick}>
                       <RiSettings5Fill/>
                    </div>
                    {/* } */}
                   

                </div>
                {(filters?.teamMemberName || filters?.servicesEnabled || filters?.onlineBookings) && (
                  <div className="applied_filter_container">
                    {filters.teamMemberName && (
                      <span>
                        {filters.teamMemberName} 
                        <i className="_cross" onClick={() => handleRestFilters('teamMemberName')}><RxCross2 /></i>
                      </span>
                    )}
                    {filters.servicesEnabled && (
                      <span>
                        {filters.servicesEnabled === "true" ? 'Services Enabled' : 'Services Disabled'} 
                        <i className="_cross" onClick={() => handleRestFilters('servicesEnabled')}><RxCross2 /></i>
                      </span>
                    )}
                    {filters.onlineBookings && (
                      <span>
                        {filters.onlineBookings === "true" ? 'Online Bookings Enabled' : 'Online Bookings Disabled'} 
                        <i className="_cross" onClick={() => handleRestFilters('onlineBookings')}><RxCross2 /></i>
                      </span>
                    )}
                  </div>
               )}
                {isLoader?<NewServiceSkeletop/>:
                <>
                {/* {console.log("servicesservices",services)} */}
                {services.length == 0 ? <p className="text-center mt-5">No Data Found</p>:
                   <div className="App">
                    <DndContext 
                    collisionDetection={closestCenter} 
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                    modifiers={[restrictToVerticalAxis]}
                    sensors={sensors}
                  >
                    <div className="app">
                      {/* <div className="categories-container"> */}
                      <div className="_dragable_component pt-4">
                        <SortableContext 
                          items={services.map((category, categoryIndex) =>category.id)} 
                          strategy={verticalListSortingStrategy}
                        >
                          {services.map((category, categoryIndex) => (
                            <CategoryItem 
                              key={category.id}
                              category={category}
                              isCollapsed={collapsedCategories[category.id]}
                              onToggleAll={handleToggleAll}
                              isDragging={isDragging}
                              mouseDownPosition={mouseDownPosition}
                              setMouseDownPosition={setMouseDownPosition}
                              dragThreshold={dragThreshold}
                              mobileDotCategoryPopup={mobileDotCategoryPopup}
                              editCategory={editCategory}
                              deleteCategory={deleteCategory}
                              mobileDotServicePopup={mobileDotServicePopup}
                              deleteService={deleteService}
                              handleCategoryOrderChange={handleCategoryOrderChange}
                              handleServiceOrderChange={handleServiceOrderChange}
                            />
                          ))}
                        </SortableContext>
                      </div>
                    </div>
                  </DndContext>
                 </div>
                }
                </>
                }
                

              </div>
             {/* } */}
            </div>
          </div>
        </div>
      </div>
      {/* ---filter popup---   */}
      <Modal 
        blurClass="blure_bg" 
        centered 
        show={filterPopup} 
        onHide={handleClose} 
        backdropClassName="_light_bg"
        size="lg"
        dialogClassName={`bottom-modal ${animate ? 'slide-in' : 'slide-out'}`}  
        className='_service__popup'>
           <Modal.Body>
            <div className="_filter">
              <h4> Filters</h4>
                    <FiltersPopup 
                    onHide={handleClose} 
                    onSend={handleApplyFilters} 
                    filters={filters} 
                    servicesList={servicesList}
                    {...props}
                    />
                </div>
            </Modal.Body>
      </Modal>
        {/* ---setting service popup---   */}
        <Modal 
        blurClass="blure_bg" 
        centered 
        show={servicePopup} 
        onHide={handleClose} 
        backdropClassName="_light_bg"
        size="lg" 
        dialogClassName={`bottom-modal ${animate ? 'slide-in' : 'slide-out'}`} 
        className='_service__popup'>
           <Modal.Body>
            <div className="_filter">
              <h4> Service Settings</h4>
              {/* {console.log("props.selectedSetting122",props.selectedSetting)} */}
                    <ServicePopup 
                    onHide={handleClose} 
                    servicesList={servicesList}
                    selectedSetting={selectedSetting}
                    />
            </div>
            </Modal.Body>
        </Modal>

 
     


        {/* <MessagePopupBody
         centered="centered"
         show={editServicePopup} 
        onHide={()=>setEditServicePopup(false)} 
         backdropClassName="_light_bg"
         size="lg" 
         className='_service__popup'
         popupType="edit"
         message="Service"
        /> */}

        
       {/* ---Edit Category popup---   */}
        <MessagePopupBody
         blurClass="blure_bg"
         centered="centered"
         show={editCategoryPopup} 
         onHide={()=>setEditCategoryPopup(false)} 
         backdropClassName="_light_bg"
         size="lg" 
         className='_service__popup _no_animation'
         popupType="edit"
         message="Category"
         initialValue={editcategoryPayload.categoryName}
         submitFunction={handleEditCategory}
        />
        {/* ---delete Category popup---   */}
        <MessagePopupBody
         blurClass="blure_bg"
         centered="centered"
         show={deleteCategoryPopup} 
         onHide={()=>setDeleteCategoryPopup(false)} 
         backdropClassName="_light_bg"
         size="lg" 
         className='_service__popup _service_delete_popup _no_animation'
         popupType="delete"
         message={deleteCategoryMsz}
         deletedId={selectedCategoryId}
         servicesList={servicesList}
        /> 
        {/* ---delete service popup---   */}
        <MessagePopupBody
         blurClass="blure_bg"
         centered="centered"
         show={deleteServicePopup} 
         onHide={()=>setDeleteServicePopup(false)}
         backdropClassName="_light_bg"
         size="lg" 
         className='_service__popup _service_delete_popup _no_animation'
         popupType="delete"
         message={deleteServiceMsz}
         deletedId={selectedServiceId}
         servicesList={servicesList}
         notAssigned={selectedNotAssigned}
        />



        {/* -----for Dot category--- */}
        <MobileDotPopup 
        blurClass="blure_bg"
        centered="centered"
        show={mobileDotCategory} 
        onHide={handleClose}
        backdropClassName="_light_bg"
        dialogClassName={`bottom-modal ${animate ? 'slide-in' : 'slide-out'}`}
        size="lg" 
        className='_service__mobile_popup _no_animation'
        type="Category"
        details={categoryEditdetail}
        editOnClickPopup={()=>editCategory(categoryEditdetail.id , categoryEditdetail.name)}
        deleteOnClickPopup={()=>deleteCategory(categoryEditdetail?.id , categoryEditdetail?.name)}

        /> 
        {/* -----for Dot service--- */}
        {/* { console.log("mobileDotServicePopupmobileDotServicePopup11",serviceEditdetail)} */}
        <MobileDotPopup 
        blurClass="blure_bg"
        centered="centered"
        show={mobileDotService} 
        onHide={handleClose}
        backdropClassName="_light_bg"
        dialogClassName={`bottom-modal ${animate ? 'slide-in' : 'slide-out'}`}
        size="lg" 
        className='_service__mobile_popup _no_animation'
        details={serviceEditdetail}
        type="Service"
        deleteOnClickPopup={()=>deleteService(serviceEditdetail?.id , serviceEditdetail?.name ,serviceEditdetail?.notAssigned)}
        notAssigned={selectedNotAssigned}
        /> 
    
    </div>
  );

}

function mapStateToProps(state) {
  return {
      ...state
  };
}
export default connect(mapStateToProps)(NewServices);

const looptest = [0,0]

// const editCategoryMsz ={
//   heading :"Edit Category",
// }

// const editServiceMsz ={
//   heading :"Edit Service",
// }

// Add this helper function for smooth scrolling
const scrollToElement = (element) => {
  const headerHeight = 80;
  const scrollPosition = element.offsetTop - headerHeight - 10; // Added small offset for visual spacing
  window.scrollTo({
    top: Math.max(0, scrollPosition),
    behavior: 'smooth'
  });
};