import React, { Component } from 'react';
import Popup from "reactjs-popup";
// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { settingConstants, bookingConstants } from "../../_constants";
import { userActions } from "../../_actions/user.actions";
import { hairdresserActions } from "../../_actions";

import { Modal } from 'react-bootstrap';
// import { Addicon } from '../SvgIcon';
// import { Dropdown } from 'react-bootstrap';
import siteSetting from "../../config/env";
import FacebookLoginApp from 'react-facebook-login/dist/facebook-login-render-props';
import AppleLogin from 'react-apple-login'
import PhoneInput from "react-phone-number-input";
import { Device } from '@capacitor/device';
import {
    SignInWithApple,
    SignInWithAppleResponse,
    SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import jwt_decode from "jwt-decode";


const queryString = require('query-string');
class WebHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            passwordError: '',
            emailError: '',
            fNameError: '',
            lNameError: '',
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            dropdown: false,
            isShowClose: true,
            isLoginPopup: this.props.setting.login,
            DeletemszPopup: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            loginPopup: true,
            loginButton: false,
            phoneNumber: '',
            platform: 'web',
            applePrivateLogin:false,
            applePrivateUser:null,
            userBookingLocalData:{}
        }
        this.toggleLogin = this.toggleLogin.bind(this);
        this.toggleSignup = this.toggleSignup.bind(this);
        this.login = this.login.bind(this);
        this.onChanged = this.onChanged.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        this.logout = this.logout.bind(this);
        this.signup = this.signup.bind(this);
        this.toggleFrogot = this.toggleFrogot.bind(this);
        this.toggleReset = this.toggleReset.bind(this);
        this.toggleThanku = this.toggleThanku.bind(this);
        this.forgot = this.forgot.bind(this);
        this.homeOpen = this.homeOpen.bind(this);
        this.togglePartner = this.togglePartner.bind(this);
        this.toggleSocialLogin = this.toggleSocialLogin.bind(this);
        this.componentClicked = this.componentClicked.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.checkApplePrivateEmail = this.checkApplePrivateEmail.bind(this);
        this.toggleAppleSignup = this.toggleAppleSignup.bind(this);
        this.applePrivateIdLogin = this.applePrivateIdLogin.bind(this);
    }

   

    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        this.setState({ platform: info.platform })
    };

    openAppleSignIn = () => {
        let self = this;
        let options: SignInWithAppleOptions = {
            clientId: 'com.app.beu',
            redirectURI: 'https://www.beu.com/login',
            scopes: 'email name',
            state: '12345',
            nonce: 'nonce',
        };

        SignInWithApple.authorize(options)
            .then((result: SignInWithAppleResponse) => {
                self.props.dispatch(hairdresserActions.dairyFilterData([]));
                var decoded = jwt_decode(result.response.identityToken);
                let user = {
                    sub: result.response.identityToken,
                    name: result.response.givenName ? result.response.givenName : (result.response.email?result.response.email.split("@")[0]:decoded.email.split("@")[0]),
                    email: result.response.email?result.response.email:decoded.email,
                }
                
                this.appleLogin(user);

            })
            .catch(error => {
                console.log(error, "apple_auth_token_error")
            });
    }

    FacebookLoginIosApp = () => {
        const FACEBOOK_PERMISSIONS = ['email', 'user_photos', 'user_gender'];
        const result = FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }).then((result: FacebookLoginResponse) => {
            console.log(result, "facebook-logged-in..........")
            FacebookLogin.getProfile({ fields: ['email', 'name'] }).then((res) => {
                let user = {
                    id: res.id,
                    name: res.name,
                    email: res.email
                }
                this.responseFacebook(user)
            })

        })
            .catch(error => {
                console.log(error, "apple_auth_token_error")
            });


    }

    container = React.createRef();


    checkApplePrivateEmail =(email)=>{
        const emailRegex = /^[a-zA-Z0-9._%+-]+@privaterelay\.appleid\.com$/;
        return emailRegex.test(email);
    }


    closeModal = () => {
        this.setState({
            DeletemszPopup: false,
        })
    }
    openDeletemszPopup = () => {
        this.setState({
            DeletemszPopup: !this.state.DeletemszPopup
        })
    }


    componentDidMount() {
        this.logDeviceInfo();
        var self = this;
        let params = {
            defaultRedirectTo: localStorage.getItem('defaultRedirectTo')
        };
        console.log('check dataaalll', self.props.setting)
        if (localStorage.getItem('accessToken')) {
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {

                })
        } 
        else{
            // debugger;
            const { location } = this.props.history; 
                const queryString = location.search;
                const queryParams = new URLSearchParams(queryString);
                console.log("queryParams=>", queryParams,this.props.history.location.pathname,this.props)

            if(this.props.history.location.pathname === "/booking"){
                this.props.history.push('/social-login?reditect=booking')
            }
        }

        if (this.props.history.location.pathname === "/login") {
            if (!localStorage.getItem('accessToken')) {
                this.toggleLogin();
            } else {
                this.props.history.push("/");
            }
        }
        if (this.props.history.location.pathname === "/social-login") {
            if (!localStorage.getItem('accessToken')) {
                this.setState({
                    error: '',
                    passwordError: '',
                    emailError: '',
                    email: '',
                    password: '',
                    dropdown: false
                })
                this.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN,value:1 });
            } else {
                this.props.history.push("/");
            }
        }
        if (this.props.history.location.pathname === "/redirect") {
            console.log("this.props.location", this.props.history.location.search);
            let params = queryString.parse(this.props.history.location.search);
            console.log("params", params)
            let user = JSON.parse(params.user);
            console.log("user", user)

            if (!localStorage.getItem('accessToken')) {
                self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
              
                    this.appleLogin(user);
            } else {
                this.props.history.push("/");
            }
        }
        document.addEventListener("mousedown", this.handleClickOutside);
        const html = document.querySelector("html");
        html.addEventListener("click", function (e) {
            document.body.classList.remove('menu_open')
        })

    }

    componentDidUpdate(preProps,prevState){
        var self = this;
        if(preProps?.userBookingState!= prevState?.userBookingLocalData){
            self.setState({
                userBookingLocalData:preProps?.userBookingState
            })
        }

    }
    
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.body.classList.remove('menu_open')
    }

    handleClickOutside = (event) => {
        if (
            this.container.current &&
            !this.container.current.contains(event.target)
        ) {
            this.setState({
                dropdown: false,
            });
        }
    };

    

    applePrivateIdLogin() {
        let self = this;
        let email = this.state.email;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let phoneNumber = this.state.phoneNumber;

        if (this.state.first_name === "" || this.state.first_name === undefined) {
            this.setState({
                fNameError: 'Please enter first name'
            })
        } else if (this.state.last_name === "" || this.state.last_name === undefined) {
            this.setState({
                lNameError: 'Please enter last name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.phoneNumber === "" || this.state.phoneNumber === undefined) {
            this.setState({
                phoneNumberError: 'Please enter a valid phone number'
            })
        }else{
            let param = {
                appleId: self?.state?.applePrivateUser?.appleId,
               // appleId: "000593.9d3601d57cbb4e84a2d4f64bc68a9d84.1234",
                name: first_name + " " + last_name,
                email: email,
                first_name: first_name,
                privateEmail:self?.state?.applePrivateUser?.email,
               // privateEmail:"bfnn2rcytt@privaterelay.appleid.com",
                last_name: last_name,
                phoneNumber: phoneNumber,
                deviceToken: 'sdadsadsada',
                deviceTypeId: 2,
                'latitude': this.state.latitude,
                'longitude': this.state.longitude,
            };
            console.log('response', self?.state?.applePrivateUser);
            self.props.dispatch(userActions.socialLogin(param))
                .then(function (response) {
                    console.log('response1111111', response.data.data.userInfo);
                    self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
                    if (response.data.errorCode === 0) {
                        self.setState({
                            applePrivateLogin:false,
                            loginPopup:false
                        });
                        self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
                        self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        if (localStorage.getItem('isBooking') === 1 || localStorage.getItem('isBooking') === "1") {
                            self.props.dispatch({ type: settingConstants.TOGGLE_BOOKING });
                            if (localStorage.getItem('redirectUrl') && localStorage.getItem('redirectUrl') !== "") {
                                self.props.history.push({
                                    pathname: localStorage.getItem('redirectUrl'),
                                    state: this?.state?.userBookingLocalData,
                                });
                            }
                        }else{
                            if (localStorage.getItem('redirectUrl') && localStorage.getItem('redirectUrl') !== "") {
                                self.props.history.push('/');
                            }
                        }
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        } 
    }


    appleLogin(user) {
        let self = this;
        if (user && user.sub) {
            //@privaterelay.appleid.com
            let param = {
                appleId: user.sub,
                name: user.name ? user.name : user.email.split("@")[0],
                email: user.email,
                first_name: user.name ? user.name : user.email.split("@")[0],
                //'socialAvatar',
                deviceToken: 'sdadsadsada',
                deviceTypeId: 2,
                'latitude': this.state.latitude,
                'longitude': this.state.longitude,
            };
            // if (fbData.picture && fbData.picture.data && fbData.picture.data.url !== "") {
            //     param.socialAvatar = fbData.picture.data.url
            // }
            self.props.dispatch(hairdresserActions.dairyFilterData([]));
            self.props.dispatch(userActions.socialLogin(param))
                .then(function (response) {
                let isPrivateMail = self.checkApplePrivateEmail(response.data.data.userInfo.email);
                if(isPrivateMail){
                    self.setState({
                        applePrivateUser:response.data.data.userInfo,
                    },()=>{
                        self.logout();
                        self.toggleAppleSignup();
                        self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
                    })
                }

                    if (response.data.errorCode === 0) {
                        self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
                        // self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        if (localStorage.getItem('isBooking') === 1 || localStorage.getItem('isBooking') === "1") {
                            self.props.dispatch({ type: settingConstants.TOGGLE_BOOKING });
                            if (localStorage.getItem('redirectUrl') && localStorage.getItem('redirectUrl') !== "") {
                                self.props.history.push({
                                    pathname: localStorage.getItem('redirectUrl'),
                                    state: this?.state?.userBookingLocalData,
                                });
                            }
                        }
                      self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        } else {
            self.setState({
                error: "Error in apple login"
            })
        }
    }

    homeOpen() {
        // this.props.history.push("/");
    }

    openDropdown() {
        this.setState({
            dropdown: !this.state.dropdown
        })
    }

    toggleFrogot(value) {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
            NameError: '',
            lNameError: ''
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_FORGOT, value: value });
    }

    toggleReset(value) {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
            NameError: '',
            lNameError: ''
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_RESET, value: value });
    }

    toggleThanku(value) {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
            NameError: '',
            lNameError: ''
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_THANKU, value: value });
    }

    toggleLogin() {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
            NameError: '',
            lNameError: ''
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_LOGIN });
    }

    togglePartner() {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
            NameError: '',
            lNameError: ''
        })
        this.props.history.push('/join');
    }

    toggleSignup() {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
            fNameError: '',
            lNameError: ''
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_SIGNUP });
    }


    toggleAppleSignup() {
        let self = this;
        self.setState({
            applePrivateLogin:!self.state.applePrivateLogin,
            loginPopup:false
        })
    }



    toggleSocialLogin() {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
            dropdown: false
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
    }

    login() {
        let self = this;
        let email = this.state.email;
        let password = this.state.password;
        let params = {
            email: email?.toLowerCase(),
            password: password,
            deviceToken: 'sdadsadsada',
            deviceTypeId: 2
        }
        if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.password === "" || this.state.password === undefined) {
            this.setState({
                passwordError: 'Please enter password'
            })
        }
        else if (this.state.password.length < 6) {
            this.setState({
                passwordError: 'The password must be at least 6 characters'
            })
        } else {
            self.props.dispatch(hairdresserActions.dairyFilterData([]));
            self.props.dispatch(userActions.login(params))
                .then(function (response) {
                    localStorage.removeItem('persist:root');
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({ type: settingConstants.TOGGLE_LOGIN });
                        if (localStorage.getItem('isBooking') === 1 || localStorage.getItem('isBooking') === "1") {
                            self.props.dispatch({ type: settingConstants.TOGGLE_BOOKING });
                        }
                        self.setState({
                            dropdown: false
                        })

                        if (response.data.data.userInfo && response.data.data.userInfo.hairdresser && response.data.data.userInfo.hairdresser.salon) {
                            //if(response.data.data.userInfo.hairdresser.isSalonOwner===1) {
                            localStorage.setItem('salonId', response.data.data.userInfo.hairdresser.salon._id);
                            localStorage.setItem('defaultRedirectTo', response.data.data.userInfo.defaultRedirectTo);
                            localStorage.setItem('isShow', response.data.data.userInfo.hairdresser.salon.isShow);
                            //self.props.history.push('/diary/' + response.data.data.userInfo.hairdresser.salon._id)
                            window.location.href = "/diary/" + response.data.data.userInfo.hairdresser.salon._id;
                            // }else{
                            //     self.props.history.push('/coming-soon');
                            // }
                        }
                        let params = queryString.parse(self.props.history.location.search);
                        console.log("params", params)
                        if(params.reditect&&params.reditect!==""){
                            self.props.history.push(params.reditect);
                        }
                        // else{
                        //     window.location.reload();
                        // }
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }
    }

    logout() {
        let self = this;
        let params = {
            userId: this.props.user.userInfo.userId
        }
        self.props.dispatch(userActions.logout(params))
            .then(function (response) {
                localStorage.removeItem('persist:root');
                localStorage.clear();
                self.props.history.push("/");
            })
        document.body.classList.remove('menu_open')
    }

    onChanged(e) {
        this.setState({
            [e.target.name]: e.target.value,
            copied: false,
            error: '',
            emailError: '',
            passwordError: '',
            fNameError: '',
            lNameError: ''
        });

        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }
        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    passwordError: ''
                })
            } else {
                this.setState({
                    passwordError: '',
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'last_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    lNameError: ''
                })
            } else {
                this.setState({
                    lNameError: '',
                })
            }
        }
    }

    validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }

    signup() {
        let self = this;
        let email = this.state.email;
        let password = this.state.password;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let phoneNumber = this.state.phoneNumber;
        let params = {
            email: email?.toLowerCase(),
            password: password,
            first_name: first_name,
            last_name: last_name,
            name: first_name + " " + last_name,
            deviceToken: 'sdadsadsada',
            phoneNumber: phoneNumber,
            deviceTypeId: 2
        }

        if (this.state.first_name === "" || this.state.first_name === undefined) {
            this.setState({
                fNameError: 'Please enter first name'
            })
        } else if (this.state.last_name === "" || this.state.last_name === undefined) {
            this.setState({
                lNameError: 'Please enter last name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.phoneNumber === "" || this.state.phoneNumber === undefined) {
            this.setState({
                phoneNumberError: 'Please enter a valid phone number'
            })
        } else if (this.state.password === "" || this.state.password === undefined) {
            this.setState({
                passwordError: 'Please enter password'
            })
        } else if (this.state.password.length < 6) {
            this.setState({
                passwordError: 'The password must be at least 6 characters'
            })
        } else {
            self.props.dispatch(userActions.signup(params))
                .then(function (response) {
                    //console.log('response',response);
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({ type: settingConstants.TOGGLE_SIGNUP });
                        //self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        if (localStorage.getItem('isBooking') === 1 || localStorage.getItem('isBooking') === "1") {
                            self.props.dispatch({ type: settingConstants.TOGGLE_BOOKING });
                        }
                        let params = queryString.parse(self.props.history.location.search);
                        console.log("params", params)
                        if(params.reditect&&params.reditect!==""){
                            self.props.history.push(params.reditect);
                        }
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }
    }

    forgot() {
        let self = this;
        let email = this.state.email;
        let params = {
            email: email?.toLowerCase(),
            deviceToken: 'sdadsadsada1',
            deviceTypeId: 2
        }
        if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else {
            self.props.dispatch(userActions.forgot(params))
                .then(function (response) {
                    //console.log('response',response);
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({ type: settingConstants.TOGGLE_THANKU });
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }
    }
    
    handleMenuOpen = () => document.body.classList.toggle('menu_open')

    handleCloseMenu = () => document.body.classList.remove('menu_open')


    componentClicked(params) {
        console.log("componentClicked", params);
    }

    responseFacebook(fbData) {
        let self = this;
        console.log("responseFacebook", fbData);
        if (fbData && fbData.id) {
            let param = {
                facebookId: fbData.id,
                name: fbData.name,
                email: fbData.email,
                first_name: fbData.name,
                //'socialAvatar',
                deviceToken: 'sdadsadsada',
                deviceTypeId: 2,
                'latitude': this.state.latitude,
                'longitude': this.state.longitude,
            };
            if (fbData.picture && fbData.picture.data && fbData.picture.data.url !== "") {
                param.socialAvatar = fbData.picture.data.url
            }
            self.props.dispatch(userActions.socialLogin(param))
                .then(function (response) {
                    console.log('response', response);
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
                        // self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        if (localStorage.getItem('isBooking') === 1 || localStorage.getItem('isBooking') === "1") {
                            self.props.dispatch({ type: settingConstants.TOGGLE_BOOKING });
                        }
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        } else {
            self.setState({
                error: "Error in facebook login"
            })
        }
    }

    switchProfile() {
        let self = this;
        let param = {
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            switchTo: 1

        }
        self.setState({ dropdown: false })
        this.props.dispatch(hairdresserActions.hairdresserSwitchProfile(param))
            .then(function (response) {

                if (response.data.data.userInfo && response.data.data.userInfo.hairdresser.salon) {
                    localStorage.setItem('salonId', response.data.data.userInfo.hairdresser.salon._id);
                    localStorage.setItem('defaultRedirectTo', response.data.data.userInfo.defaultRedirectTo);

                    self.props.history.push('/diary/' + response.data.data.userInfo.hairdresser.salon._id)
                }else{
                    localStorage.clear();
                }
            })
            .catch((err) => {
                // console.log("err",err);
                localStorage.clear();
                window.location.reload();
            })
    }

    handlePhoneChange = val => {
        this.setState({ phoneNumber: val })
    }

    

    render() {
        const { hairdresser, salon, hairdresserReview, user, setting } = this.props;
        // console.log("this.props",this.props);
        return (
            <React.Fragment>
                <div className="menu_ovelay" onClick={this.handleCloseMenu}></div>
                {user.userInfo ?
                    <nav className="navbar navbar-expand-md navbar-dark  cm-navbar-new " fixed="top">
                        <div className="logo-web ">
                            {/*<a href="/">*/}
                                <img src={require('../../assets/images/web-img/beu-logotext.png')}
                                    className="img-logo" />
                            {/*</a>*/}
                        </div>
                        <div className="mobile_trigger mr-2 " onClick={this.handleMenuOpen}>
                            <i className="fa fa-bars"></i>
                        </div>
                        {/* <!-- login for mobilescreen-> */}
                        {/* <a  className="nav-link login-btn">Log In</a> */}

                        <div className="mx-auto order-2 order-md-2 phn-name">
                            {/* <a className="avbar-brand mx-auto cm_navbar-brand" href="/">beu </a> */}
                        </div>
                        <div className="navbar-collapse collapse w-100 order-3 order-md-3 dual-collapse2">
                            <ul className="navbar-nav ml-auto">
                                <li
                                    ref={this.container}
                                    className={`nav-item dropdown ${this.state.dropdown ? 'open' : ''}`}
                                    style={{ cursor: 'pointer', minWidth: '180px' }}>
                                    <span className="nav-link dropdown-toggle" onClick={this.openDropdown.bind(this)}>
                                        <span><img className="dropdown-img"
                                            src={(user.userInfo.avatar && user.userInfo.avatar != '') ? user.userInfo.avatar : user.userInfo.userImage ? user.userInfo.userImage.circleImage && user.userInfo.userImage.circleImage != '' ? user.userInfo.userImage.circleImage : require('../../assets/images/Pixie.png') : require('../../assets/images/Pixie.png')}
                                            style={{ marginRight: '6px' }} /></span>{user.userInfo.name}
                                    </span>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item searchfield" href="/">Discover</a>
                                        <a className="dropdown-item" href="/booking">My Bookings</a>
                                        {/*<a className="dropdown-item" href="/review">Reviews</a>*/}
                                        <a className="dropdown-item" href="/myaccount">My Account</a>
                                        {user.userInfo.hairdresser ?
                                            (<a className="dropdown-item" href="#" onClick={this.switchProfile.bind(this)}>Switch To Business</a>) : ""}
                                        <a className="dropdown-item" href="/legal">Legal</a>
                                        <a className="dropdown-item" href="#"
                                            onClick={this.logout.bind(this)}>Logout</a>
                                    </div>
                                </li>
                            </ul>

                        </div>

                    </nav>
                    :
                    <nav className="navbar navbar-expand-md navbar-dark  cm-navbar-new" fixed="top">

                        <div className="logo-web ">
                            <a href="/">
                                <img src={require('../../assets/images/web-img/beu-logotext.png')}
                                    className="img-logo" /></a>
                        </div>
                        <div className="mobile_trigger mr-2 " onClick={this.handleMenuOpen}>
                            <i className="fa fa-bars"></i>
                        </div>
                        {/* <!-- login for mobilescreen-> */}
                        {/* <a  className="nav-link login-btn">Log In</a> */}
                        <div className="navbar-collapse collapse w-100 order-3 order-md-3 dual-collapse2">
                            <ul className="navbar-nav ml-auto">
                                {/*<li className="nav-item">*/}
                                {/*    <a className="nav-link login-btn" onClick={this.togglePartner}>Become a partner</a>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    {/*<a className="nav-link login-btn" onClick={this.toggleLogin}>Log In</a>*/}
                                    <a className="nav-link login-btn" onClick={this.toggleSocialLogin}>Log In</a>
                                </li>
                            </ul>
                        </div>

                    </nav>
                }

                {/* --------------------------------Social Login Popup---------------------------------------- */}
                <Modal 
                    show={this.state.applePrivateLogin?!this.state.applePrivateLogin:this.props.setting.socialLogin} 
                    onHide={this.toggleSocialLogin.bind(this)} 
                    dialogClassName="remove-margin-phone"
                >
                    {/* <Popup  open={this.state.loginPopup} closeOnDocumentClick={false} closeOnEscape={false} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            <span className="modealPrev" onHide={this.toggleSocialLogin.bind(this)}>
                                {/*<i className="fa fa-angle-left"*/}
                                {/*aria-hidden="true"></i>*/}
                            </span>
                            <button type="button" className="close" onClick={this.toggleSocialLogin.bind(this)}>&times;</button>
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '20px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            {/* <p>Almost there! login or sign up to finalise your booking--.</p> */}
                            {this.state.error ? <span className="cm_error_msg pointer_c">{this.state.error}</span> : ''}
                            <div className="btns_padding">
                                {
                                    this.state.platform == 'web' ?
                                        <FacebookLoginApp
                                            isMobile={false}
                                            appId={siteSetting.fb_appId}
                                            autoLoad={false}
                                            fields={siteSetting.fb_field}
                                            //version={siteSetting.fb_version}
                                            onClick={this.componentClicked.bind(this)}
                                            callback={this.responseFacebook.bind(this)}
                                            render={renderProps => (
                                                <button type="button" className="btn btn-default btn-block facebook-btn" onClick={renderProps.onClick}><span><img
                                                    src={require('../../assets/images/facebook white.png')} /></span>Continue with Facebook
                                                </button>
                                            )}
                                        />
                                        :
                                        <button type="button" onClick={this.FacebookLoginIosApp}  className="btn btn-default btn-block facebook-btn">
                                            <span>
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAAABHNCSVQICAgIfAhkiAAAAnBJREFUaIHtmr9rFEEUgL+X3J3n5RCJPyoRxRC1CEQQG9FCsRX/BStb/wpB/AtsBVPYiLYGBRFSaCEY1EZIIhaSTrzz9C55FrMLe+uG3HC769zt+2Cb2Tf75mN2ZmeGBcMwDMMwDMMIGvEJVtUGcApYAOqFtMifPrAJfBGRXiEZVHVRVVdU9aeGQ0dVn6nqso/LjKf7AnATmPOsVyQt4AawpKoj+/iK1wlLOuYg0AZmR63gKz41VFa89r8bkGIAbAHvgK/AD2AXaOCG2RHgInBh3EShiCvQBVaAF8Bn4DvQie7VousEcIcpEu8A94FHIrK1V5Cq1nFvwdiEIv4ceCgi22UlDGFy+w08KFMawujx98B61g1VbQNngMO4TpoHTuaRNATxNREZJAtUVXB7gnvAedysLrgFynweSUMQ/5ZR1gKu4pbHrSKShjDG+xllTWCRgqQhDPEshIK3vaGKF05lxcue3J4AG7j1d8zbjLgu8BLYSZUfBy4DZ8dtSNnij4FXwJ9E2SAdJCJdVV2NYpNcAk4zgeJ9oCciWTP5ENG3Pf19P4DboY3NpI3xNnAsjwdNmvgh4GgeDyr7Va8DzdSh4EBE0pMYqlpj+AytiZvccvm+ly1+G7jO8Kz+FHidDFLVOeBadMU0yOEAIqZs8VsZZRukxHGnpleAu0U1ZNLGeG6YeNUw8aph4lXDxKuGiVcNX/E+7lgoNHq4dv2zy9sLX/FNYBX45VmvSHq4Tc5HEdndLzjG93evJnAOWMKdhuTBGxH5kJFnmdG2oR3gE7AuIiN3iJd4omEzePxosw87WT3lkSOzvmEYhmEYhjEt/AVsPRo2K58I0gAAAABJRU5ErkJggg==" />
                                            </span>
                                            Continue with Facebook
                                        </button>
                                }

                                {this.state.platform == 'ios' &&
                                    <button className="appleButton" type="button" aria-label="Sign in with Apple" onClick={this.openAppleSignIn}>
                                        <svg width="24" height="24" viewBox="0 2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" fill="transparent"></rect><path d="M17.569 12.6254C17.597 15.652 20.2179 16.6592 20.247 16.672C20.2248 16.743 19.8282 18.1073 18.8662 19.5166C18.0345 20.735 17.1714 21.9488 15.8117 21.974C14.4756 21.9986 14.046 21.1799 12.5185 21.1799C10.9915 21.1799 10.5142 21.9489 9.2495 21.9987C7.93704 22.0485 6.93758 20.6812 6.09906 19.4673C4.38557 16.9842 3.0761 12.4508 4.83438 9.39061C5.70786 7.87092 7.26882 6.90859 8.96311 6.88391C10.2519 6.85927 11.4683 7.753 12.2562 7.753C13.0436 7.753 14.5219 6.67821 16.0759 6.83605C16.7265 6.8632 18.5527 7.09947 19.7253 8.81993C19.6309 8.87864 17.5463 10.095 17.569 12.6254ZM15.058 5.1933C15.7548 4.34789 16.2238 3.171 16.0959 2C15.0915 2.04046 13.877 2.67085 13.1566 3.5158C12.5109 4.26404 11.9455 5.46164 12.0981 6.60946C13.2176 6.69628 14.3612 6.03925 15.058 5.1933Z" fill="white"></path></svg>
                                        <span className="appleLogo">Sign in with Apple</span>
                                    </button>}
                                    {this.state.platform == 'web' &&
                                    <AppleLogin
                                        clientId={siteSetting.apple_clientId}
                                        redirectURI={siteSetting.apple_redirectURI}
                                        scope="name%20email"
                                        responseType="code id_token"
                                        responseMode="form_post"
                                        designProp={{
                                            height: 50,
                                            width: 375,
                                            border_radius: 15
                                        }}
                                    />
                                }
                                <br /><h5 className="text-center">or</h5><br />
                                <button type="button" className="btn btn-default btn-block blue_btn" onClick={this.toggleLogin}>Continue with Email</button>
                            </div>

                            <p className="cm_discript">Beu will only use your details for booking purposes. So don't
                                worry, we will not be posting on your Facebook or send you any unwanted emails without
                                your consent.</p>
                        </div>
                    </div>
                    {/* </Popup> */}
                </Modal>


                {/* --------------------------------Login Popup---------------------------------------- */}
                <Modal show={this.props.setting.login} onHide={this.toggleLogin.bind(this)} dialogClassName="remove-margin-phone">
                    {/* <Popup  open={this.props.setting.login} closeOnDocumentClick={false} closeOnEscape={false} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {/*<span className="modealPrev" onClick={this.toggleLogin.bind(this)}><i className="fa fa-angle-left" aria-hidden="true"></i></span>*/}
                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleLogin.bind(this)}>&times;</button>) : ''}
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '10px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <h3 className="text-center login_h3">Sign In</h3>

                            {this.state.error ? <span className="cm_error_msg">{this.state.error}</span> : ''}

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email..." name="email"
                                    value={this.state.email} autoComplete="off" onChange={this.onChanged} />
                                {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Password..."
                                    name="password" value={this.state.password} autoComplete="off"
                                    onChange={this.onChanged} />
                                {this.state.passwordError ?
                                    <span className="cm_error_msg">{this.state.passwordError}</span> : ''}
                            </div>

                            <div>
                                <a href="#" className="float-right" onClick={this.toggleFrogot.bind(this, 1)}>Forgot
                                    Password? </a>
                            </div>
                            <br /><br />
                            <button type="button" className="btn blue_btn btn-block btn-default"
                                onClick={this.login.bind(this)} disabled={this.state.loginButton}>Sign in
                            </button>
                            <h5 className="text-center">or</h5>
                            <button type="button" className="btn btn-default btn-block"
                                onClick={this.toggleSignup.bind(this)}>Create Account
                            </button>
                            <br />
                        </div>
                    </div>
                </Modal>
                {/* </Popup> */}



                {/* --------------------------------Create Account Popup---------------------------------------- */}
                <Modal show={this.props.setting.register} onHide={this.toggleSignup.bind(this)} dialogClassName="remove-margin-phone">
                    {/* <Popup open={this.props.setting.register} closeOnDocumentClick={false} closeOnEscape={false} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {/*<span className="modealPrev" onClick={this.closeModal}><i className="fa fa-angle-left" aria-hidden="true"></i></span>*/}

                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleSignup.bind(this)}>&times;</button>) : ''}
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '10px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <h3 className="text-center login_h3">Register</h3>

                            {this.state.error ? <span className="cm_error_msg">{this.state.error}</span> : ''}

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="First Name..."
                                    name="first_name" autoComplete="off" value={this.state.first_name}
                                    onChange={this.onChanged} />
                                {this.state.fNameError ?
                                    <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Last Name..." name="last_name"
                                    autoComplete="off" value={this.state.last_name} onChange={this.onChanged} />
                                {this.state.lNameError ?
                                    <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email..." name="email"
                                    autoComplete="off" value={this.state.email} onChange={this.onChanged} />
                                {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <PhoneInput
                                    international={true}
                                    defaultCountry="GB"
                                    country={"GB"}
                                    className="form-control"
                                    placeholder="Phone Number"
                                    name="phoneNumber"
                                    placeholder="Enter phone number"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneChange} />
                                {this.state.phoneNumberError ?
                                    <span className="cm_error_msg">{this.state.phoneNumberError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Password..."
                                    name="password" autoComplete="off" value={this.state.password}
                                    onChange={this.onChanged} />
                                {this.state.passwordError ?
                                    <span className="cm_error_msg">{this.state.passwordError}</span> : ''}
                            </div>

                            <button type="button" className="btn blue_btn btn-block btn-default"
                                onClick={this.signup.bind(this)}>
                                Sign Up
                            </button>
                            <br />
                        </div>
                    </div>
                    {/* </Popup> */}
                </Modal>

                {/*---------------------------------Create Account When apple private login-----------------------------*/}

               
                   <Modal show={this.state.applePrivateLogin} onHide={this.toggleAppleSignup.bind(this)} dialogClassName="remove-margin-phone">
                   {/* <Popup open={this.props.setting.register} closeOnDocumentClick={false} closeOnEscape={false} > */}
                   <div className="cm_modal popupscroll">
                       <div className="modal-header" style={{ borderBottom: 'none' }}>
                           {/*<span className="modealPrev" onClick={this.closeModal}><i className="fa fa-angle-left" aria-hidden="true"></i></span>*/}

                           {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleAppleSignup.bind(this)}>&times;</button>) : ''}
                       </div>
                       {/* <span className="cm_modal_close" >&times;</span> */}
                       <div className="modal-body loginPopup">
                           <div className="text-center" style={{ marginBottom: '10px' }}><img
                               src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                           <h3 className="text-center login_h3">Register</h3>

                           {this.state.error ? <span className="cm_error_msg">{this.state.error}</span> : ''}

                           <div className="form-group">
                               <input type="text" className="form-control" placeholder="First Name..."
                                   name="first_name" autoComplete="off" value={this.state.first_name}
                                   onChange={this.onChanged} />
                               {this.state.fNameError ?
                                   <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                           </div>

                           <div className="form-group">
                               <input type="text" className="form-control" placeholder="Last Name..." name="last_name"
                                   autoComplete="off" value={this.state.last_name} onChange={this.onChanged} />
                               {this.state.lNameError ?
                                   <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                           </div>

                           <div className="form-group">
                               <input type="text" className="form-control" placeholder="Email..." name="email"
                                   autoComplete="off" value={this.state.email} onChange={this.onChanged} />
                               {this.state.emailError ?
                                   <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                           </div>

                           <div className="form-group">
                               <PhoneInput
                                   international={true}
                                   defaultCountry="GB"
                                   country={"GB"}
                                   className="form-control"
                                   placeholder="Phone Number"
                                   name="phoneNumber"
                                   placeholder="Enter phone number"
                                   value={this.state.phoneNumber}
                                   onChange={this.handlePhoneChange} />
                               {this.state.phoneNumberError ?
                                   <span className="cm_error_msg">{this.state.phoneNumberError}</span> : ''}
                           </div>

                       

                           <button type="button" className="btn blue_btn btn-block btn-default"
                               onClick={this.applePrivateIdLogin.bind(this)}>
                               Sign Up
                           </button>
                           <br />
                       </div>
                   </div>
                   {/* </Popup> */}
               </Modal>

                {/* --------------------------------forgot password Popup---------------------------------------- */}
                <Modal show={this.props.setting.forgot} onHide={this.toggleFrogot.bind(this, 2)} dialogClassName="remove-margin-phone">
                    {/* <Popup open={this.props.setting.forgot} closeOnDocumentClick={false} closeOnEscape={false} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {/*<span className="modealPrev" onClick={this.toggleLogin.bind(this)}><i className="fa fa-angle-left" aria-hidden="true"></i></span>*/}

                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleFrogot.bind(this, 2)}>&times;</button>) : ''}
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '20px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <h3 className="text-center">Forgot Password</h3>
                            <br /><h5 className="text-center">It happens to the best of us...</h5>

                            {this.state.error ? <span className="cm_error_msg">{this.state.error}</span> : ''}
                            <br />
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email..." name="email"
                                    value={this.state.email} autoComplete="off" onChange={this.onChanged} />
                                {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                            </div>
                            <br />
                            <button type="button" className="btn blue_btn btn-block btn-default" onClick={this.forgot.bind(this)}>Forgot
                            </button>
                            <br />
                        </div>
                    </div>
                    {/* </Popup> */}
                </Modal>
                {/* --------------------------------Thank you Popup---------------------------------------- */}
                <Popup
                    open={this.props.setting.thanku}
                    closeOnDocumentClick={false} closeOnEscape={false}
                    className="thank-you-modall-wrps"
                >
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleThanku.bind(this, 2)}>&times;</button>) : ''}
                        </div>
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '20px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <h2 className="text-center">Thank you</h2>
                            <br />
                            <h5 className="text-center">We have sent an email to your registered email address. Click
                                the link to be redirected to reset your password.</h5>
                            <br />
                            <button type="button" className="btn blue_btn btn-block btn-default" onClick={this.toggleLogin.bind(this)}>
                                Sign in
                            </button>
                            <br />

                            <br />
                        </div>
                    </div>
                </Popup>
                {/* ----------------------------popup msz---------------------- */}

                <Popup
                    open={this.state.DeletemszPopup}
                    closeOnDocumentClick={false} onClose={this.closeModal}
                >
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.closeModal}>&times;</button>) : ''}
                        </div>
                        <div className="modal-body loginPopup">
                            <br />
                            <h5 className="text-center">Are You Sure You Want To Delete This Documents</h5>
                            <br />
                            <div  className="text-center"><button type="submit"  className="btn btn-edit btn-sm ">Yes</button>
                                <button type="submit"  className="btn btn-sm btn-edit delete">No</button>
                            </div>
                            <br />

                            <br />
                        </div>
                    </div>
                </Popup>

            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    // console.log('###########', state)
    const { hairdresser, salon, hairdresserReview, user, setting, createBooking,userBookingState } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        createBooking,
        userBookingState
    };
}

export default connect(mapStateToProps)(WebHeader);
