import React from "react"
import Skeleton from "react-loading-skeleton"
// loader
import 'react-loading-skeleton/dist/skeleton.css'
export const SalonInfoLoader = () => (
       <div className='home_main'>
        <div className='__left banner_image'>
            <Skeleton width={'100%'} height={'100%'} />
        </div>
       <div className='__right text_parent'>
         <div className='beu_logo hide-mobile'>
            <Skeleton />
         </div>
         <div className='__bottom'>
             <div className='client_logo'>
               <div className='logo hide-mobile'>
               <Skeleton />
               </div>
             </div>
             <h2 className='hide-mobile'><Skeleton /></h2>
             <p><Skeleton /></p>
           
         </div>
       </div>
     </div>
)
export const StaffLoader = () => (
<div className={`_staff`}>
    <Skeleton className="circle-img" alt="staff" />
       
      <h6><Skeleton/></h6>
       {/* <p className='hide-desktop'><Skeleton/></p> */}
</div>
)
export const ServiceLoader = () => (
    <>
            <ul className='services_tab pl-0'>
                <li > <p><Skeleton/></p></li>
            </ul>
            <div className={`_service_parent_body `} >
                    <div className='_service_parent'>
                        <h3><Skeleton/></h3>
                        <div className='_service_body'>
                        <div  className='____service '>
                            <h6><Skeleton/></h6>
                            <div className='_bottom'>
                                <p className='_time mb-0'><Skeleton/></p>
                                <p className='_price mb-0'><Skeleton/></p>
                            </div>
                            </div>
                        </div>
                  </div>
            </div>
            </>
    )
    export const SlotLoader = () => (
        <>
            <div className="__booking_slot_time mt-4">
                <div className="_time_slot">
                    <h4><Skeleton/></h4>
                    <ul className="slot_parent">
                        <li className=""><Skeleton/></li>
                        <li className=""><Skeleton/></li>
                        <li className=""><Skeleton/></li>
                    </ul>
                </div>
            </div>
        </>
        )
 {/* <div className="notify-row cursor-point">
        <div className="profile-dp">
            <td><Skeleton circle={true} height={60} width={60} /></td>

        </div>
        <div className="message">
            <div>
                <h5><b className="mr-3"><Skeleton width={100} /> </b></h5>
                <Skeleton width={300} />
            </div>
            <div className="action_group  ">
                <span className="action_group  ">
                    <Skeleton height={50} width={150} />
                </span>
            </div>
        </div>
    </div > */}
    export const NewServiceSkeletop = ()=>(
        <div class="service-category mb-3 react-draggable">
            <>
            {/* <p className="mb-0"><Skeleton height="20px" width="100%" /></p> */}
             <div class="service-items">
                 <div class="service-item react-draggable" >
                     <div class="_text">
                         <p className="d-flex mb-0"><span className="mr-4"><Skeleton height="10px" width="60px" /></span><Skeleton height="10px" width="60px" /></p>
                         <p className="mb-0"><Skeleton height="20px" width="100%" /></p>
                     </div>
                 </div>
                 <div class="service-item react-draggable" >
                     <div class="_text">
                         <p className="d-flex mb-0"><span className="mr-4"><Skeleton height="10px" width="60px" /></span><Skeleton height="10px" width="60px" /></p>
                         <p className="mb-0"><Skeleton height="20px" width="100%" /></p>
                     </div>
                 </div>
                 <div class="service-item react-draggable" >
                     <div class="_text">
                         <p className="d-flex mb-0"><span className="mr-4"><Skeleton height="10px" width="60px" /></span><Skeleton height="10px" width="60px" /></p>
                         <p className="mb-0"><Skeleton height="20px" width="100%" /></p>
                     </div>
                 </div>
             </div>
             </>
        </div>
      )

