import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BsDot } from 'react-icons/bs';
import { HiBars2 } from 'react-icons/hi2';
import { Dropdown } from 'react-bootstrap';
import { Dots } from '../SvgIcon';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useWindowSize from './useWindowSize';
import { newServicesAction } from '../../_actions';
import { useDispatch } from 'react-redux';

function ServiceItem({ item, category ,deleteService,mobileDotServicePopup}) {
  const { 
    attributes, 
    listeners, 
    setNodeRef, 
    transform, 
    transition,
    isDragging 
  } = useSortable({ 
    id: item.id,
    data: {
      type: 'item',
      category: category,
      item: item
    }
  });
  const { width } = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("widthserviceitem",item,category)
  const [openAlert,setOpenAlert]=useState(false)
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };
  const OpenAlert=()=>{
    alert("hello radha")
  }
  const listHairdresseData =(id)=>{
    // debugger
    let payload = { }
    dispatch(newServicesAction.listHairDresserData(payload))
      .then((res) => {
        console.log("listHairdresserlistHairdresser3333", res);
      })
      .catch((error) => {
        console.error("Error edit category:", error);
      });
      history.push(`/edit-services/${id}`)
  }
  return (
    // <div 
    //   ref={setNodeRef} 
    //   style={style} 
    //   className="item"
    //   data-dragging={isDragging}
    // >
    <div 
      className="service-item" 
      ref={setNodeRef} 
      style={style} 
      data-dragging={isDragging}
      onClick={width < 991 ? ()=>mobileDotServicePopup(item) :null} 
      >
        <HiBars2 className="menu-icon service-menu-icon drag-handle" 
            {...attributes} {...listeners} />
      {/* <div className="drag-handle" {...attributes} {...listeners}>
        <svg viewBox="0 0 20 20" width="12">
          <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
        </svg>
      </div> */}
       <div className="_text">
              <div className="service-header">
                {console.log("itemhsdusdhsudhs_121",item)}
                <h3>{item.name} 
                  {item?.deposit ?<span className="__doty"><BsDot /></span> :''}
                  <span>{item?.deposit? <>£{item?.deposit} deposit</>:''}</span> 
                  {item?.notAssigned == true ?<span className="__doty"><BsDot /></span> :''}
                  {item?.notAssigned == true ? <span className="_not_aasigned">Not assigned</span>:''}
                </h3>
              </div>
              <p className="_cost">
                <span>{item?.duration}m
                {item?.duration ? <span className="__doty"><BsDot /></span> :""}
                {item?.processingTime ? <>{item?.processingTime}m processing time</>:''}
                </span><b>£{item?.price ? item?.price : 0}</b></p>
                <p className="descption">{item?.description}</p>
        </div>
        {width > 991 ?
          <Dropdown className="transaction_Dropdown __service_dropdown " 
          drop="up"
            >
                <Dropdown.Toggle  id="dropdown-basic">
                  <span> <Dots/></span>
                </Dropdown.Toggle>
                <Dropdown.Menu >
                    <Dropdown.Item className="w-100" onClick={()=>listHairdresseData(item?.id)}>Edit Service
                      {/* <Link className="w-100 d-block" to={`/edit-services/${item?.id}`} >Edit Service</Link> */}
                      </Dropdown.Item>
                    <Dropdown.Item className="text-danger w-100" onClick={()=>deleteService(item?.id , item?.name ,item?.notAssigned)}>Delete Service</Dropdown.Item>
                </Dropdown.Menu>
          </Dropdown>
        :''
       }



      {/* <div className="item-content">
        <h4>{item.name}</h4>
        <p>
          <span>Duration:</span>
          <span>{item.duration} mins</span>
        </p>
        <p>
          <span>Price:</span>
          <span>${item.price}</span>
        </p>
        <p>
          <span>Deposit:</span>
          <span>${item.deposit}</span>
        </p>
        <p>{item.description}</p>
      </div> */}
    </div>
  );
}

export default ServiceItem;
