import React from 'react'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useWindowSize from '../useWindowSize';
import { useHistory } from 'react-router-dom';

function CancelSaveBtn(props) {
  const { width } = useWindowSize();
  const history = useHistory();

  const cancelBtnClick=()=>{
    window.history.back();
    // history.push('/new-services')
  }
  return (
    <div className={width>1101 ? '':'bottom_fixed_btn'}>
    {/* <Link className="btn btn-white  pl-4 pr-4" to="/new-services" > Cancel </Link> */}
    <button className="btn btn-white pl-4 pr-4"  onClick={cancelBtnClick}>Cancel</button>
    <button className={"btn btn-dark-blue ml-3 pl-4 pr-4"}
    // className={props?.type==='reminder'?"btn btn-dark-blue ml-3 pl-4 pr-4":"btn btn-dark-blue ml-3"} 
    onClick={props.onSave}
     > Save </button>
   </div>
  )
}

export default CancelSaveBtn