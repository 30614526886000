import React, { useState, useEffect } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { useHistory } from "react-router-dom";

function Preview(props) {
  const [preview, setPreview] = useState({});
  const router = useHistory();

  useEffect(() => {
    if (router?.location.state) {
      setPreview({ ...router?.location.state });
    }

  /* eslint-disable-next-line */
  }, []);

  return (
    <div className="h_n">
      <div className="body_container cm_bg_white">
        <TopHeader
          type='reminder'
          previousPageUrl={preview?.previousUrl}
          headerButtonStatus={true}
          cancelButton={true}
          cancelButtonText={"Close"}
          title={preview?.type ? "Preview " + preview?.type : "Preview"}
          {...props}
        />
        <SideNav {...props} />
        <div className="new-setting-wrapper __reminder">
          <div className="container">
            <div className="row">
              <div className="_reminder_section " style={{ height: "400px" }}>
                <div className="new-stng-cmn _preview_reminder">
                  <div className="_circle">
                    <div className="_phone">
                      <div className="_dot"></div>
                      <div className="_msz_box mt-5">
                        <p>
                        {preview?.previewText===''?"Hi Ben, your appointment at BIZ NAME with STAFF MEMBER is on SUNDAY 16th DECEMBER at"+ 14.45:preview?.previewText}
                        </p>
                        <p className="mb-0 mt-3">(No Reply)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preview;
