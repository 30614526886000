import { NotificationManager } from 'react-notifications';
import { newServicesConstants } from '../_constants';
import { newServices } from '../_services';
import { ResponseFilter } from '../config/response-handler';
import { alertActions } from './';
import { toast } from 'react-toast-notification';

export const newServicesAction = {
    createCategoryData,
    editCategoryData,
    deleteCategoryData,
    listCategoryData,
    listServicesData,
    createServicesData,
    viewServiceData,
    editServicesData,
    deleteServicesData,
    listHairDresserData,
    categoryOrderChange,
    serviceOrderChange,
    serviceShareSetting,
    serviceStatusChange
};

function createCategoryData(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.createCategory(params)
                .then(response => {
                    // console.log("response action  : ", response)
                    let { serverResponseData,serverResponseStatus } = ResponseFilter(response);
                    console.log("response action filter1  : ", serverResponseData)
                    resolve({newCategory:serverResponseData});
                    dispatch(success({newCategory:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(createCategoryRequest) { return { type: newServicesConstants.CREATE_CATEGORY_REQUEST, createCategoryRequest } }
    function success(createCategorySuccess) { return { type: newServicesConstants.CREATE_CATEGORY_SUCCESS, createCategorySuccess } }
    function failure(createCategoryError) { return { type: newServicesConstants.CREATE_CATEGORY_ERROR, createCategoryError } }
}


function editCategoryData(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.editCategory(params)
                .then(response => {
                    console.log("response edit action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    console.log("response edit action filter  : ", serverResponseData)
                    resolve({editCategory:serverResponseData});
                    dispatch(success({editCategory:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(editCategoryRequest) { return { type: newServicesConstants.EDIT_CATEGORY_REQUEST, editCategoryRequest } }
    function success(editCategorySuccess) { return { type: newServicesConstants.EDIT_CATEGORY_SUCCESS, editCategorySuccess } }
    function failure(editCategoryError) { return { type: newServicesConstants.EDIT_CATEGORY_ERROR, editCategoryError } }
}


function deleteCategoryData(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.deleteCategory(params)
                .then(response => {
                    // console.log("response action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("response action filter  : ", serverResponseData)
                    resolve({deleteCategory:serverResponseData});
                    dispatch(success({deleteCategory:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(deleteCategoryRequest) { return { type: newServicesConstants.DELETE_CATEGORY_REQUEST, deleteCategoryRequest } }
    function success(deleteCategorySuccess) { return { type: newServicesConstants.DELETE_CATEGORY_SUCCESS, deleteCategorySuccess } }
    function failure(deleteCategoryError) { return { type: newServicesConstants.DELETE_CATEGORY_ERROR, deleteCategoryError } }
}

function listCategoryData(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.listCategory(params)
                .then(response => {
                    // console.log("response action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("response action filter  : ", serverResponseData)
                    resolve({listCategory:serverResponseData?.responseData?.result});
                    dispatch(success({listCategory:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(listCategoryRequest) { return { type: newServicesConstants.LIST_CATEGORY_REQUEST, listCategoryRequest } }
    function success(listCategorySuccess) { return { type: newServicesConstants.LIST_CATEGORY_SUCCESS, listCategorySuccess } }
    function failure(listCategoryError) { return { type: newServicesConstants.LIST_CATEGORY_ERROR, listCategoryError } }
}

function listServicesData(params) {
    return function (dispatch) {
        dispatch(request());  
        return new Promise(function (resolve, reject) {
            newServices.listServices(params)
                .then(response => {
                    // console.log("response services action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    console.log("response services action filter scscscscscs : ", serverResponseData)
                    resolve({listServices:serverResponseData?.responseData?.result , shareService:serverResponseData?.responseData?.shareService});
                    dispatch(success({listServices:serverResponseData?.responseData?.result,shareService:serverResponseData?.responseData?.shareService}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(listServicesRequest) { return { type: newServicesConstants.LIST_SERVICES_REQUEST, listServicesRequest } }
    function success(listServicesSuccess) { return { type: newServicesConstants.LIST_SERVICES_SUCCESS, listServicesSuccess } }
    function failure(listServicesError) { return   { type: newServicesConstants.LIST_SERVICES_ERROR,   listServicesError } }
}

function listHairDresserData(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.listHairdresser(params)
                .then(response => {
                    console.log("response hair action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    console.log("response hair action filter  : ", serverResponseData)
                    resolve({listHairdresser:serverResponseData?.responseData?.result});
                    dispatch(success({listHairdresser:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(listHairdresserRequest) { return { type: newServicesConstants.LIST_HAIRDRESSER_REQUEST, listHairdresserRequest } }
    function success(listHairdresserSuccess) { return { type: newServicesConstants.LIST_HAIRDRESSER_SUCCESS, listHairdresserSuccess } }
    function failure(listHairdresserError)   { return   { type: newServicesConstants.LIST_HAIRDRESSER_ERROR,   listHairdresserError } }
}

function createServicesData(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.createServices(params)
                .then(response => {
                    // console.log("response action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("response action filter  : ", serverResponseData)
                    resolve({newServices:serverResponseData});
                    dispatch(success({newServices:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(createServicesRequest) { return { type: newServicesConstants.CREATE_SERVICES_REQUEST, createServicesRequest } }
    function success(createServicesSuccess) { return { type: newServicesConstants.CREATE_SERVICES_SUCCESS, createServicesSuccess } }
    function failure(createServicesError) { return   { type: newServicesConstants.CREATE_SERVICES_ERROR  , createServicesError } }
}

function viewServiceData(params) {
    return function (dispatch) {
        dispatch(request());  
        return new Promise(function (resolve, reject) {
            newServices.viewService(params)
                .then(response => {
                    // console.log("response services action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("response services action filter  : ", serverResponseData)
                    resolve({viewService:serverResponseData?.responseData?.result});
                    dispatch(success({viewService:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(viewServiceRequest) { return { type: newServicesConstants.VIEW_SERVICE_REQUEST, viewServiceRequest } }
    function success(viewServiceSuccess) { return { type: newServicesConstants.VIEW_SERVICE_SUCCESS, viewServiceSuccess } }
    function failure(viewServiceError) { return   { type: newServicesConstants.VIEW_SERVICE_ERROR,   viewServiceError } }
}


function editServicesData(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.editServices(params)
                .then(response => {
                    // console.log("response edit action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("response edit action filter  : ", serverResponseData)
                    resolve({editServices:serverResponseData});
                    dispatch(success({editServices:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(editServicesRequest) { return { type: newServicesConstants.EDIT_SERVICES_REQUEST, editServicesRequest } }
    function success(editServicesSuccess) { return { type: newServicesConstants.EDIT_SERVICES_SUCCESS, editServicesSuccess } }
    function failure(editServicesError)   { return { type: newServicesConstants.EDIT_SERVICES_ERROR  , editServicesError } }
}


function deleteServicesData(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.deleteServices(params)
                .then(response => {
                    // console.log("response action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("response action filter  : ", serverResponseData)
                    resolve({deleteServices:serverResponseData});
                    dispatch(success({deleteServices:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(deleteServicesRequest) { return { type: newServicesConstants.DELETE_SERVICES_REQUEST, deleteServicesRequest } }
    function success(deleteServicesSuccess) { return { type: newServicesConstants.DELETE_SERVICES_SUCCESS, deleteServicesSuccess } }
    function failure(deleteServicesError)   { return { type: newServicesConstants.DELETE_SERVICES_ERROR  , deleteServicesError } }
}


function categoryOrderChange(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.categoryOrderChange(params)
                .then(response => {
                    // console.log("response action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("response action filter  : ", serverResponseData)
                    resolve({categoryOrderChange:serverResponseData?.responseData?.result});
                    dispatch(success({categoryOrderChange:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(categoryOrderChangeRequest) { return { type: newServicesConstants.CATEGORY_ORDERCHANGE_REQUEST, categoryOrderChangeRequest } }
    function success(categoryOrderChangeSuccess) { return { type: newServicesConstants.CATEGORY_ORDERCHANGE_SUCCESS, categoryOrderChangeSuccess } }
    function failure(categoryOrderChangeError)   { return { type: newServicesConstants.CATEGORY_ORDERCHANGE_ERROR  , categoryOrderChangeError } }
}

function serviceOrderChange(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.serviceOrderChange(params)
                .then(response => {
                    // console.log("response action  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("response action filter  : ", serverResponseData)
                    resolve({serviceOrderChange:serverResponseData?.responseData?.result});
                    dispatch(success({serviceOrderChange:serverResponseData?.responseData?.result}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(serviceOrderChangeRequest) { return { type: newServicesConstants.SERVICE_ORDERCHANGE_REQUEST, serviceOrderChangeRequest } }
    function success(serviceOrderChangeSuccess) { return { type: newServicesConstants.SERVICE_ORDERCHANGE_SUCCESS, serviceOrderChangeSuccess } }
    function failure(serviceOrderChangeError)   { return { type: newServicesConstants.SERVICE_ORDERCHANGE_ERROR  , serviceOrderChangeError } }
}

function serviceShareSetting(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.serviceShareSetting(params)
                .then(response => {
                    console.log("response action serviceShareSetting  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    console.log("response action serviceShareSetting filter  : ", serverResponseData)
                    resolve({serviceShareSetting:serverResponseData});
                    dispatch(success({serviceShareSetting:serverResponseData}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(serviceShareSettingRequest) { return { type: newServicesConstants.SERVICE_SHARESETTING_REQUEST, serviceShareSettingRequest } }
    function success(serviceShareSettingSuccess) { return { type: newServicesConstants.SERVICE_SHARESETTING_SUCCESS, serviceShareSettingSuccess } }
    function failure(serviceShareSettingError)   { return { type: newServicesConstants.SERVICE_SHARESETTING_ERROR  , serviceShareSettingError } }
}
function serviceStatusChange(params) {
    return function (dispatch) {
        return new Promise(function (resolve, reject) {
            newServices.serviceStatusChange(params)
                .then(response => {
                    console.log("response action serviceShareSetting  : ", response)
                    let { serverResponseData } = ResponseFilter(response);
                    console.log("response action serviceShareSetting filter  : ", serverResponseData)
                    resolve({serviceStatusChange:serverResponseData});
                    dispatch(success({serviceStatusChange:serverResponseData}));
                    
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(serviceStatusChangeRequest) { return { type: newServicesConstants.SERVICE_SHARESETTING_REQUEST, serviceStatusChangeRequest } }
    function success(serviceStatusChangeSuccess) { return { type: newServicesConstants.SERVICE_SHARESETTING_SUCCESS, serviceStatusChangeSuccess } }
    function failure(serviceStatusChangeError)   { return { type: newServicesConstants.SERVICE_SHARESETTING_ERROR  , serviceStatusChangeError } }
}


