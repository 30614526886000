import React, { Component } from 'react';
import { connect } from 'react-redux';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import {hairdresserActions, paymentActions} from "../../_actions";
import siteSetting from "../../config/env";
import Select from "react-select";
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';
import {Modal} from "react-bootstrap";
import ReactCountryFlagsCurrencySelect from "react-country-flag-currency-picker";
import Switch from "react-switch";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Close, InfoIcon } from '../SvgIcon';

const options = [
    { value: '1', label: 'Employed' },
    { value: '2', label: 'Self-Employed' },
];

class EditStaff extends Component {

    constructor(props){
        super(props);

        this.state = {
            name:'',
            first_name:'',
            last_name:'',
            email:'',
            phoneNumber:'',
            jobTitle:'',
            hairdresserBookingInterval:'',
            shortBio:'',
            circleImagePrev:require('../../assets/images/user-placeholder.png'),
            circleImage:require('../../assets/images/user-placeholder.png'),
            countryId:'217',
            salonId:localStorage.getItem('salonId'),
            employmentTypeId:1,
            employmentType:{ value: '1', label: 'Employed' },
            removeStaffMember:false,
            currencyCountry:"GB",
            currencySymbol:"£",
            currency:"GBP",
            salonCommissionRateStatus:false,
            productCommissionRateStatus:false,
            salonCommissionRate:'',
            productCommissionRate : "",
            totalPayment:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            totalPaymentBefore:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            planPrice:siteSetting.stripe.salonMonthlyPrice,
            planType:"month",
            subscriptionInfo:{},
            oneSelfEmpPrice:siteSetting.stripe.oneSelfEmpPrice,
            oneSelfEmpPriceBefore:siteSetting.stripe.oneSelfEmpPrice,
            quantity:0,
            commissionPopup: false,
            // productTypeId:1,
        }
        this.editStaff=this.editStaff.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.onDataChange=this.onDataChange.bind(this);
        this.validateEmail=this.validateEmail.bind(this);
        this.getPhoneNumber=this.getPhoneNumber.bind(this);
        this.removeStaff=this.removeStaff.bind(this);
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            self.subscriptionGet();
            let params={
                hairdresserId:this.props.match.params.id,
                lastName:'',
                email:'',
                jobTitle:'',
                phoneNumber:''
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
                .then(function (response) {
                    console.log("response23232",response.data.data);
                    if(response.data.data) {
                        if(response.data.data.userInfo.hairdresser.employmentTypeId===1){
                            employmentType={ value: '1', label: 'Employed' }
                        }else{
                            employmentType={ value: '2', label: 'Self-Employed' }
                        }
                        let phoneNumber=response.data.data.userInfo.phoneNumber?self.getPhoneNumber(response.data.data.userInfo.phoneNumber):"";
                        //console.log("phoneNumber",phoneNumber);
                        self.setState(
                            {
                                employmentTypeId:response.data.data.userInfo.hairdresser.employmentTypeId,
                                employmentType:employmentType,
                                first_name: response.data.data.userInfo.first_name&&response.data.data.userInfo.first_name!==""?response.data.data.userInfo.first_name:response.data.data.userInfo.name,
                                last_name:response.data.data.userInfo.last_name,
                                email: response.data.data.userInfo.email,
                                phoneNumber: phoneNumber,
                                jobTitle: response.data.data.userInfo.hairdresser.jobTitle,
                                hairdresserBookingInterval:response.data.data.userInfo.hairdresser.hairdresserBookingInterval,
                                shortBio: response.data.data.userInfo.hairdresser.shortBio,
                                circleImagePrev:response.data.data.userInfo.userImage.circleImage?response.data.data.userInfo.userImage.circleImage:require('../../assets/images/user-placeholder.png'),
                                currency:response.data.data.userInfo.hairdresser.currency?response.data.data.userInfo.hairdresser.currency:"",
                                currencySymbol:response.data.data.userInfo.hairdresser.currencySymbol?response.data.data.userInfo.hairdresser.currencySymbol:"£",
                                currencyCountry:response.data.data.userInfo.hairdresser.currencyCountry?response.data.data.userInfo.hairdresser.currencyCountry:"GB",
                                salonCommissionRate:response.data.data.userInfo.hairdresser.salonCommissionRate?response.data.data.userInfo.hairdresser.salonCommissionRate:"",
                                productCommissionRate:response.data.data.userInfo.hairdresser.productCommissionRate?response.data.data.userInfo.hairdresser.productCommissionRate:"",
                                salonCommissionRateStatus:response.data.data.userInfo.hairdresser.salonCommissionRate?response.data.data.userInfo.hairdresser.salonCommissionRate>0?true:false:false,
                                productCommissionRateStatus: response.data.data.userInfo.hairdresser.productCommissionRate?response.data.data.userInfo.hairdresser.productCommissionRate>0?true:false:false,
                            }
                        )
                        let currencyCountry=response.data.data.userInfo.hairdresser.currencyCountry?response.data.data.userInfo.hairdresser.currencyCountry:"GB"
                        self.refs.userCurrency.updateSelected(currencyCountry)

                    }
                })
        }else{
            this.props.history.push('/')
        }
    }

    subscriptionGet=()=>{
        let self=this;
        let data={

        }
        self.props.dispatch(paymentActions.subscriptionGet(data))
            .then(function (response) {
                console.log("response",response);
                if (response.data.statusCode===1) {
                    let subscriptionInfo=response.data.responseData.result
                    if(subscriptionInfo?.plan?.interval==="year"){
                        self.setState({
                            subscriptionInfo: subscriptionInfo,
                            planPrice:siteSetting.stripe.salonYearlyPrice,
                            totalPayment:siteSetting.stripe.salonYearlyPrice,
                            totalPaymentBefore:siteSetting.stripe.salonYearlyPrice,
                            planType:"year",
                            quantity:response.data.responseData?.selfEmpPlan?.quantity?response.data.responseData?.selfEmpPlan?.quantity:0
                        })
                    }else{
                        self.setState({
                            subscriptionInfo: subscriptionInfo,
                            quantity:response.data.responseData?.selfEmpPlan?.quantity?response.data.responseData?.selfEmpPlan?.quantity:0
                        })
                    }

                    // self.props.history.push('/staff')
                }else{
                    if (response.data.error.errorCode===3) {
                        self.setState({
                            emailError: response.data.error.responseMessage
                        })
                    }else{
                        self.setState({
                            emailError: response.data.error.responseMessage
                        })
                    }
                }
            })
    }

    editStaff(){
        this.setState({
            error:'',
            emailError:'',
            lNameError:'',
            fNameError:'',
            phoneNumberError:'',
            jobError:'',
            hairdresserBookingIntervalError:''
        })
        let self = this;
        let params = {};
        params.name=this.state.first_name+" "+this.state.last_name;
        params.first_name=this.state.first_name;
        params.last_name=this.state.last_name;
        params.email=this.state.email;
        params.phoneNumber=this.state.phoneNumber;
        params.jobTitle=this.state.jobTitle;
        params.hairdresserBookingInterval= this.state.hairdresserBookingInterval;
        params.shortBio=this.state.shortBio;
        params.employmentTypeId=this.state.employmentTypeId;
        params.salonId=this.state.salonId;
        params.countryId=this.state.countryId;

        if (this?.state?.first_name === "" || this?.state?.first_name === undefined) {
            this.setState({
                fNameError: self?.state.first_name!==''?'':'Please enter first name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: self?.state.email!==''?'':"Please enter email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state?.phoneNumber === "" || this.state.phoneNumber === undefined ) { //!isValidPhoneNumber(this.state.phoneNumber)
            this.setState({
                phoneNumberError: self?.state.phoneNumberError!==''?'':'Please enter a valid phone number'
            })
        } else if (this.state?.jobTitle === "" || this.state?.jobTitle === undefined) {
            this.setState({
                jobError: self?.state?.jobError!==''?'':'Please enter a job title'
            })
        }else if (this.state.hairdresserBookingInterval === "" || this.state.hairdresserBookingInterval === undefined) {
            this.setState({
                hairdresserBookingIntervalError: self?.state.hairdresserBookingIntervalError!==''?'dddddd':"Please enter Booking Intervals"
            })
        } else if (this.state?.hairdresserBookingInterval!=0 && this.state?.hairdresserBookingInterval%5!=0) {
            this.setState({
                hairdresserBookingIntervalError: 'Booking Interval Must be multiple of 5.'
            })
        } else if (this.state.salonCommissionRateStatus === true && (this.state.salonCommissionRate === '' || this.state.salonCommissionRate === null || this.state.salonCommissionRate < 0 || this.state.salonCommissionRate >100)) {
            this.setState({
                salonCommissionRateError: 'Commission should be between 1 to 100'
            })
        }else if (this.state.productCommissionRateStatus === true && (this.state.productCommissionRate === '' || this.state.productCommissionRate === null || this.state.productCommissionRate < 0 || this.state.productCommissionRate >100)) {
            this.setState({
                productCommissionRateError: 'Commission should be between 1 to 100'
            })
        }
        // else if (this.state.shortBio === "" || this.state.shortBio === undefined) {
        //     this.setState({
        //         shortBioError: 'Please enter a short bio'
        //     })
        // }
        else {
            let data = new FormData();
            data.append('userId', this.props.match.params.id);
            data.append('name', this.state.first_name+" "+this.state.last_name);
            data.append('first_name', this.state.first_name);
            data.append('last_name', this.state.last_name);
            data.append('email', this.state.email);
            data.append('phoneNumber', this.state.phoneNumber);
            data.append('jobTitle', this.state.jobTitle);
            data.append('hairdresserBookingInterval', this.state.hairdresserBookingInterval);
            data.append('shortBio', this.state.shortBio);
            data.append('employmentTypeId', this.state.employmentTypeId);
            data.append('salonId', this.state.salonId);
            data.append('countryId', this.state.countryId);
            data.append('circleImage', this.state.circleImage);
            data.append('rectangleImage', this.state.circleImage);
            data.append('currency', this.state.currency);
            data.append('currencySymbol', this.state.currencySymbol);
            data.append('currencyCountry', this.state.currencyCountry);
            data.append('salonCommissionRate', this.state.salonCommissionRate!=''?this.state.salonCommissionRate:0);
            data.append('productCommissionRate', this.state.productCommissionRate!=''?this.state.productCommissionRate:0);

            self.props.dispatch(hairdresserActions.editStaff(data))
                .then(function (response) {
                    //console.log('client response', response);

                    if (response.data.errorCode) {
                        if(response.data.errorCode===51){
                            self.setState({
                                emailError: response.data.messages
                            })
                        }else if(response.data.errorCode===57){
                            self.setState({
                                phoneNumberError: response.data.messages
                            })
                        }else{
                            self.setState({
                                error: response.data.messages
                            })
                        }

                    } else {
                        self.props.history.push('/staff')
                    }
                })
                .catch((err) => {
                    console.log("errr",err);
                    //dispatch(failure(err));

                })
        }
    }

    onChanged(e) {
        this.setState({ [e.target.name]: e.target.value, copied: false });

        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'last_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    lNameError: ''
                })
            } else {
                this.setState({
                    lNameError: '',
                })
            }
        }

        if (e.target.name === 'phoneNumber') {
            if (e.target.value.length!==10) {
                this.setState({
                    phoneNumberError: 'Please enter a valid phone number'
                })
            } else {
                this.setState({
                    phoneNumberError: '',
                })
            }
        }

        if (e.target.name === 'jobTitle') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    jobError: ''
                })
            } else {
                this.setState({
                    jobError: '',
                })
            }
        }
        if (e.target.name === 'hairdresserBookingInterval') {
            if (!e.target.value!=0 && e.target.value%5!=0) {
                this.setState({
                    hairdresserBookingIntervalError: 'Booking Interval Must be multiple of 5.'
                })
            } else {
                this.setState({
                    hairdresserBookingIntervalError: ''
                })
            }
        }
        // if (e.target.name === 'shortBio') {
        //     if (e.target.value === '' || e.target.value === null||e.target.value.length<10) {
        //         this.setState({
        //             shortBioError: 'The shortBio must be at least 10 characters.'
        //         })
        //     } else {
        //         this.setState({
        //             shortBioError: '',
        //         })
        //     }
        // }

        if (e.target.name === 'salonCommissionRate'){
            if (e.target.value === '' || e.target.value === null || e.target.value < 0 || e.target.value >100) {
                this.setState({
                    salonCommissionRateError: 'Commission should be between 1 to 100'
                })
            } else {
                this.setState({
                    salonCommissionRateError: '',
                })
            }
        }

        if (e.target.name === 'productCommissionRate'){
            if (e.target.value === '' || e.target.value === null || e.target.value < 0 || e.target.value >100) {
                this.setState({
                    productCommissionRateError: 'Commission should be between 1 to 100'
                })
            } else {
                this.setState({
                    productCommissionRateError: '',
                })
            }
        }

        if (e.target.name === 'circleImage') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    circleImagePrev: reader.result
                });
            }
            reader.readAsDataURL(file)


            this.setState({
                circleImagePrev: file
            })
            this.setState({
                circleImage: e.target.files[0]
            });
        }
    }

    onDataChange(value, action){
        this.setState({
            [action.name]: value,
            employmentTypeId:value.value
        })
    }

    validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }

    cancel(){
        this.props.history.push('/staff')
    }

    handlePhoneChange = val => {
        this.setState({phoneNumber: val})
        // if(!isValidPhoneNumber(val)) {
        //     this.setState({
        //         phoneNumberError: 'Please enter a valid phone number'
        //     })
        // }
    }

    getPhoneNumber(phoneNumber){
        let phone=phoneNumber.toString()
        //console.log("phone",phone);
        if(phone.length===10){
            //console.log("phone1",phone);
            if (phone[0] === 0||phone[0] === "0") {
                //console.log("phone1.1",phone);
                return "+44"+phone.substr(1)
            }else{
                //console.log("phone1.2",phone);
                return "+44"+phone
            }
        }else {
            //console.log("phone2",phone);
            if (phone[0] === 0||phone[0] === "0") {
                //console.log("phone2.1",phone);
                return "+44"+phone.substr(1)
            } else if (phone[0] === 4||phone[0] === "4") {
                //console.log("phone2.2",phone);
                return "+" + phone
            } else {
                //console.log("phone2.3",phone);
                return "+44"+phone
            }
        }
    }

    removeStaff(){
        let self=this;
        let params={
            employeeId:this.props.match.params.id,
            hairdresserId:localStorage.getItem('userId'),
            salonLeaveDate:''
        }
        self.props.dispatch(hairdresserActions.removeStaff(params))
            .then(function (removeStaff) {
                //console.log('removeStaff',removeStaff);
                self.props.history.push('/staff')
            })
    }

    handleClose = () => this.setState({
        removeStaffMember: false,
    })

    handleRemoveStaffMember=()=>{
        this.setState({
            removeStaffMember:true
        })
    }

    onSelectCurrency=(country)=>{
        //console.log("country",country);
        this.setState({
            currency:country.currency,
            currencySymbol:country.symbol,
            currencyCountry:country.countryCode,
        })
    }

    onChangeSalonCommissionRate = (checked) => {
        console.log(`switch to ${checked}`);
        this.setState({
            salonCommissionRateStatus:checked
        })
        if(!checked){
            this.setState({
                salonCommissionRate:''
            })
        }
    };

    onChangeProductCommissionRate = (checked) => {
        console.log(`switch to ${checked}`);
        this.setState({
            productCommissionRateStatus:checked
        })
        if(!checked){
            this.setState({
                productCommissionRate:''
            })
        }
    };

    closeCommissionPopup = () => {
        this.setState({ commissionPopup: false });
      };

      openCommissionPopup = ()=>{
        let self = this;
        self.setState({
            commissionPopup: true
        })
      }
      showTooltip=()=>{
        this.setState({
            showTooltip:true
        })
     }
     hideTooltip=()=>{
        this.setState({
            showTooltip:false
        })
     }
    render() {
        let {
            removeStaffMember
        } = this.state;
        //console.log("this.prop",this.state,this.state.employmentTypeId!=1);
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white staff_page">

                <TopHeader title="Edit Staff" {...this.props}/>
                <SideNav {...this.props}/>


                <div className="container">

                        <div className="add_staff_form">
                            <div className="row">

                            <div className="col-md-6 form-group">
                                    <div className="file_upload">
                                        <img src={this.state.circleImagePrev}  alt="staff" onClick={(e) => this.circleImage.click()} />
                                        <input type="file" ref={(ref) => this.circleImage = ref} name="circleImage" id="circleImage" onChange={this.onChanged} />
                                        <i className="fa fa-plus" />
                                    </div>
                                    
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Currency</label>
                                    <ReactCountryFlagsCurrencySelect className="form-control CurrencySelect"
                                                                     defaultCountry={this.state.currencyCountry}
                                                                     showOptionLabel={false}
                                                                     showCurrency={true}
                                                                     showCurrencySymbol={true}
                                                                     showSelectedLabel={false}
                                                                     onSelect={this.onSelectCurrency}
                                                                     ref="userCurrency"
                                    />
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" value={this.state.first_name} name="first_name" onChange={this.onChanged}/>
                                    {this.state.fNameError ?
                                        <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Surname</label>
                                    <input type="text" className="form-control" value={this.state.last_name}  name="last_name" onChange={this.onChanged}/>
                                    {this.state.lNameError ?
                                        <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" value={this.state.email} name="email" onChange={this.onChanged}/>
                                    {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group cm_PhoneInput">
                                    <label>Mobile Number</label>
                                    {/*<input type="number" className="form-control" value={this.state.phoneNumber} name="phoneNumber" onChange={this.onChanged}/>*/}
                                    <PhoneInput
                                        international={true}
                                        defaultCountry="GB"
                                        // country={"GB"}
                                        className="form-control"
                                        placeholder="Phone Number"
                                        name="phoneNumber"
                                        value={this.state.phoneNumber?this.state.phoneNumber:""}
                                        onChange={this.handlePhoneChange}/>
                                    {this.state.phoneNumberError ?
                                        <span className="cm_error_msg">{this.state.phoneNumberError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Job Title (viewable when making online booking)</label>
                                    <input type="text" className="form-control" value={this.state.jobTitle} name="jobTitle" onChange={this.onChanged}/>
                                    {this.state.jobError ?
                                        <span className="cm_error_msg">{this.state.jobError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Employment Type</label>
                                    <Select
                                        options={options}
                                        classNamePrefix="custom"
                                        name="employmentType"
                                        //onChange={this.onDataChange}
                                        value={this.state.employmentType}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                  <label>Booking Interval</label>
                                    <input type="text" 
                                    className="form-control" 
                                    value={this.state?.hairdresserBookingInterval} 
                                    name="hairdresserBookingInterval" 
                                    onChange={this.onChanged}/>
                                    {this.state.hairdresserBookingIntervalError ? 
                                    <span className="cm_error_msg">{this.state.hairdresserBookingIntervalError}</span> : ''}
                                </div>

                                {/* <div className="col-md-6 form-group">
                                    <label>Currency</label>
                                    <ReactCountryFlagsCurrencySelect className="form-control CurrencySelect"
                                                                     defaultCountry={this.state.currencyCountry}
                                                                     showOptionLabel={false}
                                                                     showCurrency={true}
                                                                     showCurrencySymbol={true}
                                                                     showSelectedLabel={false}
                                                                     onSelect={this.onSelectCurrency}
                                                                     ref="userCurrency"
                                    />
                                </div> */}
                                
                                <div className="col-md-12 form-group">
                                    <label>Bio</label>
                                    <textarea class="form-control" value={this.state.shortBio} name="shortBio" onChange={this.onChanged}/>
                                    {this.state.shortBioError ?
                                        <span className="cm_error_msg">{this.state.shortBioError}</span> : ''}
                                </div>
                                {this.state.employmentTypeId!==1?
                                <div className="col-md-12 mt-3 mb-4"><h4 className=''>Commissions &nbsp; 
                                    {/* <AiOutlineInfoCircle onClick={this.openCommissionPopup} /> */}
                                    <div className='tooltip_parent  _edit_staff_tooltip'>
                                        
                                        <span className='info' onClick={() =>this.showTooltip()}><InfoIcon/></span>
                                        {this.state.showTooltip===true?
                                        <div className='tooltip-body'>
                                            <span className='close' onClick={() =>this.hideTooltip()}><Close/></span>
                                            {/* <h5>Access Settings</h5>
                                            <p>Enter the admin pin 07139 to access settings.</p> */}
                                            <h6>Commission Info</h6>
                      <p className='about_comm'><b>Service Commission</b> - this is the commission paid to the salon manager by the self-employed staff member.<br></br><br></br>  e.g. if the self-employed stylist has a commission value of 30% set, then the payment will be split at point of sale, with 30% of the service value sent to the salon manager. </p>
                      <p className='about_comm'><b>Product Commission</b> - this is the commission on the profit that will be paid to the self-employed staff member if they sell a product.<br></br> <br></br>  e.g. if the staff member has a commission value of 30% set and they sell a product, then 30% of the profit from that product will be sent to the self-employed staff members bank account.<br></br> <br></br>  The PROFIT is calculated as the RETAIL PRICE - STOCK PRICE, as set in the products info.<br></br> <br></br>  NOTE: if Product Commission is set at 100%, then all the profit goes to the self-employed staff member.</p>
                      
                                        </div>:''}
                                    </div></h4>
                                </div>
                                
                                :""}
                                {this.state.employmentTypeId!==1?
                                <div className="col-md-6 form-group">
                                    <label>Booking Commission <small className='without__b'>(paid to salon manager)</small></label>
                                    <div className="d-flex align-items-center">
                                        <p  className="text-center toggle ml-4 mr-4 mb-0">
                                            {/*<label className="switch mb-0">*/}
                                            {/*    <input type="checkbox"/>*/}
                                            {/*    <span className="slider round"></span>*/}
                                            {/*</label>*/}
                                            <Switch onColor='#007EA0' onChange={this.onChangeSalonCommissionRate} checked={this.state.salonCommissionRateStatus} />
                                        </p>
                                        <input type="number" className="form-control text-center" value={this.state.salonCommissionRate} name="salonCommissionRate" onChange={this.onChanged} disabled={!this.state.salonCommissionRateStatus} />

                                    </div>
                                    {this.state.salonCommissionRateError ?
                                        <span className="cm_error_msg">{this.state.salonCommissionRateError}</span> : ''}
                                </div>:""}
                                {this.state.employmentTypeId!==1?
                                <div className="col-md-6 form-group">
                                    <label>Product Commission <small className='without__b'>(on profits, paid to staff member)</small></label>
                                    <div className="d-flex align-items-center">
                                        <p  className="text-center toggle ml-4 mr-4 mb-0">
                                            <Switch onColor='#007EA0' onChange={this.onChangeProductCommissionRate} checked={this.state.productCommissionRateStatus} />
                                        </p>
                                        <input type="number" className="form-control text-center" value={this.state.productCommissionRate} name="productCommissionRate" onChange={this.onChanged} disabled={!this.state.productCommissionRateStatus} />

                                    </div>
                                    {this.state.productCommissionRateError ?
                                        <span className="cm_error_msg">{this.state.productCommissionRateError}</span> : ''}
                                </div>:""}
                            </div>
                        </div>

                        <div className="text-center text-md-right">
                            <button className="btn btn-pink pl-4 pr-4 mobv-blck" onClick={this.handleRemoveStaffMember} >Remove from Salon </button>
                            <button className="btn btn-outline-dark pl-4 pr-4 ml-md-3" onClick={this.cancel.bind(this)}>Cancel</button>
                            <button className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.editStaff.bind(this)}>Save</button>
                        </div>
                </div>
            </div>
                <Modal
                    //   blurClass="blur" 
                      centered
                      show={this.state.commissionPopup}
                    //   onHide={this.closeCommissionPopup}
                      size="lg"
                      className='commission_modal'
                    >
                      <Modal.Header closeButton onClick={this.closeCommissionPopup}></Modal.Header>
                      <Modal.Body>
                      <h6>Commission Info</h6>
                      <p className='about_comm'><b>Service Commission</b> - this is the commission paid to the salon manager by the self-employed staff member.<br></br><br></br>  e.g. if the self-employed stylist has a commission value of 30% set, then the payment will be split at point of sale, with 30% of the service value sent to the salon manager. </p>
                      <p className='about_comm'><b>Product Commission</b> - this is the commission on the profit that will be paid to the self-employed staff member if they sell a product.<br></br> <br></br>  e.g. if the staff member has a commission value of 30% set and they sell a product, then 30% of the profit from that product will be sent to the self-employed staff members bank account.<br></br> <br></br>  The PROFIT is calculated as the RETAIL PRICE - STOCK PRICE, as set in the products info.<br></br> <br></br>  NOTE: if Product Commission is set at 100%, then all the profit goes to the self-employed staff member.</p>
                      </Modal.Body>
                </Modal>

                <Modal size='lg' show={removeStaffMember} onHide={this.handleClose} className="bg_white_modal">
                    <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                    <Modal.Body>
                        <div className="p-1 px-md-5 pt-md-5 pb-md-2 manage-Staffs-members">
                            {console.log("erferfe", this.state.employmentType.label)}

                            <div className={`staff-mebers-img text-center mb-4 ${this.state.employmentType.label=='Employed' ? 'employedImge' : ''}`}>
                                <img src={require('../../assets/images/remove-staff-member-img.png')} className="img-fluid mb-3" />
                            </div>
                            <div className='staff-mebers-content mb-3 mb-md-4'>
                                <h3 className="mb-3">{this.state.employmentType.label=='Employed' ? 'Remove Employed Staff Member' : 'Remove Self Employed Staff Member'}</h3>
                                <p className='mb-0'>This will remove the staff member from the salon. This will cancel all of the stylists bookings and notify the clients via email.  {this.state.employmentType.label=='Employed' ? 'As the staff member was employed, your subscription fee remains unchanged.' : 'As you were paying for the self employed staff member, your subscription fee will decrease as follows:'}  </p>
                            </div>

                           {this.state.employmentType.label=='Employed' ? '' : <div className='self-staff-mebrs text-left'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='staff-memb-colmn'>
                                            <h4>Current Plan</h4>
                                            <p>{this.state.quantity>0?`Salon + ${this.state.quantity} Self Employed`:"Just Salon"}</p>
                                            <p><>£{this.state.totalPayment} a {this.state.planType}</>+£{this.state.oneSelfEmpPrice*(this.state.quantity)} a month</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='staff-memb-colmn'>
                                            <h4>New Plan</h4>
                                            <p>{this.state.quantity>1?`Salon + ${this.state.quantity-1} Self Employed`:"Just Salon"}</p>
                                            <p>{this.state.quantity>1 ?<>£{this.state.totalPayment} a {this.state.planType}+£{this.state.oneSelfEmpPrice*(this.state.quantity-1)} a month </>: <>£{this.state.totalPayment} a {this.state.planType}</>}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> }

                            <div className="stap-action mt-5 text-right">
                                <button className="btn btn-outline-dark mr-2 mr-md-3" onClick={this.handleClose}>Cancel</button>
                                <button className="btn btn-pink" onClick={this.removeStaff.bind(this)}>Remove Staff</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
        </div>
        )
    }
}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EditStaff);





