import React, {Component} from 'react';
import {connect} from "react-redux";
import {bookingActions, hairdresserActions, paymentActions} from "../../_actions";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import "react-tabs/style/react-tabs.css";
// import StarRatings from "react-star-ratings";
import dateFormat from "dateformat";
// import Popup from "reactjs-popup";
import {Modal, Spinner} from 'react-bootstrap';
import ReactWeeklyDayPicker from "react-weekly-day-picker";
// import {Addicon} from "../SvgIcon";
import siteSetting from "../../config/env";
import {bookingConstants, settingConstants} from "../../_constants";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import Lottie from 'react-lottie';
import animationData from '../../assets/animation-data/tick.json';
import moment from 'moment';
import LoaderSetting from "../settings/Loader";
import toast from "react-toast-notification";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(siteSetting.stripeKey);

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

var _ = require('lodash');
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getslotTime(time){
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min));
}

const MapWithAMarker = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
        {props.isMarkerShown && <Marker position={{lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
))

const mapUrl="https://maps.googleapis.com/maps/api/js?key="+siteSetting.google_map_key+"&libraries=places"

class Booking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'upcoming',
            bookingId:'',

            date: new Date(),
            isOpenCalender: false,
            selectedService: [],
            isBookingButton: false,
            selectedDay:new Date(),
            bookingDate:null,
            month:monthNames[new Date().getMonth()],
            year:new Date().getFullYear(),
            bookingSlots:[],
            finalBookingSlots:[],
            latitude: siteSetting.default_latitude,
            longitude:siteSetting.default_longitude,
            totalAmount:0,
            totalTime:0,
            selectedBookingSlots:'',
            selectedBookingSlotsIndex:'',
            slotInfo:{},
            hairdresserId:'',
            DeletemszPopup:false,
            cancelBookingId:'',
            selectedCurrencySymbol:'£',
            animationModal:false,
            selectedBooking:null,
            isCancelChecked:false,
            cancelCharges:0,
            isCancelLoader:false,
            confirmBtnLoader:false,
            loadingBookings:{}
        }
        this.selectDay=this.selectDay.bind(this);
        this.unselectDay=this.unselectDay.bind(this);
        this.PrevClick=this.PrevClick.bind(this);
        this.NextClick=this.NextClick.bind(this);
        this.gotoBookingPage = this.gotoBookingPage.bind(this);
        this.cancelBooking=this.cancelBooking.bind(this);
        this.rescheduleBooking=this.rescheduleBooking.bind(this);
        this.rescheduleBookingPopUp=this.rescheduleBookingPopUp.bind(this);
        this.toggleBooking = this.toggleBooking.bind(this);
        this.bookingSlotCreate=this.bookingSlotCreate.bind(this);
        // this.noLocation=this.noLocation.bind(this);
        // this.foundLocation=this.foundLocation.bind(this);
        this.checkSelectedBookingSlots=this.checkSelectedBookingSlots.bind(this);
        this.getslotTime=this.getslotTime.bind(this);
        this.selectSlot=this.selectSlot.bind(this);
        this.openSalon=this.openSalon.bind(this);
        this.closeCancelBooking=this.closeCancelBooking.bind(this);
        this.cancelBookingPopup=this.cancelBookingPopup.bind(this);
    }
  
    closeCancelBooking(){
        this.setState({
            DeletemszPopup:false,
            cancelBookingId:'',
            error:''
        })
    }

    cancelBookingPopup(bookingId,booking){
        let self=this;
        this.setState({
            cancelBookingId:bookingId,
            DeletemszPopup:true,
            selectedBooking:booking,
            cancelCharges:0,
            isCancelLoader:false,
            isCancelChecked:false,
            error:''
        })
        this.calculateCharge(booking);
    }

    getslotTime(time){
        let hr=parseInt(time/60);
        let min=time-(hr*60)
        return hr+':'+(min==0?'00':min);
    }

    openSalon(hairdresserId, salonId) {
        this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
    }

    // foundLocation(location) {
    //
    //     this.setState({
    //         currentLocation:location.coords,
    //         latitude: location.coords.latitude?location.coords.latitude:siteSetting.default_latitude,
    //         longitude:location.coords.longitude?location.coords.longitude:siteSetting.default_longitude,
    //     })
    // }
    //
    // noLocation() {
    //
    // }

    componentDidMount() {
        document.body.classList.add('login-home-temp');
        let {dispatch} = this.props;
        let self = this;
        let params = this.state.currentTab;
        if (localStorage.getItem('accessToken')) {
            dispatch(bookingActions.upcomingOrPastBooking(params))
        }
        // navigator.geolocation.getCurrentPosition(this.foundLocation,this.noLocation.bind(this))
        // setTimeout(()=>{
        //     // debugger
        //     this.checkCardStatus()
        // },1000)
        this.checkCardStatus()
        
    }

    PrevClick(e){
        //console.log('PrevClick',e);
        let date=new Date(e._d);
        this.setState({
            month:monthNames[date.getMonth()],
            year:date.getFullYear()
        },function () {
            //console.log('@@@@@@@@@@@@@');
        })
    }

    NextClick(e){
        //console.log('NextClick',e);
        let date=new Date(e._d)
        this.setState({
            month:monthNames[date.getMonth()],
            year:date.getFullYear()
        },function () {
            //console.log('$$$$$$$$$$$$$$$$$$');
        })
    }

    selectDay(e){
        console.log('selectDay',e);
        this.state.bookingDate=e[0];
        this.state.selectedDay=e[0];
        //console.log(this.state.bookingDate)
        //this.setState({bookingDate:e[0]})
        let params = {};
        let self = this;
        let sdate=new Date(e[0])
        self.setState({
            month:monthNames[sdate.getMonth()],
            year:sdate.getFullYear()
        })

        params.hairdresserId = this.state.hairdresserId;
        params.latitude = this.state.latitude
        params.longitude = this.state.longitude
        params.slotsForBookingDate=dateFormat(e[0],'yyyy-mm-dd');
        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {
                    console.log(response);
                    self.setState({
                        bookingSlots:response.data.data.bookingSlots,
                        selectedBookingSlotsIndex:'',
                        finalBookingSlots:[]
                    },function () {
                        self.bookingSlotCreate(0);
                    })
                }
            });
    }

    cancelBooking(bookingId){
        let self=this;
        if(this.state.isCancelChecked===true) {
            this.setState({
                bookingId,
                // DeletemszPopup: false,
                //animationModal: true,
                isCancelLoader:true
            })
            let params = {bookingId: bookingId};
            self.props.dispatch(bookingActions.userCancelBooking(params))
                .then(function (response) {
                    if (response.data.statusCode === 0) {
                        self.setState({
                            isCancelLoader:false,
                            error: response.data?.error?.responseMessage
                        })
                        //toast.error(response.data.error.responseMessage);
                    } else {
                        self.setState({
                            DeletemszPopup: false,
                            cancelBookingId: '',
                            error: '',
                            animationModal: true
                        },function (){
                            setTimeout(()=>{
                                this.setState({
                                    animationModal:false
                                })
                            },4000)
                        })
                        let params1 = self.state.currentTab;
                        self.props.dispatch(bookingActions.upcomingOrPastBooking(params1))
                    }
                })
        }else{
            self.setState({
                error: 'Please select check box'
            })
        }
    }

    unselectDay(e){
        console.log('unselectDay',e);
    }

    rescheduleBookingPopUp(bookingInfo){
        let self=this;
        let params={};
        params.hairdresserId = bookingInfo.hairdresserId;
        params.latitude = this.state.latitude
        params.longitude = this.state.longitude
        //params.slotsForBookingDate=dateFormat(e[0],'yyyy-mm-dd');
        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.errorCode!=0) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    //console.log('++++++++++++++',response.data.data.bookingSlots);
                    self.setState({
                        hairdresserId:bookingInfo.hairdresserId,
                        bookingId:bookingInfo._id,
                        bookingSlots:response.data.data.bookingSlots,
                        selectedBookingSlotsIndex:'',
                        finalBookingSlots:[],
                        selectedService:bookingInfo.servicesBooked,
                        error:'',
                        selectedCurrencySymbol:bookingInfo.currencySymbol&&bookingInfo.currencySymbol!==''?bookingInfo.currencySymbol:"£"
                    },function () {
                        self.bookingSlotCreate(0);
                        self.toggleBooking(1,bookingInfo);
                    })
                }
            });

    }


    rescheduleBooking(bookingId){
        let self=this;

        this.setState({
            bookingId
        })
        console.log('cancelBooking');
        let params = {
            bookingId:bookingId,
            deviceToken:'sadsadsad',
        };
        params.bookingSlot = [this.state.slotInfo.startTime, (this.state.slotInfo.startTime + this.state.totalTime)]
        params.bookingDate = dateFormat(this.state.bookingDate, 'yyyy-mm-dd')

        self.props.dispatch(bookingActions.rescheduleBooking(params))
            .then(function (response) {
                if (response.data.errorCode!=0) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    let params1 = self.state.currentTab;
                    self.props.dispatch(bookingActions.upcomingOrPastBooking(params1))
                    self.toggleBooking(2);
                }
            })
    }

    gotoBookingPage(e) {
        let {dispatch} = this.props;
        let self = this;
        let params = this.state.currentTab;

        if (e.tab === 'upcoming') {


            dispatch(bookingActions.upcomingOrPastBooking(e.tab)).then(res => {

            })
        }
        if (e.tab === 'past') {


            dispatch(bookingActions.upcomingOrPastBooking(e.tab)).then(res => {


            })

        }
    }

    bookingSlotCreate(index){

        let self=this;
        let finalBookingSlots=[];
        let pre;
        let next;
        let  tempArr = []
        let flag = false;
        let k =index;
        let arrslots=[];
        let bookingSlots=self.state.bookingSlots;
        //bookingSlots.map(function(slots, i) {
        for(let i=index;i<bookingSlots.length;i++) {
            //console.log(index,bookingSlots.length,i, self.state.totalTime, bookingSlots[i],bookingSlots[i].status);
            let check = false;
            if (bookingSlots[i].status == 0) {
                tempArr.push(bookingSlots[i])
                // console.log('tempArr', tempArr);
                if (((tempArr[tempArr.length - 1]["startTime"]) - (tempArr[0]["startTime"])) == self.state.totalTime) {

                    finalBookingSlots.push(tempArr[0])

                    for (let m = 0; m < bookingSlots.length; m++) {
                        if (tempArr[0]["startTime"] == bookingSlots[m]["startTime"]) {
                            k = m + 1;
                            flag = true;
                            break;
                        }
                    }
                    tempArr = []
                }
            } else {
                tempArr = []
            }

            if (flag == true) {
                break
            }
        }
        if (flag&&k<bookingSlots.length)
        {
            self.bookingSlotCreate(k)
        }
        //console.log('finalBookingSlots', finalBookingSlots);
        if(finalBookingSlots[0]) {

            let finalslot = this.state.finalBookingSlots;
            finalslot.unshift(finalBookingSlots[0]);
            // console.log('finalslot', finalslot);
            this.setState({
                finalBookingSlots: finalslot
            })
        }
    }

    checkSelectedBookingSlots(index){

        if (this.state.selectedBookingSlotsIndex===index) {
            return true;
        } else {
            return false;
        }
    }

    toggleBooking(value,bookingInfo) {
        //console.log('value', value, service, selection);
        let self = this;
        let totalAmount=this.state.totalAmount;
        let totalTime=this.state.totalTime;
        let selectedService = this.state.selectedService;
        //console.log('selectedService', selectedService);
        if (value === 1) {

            for(let i=0;i<bookingInfo.servicesBooked.length;i++){
                totalAmount += bookingInfo.servicesBooked[i].servicePrice;
                totalTime += bookingInfo.servicesBooked[i].serviceTime;
            }

            this.setState({
                selectedService,
                totalAmount,
                totalTime,
                selectedBookingSlotsIndex: '',
                finalBookingSlots:[]
            }, function () {
                self.bookingSlotCreate(0);
            })
        }
        if(value===2){
            this.props.dispatch({type: settingConstants.TOGGLE_BOOKING,value:2});
        }else if(value===1){
            this.props.dispatch({type: settingConstants.TOGGLE_BOOKING,value:1});
        }
    }

    selectSlot(slotInfo,index,isSelect){
        let self=this;
        this.setState({
            finalBookingSlots:[]
        },function () {
            self.bookingSlotCreate(0);
            //console.log('slotInfo,index',slotInfo,index,isSelect);
            let finalBookingSlots=self.state.finalBookingSlots;
            let totalTime=self.state.totalTime;

            if(isSelect==1) {
                let start=index+1;
                let res=_.remove(finalBookingSlots, function (o) {
                    return o.startTime<=slotInfo.startTime+totalTime && o.startTime>slotInfo.startTime
                });
                //finalBookingSlots=res;
                //console.log('finalBookingSlots',finalBookingSlots);
                self.setState({
                    selectedBookingSlotsIndex: index,
                    slotInfo: slotInfo,
                    finalBookingSlots,
                    isBookingButton:true
                })
            }else{
                self.setState({
                    selectedBookingSlotsIndex: '',
                    slotInfo: {}
                })
            }
        })
    }
    // cancelButtonSubmit(){
    //     this.setState({
    //         DeletemszPopup:false,
    //         animationModal:true
    //     })
    //     setTimeout(()=>{
    //         this.setState({
    //             animationModal:false
    //         })
    //     },4000)
    // }
    checkBoxChange=()=>{
        this.setState({
            isCancelChecked:!this.state.isCancelChecked
        })
    }

    getBookingCancelDate=(bookingDate,beforeBookingHour)=>{
        return moment(bookingDate).add(-beforeBookingHour, 'hours').format("DD MMM YYYY")
    }

    getslotTimeSec=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60);
        return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min)) + ":00";
    }

    getBookingCancelTime=(time,bookingDate,beforeBookingHour)=>{
        let todayStr = moment(bookingDate).local().format('YYYY-MM-DD');
        let finalDate=todayStr + '-' + this.getslotTimeSec(time);
        return moment(finalDate,"YYYY-MM-DD-HH:mm:ss").add(-beforeBookingHour, 'hours').format("hh:mm A")
    }

    getBookingCancelDateTime=(time,bookingDate,beforeBookingHour)=>{
        let todayStr = moment(bookingDate).local().format('YYYY-MM-DD');
        let finalDate=todayStr + '-' + this.getslotTimeSec(time);

        return moment(finalDate,"YYYY-MM-DD-HH:mm:ss").add(-beforeBookingHour, 'hours').format('YYYYMMDDHHmmss')
    }

    getBookingDateTime=(time,bookingDate)=>{
        let todayStr = moment(bookingDate).local().format('YYYY-MM-DD');
        let finalDate=todayStr + '-' + this.getslotTimeSec(time);

        return moment(finalDate,"YYYY-MM-DD-HH:mm:ss").format('dddd,DD MMM YYYY')
    }

    calculateCharge=(booking)=>{
        console.log("booking",booking);
        let cancellationPolicy1=[];
        // if(booking?.hairdresser?.hairdresser.isSalonOwner===1||(booking?.hairdresser?.hairdresser.isSalonOwner===0&&booking?.hairdresser?.hairdresser.employmentTypeId===2)) {
        //     cancellationPolicy1=booking?.hairdresser?.hairdresser?.cancellationPolicy;
        // }else{
        //     cancellationPolicy1=booking?.hairdresserCancelPolicy;
        // }
        let depositAmount=0
        if(booking.isDepositPay===1){
            depositAmount=booking.depositAmount
        }
        cancellationPolicy1=booking?.cancellationPolicy;
            if (cancellationPolicy1 && cancellationPolicy1.length > 0) {
                let cancelCharges = 0;
                let totalAmount = booking.totalAmount-depositAmount;
                let calPolicy =cancellationPolicy1;
                let cancellationPolicy = _.sortBy(calPolicy, 'beforeBookingHour');
                console.log("cancellationPolicy", cancellationPolicy);
                cancellationPolicy.map((obj) => {
                    // console.log("####1", moment().format('YYYYMMDDHHmmss'), this.getBookingCancelDateTime(booking.bookingSlot[0], booking.bookingDate, obj.beforeBookingHour))
                    if (moment().format('YYYYMMDDHHmmss') > this.getBookingCancelDateTime(booking.bookingSlot[0], booking.bookingDate, obj.beforeBookingHour)) {
                        console.log("totalAmount", totalAmount, obj.amountInPercentage);
                        cancelCharges = totalAmount * obj.amountInPercentage / 100;
                        console.log("cancelCharges", cancelCharges);
                    }
                })
                if (cancelCharges == 0) {
                    // console.log("####2", moment().format('YYYYMMDDHHmmss'), this.getBookingCancelDateTime(booking.bookingSlot[0], booking.bookingDate, 0))
                    if (moment().format('YYYYMMDDHHmmss') > this.getBookingCancelDateTime(booking.bookingSlot[0], booking.bookingDate, 0)) {
                        cancelCharges = totalAmount * cancellationPolicy[0].amountInPercentage / 100;
                    }
                }
                console.log("cancelCharges", cancelCharges);
                this.setState({
                    cancelCharges
                })
            }

    }

    getCancelPolicy=(booking)=>{
        let cancellationPolicy1=[];
        // if(booking?.hairdresser?.hairdresser.isSalonOwner===1||(booking?.hairdresser?.hairdresser.isSalonOwner===0&&booking?.hairdresser?.hairdresser.employmentTypeId===2)) {
        //     cancellationPolicy1=booking?.hairdresser?.hairdresser?.cancellationPolicy;
        // }else{
        //     cancellationPolicy1=booking?.cancellationPolicy;
        // }
        cancellationPolicy1=booking?.cancellationPolicy;
        //console.log("cancellationPolicy",cancellationPolicy1);

            if (cancellationPolicy1 && cancellationPolicy1.length > 0) {

                let calPolicy = cancellationPolicy1;
                let cancellationPolicy = _.sortBy(calPolicy, 'beforeBookingHour');
                let lastIndex = cancellationPolicy.length - 1;
                // console.log("cancellationPolicy",cancellationPolicy);

                return (<div className='_cancel_policy'>
                    <h6 className='mb-3'>Cancelation Policy</h6>

                    <p>You have
                        until <span>{this.getBookingCancelTime(booking.bookingSlot[0], booking.bookingDate, cancellationPolicy[lastIndex].beforeBookingHour)}</span> on <span>{this.getBookingCancelDate(booking.bookingDate, cancellationPolicy[lastIndex].beforeBookingHour)}</span> to
                        cancel this appointment
                        without being charged.</p>
                    {
                        cancellationPolicy.map((obj, i) => {
                            return (<p key={'cp-' + i}>Cancellations
                                after <span>{this.getBookingCancelTime(booking.bookingSlot[0], booking.bookingDate, obj.beforeBookingHour)}</span>
                                <span> on {this.getBookingCancelDate(booking.bookingDate, obj.beforeBookingHour)}</span> will
                                be charged {obj.amountInPercentage}% of the remaining cost of booking. </p>)
                        })
                    }
                </div>)
            }

    }

// <-----new changes start----->
handleCaptureCard= async(bookingId) => {
    // debugger
    let self = this;
    const stripe = await stripePromise;
    this.setState({confirmBtnLoader:true})
    this.setState(prevState => ({
        loadingBookings: {
            ...prevState.loadingBookings,
            [bookingId]: true
        }
    }));
    let paymentParam={
        // bookingId: response.data.data.bookingInfo.id,
        bookingId:bookingId,
        userConfirm:1,
    }
    if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
        // if(self.state.totalDeposit>0){
            self.setState({
                isFormLoader: true,
                isBookingButton:false
            });

            self.props.dispatch(paymentActions.bookingPayment(paymentParam))
                .then(function (response) {
                    self.setState({
                        isFormLoader: false,
                        isBookingButton:true
                    });
            
                    if (response.data.errorCode) {
                        self.setState(prevState => ({
                            loadingBookings: {
                                ...prevState.loadingBookings,
                                [bookingId]: false
                            },
                            error: response.data.messages
                        }))
                        toast.error(response.data.messages);
                    } else {

                    console.log('payment response', response);
                        let transactionId=response.data.responseData.result.transactionId;
                        //localStorage.setItem('bookingId',bookingId);
                        //localStorage.setItem('transactionId',transactionId);
                        let sessionId=response.data.responseData.result.id;
                        stripe.redirectToCheckout({
                            sessionId: sessionId,
                        }).then(function (res){
                            console.log("stripe res=",res);
                            // self.setState({
                            //     paymentType:"Paid Online"
                            // })
                            // let getparams = {
                            //     bookingId: bookingId,
                            //     getBookingsOfType: 'upcoming',
                            //     deviceToken: 'sfsadfsdfds',
                            //     error: ''
                            // }
                            // self.props.dispatch(bookingActions.getBooking(getparams))
                            //     .then(function (response) {
                            //         this.setState({
                            //             confirmBtnLoader:false
                            //         })
                            //
                            //
                            //     })
                            //self.toggleBookingConf();
                        }).catch(function (error){
                            self.setState(prevState => ({
                                loadingBookings: {
                                    ...prevState.loadingBookings,
                                    [bookingId]: false
                                }
                            }));
                            toast.error(error.messages);
                            console.log("stripe error=",error);
                        })
                    }
                })
        // }else{
        // localStorage.setItem('bookingId',bookingId);
        // //window.location.assign(`/card-setup/${self.props.match.params.salonId}/professional/${self.props.match.params.hairdresserId}/card`)
        // window.location.assign(`/salon/${self.props.match.params.salonId}/professional/${self.props.match.params.hairdresserId}/success?bid=`+bookingId);
        // }
    }
 
}

checkCardStatus = ()=>{
    // debugger
    let self = this;
    // if(this.props.match.path=="/booking?s=s&bid=sdfsdfdsfgdsgds&tid=dsgdsgdssd&session_id=cvchvjchjgh")
    const queryParams = new URLSearchParams(this.props.location.search);
    const successKey = queryParams.get('s');
    const bid = queryParams.get('bid');
    const tid = queryParams.get('tid');
    const sessionId = queryParams.get('session_id');
    let paymentData={
        // transactionId: localStorage.getItem('transactionId'),
        transactionId:tid
    }
    // let getparams = {
    //     bookingId: bid,
    //     getBookingsOfType: 'upcoming',
    //     deviceToken: 'sfsadfsdfds',
    //     error: ''
    // }
    if(queryParams?.size > 0){
        if(successKey == 's'){
            if(sessionId&&sessionId!==""){
                self.props.dispatch(paymentActions.makeCustomerPrimaryCard({bookingId:bid,sessionId:sessionId})).then(res=>{
                   console.log('check updated booking id',res);
                    toast.success("Booking have been saved successfully")
                    // self.props.dispatch(bookingActions.getBooking(getparams))
                    self.props.dispatch(bookingActions.upcomingOrPastBooking('upcoming'))
                    .then(function (response) {
                        self.props.history.push("/booking")
                        console.log("BookingDetailresponse=>",response)
                    } )
                }).catch(function (error){
                    toast.error("Bookings are not confirmed. Please try again.d")
                  })
              }else{
                 toast.error("Bookings are not confirmed. Please try again.")
                  // if(tid&&tid!=="") {
                  //     self.props.dispatch(paymentActions.bookingPaymentDane(paymentData))
                  //         .then(function (response) {
                  //             self.props.dispatch(bookingActions.getBooking(getparams))
                  //               .then(function (response) {
                  //                   console.log("BookingDetailresponse=>",response)
                  //               } )
                  //
                  //         })
                  // }else{
                    //   self.toggleBookingConf();
                  //}
              }
        }else{
            toast.error("Bookings are not confirmed. Please try again.")
            self.props.history.push("/booking")
        }

        }
   

    console.log("queryParamsqueryParams",queryParams)
}

redirectToDetailPage = (id)=>{
    // debugger
    this.props.history.push('/booking-detail/' + id)
}
// <-----new changes end----->

    render() {
        let props=this.props;
        console.log("props",props);
        console.log("state",this.state);
        const classNames = {
            // container : 'red',
            // prevWeekArrow: 'red',
            // nextWeekArrow: 'red',
            // dayBox: 'gray-holiday',
            // dayCircleContainer: 'gray-holiday',
            // dayCicle: 'gray-holiday',
            // dayCircleTodayText: 'gray-holiday',
            dayCircleUnavailable: 'rwdp-DayCircle rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center gray',
            //dayCircleUnavailableText: 'gray-holiday',
            // dayCicleSelected: 'skyblue-holiday',

        };
        return (
            <React.Fragment>

                <Tabs>
                    <TabList className="booking">
                        <h2 className="cm_bookin_deatils booking_tab row">
                            <Tab onClick={this.gotoBookingPage.bind(this, {tab: 'upcoming'})}>Upcoming</Tab>
                            <Tab onClick={this.gotoBookingPage.bind(this, {tab: 'past'})}>Past</Tab>
                        </h2>

                    </TabList>

                    <TabPanel>
                        {this.props.upcomingOrPastBooking.bookings && this.props.upcomingOrPastBooking.paginationObject.totalRecords > 0 ?

                        this.props.upcomingOrPastBooking.bookings.map((value, index) => (
                            value.salonInfo?
                            <React.Fragment key={'upcomming_'+value._id}>
                                <div className="contentBg" >
                                    <div className="booking_dateinfo ">
                                        <div className="review_star algnCc">
                        <span>
                            {/*<StarRatings*/}
                            {/*    rating={parseFloat(value.hairdresser.hairdresser.reviewStats.ratingsAverage)}*/}
                            {/*    starRatedColor="#aa9567"*/}
                            {/*    numberOfStars={5}*/}
                            {/*    name='rating'*/}
                            {/*    starDimension="25px"*/}
                            {/*    starSpacing="2px"*/}
                            {/*/>*/}
                        </span>
                                        </div>
                                        <div className="date_info booking_confirm">
                                            {/*<h4 className="text-right">{dateFormat(value.bookingDate, "dddd,dd mmm yyyy")}</h4>*/}
                                            <h4 className="text-right">{this.getBookingDateTime(value.bookingSlot[0],value.bookingDate)}</h4>
                                            <h5 className="text-right">{getslotTime(value.bookingSlot[0])} - {getslotTime(value.bookingSlot[1])}</h5>
                                        </div>
                                    </div>
                                    <div className="row p-rel">
                                        <div className="col-sm-6 pad-R0">
                                        {console.log('check salon data',value.hairdresser.hairdresser.salon?.imageUrl)}
                                            <img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer', 'max-width': '100%','min-width': '100%'}}
                                                 src={value.hairdresser.hairdresser.salon?.imageUrl ? value.hairdresser.hairdresser.salon?.imageUrl : require('../../assets/images/salonpic.png')}
                                                 className="img-fluid imgheight" />
                                        </div>
                                        <div className="team-round-img"><img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer'}}
                                                                             src={value.hairdresser.userImage.circleImage ? value.hairdresser.userImage.circleImage : require('../../assets/images/Pixie.png')}
                                                                             className="cm_team-round-img"/></div>
                                        <div className="col-sm-6 pad-L0">
                                            <div className="gmap_canvas">
                                                <a href={'http://maps.google.com/?q='+value.salonInfo.loc[1]+','+value.salonInfo.loc[0]} target="_blank">
                                                <MapWithAMarker
                                                    googleMapURL={mapUrl}
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `100%` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                    isMarkerShown={true}
                                                    lat={value.salonInfo.loc[1]}
                                                    lng={value.salonInfo.loc[0]}
                                                />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="booking_dateinfo">
                                        <div className="review_star  col-md-6 pad-0">
                                            {value.servicesBooked.map((service, index) => (
                                            <h6 key={'service_'+index}>{service.service.serviceName}, {value.currencySymbol&&value.currencySymbol!=''?value.currencySymbol:"£"} {service.servicePrice.toFixed(2)} </h6>
                                            ))}
                                            <p>{value.hairdresser.name} from {value.salonInfo.salonName}</p>
                                        </div>
                                        <div className="date_info colgray col-md-6 pad-0">
                                            <h6 className="text-right">{value.salonInfo.formattedAddress}</h6>

                                        </div>
                                    </div>
                                    <div className="booking-footer">
                                        <div>
                                            <p>{value.bookingStatusText}, {value.bookingPaymentMethod}</p>
                                        </div>
                                        <div className="btnsml-screen">
                                        {/* <button onClick={()=>this.redirectToDetailPage(value?.id)}>View</button> */}
                                          {/*<button type="submit" className="btn btn-edit btn-sm " onClick={this.rescheduleBookingPopUp.bind(this,value)}>Reschedule</button>*/}
                                          {/* stripePaymentMethodId */}
                                          {console.log("chercksdss",value?.stripePaymentMethodId,value?.hairdresserCardCapture)}
                                          {!value?.stripePaymentMethodId ?
                                          <> { value?.hairdresserCardCapture==1 && 
                                          <button className="btn btn-sm btn-edit blue_btn" 
                                          style={{color:"#fff"}} 
                                          onClick={()=>this.handleCaptureCard(value?.id)}>
                                         {this.state.loadingBookings[value?.id]  ?  <Spinner animation="border" size="sm" />: 'Confirm Booking'}</button>
                                          }
                                          </>
                                          :""
                                          
                                          }
                                            
                                            <button type="submit" className="btn btn-sm btn-edit delete" onClick={this.cancelBookingPopup.bind(this,value._id,value)}>Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <br/><br/>
                            </React.Fragment>
                            :''
                        )
                        )
                   :
                        (
                            <div className="contentBg">
                                <h3 className="text-center">No upcoming bookings</h3>
                            </div>
                        )
                    }

                    </TabPanel>

                    <TabPanel>
                        {(this.props.upcomingOrPastBooking.bookings && this.props.upcomingOrPastBooking.paginationObject.totalRecords > 0) ?


                            this.props.upcomingOrPastBooking.bookings.map((value, index) => (
                                value.salonInfo?
                                <React.Fragment key={'past_'+value._id}>
                                    <div className="contentBg" >
                                        <div className="booking_dateinfo">
                                            <div className="review_star">
                                            <span>
                                                {/*<StarRatings*/}
                                                {/*    rating={parseFloat(value.hairdresser.hairdresser.reviewStats.ratingsAverage)}*/}
                                                {/*    starRatedColor="#aa9567"*/}
                                                {/*    numberOfStars={5}*/}
                                                {/*    name='rating'*/}
                                                {/*    starDimension="25px"*/}
                                                {/*    starSpacing="2px"*/}
                                                {/*/>*/}
                                            </span>
                                            </div>
                                            <div className="date_info booking_confirm">
                                                {/*<h4 className="text-right">{dateFormat(value.bookingDate, "dddd,dd mmm yyyy")}</h4>*/}
                                                <h4 className="text-right">{this.getBookingDateTime(value.bookingSlot[0],value.bookingDate)}</h4>
                                                <h5 className="text-right">{getslotTime(value.bookingSlot[0])} - {getslotTime(value.bookingSlot[1])}</h5>
                                            </div>
                                        </div>
                                        <div className="row p-rel">
                                            <div className="col-sm-6 pad-R0">
                                                {/*<img onClick={this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id)} style={{cursor:'pointer'}}*/}
                                                {/*    src={value.hairdresser.hairdresser.salon.imageUrl ? value.hairdresser.hairdresser.salon.imageUrl : require('../../assets/images/salonpic.png')}*/}
                                                {/*    className="img-fluid imgheight" />*/}
                                                <img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer','max-width': '100%','min-width': '100%'}}
                                                     src={value.hairdresser.hairdresser.salon?.imageUrl  ? value.hairdresser.hairdresser.salon?.imageUrl : require('../../assets/images/salonpic.png')}
                                                     className="img-fluid imgheight" />
                                            </div>
                                            <div className="team-round-img"><img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer'}}
                                                src={value.hairdresser.userImage.circleImage ? value.hairdresser.userImage.circleImage : require('../../assets/images/Pixie.png')}
                                                className="cm_team-round-img"/></div>
                                            <div className="col-sm-6 pad-L0">
                                                <div className="gmap_canvas">
                                                    <a href={'http://maps.google.com/?q='+value.salonInfo.loc[1]+','+value.salonInfo.loc[0]} target="_blank">
                                                    <MapWithAMarker
                                                        googleMapURL={mapUrl}
                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                        containerElement={<div style={{ height: `100%` }} />}
                                                        mapElement={<div style={{ height: `100%` }} />}
                                                        isMarkerShown={true}
                                                        lat={value.salonInfo.loc[1]}
                                                        lng={value.salonInfo.loc[0]}
                                                    />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="booking_dateinfo" style={{alignItems: 'center'}}>
                                            <div className="review_star  col-md-6 pad-0">
                                                {value.servicesBooked.map((service, index) => (
                                                    <h6 key={'service_'+index}>{service.service.serviceName}, {value.currencySymbol&&value.currencySymbol!=''?value.currencySymbol:"£"} {service.servicePrice.toFixed(2)} </h6>
                                                ))}
                                                <p>{value.hairdresser.name} from {value.salonInfo.salonName}</p>
                                            </div>
                                            <div className="date_info colgray col-md-6 pad-0">
                                                <h6 className="text-right">{value.salonInfo.formattedAddress}</h6>

                                            </div>
                                        </div>
                                        <div className="booking-footer">
                                            <div>
                                                <p>{value.bookingStatusText}, {value.bookingPaymentMethod}</p>
                                            </div>
                                            {/*<div>*/}
                                            {/*    <button type="submit" className="btn btn-edit btn-sm ">Reschedule</button>*/}
                                            {/*    <button type="submit" className="btn btn-sm btn-edit delete">Cancel</button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>

                                    <br/><br/>
                                </React.Fragment>
                                :''
                            ))

                   :
                       (
                            <div className="contentBg">
                                <h3 className="text-center">No past bookings </h3>
                            </div>
                        )
                    }
                    </TabPanel>
                </Tabs>


                {/* --------------------------------Booking Popup---------------------------------------- */}
                
                {/*<Modal show={this.props.setting.booking}   onHide={this.toggleBooking.bind(this,2)}>*/}
                {/*    <div className="cm_modal popupscroll cm_calendar">*/}
                {/*        <div className="modal-header" style={{borderBottom: 'none'}}>*/}

                {/*            <button type="button" className="close" onClick={this.toggleBooking.bind(this,2)}>&times;</button>*/}
                {/*        </div>*/}
                {/*        <div className="modal-body">*/}
                {/*            <h5 className="month">{this.state.month} {this.state.year}</h5>*/}
                {/*            <ReactWeeklyDayPicker*/}
                {/*                daysCount={7}*/}
                {/*                classNames={classNames}*/}
                {/*                //startDay={new Date()} // First day as Date Object or 22 June 2016*/}
                {/*                multipleDaySelect={false}*/}
                {/*                selectedDays={[this.state.selectedDay]} // Selected days list*/}
                {/*                selectDay={this.selectDay.bind(this)}*/}
                {/*                //unselectDay={this.unselectDay.bind(this)}*/}
                {/*                //format={'YYYY-MM-DD'} //format of dates that handled in selectDay and unselectDay functions*/}
                {/*                firstLineFormat={'ddd'} // format for the first line of the day button*/}
                {/*                secondLineFormat={'D'} // format for the second line of the day button*/}
                {/*                //firstLineMobileFormat={'dddd'} // format for the first line of the day button mobile*/}
                {/*                //secondLineMobileFormat={'MMMM D, Y'} // format for the second line*/}
                {/*                onPrevClick={this.PrevClick.bind(this)} // called with the new startDay*/}
                {/*                onNextClick={this.NextClick.bind(this)} // called with the new startDay*/}


                {/*            />*/}

                {/*            <div className="col-sm-12">*/}
                {/*                <div className="row timeslot">*/}
                {/*                    <div className="col-12 timeslot-date">*/}
                {/*                        <h3>Morning</h3>*/}
                {/*                        <ul>*/}
                {/*                            {this.state.finalBookingSlots?this.state.finalBookingSlots.map((bookingSlot, i) => (*/}
                {/*                                bookingSlot?bookingSlot.status==0?bookingSlot.startTime<720?*/}
                {/*                                    <React.Fragment key={i}>*/}
                {/*                                        <li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime)}</li>*/}
                {/*                                        {this.checkSelectedBookingSlots(i)?<li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime+this.state.totalTime)}</li>:''}*/}
                {/*                                    </React.Fragment>:'':'':''*/}
                {/*                            )):''}*/}
                {/*                        </ul>*/}
                {/*                        <h3>Afternoon</h3>*/}
                {/*                        <ul>*/}
                {/*                            {this.state.finalBookingSlots?this.state.finalBookingSlots.map((bookingSlot, i) => (*/}
                {/*                                bookingSlot?bookingSlot.status==0?(bookingSlot.startTime>=720&&bookingSlot.startTime<1020)?*/}
                {/*                                    <React.Fragment key={i}>*/}
                {/*                                        <li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime)}</li>*/}
                {/*                                        {this.checkSelectedBookingSlots(i)?<li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime+this.state.totalTime)}</li>:''}*/}
                {/*                                    </React.Fragment>:'':'':''*/}
                {/*                            )):''}*/}
                {/*                        </ul>*/}
                {/*                        <h3>Evening</h3>*/}
                {/*                        <ul>*/}
                {/*                            {this.state.finalBookingSlots?this.state.finalBookingSlots.map((bookingSlot, i) => (*/}
                {/*                                bookingSlot?bookingSlot.status==0?bookingSlot.startTime>=1020?*/}
                {/*                                    <React.Fragment key={i}>*/}
                {/*                                        <li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime)}</li>*/}
                {/*                                        {this.checkSelectedBookingSlots(i)?<li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime+this.state.totalTime)}</li>:''}*/}
                {/*                                    </React.Fragment>:'':'':''*/}
                {/*                            )):''}*/}
                {/*                        </ul>*/}
                {/*                    </div>*/}

                {/*                </div>*/}


                {/*            </div>*/}
                {/*            <table className="confirm-book">*/}
                {/*                <tbody>*/}
                {/*                {this.state.selectedService.map((services, i) => (*/}
                {/*                    <tr key={"popup_"+services._id}>*/}
                {/*                        <td>{services.service.serviceName}</td>*/}
                {/*                        <td>{services.serviceTime}m</td>*/}
                {/*                        <td>{this.state.selectedCurrencySymbol}{services.servicePrice.toFixed(2)}</td>*/}
                {/*                    </tr>*/}
                {/*                ))}*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*            /!*<h6 className="text-center  pad-14" onClick={this.toggleBookingService.bind(this,1)}><span >Add another service</span>&nbsp;&nbsp;<span className="addservices"><Addicon/> </span></h6>*!/*/}
                {/*            <div className="col-sm-12 text-center">*/}
                {/*                <span className="cm_error_msg">{this.state.error}</span>*/}
                {/*            </div>*/}
                {/*            <div className="text-center">*/}
                {/*                <button type="button" className="btn booknow-btn" disabled={this.state.isBookingButton ? false : true} onClick={this.rescheduleBooking.bind(this,this.state.bookingId)}>Reschedule Now, {this.state.selectedCurrencySymbol}{this.state.totalAmount.toFixed(2)} </button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Modal>*/}

                {/* --------------------------------Booking Popup---------------------------------------- */}

                {/* --------------------------------Cancel Booking Popup---------------------------------------- */}
                <Modal show={this.state.DeletemszPopup}   onHide={this.closeCancelBooking.bind(this)}
                className='cancel_appointment_modal_parent' dialogClassName="hide_gray_content_bg" centered>
                {/* <Popup open={this.state.DeletemszPopup} closeOnDocumentClick={false} onClose={this.closeCancelBooking} > */}
                    <div className="cm_modal ">
                    <div className="modal-header" style={{borderBottom: 'none'}}>
                           <button type="button" className="close" onClick={this.closeCancelBooking.bind(this)}>&times;</button>
                        </div>
                        <div className='cancel_appointment_modal'>
                             <h3>Cancel Appointment</h3>
                            <div className='_cancel_detail'>
                                <div className='_first'>
                                    <div className='_img'>
                                        <img src={this.state.selectedBooking?.hairdresser.userImage.circleImage ? this.state.selectedBooking?.hairdresser.userImage.circleImage : require('../../assets/images/Pixie.png')}
                                             className=""/>
                                    </div>
                                    <div className='_text'>
                                        {this.state.selectedBooking?.servicesBooked.map((service, index) => (
                                            <h5 key={'service_'+index}>{service.service.serviceName} - {this.state.selectedBooking?.currencySymbol&&this.state.selectedBooking?.currencySymbol!=''?this.state.selectedBooking?.currencySymbol:"£"} {service.servicePrice.toFixed(2)} </h5>
                                        ))}
                                        <p  className='mb-0'>with {this.state.selectedBooking?.hairdresser?.name} at {this.state.selectedBooking?.salonInfo.salonName}</p>
                                    </div>
                                </div>
                                <div className='_last'>
                                    <p  className='mb-0'>{dateFormat(this.state.selectedBooking?.bookingDate, "mmm")}</p>
                                    <h2 className='mb-0'>{dateFormat(this.state.selectedBooking?.bookingDate, "dd")}</h2>
                                    <p  className='mb-0'>{getslotTime(this.state.selectedBooking?.bookingSlot[0])}</p>
                                </div>
                            </div>
                            {this.getCancelPolicy(this.state.selectedBooking)}
                            <div className='_checkbox'>
                            <div className="check_wraper check-wrp-settings para-30 mt-5">
                                <input
                                    type="checkbox"
                                    checked={this.state.isCancelChecked}
                                    name="allow"
                                    onChange={this.checkBoxChange}
                                    // disabled={this.checkReadOnly() ? "" : "disabled"}
                                />
                                <span />Cancel booking - £{this.state.cancelCharges.toFixed(2)} Charge
                            </div>

                            </div>
                            <div className="col-sm-12 p-0 my-3">
                                <span className="cm_error_msg">{this.state.error}</span>
                            </div>

                           <div className='text-right'>
                               {this.state.isCancelLoader===true?
                               <p className={`btn btn-info blue_btn px-5 mt-4 remove-absoute' }`}>
                                   <Spinner animation="border" size="sm" />
                               </p>:
                                <button type="button" className="btn btn-inf mr-4 mb-4" onClick={()=>this.cancelBooking(this.state.selectedBooking?._id)}>Cancel

                                </button>}
                           </div>

                        </div>
                      
                    </div>
                </Modal>
                <Modal show={this.state.animationModal}   
                  className='animation_modal' centered>
                    <div className='_animation'>
                   <div>
                   <Lottie options={defaultOptions}
                                height={100}
                                width={100}
                                />
                                <h4>Booking Cancelled</h4>
                   </div>

                    </div>


                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {
        hairdresser, salon, hairdresserReview, user,
        setting, upcomingOrPastBooking,
        createBooking
    } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        createBooking,
        upcomingOrPastBooking
    };
}

export default connect(mapStateToProps)(Booking);

