import React from 'react'
import { Modal } from 'react-bootstrap'
import { BsDot } from 'react-icons/bs'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function MobileDotPopup(props) {
  const history = useHistory();
  
  // const handleBodyClick = () => {
  //   console.log('Modal body clicked, closing modal.');
  //   props?.onHide(); // Close the modal when the body is clicked
  // };
  // debugger;
  const editServiceClick =(id)=>{
    history.push(`/edit-services/${id}`)
  }
  console.log("MobileDotPopupMobileDotPopup",props)
  return (
    <Modal 
    blurClass={props?.blurClass}
    centered={props?.centered}
    show={props?.show} 
    onHide={props?.onHide} 
    backdropClassName={props?.backdropClassName}
    size={props?.size} 
    dialogClassName={props?.dialogClassName} 
    className={props?.className} >
    <Modal.Body className='__dot_popup'  onClick={props?.onHide}>
    {props?.details && <div className='detail' >
       <div className="service-items w-100">
        {props?.type=="Category" ?  <div className="_text">
          <div className="service-header">
            <h4 className='mb-0'><b>{props?.details?.name}</b> </h4>
          </div>
        </div> :
       <div className="_text w-100">
        {console.log("details12312",props?.details)}
              <div className="service-header">
                <h3 className='w-100 d-flex justify-content-between'>
                  <span><b>{props?.details?.name}</b> 
                        <span className="__doty"><BsDot /></span> 
                        {props?.details?.deposit && <>{props?.details?.deposit} deposit</>} 
                        {props?.details?.notAssigned && <span className='_notassign'>Not assigned</span>}</span><b>£40.00</b></h3>
              </div>
              <p className="_cost">
                <span><b>{props?.details?.duration}min</b><span className="__doty"><BsDot /></span>
                {props?.details?.processingTime && <>{props?.details?.processingTime}min processing time</>}
                </span>
              </p>
              <p className="descption">{props?.details?.description}</p>
              {/* <p className="descption">Balayage is a hair colouring technique where the stylist hand-paints highlights onto the hair in a sweeping motion, creating a natural, sun-kissed effect. Unlike traditional foil highlights, balayage allows for a softer, blended look with no harsh lines.</p> */}
        </div>
        }
       </div>
       </div>}
      
        {props?.type=="Add" ?
        <div className='_option'>
          <p ><Link to={props?.linkName} className="w-100 d-block">New Service</Link></p> 
          <p onClick={props?.btnClickProps}>New Category</p>
        </div>
         :
         <div className='_option'>
          {props?.type=="Category" ?
          <p onClick={props?.editOnClickPopup}>Edit {props?.type} </p> 
          :<p onClick={()=>editServiceClick(props?.details?.id)}>Edit Service
            {/* <Link className="w-100 d-block" to={`/edit-services/${props?.details?.id}`}>Edit Service</Link> */}
            </p>
          }
          <p className='red' onClick={props?.deleteOnClickPopup}>Delete {props?.type}</p>
        </div>
       }
  
       
    </Modal.Body>
</Modal>
  )
}

export default MobileDotPopup